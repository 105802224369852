import { Component, OnInit } from '@angular/core';
import { concat } from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';

@Component({
  selector: 'app-am-dashboard',
  templateUrl: './AMDashboard.component.html',
  styleUrls: ['./AMDashboard.component.css'],
})
export class AMDashboardComponent implements OnInit {
  supersList = [
    {
      name: 'Bobby',
      sageName: 'ROBERT (BOBBY) LAQUE',
      punchGuy: '15GAR04',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'Jonathan',
      sageName: 'JONATHAN SAUCEDO',
      punchGuy: '13TAY01',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'Kenneth',
      sageName: 'KENNETH RODRIGUEZ',
      punchGuy: '15SAU01',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'Joseph',
      sageName: 'JOSEPH BUSTOS',
      punchGuy: '15GON07',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'George',
      sageName: 'JORGE (GEORGE) CERVANTES',
      punchGuy: '15ROD01',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'Fernando',
      sageName: 'FERNANDO PEREZ',
      punchGuy: '15PRA01',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'Robert',
      sageName: 'ROBERT RAMIREZ',
      punchGuy: '15FAR01',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
    {
      name: 'JoeJr',
      sageName: 'JOE BUSTOS, JR.',
      punchGuy: '15GON04',
      selected: false,
      ticketsDisplayed: 0,
      tickets: [],
    },
  ];

  statusesList = [];
  statusesSelected = [];

  phasesList = [];
  phasesSelected = [];

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getSupers();
  }

  drop(event: CdkDragDrop<string[]>, newSuperContainer) {
    if (event.previousContainer === event.container) {
      // ToDo: Something
    } else {
      const jsonStringPrev = JSON.stringify(
        event.previousContainer.data[event.previousIndex]
      );
      const obj = {
        salesOrderNo: JSON.parse(jsonStringPrev).soNum,
        wtNumber: JSON.parse(jsonStringPrev).wtNum,
        wtStep: JSON.parse(jsonStringPrev).wtStepNum,
        newSuper: newSuperContainer.sageName,
        oldSuper: JSON.parse(jsonStringPrev).superName,
        reassignedBy: this.authService.decodedToken.nameid,
        superOverwrite: false,
      };
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.onReassignSuper(obj, newSuperContainer, event.currentIndex);
    }
  }

  onSuperSelected(name, superListIndex) {
    if (this.supersList[superListIndex].selected) {
      this.supersList[superListIndex].selected = false;
    } else if (name === '') {
      this.supersList.forEach(supers => {
        supers.selected = false;
      });
    } else {
      this.getSupersTickets(name, superListIndex);
      this.supersList[superListIndex].selected = true;
    }
  }

  onStatusSelected(status, statusListIndex) {
    if (this.statusesSelected.includes(status)) {
      this.statusesSelected.splice(this.statusesSelected.indexOf(status), 1);
      this.statusesList[statusListIndex].selected = false;
    } else if (status === '') {
      this.statusesSelected.length = 0;
      this.statusesList.forEach(statuses => {
        statuses.selected = false;
      });
    } else {
      this.statusesSelected.push(status);
      this.statusesList[statusListIndex].selected = true;
    }
  }

  onPhaseSelected(phase, phaseListIndex) {
    if (this.phasesSelected.includes(phase)) {
      this.phasesSelected.splice(this.phasesSelected.indexOf(phase), 1);
      this.phasesList[phaseListIndex].selected = false;
    } else if (phase === '') {
      this.phasesSelected.length = 0;
      this.phasesList.forEach(phases => {
        phases.selected = false;
      });
    } else {
      this.phasesSelected.push(phase);
      this.phasesList[phaseListIndex].selected = true;
    }
  }

  showEmptyTicket(superListIndex: number) {
    let showEmptyBox = false;
    this.supersList[superListIndex].tickets.forEach(ticket => {
      if (this.statusesSelected.includes(ticket.status)) {
        showEmptyBox = true;
      }
    });
    return showEmptyBox;
  }

  displayTicket(superListIndex: number, ticketListIndex: number) {
    let displayTicket = false;
    const status = this.supersList[superListIndex].tickets[ticketListIndex]
      .status;
    const phase = this.supersList[superListIndex].tickets[ticketListIndex]
      .phase;

    if (
      (this.statusesSelected.includes(status) ||
        this.statusesSelected.length === 0) &&
      (this.phasesSelected.includes(phase) ||
        this.phasesSelected.length === 0) &&
      (this.phasesSelected.length !== 0 || this.statusesSelected.length !== 0)
    ) {
      this.supersList[superListIndex].tickets[ticketListIndex].display = true;
      displayTicket = true;
    } else {
      this.supersList[superListIndex].tickets[ticketListIndex].display = false;
    }
    return displayTicket;
  }

  countDisplayedTicket() {
    this.supersList.forEach(name => {
      const arr = name.tickets;
      const count = arr.reduce(
        (acc: any, cur: any) => (cur.display === true ? ++acc : acc),
        0
      );
      name.ticketsDisplayed = count;
    });
  }

  sortTickets(superListIndex: number, keyToSortBy: any) {
    const arr = this.supersList[superListIndex].tickets;
    arr.sort((a: any, b: any) => {
      let ascDirection = false; // sort numbers in descending
      let ItemOne: any = a[keyToSortBy];
      let ItemTwo: any = b[keyToSortBy];

      if (typeof a[keyToSortBy] === 'string') {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = true;
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  refreshSupers() {
    let initIndex = 0;
    this.supersList.forEach(name => {
      if (name.selected) {
        this.getSupersTickets(name.name, initIndex);
      }
      initIndex++;
    });
  }

  getSupers() {
    this.supersList = [];
    this.sageApi.pullReport('ReassignSuper/SuperList?role=super').subscribe(
      (list: Array<any>) => {
        if (Array.isArray(list)) {
          list.forEach(item => {
            const superName = {
              name: item.superName,
              sageName: item.SageSuperName,
              punchGuy: item.techCode,
              selected: false,
              ticketsDisplayed: 0,
              tickets: [],
            };

            this.supersList.push(superName);
          });
        }
      },
      err => {
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  getSupersTickets(name: string, superListIndex: number) {
    this.supersList[superListIndex].tickets.length = 0;

    // Punch Phase Tickets
    this.sageApi.pullReport('SuperReport/Workday?leadman=' + name).subscribe(
      (list: Array<any>) => {
        if (Array.isArray(list)) {
          list.forEach(item => {
            const obj = {
              superName: item.AssignedLeadman,
              soNum: item.SalesOrderNo,
              wtNum: item.WTNumber,
              wtStepNum: item.WTStep,
              address: item.ShipToName,
              description: item.ItemCodeDesc,
              punchTime:
                item.UDF_PUNCH_TIME > 60
                  ? item.UDF_PUNCH_TIME / 60 + ' hrs'
                  : item.UDF_PUNCH_TIME + ' mins',
              subdivision: item.UDF_SUBDIVISION,
              phase: item.HdrParentItemCode.slice(1),
              status: item.StatusCode,
              dip: item.DIP,
              display: false,
              disabled: false,
              updated: false,
              errorMessage: 'You are an error',
            };
            this.supersList[superListIndex].tickets.push(obj);
            if (!this.statusesList.some(status => status.name === obj.status)) {
              this.statusesList.push({ name: obj.status, selected: false });
            }
            if (!this.phasesList.some(status => status.name === obj.phase)) {
              this.phasesList.push({ name: obj.phase, selected: false });
            }
          });
        } else {
          // will be a single object
        }
      },
      err => {
        console.log('error', err);
      },
      () => {
        // Pull the Punch Items
        this.sageApi.pullReport('SuperReport/Punch?leadman=' + name).subscribe(
          (list: Array<any>) => {
            if (Array.isArray(list)) {
              list.forEach(item => {
                const obj = {
                  superName: item.AssignedLeadman,
                  soNum: item.SalesOrderNo,
                  wtNum: item.WTNumber,
                  wtStepNum: item.WTStep,
                  address: item.ShipToName,
                  description: item.ItemCodeDesc,
                  punchTime:
                    item.UDF_PUNCH_TIME > 60
                      ? item.UDF_PUNCH_TIME / 60 + ' hrs'
                      : item.UDF_PUNCH_TIME + ' mins',
                  subdivision: item.UDF_SUBDIVISION,
                  phase: item.HdrParentItemCode.slice(1),
                  status: item.StatusCode,
                  dip: item.DIP,
                  display: false,
                  disabled: false,
                  updated: false,
                  errorMessage: '',
                };
                this.supersList[superListIndex].tickets.push(obj);
                if (
                  !this.statusesList.some(status => status.name === obj.status)
                ) {
                  this.statusesList.push({ name: obj.status, selected: false });
                }
                if (
                  !this.phasesList.some(status => status.name === obj.phase)
                ) {
                  this.phasesList.push({ name: obj.phase, selected: false });
                }
              });
            } else {
              // will be a single object
            }
          },
          err => {
            console.log('error', err);
          },
          () => {
            this.sortTickets(superListIndex, 'dip');
            this.sortTickets(superListIndex, 'status');
            this.sortTickets(superListIndex, 'phase');
          }
        );
      }
    );
  }

  onReassignSuper(object, newSuperContainer, newSuperContainerIndex: number) {
    newSuperContainer.tickets[newSuperContainerIndex].disabled = true;

    const observables = [];

    observables.push(this.sageApi.reassignSuper(object));

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        newSuperContainer.tickets[newSuperContainerIndex].updated = true;
        newSuperContainer.tickets[newSuperContainerIndex].errorMessage =
          err.error.Message;
        this.alertify.error('Error ' + err.error.Message);
      },
      () => {
        newSuperContainer.tickets[newSuperContainerIndex].disabled = false;
        newSuperContainer.tickets[newSuperContainerIndex].updated = true;
        newSuperContainer.tickets[newSuperContainerIndex].errorMessage = '';
        newSuperContainer.tickets[newSuperContainerIndex].superName =
          object.newSuper;
        this.alertify.success(
          'Reassigned ' +
            newSuperContainer.tickets[newSuperContainerIndex].address +
            ' To ' +
            object.newSuper
        );
      }
    );
  }
}
