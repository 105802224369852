import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../../Platform/bottom-sheet/bottom-sheet.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import { map } from 'rxjs-compat/operator/map';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';
import KitUtil from 'src/app/utils/KitUtil';
import { Context } from 'vm';
import { Observable, zip } from 'rxjs';
import KitPartPhases from '../KitPartPhases';
import kitPartPhases from '../KitPartPhases';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import BuildLocationDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BuildLocationDtoInterface';

interface AppPartsItem {
  data: ContextKitDtoInterface | ContextPartDtoInterface;
  selected: boolean;
  quantity: number;
  phase: string;
}

interface SelectableKitPart {
  data: ContextKitPartDtoInterface;
  selected: boolean;
  phase: string;
}

@Component({
  selector: 'app-kitview',
  templateUrl: './KitViewComponent.html',
  styleUrls: ['./KitViewComponent.css', '../QuotingTheme.scss'],
})
export class KitViewComponent extends KitUtil implements OnInit {
  kidGuid = this.activatedRoute.snapshot.params.kidGuid;

  isLoading: boolean | 'error' = true;
  koqappbarSaving = false;

  kit: ContextKitDtoInterface | null = null;
  totalParts = 0;
  totalCost = 0;

  subKits: SelectableKitPart[] = [];
  parts: SelectableKitPart[] = [];

  addPartsSheetOpen = false;
  addPartsLoading = false;
  addPartsTypeControl = new FormControl('Part', [Validators.required]);
  addPartsNameControl = new FormControl('');

  addPartsList: AppPartsItem[] = [];

  editPartLoading = false;
  editPartQuantityControl = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(9999),
  ]);
  editPartPhaseControl = new FormControl(kitPartPhases[0].valueOf(), [
    Validators.required,
  ]);

  kitPartPhases = KitPartPhases;

  sidepanelOpen = false;
  editPartBotSheetOpen = false;

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.getKit();
    // Add an escape listener to close the sidepanel, and if it's already closed, close the bottom sheet, and if that's already closed, unselect the selected kitparts
    document.addEventListener('keydown', e => {
      if (e.key == 'Escape') {
        if (this.sidepanelOpen) {
          this.sidepanelOpen = false;
        } else if (this.editPartBotSheetOpen) {
          this.editPartBotSheetOpen = false;
        } else {
          this.getSelectedKitParts().forEach(part => {
            part.selected = false;
          });
        }
      } else if (e.key == 'Delete' && this.getSelectedKitParts().length > 0) {
        this.destroySelectedKitParts();
      }
      if (
        e.key.toLowerCase() == 'e' &&
        this.editPartBotSheetOpen == false &&
        this.getSelectedKitParts().length == 1
      ) {
        this.openEditPartBotSheet();
      }
    });
  }

  resetSubKitsAndParts(data?: ContextKitDtoInterface) {
    if (data) {
      // init subKits
      this.subKits = this.getImmediateSubkits(data).map(subKit => {
        return {
          data: subKit,
          selected: false,
          phase: null,
        };
      });

      // init parts
      this.parts = this.getImmediateKitParts(data).map(part => {
        return {
          data: part,
          selected: false,
          phase: kitPartPhases[0].valueOf(),
        };
      });
    } else {
      this.subKits = [];
      this.parts = [];
    }
  }

  getKit() {
    this.isLoading = true;
    this.api.pullReport(`kit/${this.kidGuid}`).subscribe(
      (data: ContextKitDtoInterface) => {
        this.kit = data;
        this.isLoading = false;
        this.resetSubKitsAndParts(data);
        this.totalParts = this.getKitTotalParts(data);
        this.totalCost = this.getKitCost(this.kit);
      },
      err => {
        console.log(err);
        if (err.status == 404) {
          this.snackBar.open(
            "Can't find the Kit you selected. It may have been deleted",
            'Close',
            { duration: Infinity }
          );
        } else {
          this.snackBar.open(err.message, 'Close', { duration: Infinity });
        }
        this.isLoading = 'error';
      }
    );
  }

  putKit(kitChanges: {
    Kit_Name?: string;
    Kit_Desc?: string;
    Kit_Region?: string;
    BuildLocation_guid?: string | null;
  }) {
    const putReq = this.api.putRequest(`kit/${this.kidGuid}`, kitChanges);
    putReq.subscribe(
      (data: KitDtoInterface) => {
        this.kit.Kit = { ...this.kit.Kit, ...data };
      },
      err => {
        console.log(err);
        this.snackBar.open("Couldn't save Kit_Name changes", 'Close', {
          duration: Infinity,
        });
      }
    );
    return putReq;
  }

  kitChange(change: {
    title: string;
    region: string;
    phase: string;
    description: string;
    buildLocation: BuildLocationDtoInterface | null;
  }) {
    this.koqappbarSaving = true;
    this.putKit({
      Kit_Name: change.title,
      Kit_Region: change.region,
      Kit_Desc: change.description,
      BuildLocation_guid:
        change.buildLocation?.BuildLocation_guid ||
        this.kit.BuildLocation?.BuildLocation_guid,
    }).subscribe(() => {
      this.koqappbarSaving = false;
    });
  }

  updateKitParts() {
    this.koqappbarSaving = true;
    this.api
      .patchRequest(`update-kit-pricing/${this.kit.Kit.Kit_guid}`)
      .subscribe((updatedParts: PartDtoInterface[]) => {
        // I should just update the pricing on all the parts in the kit from updatedParts, but I am lazy. Sue me.

        this.getKit();

        this.koqappbarSaving = false;
      });
  }

  openAddPartsSheet() {
    this.addPartsSheetOpen = true;
  }

  removeUnselectedParts() {
    this.addPartsList = this.addPartsList.filter(part => {
      return part.selected;
    });
  }

  searchPartOrKit() {
    this.removeUnselectedParts();
    this.addPartsLoading = true;
    if (this.addPartsTypeControl.value == 'Part') {
      const existingPartGuids: string[] = this.addPartsList
        .filter(apli => {
          return (apli.data as any)?.Part_guid != undefined;
        })
        .map(p => {
          return ((p.data as unknown) as PartDtoInterface)?.Part_guid;
        });

      this.api
        .pullReport(`parts?Part_Code=${this.addPartsNameControl.value}`)
        .subscribe((data: ContextPartDtoInterface[]) => {
          this.addPartsLoading = false;
          this.addPartsList = [
            ...this.addPartsList,
            ...data
              .map(part => {
                return {
                  data: part,
                  selected: false,
                  quantity: 1,
                  phase: kitPartPhases[0].valueOf(),
                };
              })
              .filter(itm => {
                return !existingPartGuids.includes(itm.data.Part.Part_guid);
              }),
          ];
        });
    } else if (this.addPartsTypeControl.value == 'Kit') {
      this.api
        .pullReport(`kits?Kit_Name=${this.addPartsNameControl.value}`)
        .subscribe((data: ContextKitDtoInterface[]) => {
          this.addPartsLoading = false;
          const existingKitGuids: string[] = this.addPartsList
            .filter(apli => {
              return (apli.data as any)?.Kit != undefined;
            })
            .map(k => {
              return ((k.data as unknown) as ContextKitDtoInterface)?.Kit
                ?.Kit_guid;
            });

          this.addPartsList = [
            ...this.addPartsList,
            ...data
              .map(part => {
                return {
                  data: part,
                  selected: false,
                  quantity: 1,
                  phase: null,
                };
              })
              .filter(itm => {
                return !existingKitGuids.includes(itm.data.Kit.Kit_guid);
              }),
          ];
        });
    }
  }

  closeAddPartsSheet() {
    this.addPartsSheetOpen = false;
    this.addPartsList = [];
    this.addPartsNameControl.setValue('');
  }

  addToKit(toAdd: AddItemsOutputInterface) {
    if (!this.addPartsLoading) {
      this.addPartsLoading = true;
      this.api
        .postRequest(`add-to-kit/${this.kidGuid}`, {
          parts: toAdd.parts.map(part => {
            return {
              Part_guid: part.Part_guid,
              KitPart_Quantity: part.quantity,
              KitPart_Phase: part.phase,
            };
          }),
          kits: toAdd.kits.map(kit => {
            return {
              Kit_guid: kit.Kit_guid,
              KitPart_Quantity: kit.quantity,
            };
          }),
        })
        .subscribe(
          (data: {
            kits: ContextKitPartDtoInterface[];
            parts: ContextKitPartDtoInterface[];
          }) => {
            this.addPartsLoading = false;
            // Find all the kitparts with the same guid and replace them with the new data
            data.kits.forEach(newKitPart => {
              const existingKitPart = this.subKits.find(sp => {
                return (
                  sp.data.KitPart.KitPart_guid ==
                  newKitPart.KitPart.KitPart_guid
                );
              });
              if (existingKitPart) {
                existingKitPart.data.KitPart = newKitPart.KitPart;
                existingKitPart.data = JSON.parse(
                  JSON.stringify(existingKitPart.data)
                ); // Filthy hack to force a re-render
                // Basically, I lacked the forsight to make this whole state machine a service like in quoting, so for kits we are doomed until I have more time
              } else {
                this.kit.KitParts.push(newKitPart);
                this.subKits.push({
                  data: newKitPart,
                  selected: false,
                  phase: null,
                });
              }
            });
            data.parts.forEach(newKitPart => {
              const existingKitPart = this.parts.find(sp => {
                return (
                  sp.data.KitPart.KitPart_guid ==
                  newKitPart.KitPart.KitPart_guid
                );
              });
              if (existingKitPart) {
                existingKitPart.data.KitPart = newKitPart.KitPart;
                existingKitPart.data = { ...existingKitPart.data };
              } else {
                this.kit.KitParts.push(newKitPart);
                this.parts.push({
                  data: newKitPart,
                  selected: false,
                  phase: kitPartPhases[0].valueOf(),
                });
              }
            });
            this.parts = [...this.parts];
            this.totalParts = this.getKitTotalParts(this.kit);
            this.totalCost = this.getKitCost(this.kit);
            this.closeAddPartsSheet();
          },
          err => {
            this.addPartsLoading = false;
            console.log(err);
            this.snackBar.open(
              this.removeGuidPortionFromKitErrorMessage(err.error.Message),
              'Close',
              { duration: Infinity }
            );
          }
        );
    }
  }

  getSelectedAddPartsToKit() {
    return this.addPartsList.filter(item => item.selected);
  }

  getSelectedKitParts() {
    return [...this.subKits, ...this.parts].filter(td => {
      return td.selected;
    });
  }

  destroySelectedKitParts() {
    const toDestroy = this.getSelectedKitParts();
    const confTitle =
      toDestroy.length == 1
        ? `Really delete ${toDestroy.length} item?`
        : `Really delete ${toDestroy.length} items?`;
    const confMsg =
      toDestroy.length == 1
        ? `Are you sure you want to delete ${toDestroy.length} item?`
        : `Are you sure you want to delete ${toDestroy.length} items?`;

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { title: confTitle, message: confMsg },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        // foreach over each toDestroy and make a delete request
        const deleteReqs = toDestroy.map(td => {
          const prom = new Promise((resolve, reject) => {
            this.api
              .deleteRequest(`kitpart/${td.data.KitPart.KitPart_guid}`)
              .subscribe(
                data => {
                  resolve(td);
                  this.kit.KitParts = this.kit.KitParts.filter(kp => {
                    return (
                      kp.KitPart.KitPart_guid != td.data.KitPart.KitPart_guid
                    );
                  });
                },
                err => {
                  console.log(err);
                  this.snackBar.open(
                    'Failed to remove some parts from kit',
                    'Close',
                    { duration: Infinity }
                  );
                  reject(err);
                }
              );
          });
          return prom;
        });

        // wait for all elements in delete reqs to complete
        Promise.all(deleteReqs).then((destroyed: SelectableKitPart[]) => {
          // Go through the kit and find any KitParts with matching KitPart_guid's and remove those elements
          this.subKits = this.subKits.filter(subKit => {
            return !destroyed.some(destroyedKitPart => {
              return (
                destroyedKitPart.data.KitPart.KitPart_guid ==
                subKit.data.KitPart.KitPart_guid
              );
            });
          });
          this.parts = this.parts.filter(part => {
            return !destroyed.some(destroyedKitPart => {
              return (
                destroyedKitPart.data.KitPart.KitPart_guid ==
                part.data.KitPart.KitPart_guid
              );
            });
          });

          this.totalParts = this.getKitTotalParts(this.kit);
          this.totalCost = this.getKitCost(this.kit);
        });
      }
    });
  }

  openEditPartBotSheet() {
    this.resetEditPartBotSheetControls();
    this.editPartBotSheetOpen = true;
  }

  resetEditPartBotSheetControls() {
    const selectedKit = this.getSelectedKitParts()[0];
    this.editPartQuantityControl = new FormControl(
      selectedKit.data.KitPart.KitPart_Quantity,
      [Validators.required, Validators.min(1), Validators.max(9999)]
    );
    this.editPartPhaseControl = new FormControl(
      selectedKit.data.KitPart.KitPart_Phase,
      [Validators.required]
    );
  }

  editPartBotSheetSaveDisabled() {
    const selectedKit = this.getSelectedKitParts()[0];
    if (selectedKit.data.Kit != undefined) {
      return !(
        this.editPartQuantityControl.valid &&
        this.editPartQuantityControl.value !=
          selectedKit.data.KitPart.KitPart_Quantity
      );
    } else {
      return !(
        this.editPartQuantityControl.valid &&
        this.editPartPhaseControl.valid &&
        (this.editPartQuantityControl.value !=
          selectedKit.data.KitPart.KitPart_Quantity ||
          this.editPartPhaseControl.value !=
            selectedKit.data.KitPart.KitPart_Phase)
      );
    }
  }

  saveKitPartChanges() {
    const selectedKit = this.getSelectedKitParts()[0];
    const changes: {
      KitPart_Quantity: number;
      KitPart_Phase?: string; // Only for parts
    } = {
      KitPart_Quantity: this.editPartQuantityControl.value,
    };
    if (selectedKit.data.Kit) {
      changes.KitPart_Phase = this.editPartPhaseControl.value;
    }
    this.api
      .patchRequest(`kitpart/${selectedKit.data.KitPart.KitPart_guid}`, {
        KitPart_Quantity: this.editPartQuantityControl.value,
        KitPart_Phase: this.editPartPhaseControl.value,
      })
      .subscribe(
        (data: KitPartDtoInterface) => {
          // Update the data and close the sheet
          this.getSelectedKitParts()[0].data.KitPart = data;
          this.editPartBotSheetOpen = false;
          // unselect all selected kit parts
          this.getSelectedKitParts().forEach(part => {
            part.selected = false;
          });
        },
        error => {
          console.log(error);
          this.snackBar.open('Failed to save changes', 'Close', {
            duration: Infinity,
          });
        }
      );
  }
}
