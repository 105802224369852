import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';

import KitUtil, { getKitCost } from 'src/app/utils/KitUtil';
import { FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import GetColorFromString from 'src/app/utils/GetColorFromString';

@Component({
  selector: 'app-kitpartrow',
  templateUrl: './KitPartRowComponent.html',
  styleUrls: ['./KitPartRowComponent.css', '../QuotingTheme.scss'],
})
export class KitPartRowComponent extends KitUtil implements OnChanges {
  @Input() kitPart: ContextKitPartDtoInterface;
  @Input() selected = false;
  @Input() editable = false;
  @Input() subEditable = false;

  cost = 0;
  quantityControl: FormControl = new FormControl(
    { value: 1, disabled: !this.editable },
    [Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
  );
  phaseControl: FormControl = new FormControl(KitPartPhases[0].valueOf(), [
    Validators.required,
  ]);

  kitPartPhases = KitPartPhases;

  isHovered = false;

  expanded = false;

  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  save: EventEmitter<KitPartDtoInterface> = new EventEmitter<KitPartDtoInterface>();

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
    this.generateCost();
  }

  resetControls() {
    this.quantityControl = new FormControl(
      {
        value: this.kitPart.KitPart.KitPart_Quantity,
        disabled: !this.editable,
      },
      [Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    );
    this.phaseControl = new FormControl(this.kitPart.KitPart.KitPart_Phase, [
      Validators.required,
    ]);
  }

  generateCost() {
    if (this.kitPart.Part != null) {
      this.cost =
        this.kitPart.Part.Part.Part_Cost *
        this.kitPart.KitPart.KitPart_Quantity;
    } else if (this.kitPart.Kit != null) {
      this.cost =
        getKitCost(this.kitPart.Kit) * this.kitPart.KitPart.KitPart_Quantity;
    }
  }

  selectedChanged(newSelected: boolean) {
    this.selectedChange.emit(newSelected);
  }

  quantityControlInput(e: InputEvent) {
    // Test if e.value is a number
    if (!/^[0-9]*$/.test((e.target as HTMLInputElement).value)) {
      const newValue = (e.target as HTMLInputElement).value
        .replace(/\D/g, '')
        .slice(0, 4);
      // First, get the cursor position in the input
      const cursorPosStart = (e.target as HTMLInputElement).selectionStart - 1;
      const cursorPosEnd = (e.target as HTMLInputElement).selectionEnd - 1;

      (e.target as HTMLInputElement).value = newValue;
      this.quantityControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      (e.target as HTMLInputElement).setSelectionRange(
        cursorPosStart,
        cursorPosEnd
      );
      setTimeout(() => {
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        (e.target as HTMLInputElement).setSelectionRange(
          cursorPosStart,
          cursorPosEnd
        );
      }, 0);
    } else {
      this.quantityControl.setValue(this.quantityControl.value.slice(0, 4));
    }
  }

  saveChanges() {
    this.api
      .patchRequest(`kitpart/${this.kitPart.KitPart.KitPart_guid}`, {
        KitPart_Quantity: this.quantityControl.value,
        KitPart_Phase: this.phaseControl.value,
      })
      .subscribe(
        (data: KitPartDtoInterface) => {
          this.kitPart.KitPart = data;
          this.generateCost();
          this.save.emit(data);
        },
        error => {
          console.log(error);
          this.snackBar.open('Failed to save changes', 'Close', {
            duration: Infinity,
          });
        }
      );
  }

  cancelChanges() {
    this.resetControls();
  }

  mOver(e: MouseEvent) {
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = true;
  }

  mOut(e: MouseEvent) {
    // Needed so we dont tell the parent to add the hover class to it
    e.preventDefault();
    e.stopPropagation();
    this.isHovered = false;
  }

  getTagColor(tagName: string) {
    return GetColorFromString(tagName);
  }
}
