<div>
  <div class="controls">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [(ngModel)]="startDate" />
        <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button
      mat-button
      mat-flat-button
      color="primary"
      (click)="getInformation()"
    >
      Refresh
    </button>
  </div>

  <mat-tab-group>
    <mat-tab
      *ngFor="let up of WarehouseMap | keyvalue: originalOrder"
      [label]="warehouseName(up.key)"
    >
      <app-data-table
        [rawData]="up.value"
        [tableColumns]="[
        { col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SO#', size: 'sm'},
        { col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
        { col: 'UDF_DATE_SCHEDULED', value: normalizeDate, title: 'Date Scheduled', size: 'sm'},
        { col: 'WarehouseCode', value: 'WarehouseCode', title: 'WH', size: 'xs'},
        { col: 'BillToName', value: 'BillToName', title: 'Customer', size: 'sm'},
        { col: 'UDF_SUBDIVISION', value: 'UDF_SUBDIVISION', title: 'Subdivision', size: 'md'},
        { col: 'ShipToAddress1', value: 'ShipToAddress1', title: 'Address', size: 'md'},
        { col: 'UDF_ASSIGNED_TO_BUILD', value: 'UDF_ASSIGNED_TO_BUILD', title: 'Assigned', size: 'sm'},
        { col: 'UDF_TYPE', value: 'UDF_TYPE', title: 'Type', size: 'sm'},
        { col: 'TechName', value: 'TechName', title: 'Crew Name', size: 'md'},
        { col: 'UDF_LEADMAN', value: 'UDF_LEADMAN', title: 'Leadman', size: 'md'},
        { col: 'StatusCode', value: 'StatusCode', title: 'Status Code', size: 'xs'},
      ]"
        [inColumns]="[
        { col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SalesOrderNo' },
        { col: 'ShipToAddress1', value: 'ShipToAddress1', title: 'Address' },
      ]"
        [mselColumns]="[
      { col: 'Phase', title: 'Phase' },
      { col: 'UDF_TYPE',  title: 'Type' },
      { col: 'BillToName', title: 'Customer' },
      { col: 'StatusCode', title: 'StatusCode' },
      { col: 'UDF_SUBDIVISION', title: 'Subdivision' },
      { col: 'UDF_LEADMAN', title: 'Leadman' },
    ]"
        [drangeColumns]="[
        { col: 'UDF_DATE_SCHEDULED', value: 'UDF_DATE_SCHEDULED', title: 'Date Scheduled', range: {min: DateContrlMin, max: DateContrlMax}},
      ]"
        [isLoading]="salesOrdersLoading"
        [includeColumnSelector]="true"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Scheduled Phases for {{ warehouseName(up.key) }}"
        pdfTitle="Scheduled Phases for {{ warehouseName(up.key) }}"
        [includePdfDownload]="true"
      ></app-data-table
    ></mat-tab>
  </mat-tab-group>
</div>
