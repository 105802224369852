import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import KitUtil from 'src/app/utils/KitUtil';
import kitPartPhases from '../KitPartPhases';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import { QuoteKitPartEditableDataInterface } from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import InputIntSanitizer from 'src/app/utils/InputIntSanitizer';
import CustomQuotePartToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuotePartToAddInterface';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';

@Component({
  selector: 'app-customqpart-botsheet',
  templateUrl: './CustomQuotePartBotSheetComponent.html',
  styleUrls: ['./CustomQuotePartBotSheetComponent.css', '../QuotingTheme.scss'],
})
export class CustomQuotePartBotSheetComponent implements OnChanges {
  kitutil = new KitUtil();
  quoteutil = new QuoteUtil();

  @Input() parentQuoteOrKit:
    | QuoteDtoInterface
    | QuoteKitPartEditableDataInterface;

  @Input() open = false;

  loading = false;

  codeControl = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(30),
  ]);
  costControl = new FormControl('', [
    Validators.required,
    Validators.min(0.0001),
    Validators.max(99999999.9999),
  ]);
  quantityControl = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(9999),
  ]);
  descControl = new FormControl('', [Validators.maxLength(255)]);
  phaseControl = new FormControl('NA', [
    Validators.required,
    Validators.maxLength(30),
  ]);

  kitPartPhases = kitPartPhases;

  @Output() addQuotePart = new EventEmitter<CustomQuotePartToAddInterface>();
  @Output() sheetClosed = new EventEmitter();

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // if open changed to true, make sure you run the sheetOpened function
    if (changes?.open?.currentValue == true) {
      this.sheetOpened();
    }
    this.resetControls();
  }

  resetControls() {
    this.codeControl = new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]);
    this.costControl = new FormControl('', [
      Validators.required,
      Validators.min(0.0001),
      Validators.max(99999999.9999),
    ]);
    this.quantityControl = new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(9999),
    ]);
    this.descControl = new FormControl('', [Validators.maxLength(255)]);
    this.phaseControl = new FormControl('NA', [
      Validators.required,
      Validators.maxLength(30),
    ]);
  }

  sheetOpened() {
    this.loading = false;
  }

  closeSheet() {
    this.open = false;
    this.sheetClosed.emit();
  }

  canAdd() {
    return (
      this.codeControl.valid &&
      this.costControl.valid &&
      this.quantityControl.valid &&
      this.descControl.valid &&
      this.phaseControl.valid
    );
  }

  addCustomKit() {
    this.addQuotePart.emit({
      code: this.codeControl.value,
      cost: this.costControl.value,
      quantity: this.quantityControl.value,
      desc: this.descControl.value,
      phase: this.phaseControl.value,
    });
  }

  quantityInput(e: InputEvent) {
    const val = InputIntSanitizer(e);
    this.quantityControl.setValue(val);
  }

  costControlInput(e: InputEvent) {
    const val = InputDecimalSanitizer(e);
    this.costControl.setValue(val);
  }

  getTitle() {
    let title = '';
    if (this.parentQuoteOrKit != null) {
      if (
        'Quote_Name' in this.parentQuoteOrKit &&
        this.parentQuoteOrKit?.Quote_Name != null
      ) {
        title = this.parentQuoteOrKit.Quote_Name;
      } else if (
        'QuoteKit' in this.parentQuoteOrKit &&
        this.parentQuoteOrKit?.QuoteKit?.QuoteKit?.QuoteKit_Name != null
      ) {
        title = this.parentQuoteOrKit.QuoteKit.QuoteKit.QuoteKit_Name;
      }
    }
    return title;
  }
}
