import {Component, HostBinding, OnInit} from '@angular/core';
import { AuthService } from '../../_services/auth/auth.service';
import { version, buildDate, commitHash} from '../../../environments/version';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  constructor(
    public authService: AuthService
  ) { }

  @HostBinding('attr.app-version') appVersionAttr = version;

  ngOnInit() {
  }

  getVersion(): string{
    // return 'Version ' + version + ', commit ' + commitHash + ', built at ' + buildDate;
    return 'Version - ' + version ;
  }

}
