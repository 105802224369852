import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-buff-searchable-sel',
  templateUrl: './buff-searchable-sel.component.html',
  styleUrls: ['./buff-searchable-sel.component.css'],
})
export class BuffSearchableSelComponent implements OnChanges {
  isMobile = false;

  @Input() options: string[];
  @Input() defaultSelected: string;
  @Input() disabled = false;
  @Input() placeholder = 'Placeholder';
  @Input() required = false;
  @Input() ffClass = '';
  @Input() selClass = '';
  @Input() class = '';
  @Input() debounceTime = 500;
  @Input() minCharactersForSearch = 3;

  @Input() inputControl: FormControl = new FormControl();
  @Input() skipClientMatching = false;

  @Output() selectChanged = new EventEmitter<FormControl>();
  @Output() selectInputChange = new EventEmitter<string>();

  @ViewChild(MatSelect) searchableSelect: MatSelect;

  @Input() filteredOptions: string[];
  @Input()
  selectedOption: FormControl /*<(typeof this.options[number])[]>*/ = new FormControl();

  filteredOptionsSubject: Subject<string> = new Subject<string>();

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });

    this.filteredOptionsSubject
      .pipe(debounceTime(this.debounceTime))
      .subscribe(fo => {
        this.onSearch(fo);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions = [];
    if (this.disabled) {
      this.selectedOption.disable();
    } else {
      this.selectedOption.enable();
    }
    if (this.defaultSelected) {
      this.selectedOption.setValue(this.defaultSelected);
    }
    if (this.required && this.options.length > 0) {
      this.selectedOption.setValue(this.options[0]);
    }
  }

  onSelChange() {
    this.selectChanged.emit(this.selectedOption);
  }

  onSearchDebounce(value: string | null) {
    this.filteredOptionsSubject.next(value);
  }

  onSearch(value: string | null) {
    if (value.trim().length >= this.minCharactersForSearch) {
      this.selectInputChange.emit(value);
      const newFOps = [];
      this.options.map(op => {
        if (
          value == null ||
          op.toString().toLowerCase().includes(value.toString().toLowerCase())
        ) {
          newFOps.push(op.toString());
        }
      });
      this.filteredOptions = newFOps;
    } else {
      this.filteredOptions = [];
    }
  }

  isThisOptionHidden(option: string) {
    return (
      this.filteredOptions.findIndex(fop => fop == option) == -1 &&
      this.searchableSelect.value != option
    );
  }
}
