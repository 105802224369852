<div class="hsedtr">
  <div *ngIf="initLoading.isLoading()" class="hsedtr-loading">
    <mat-spinner diameter="75"></mat-spinner>
  </div>
  <ng-container *ngIf="house != null && !initLoading.isLoading()">
    <div class="hsedtr-hdr">
      <div class="hsedtr-hdr-s">
        <h3>{{ house.SalesOrderHeader.ShipToName }}</h3>
      </div>
      <div class="hsedtr-hdr-e"></div>
    </div>
    <div class="hsedtr-ctnt">
      <h5 class="hsedtr-ctnt-phsesttl">Phases</h5>
      <div class="hsedtr-ctnt-phses">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="hsedtr-ctnt-phses-tbl"
        >
          <!-- Position Column -->
          <ng-container matColumnDef="ItemCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
            <td mat-cell *matCellDef="let element">
              {{ element.ItemCode.slice(1) | titlecase }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="WTNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>WT#</th>
            <td mat-cell *matCellDef="let element">{{ element.WTNumber }}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Template">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Template</th>
            <td mat-cell *matCellDef="let element">{{ element.Template }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="PONumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PO#</th>
            <td mat-cell *matCellDef="let element">{{ element.PONumber }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
            <td mat-cell *matCellDef="let element">{{ element.Price }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf="element.canDelete"
                (click)="confirmDeletePhase(element)"
                [disabled]="loading.isLoading()"
                color="accent"
                mat-flat-button
              >
                Delete
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>
