<div [class]="'qvsum quoting-theme ' + screenSize.getClasses()">
  <div class="qvsum-hdr">
    <div class="qvsum-hdr-info">
      <h4 class="qvsum-hdr-info-itm">
        Total Items:
        <span class="qvsum-hdr-info-itm-val">{{ itemTotal }}</span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Total Cost:
        <span class="qvsum-hdr-info-itm-val">
          <span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ costTotal.toFixed(2) }}
        </span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Raw Cost:
        <span class="qvsum-hdr-info-itm-val"
          ><span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ (partCost + laborCost).toFixed(2) }}
        </span>
      </h4>

      <hr />
      <h4 class="qvsum-hdr-info-itm">
        Mat Items:
        <span class="qvsum-hdr-info-itm-val">{{ partTotal }}</span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Mat Cost:
        <span class="qvsum-hdr-info-itm-val">
          <span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ partTotalCost.toFixed(2) }}
        </span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Raw Mat Cost:
        <span class="qvsum-hdr-info-itm-val">
          <span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ partCost.toFixed(2) }}
        </span>
      </h4>

      <hr />
      <h4 class="qvsum-hdr-info-itm">
        Labor Items:
        <span class="qvsum-hdr-info-itm-val">{{ laborTotal }}</span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Labor Cost:
        <span class="qvsum-hdr-info-itm-val"
          ><span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ laborTotalCost.toFixed(2) }}
        </span>
      </h4>
      <h4 class="qvsum-hdr-info-itm">
        Raw Labor Cost:
        <span class="qvsum-hdr-info-itm-val"
          ><span class="qvsum-hdr-info-itm-val-icon">$</span>
          {{ laborCost.toFixed(2) }}
        </span>
      </h4>
      <hr />

      <div class="add-attachment">
        <h4 class="qvsum-hdr-info-itm">Attachments</h4>
        <button
          mat-flat-button
          class="add-attachment-btn"
          (click)="openDialog()"
        >
          <mat-icon mat-list-icon>folder_open</mat-icon>
        </button>
      </div>
    </div>
    <div class="qvsum-controls">
      <div class="qvsum-controls-rev">
        <h4 class="qvsum-controls-rev-ttl">
          Revision: {{ quote.Quote_Revision + 1 }}
        </h4>
      </div>
      <mat-form-field appearance="outline" class="qvsum-status-ff qvsum-ff">
        <mat-label>Status</mat-label>
        <mat-select
          [disabled]="saving"
          [formControl]="statusControl"
          class="qvsum-status-ff-sel qvsum-sel"
        >
          <mat-option
            *ngFor="let status of quoteStatuses"
            [value]="status.valueOf()"
          >
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-ff">
        <mat-label>Region</mat-label>
        <mat-select [formControl]="regionControl" [disabled]="saving">
          <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">
            {{ reg }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-desc-ff qvsum-ff">
        <mat-label>Builder</mat-label>
        <input
          [disabled]="saving"
          [formControl]="builderControl"
          matInput
          type="text"
          class="qvsum-desc-ff-in"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-ff">
        <mat-label>Sq. Footage</mat-label>
        <input
          [disabled]="saving"
          [formControl]="squareFootageControl"
          (input)="squareFootageInput($event)"
          matInput
          type="text"
          class="quotesview-create-ff-in"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="quote.Quote_Expiration != null"
        appearance="outline"
        class="qvsum-exp-ff qvsum-ff"
      >
        <mat-label>Expiration</mat-label>
        <input
          [formControl]="expirationControl"
          [matDatepicker]="datePicker"
          [min]="minExpiration"
          [disabled]="saving"
          matInput
          class="qvsum-exp-ff-in"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker [disabled]="saving"></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-desc-ff qvsum-ff">
        <mat-label>Description</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="descControl"
          matInput
          type="text"
          class="qvsum-desc-ff-in"
        ></textarea>
      </mat-form-field>

      <!-- <mat-form-field
        appearance="outline"
        class="qvsum-matmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Material Margin Cost</mat-label>
        <mat-icon matPrefix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            $
          </span>
        </mat-icon>
        <input
          [disabled]="saving"
          [value]="getMaterialDollar()"
          (input)="materialDollarInput($event)"
          matInput
          type="text"
          class="qvsum-matmar-ff-in qvsum-margin-ff-in"
        />
      </mat-form-field> -->

      <mat-form-field
        appearance="outline"
        class="qvsum-matmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Indirect Material</mat-label>
        <input
          [disabled]="saving"
          [formControl]="materialMarginControl"
          (input)="materialInput($event)"
          matInput
          type="text"
          class="qvsum-matmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon"> percent </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-labmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Indirect Labor</mat-label>
        <input
          [disabled]="saving"
          [formControl]="laborMarginControl"
          (input)="laborInput($event)"
          matInput
          type="text"
          class="qvsum-labmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon"> percent </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-gibmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Gibson Margin</mat-label>
        <input
          [disabled]="saving"
          [formControl]="gibsonMarginControl"
          (input)="gibsonInput($event)"
          matInput
          type="text"
          class="qvsum-gibmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon"> percent </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-taxmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Tax Markup</mat-label>
        <input
          [disabled]="saving"
          [formControl]="taxMarginControl"
          (input)="taxInput($event)"
          matInput
          type="text"
          class="qvsum-taxmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon"> percent </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-fixtures-ff qvsum-ff">
        <mat-label>Fixtures</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="fixturesControl"
          matInput
          type="text"
          class="qvsum-fixtures-ff-in"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-stories-ff qvsum-ff">
        <mat-label>Stories</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="storiesControl"
          matInput
          type="text"
          class="qvsum-stories-ff-in"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-bath-ff qvsum-ff">
        <mat-label>Bath</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="bathControl"
          matInput
          type="text"
          class="qvsum-bath-ff-in"
        ></textarea>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-waterheater-ff qvsum-ff"
      >
        <mat-label>water Heater</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="waterHeatersControl"
          matInput
          type="text"
          class="qvsum-waterheater-ff-in"
        ></textarea>
      </mat-form-field>

      <div class="qvsum-actions">
        <button
          (click)="resetControls()"
          [disabled]="cancelDisabled()"
          color="warn"
          mat-flat-button
          class="qvsum-actions-cancel"
        >
          Cancel
        </button>
        <button
          [disabled]="!canSave()"
          (click)="saveChanges()"
          color="primary"
          mat-flat-button
          class="qvsum-actions-save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="qvsum-finalize">
    <div *ngIf="quote.Bid_guid != null" class="qvsum-finalize-bid">
      This quote is part of a
      <a [routerLink]="'/quoting/bid/' + quote.Bid_guid">bid</a>. It is
      finalized by the bid's summary page.
    </div>
    <button
      (click)="finalizeBid()"
      [disabled]="loadingPDF || quote.Bid_guid != null"
      breadcrumb-end-content
      color="primary"
      mat-flat-button
      class="qvsum-finalize-btn"
    >
      {{ quote.Quote_Revision == 0 ? 'Finalize Quote' : 'Create New Revision' }}
    </button>
  </div>
</div>
