<div [class]="screenSize.getClasses() + ' quoting-theme kitsview'">
  <h1 class="kitsview-title">
    Kits
    <mat-spinner
      *ngIf="kitsLoading"
      [diameter]="30"
      class="kitsview-title-spinner"
    ></mat-spinner>
  </h1>
  <div class="kitsview-controls">
    <mat-form-field appearance="outline" class="kitsview-controls-kitsearch">
      <mat-label>Kit</mat-label>
      <input
        (keyup.enter)="searchKits()"
        matInput
        type="text"
        [formControl]="kitContol"
        class="kitsview-controls-kitsearch-in"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="kitsview-controls-region">
      <mat-label>Region</mat-label>
      <mat-select [formControl]="regionControl">
        <mat-option [value]="'All'">All</mat-option>
        <mat-option *ngFor="let reg of kitRegions" [value]="reg.valueOf()">
          {{ reg }}
        </mat-option>
        <mat-option [value]="'NA'">NA</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      [disabled]="kitsLoading"
      (click)="searchKits()"
      color="accent"
      mat-flat-button
      class="kitsview-controls-search"
    >
      Search
    </button>
  </div>

  <div class="kitsview-add">
    <button (click)="openNewKitSheet()" mat-fab class="kitsview-add-button">
      <i class="fas fa-plus"></i>
    </button>
  </div>

  <div class="kitsview-list">
    <mat-card
      class="kitsview-list-item"
      *ngFor="let kit of kits; let kIndex = index"
    >
      <div
        class="kitsview-list-item-content"
        [class.kitsview-kit-selected]="kit.selected"
      >
        <div class="kitsview-list-item-content-header">
          <span class="kitsview-list-item-content-title">
            {{ kit.Kit_Name }}
            <sup class="kitsview-list-item-content-region">
              {{ kit.Kit_Region }}
            </sup>
          </span>
          <!-- <span class="kitsview-list-item-content-parts">{{ kit.Kit_TotalPart > 0? kit.Kit_TotalPart + " parts" : "No parts" }}</span> -->
          <!-- <span class="kitsview-list-item-content-cost">
            <span class="kitsview-list-item-content-cost-icon">$</span>{{ kit.Kit_TotalCost.toFixed(2) }}
          </span> -->
        </div>
        <div class="kitsview-list-item-content-description">
          {{ kit.Kit_Desc }}
        </div>
      </div>
      <div class="kitsview-list-item-controls">
        <a [routerLink]="'/quoting/kit/' + kit.Kit_guid">
          <button
            (contextmenu)="menuTrigger.openMenu(); $event.preventDefault()"
            color="primary"
            mat-mini-fab
            class="kitsview-list-item-controls-view"
          >
            <i class="fas fa-cog"></i>
            <button
              mat-icon-button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [style]="{ width: '0px', height: '0px' }"
            ></button>
            <mat-menu #menu="matMenu">
              <button
                [routerLink]="'/quoting/kit/' + kit.Kit_guid"
                mat-menu-item
              >
                Edit Kit
              </button>
              <button (click)="copyKit(kit)" mat-menu-item>Copy Kit</button>
            </mat-menu>
          </button>
        </a>

        <button
          (click)="deleteKit(kit, kIndex)"
          color="accent"
          mat-mini-fab
          class="kitsview-list-item-controls-delete"
        >
          <span class="kitsview-list-item-controls-delete-icon">&times;</span>
        </button>
      </div>
    </mat-card>
  </div>

  <div *ngIf="!kitsLoading && kitPageHasMore" class="kitsview-loadmore">
    <button (click)="searchKits(kitPage + 1)" mat-button>Load more</button>
  </div>

  <div *ngIf="kitsLoading && kitPageHasMore" class="kitsview-loading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div
    *ngIf="
      hasSearched &&
      !kitsLoading &&
      ((kitPage > 0 && !kitPageHasMore) || (kitPage == 0 && kits.length == 0))
    "
    class="kitsview-empty"
  >
    <span>No kits found</span>
  </div>

  <app-bottom-sheet
    [open]="newKitOpen"
    (sheetClosed)="newKitOpen = false"
    [sheetWidth]="
      screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'
    "
    class="kitsview-create"
  >
    <h1>
      Create Kit
      <mat-spinner
        *ngIf="newKitLoading"
        [diameter]="30"
        class="kitsview-create-spinner"
      ></mat-spinner>
    </h1>
    <div class="kitsview-create-controls">
      <mat-form-field appearance="outline" class="kitsview-create-ff">
        <mat-label>Name</mat-label>
        <input
          [formControl]="newKitNameControl"
          [disabled]="newKitLoading"
          matInput
          type="text"
          class="kitsview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="kitsview-create-ff">
        <mat-label>Region</mat-label>
        <mat-select
          [formControl]="newKitRegionControl"
          [disabled]="newKitLoading"
        >
          <mat-option *ngFor="let reg of kitRegions" [value]="reg.valueOf()">
            {{ reg }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field
      appearance="outline"
      class="kitsview-create-ff kitsview-create-desc"
    >
      <mat-label>Description</mat-label>
      <textarea
        [formControl]="newKitDescControl"
        [disabled]="newKitLoading"
        matInput
        type="text"
        class="kitsview-create-ff-in"
      ></textarea>
    </mat-form-field>
    <span *ngIf="newKitErrorMsg != null" class="kitsview-create-error"
      >*{{ newKitErrorMsg }}</span
    >
    <div class="kitsview-create-actions">
      <button
        (click)="createKit()"
        [disabled]="!newKitValid()"
        color="primary"
        mat-flat-button
        class="kitsview-create-actions-submit"
      >
        Create Kit
      </button>
    </div>
  </app-bottom-sheet>
</div>
