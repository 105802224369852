const InputDecimalSanitizer = (
  event: InputEvent,
  intLength = 8,
  decLength = 4
) => {
  const wholeValueMax = intLength || 8;
  const decimalValueMax = decLength || 4;

  // test is e.value has more than one period. If it does, remove all but the first one
  if ((event.target as HTMLInputElement).value.split('.').length > 2) {
    const splitByPeriod = (event.target as HTMLInputElement).value.split('.');
    const firstTwo = splitByPeriod.slice(0, 2).join('.');
    const newValue = [firstTwo, ...splitByPeriod.slice(2)].join('');

    (event.target as HTMLInputElement).value = newValue;

    const cursorPosStart = newValue.split('.')[0].length + 1;
    const cursorPosEnd = newValue.split('.')[0].length + 1;
    (event.target as HTMLInputElement).setSelectionRange(
      cursorPosStart,
      cursorPosEnd
    );
    setTimeout(() => {
      // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
      (event.target as HTMLInputElement).setSelectionRange(
        cursorPosStart,
        cursorPosEnd
      );
    }, 0);
    return newValue;
  }

  // Test if event.value is a number
  if (!/^\d*\.?\d*$/.test((event.target as HTMLInputElement).value)) {
    const arrVal = (event.target as HTMLInputElement).value
      .replace(/\D/g, '')
      .split('.');
    arrVal[0] = arrVal[0].slice(0, wholeValueMax);
    if (arrVal.length >= 2) {
      arrVal[1] = arrVal[1].slice(0, decimalValueMax);
    }
    const newValue = arrVal.join('.');
    // First, get the cursor position in the input
    const cursorPosStart =
      (event.target as HTMLInputElement).selectionStart - 1;
    const cursorPosEnd = (event.target as HTMLInputElement).selectionEnd - 1;

    (event.target as HTMLInputElement).value = newValue;
    // Now, set the cursor position back to cursorPos
    (event.target as HTMLInputElement).setSelectionRange(
      cursorPosStart,
      cursorPosEnd
    );
    setTimeout(() => {
      // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
      (event.target as HTMLInputElement).setSelectionRange(
        cursorPosStart,
        cursorPosEnd
      );
    }, 0);
    return newValue;
  } else {
    const arrVal = (event.target as HTMLInputElement).value.split('.');
    arrVal[0] = arrVal[0].slice(0, wholeValueMax);
    if (arrVal.length >= 2) {
      arrVal[1] = arrVal[1].slice(0, decimalValueMax);
    }
    const newValue = arrVal.join('.');

    return newValue;
  }
};
export default InputDecimalSanitizer;
