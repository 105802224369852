import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import KitRegions from '../KitRegions';
import KitUtil from 'src/app/utils/KitUtil';
import KitPhases from '../KitPartPhases';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';

@Component({
  selector: 'app-kitsview',
  templateUrl: './KitsViewComponent.html',
  styleUrls: ['./KitsViewComponent.css', '../QuotingTheme.scss'],
})
export class KitsViewComponent extends KitUtil {
  kitContol = new FormControl('');

  kits: KitOverviewDtoInterface[] = [];
  kitsLoading = false;

  newKitOpen = false;
  newKitLoading = false;
  newKitErrorMsg = null;

  newKitNameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(30),
  ]);
  newKitRegionControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newKitDescControl = new FormControl('', [Validators.maxLength(255)]);

  kitRegions = KitRegions;
  regionControl = new FormControl('All');

  kitPage = 0;
  kitPageSize = 25;
  kitPageHasMore = false;
  hasSearched = false;

  constructor(
    public api: SageApiService,
    private router: Router,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  disableSearchControls() {
    this.kitContol.disable();
    this.regionControl.disable();
  }

  enableSearchControls() {
    this.kitContol.enable();
    this.regionControl.enable();
  }

  searchKits(page?: number) {
    this.hasSearched = true;
    if (page != undefined) {
      this.kitPage = page;
    } else {
      this.kitPage = 0;
      this.kitPageHasMore = false;
    }

    if (!this.kitsLoading) {
      this.kitsLoading = true;
      this.disableSearchControls();
      let kitsurl = `kits-overview?size=${this.kitPageSize}&offset=${
        this.kitPage != 0 ? this.kits.length : 0
      }&Kit_Name=${this.kitContol.value}`;
      if (this.regionControl.value != 'All') {
        kitsurl += `&Kit_Region=${this.regionControl.value}`;
      }
      this.api.pullReport(kitsurl).subscribe(
        (data: KitOverviewDtoInterface[]) => {
          this.enableSearchControls();
          this.kitsLoading = false;

          this.kitPageHasMore = data.length == this.kitPageSize;

          this.kits = page == undefined ? data : this.kits.concat(data);
        },
        err => {
          this.enableSearchControls();
          this.kitsLoading = false;
          this.snackBar.open('Error loading kits', 'close', {
            duration: Infinity,
          });
        }
      );
    }
  }

  deleteKit(kit: KitDtoInterface, kitIndex: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: `Really delete ${kit.Kit_Name}?`,
        message: `Are you sure you want to delete ${kit.Kit_Name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.api.deleteRequest(`kit/${kit.Kit_guid}`).subscribe(
          () => {
            this.kits.splice(kitIndex, 1);
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  }

  createKit() {
    this.newKitLoading = true;
    this.api
      .postRequest('kit', {
        Kit_Name: this.newKitNameControl.value,
        Kit_Region: this.newKitRegionControl.value,
        Kit_Desc: this.newKitDescControl.value,
      })
      .subscribe(
        dataRaw => {
          const data: KitDtoInterface = (dataRaw as unknown) as KitDtoInterface;
          this.router.navigate([`/quoting/kit/${data.Kit_guid}`]);
          this.newKitLoading = false;
        },
        err => {
          console.log(err);
          if (err.error) {
            this.newKitErrorMsg = err.error;
          }
          if (err.error.includes('Kit_Name')) {
            this.newKitNameControl.setErrors({ unique: true });
          }
          if (err.error.includes('Kit_Region')) {
            this.newKitRegionControl.setErrors({ unique: true });
          }
          if (err.error.includes('Kit_Desc')) {
            this.newKitDescControl.setErrors({ unique: true });
          }
          this.newKitLoading = false;
        }
      );
  }

  openNewKitSheet() {
    this.newKitNameControl = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(30),
    ]);
    this.newKitRegionControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newKitDescControl = new FormControl('', [Validators.maxLength(255)]);
    this.newKitErrorMsg = null;
    this.newKitOpen = true;
  }

  newKitValid() {
    return (
      this.newKitNameControl.valid &&
      this.newKitRegionControl.valid &&
      this.newKitDescControl.valid &&
      this.newKitLoading == false
    );
  }

  copyKit(kit) {
    this.api.postRequest(`copy-kit/${kit.Kit_guid}`, {}).subscribe(
      (data: ContextKitDtoInterface) => {
        this.router.navigate([`/quoting/kit/${data.Kit.Kit_guid}`]);
      },
      err => {
        console.log(err);
      }
    );
  }
}
