/*
 * ParsedCsvInterface
 * @description
 * Interface for the ParsedCsv object.
 * headers: string[] - The column headers.
 * data: { [key: string]: string | number }[] - The data rows.
 */
export interface ParsedCsvInterface {
  headers: string[];
  data: {
    [key: string]: string | number;
  }[];
}

/*
 * ParseCsv
 * @param csvStr: string
 * @returns ParsedCsvInterface
 * @description
 * Parse a string of CSV data into an object with headers and data.
 */
const ParseCsv = (csvStr: string): ParsedCsvInterface => {
  // Parse the csvStr. The function should be able to handle columns wrapped in quotes.
  // If a comma or quote is escaped with a backslash, it is part of the column value.
  // The function should return an array of arrays, where each array represents a row in the csv.
  // The first array should contain the column headers.
  // If the column is all numbers and does not have preceding or trailing spaces or preceding 0s, it should be converted to a number.

  const splitOnNonEscapedCommas = (str: string): string[] => {
    const result = [];
    let current = '';
    let escaped = false;
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '\\' && !escaped) {
        escaped = true;
      } else if (str[i] === ',' && !escaped) {
        result.push(current);
        current = '';
      } else {
        current += str[i];
        escaped = false;
      }
    }
    result.push(current);
    return result;
  };

  const getNonEscapedColumn = (colStr: string): string => {
    // If the column is empty, return it as is
    if (!colStr) {
      return colStr;
    }
    // Leave characters escaped with a backslash, but take surrounding quotes off
    if (
      colStr.length > 1 &&
      colStr[0] === '"' &&
      colStr[colStr.length - 1] === '"'
    ) {
      // Also remove carriage returns
      return colStr.slice(1, -1);
    }
    return colStr;
  };

  // Split the csvStr into lines
  const lines = csvStr.replace(/\r/g, '').split('\n');
  // Get the headers
  const headers = splitOnNonEscapedCommas(lines[0]).map(getNonEscapedColumn);
  // Initialize the result array
  const result = [];
  // Loop through the lines
  for (let i = 1; i < lines.length; i++) {
    // If the line is empty, skip it
    if (!lines[i]) {
      continue;
    }
    // Split the line into columns
    const currentline = splitOnNonEscapedCommas(lines[i]);
    // Initialize the object
    const obj = {};
    // Loop through the columns
    for (let j = 0; j < headers.length; j++) {
      const currCol = getNonEscapedColumn(currentline[j]);
      // If the column is all numbers and does not have preceding or trailing spaces or preceding 0s, convert it to a number
      if (
        /^[\d,.]+$/.test(currCol) &&
        currCol != '.' &&
        currCol.split('.').length < 3
      ) {
        // If the column has prefix 0s, it should be treated as a string
        if (currCol[0] === '0' && currCol.length > 1) {
          obj[headers[j]] = currCol;
        } else if (currCol.includes('.')) {
          obj[headers[j]] = parseFloat(currCol);
        } else {
          obj[headers[j]] = parseInt(currCol);
        }
      } else {
        obj[headers[j]] = currCol;
      }
    }
    // Push the object to the result array
    result.push(obj);
  }
  // Return the result array
  return { headers, data: result };
};
export default ParseCsv;
