import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface SwipableParamsInterface {
  threshhold: number;
  stopPropagation: boolean;
}

export interface SwipedEventInterface {
  direction: 'left' | 'right' | 'up' | 'down';
  touch: {
    touchstartX: number;
    touchendX: number;
    touchstartY: number;
    touchendY: number;
  };
  deg: number;
  distance: number;
  time: number;
  touchTarget: EventTarget;
}

@Directive({
  selector: '[appSwipeable]',
})
export class SwipeableDirective implements AfterViewInit {
  @Input('appSwipeable') swipeable: SwipableParamsInterface;
  defThreshhold = 150;
  defStopPropagation = true;

  touchTarget: EventTarget = null;

  touchStartTime = 0;
  touchEndTime = 0;

  touchstartX = 0;
  touchendX = 0;
  touchstartY = 0;
  touchendY = 0;

  @Output() swiped = new EventEmitter<SwipedEventInterface>();

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const stopPropagation =
      this.swipeable.stopPropagation != undefined
        ? this.swipeable.stopPropagation
        : this.defStopPropagation;
    if (this.el) {
      this.el.nativeElement.addEventListener('touchend', (e: TouchEvent) => {
        this.touchEndTime = new Date().getTime();
        this.touchendX = e.changedTouches[0].screenX;
        this.touchendY = e.changedTouches[0].screenY;
        this.checkDirection();
        if (stopPropagation) {
          e.stopPropagation();
        }
      });
      this.el.nativeElement.addEventListener('touchstart', (e: TouchEvent) => {
        this.touchTarget = e.target;
        this.touchStartTime = new Date().getTime();
        this.touchstartX = e.changedTouches[0].screenX;
        this.touchstartY = e.changedTouches[0].screenY;
        if (stopPropagation) {
          e.stopPropagation();
        }
      });
      this.el.nativeElement.addEventListener('scroll', e => {
        if (stopPropagation) {
          e.stopPropagation();
        }
      });
    }
  }

  checkDirection() {
    const thresh: number = this.swipeable.threshhold || this.defThreshhold;

    const touchInfo = {
      touchstartX: this.touchstartX,
      touchendX: this.touchendX,
      touchstartY: this.touchstartY,
      touchendY: this.touchendY,
    };

    let deg =
      (Math.atan2(
        this.touchendY - this.touchstartY,
        this.touchendX - this.touchstartX
      ) *
        180) /
      Math.PI;
    if (deg < 0) {
      deg = 360 + deg;
    }
    deg = 360 - deg;

    const xdiff = Math.abs(this.touchendX - this.touchstartX);
    const ydiff = Math.abs(this.touchendY - this.touchstartY);

    const distance = Math.sqrt(xdiff * xdiff + ydiff * ydiff);

    const time = this.touchEndTime - this.touchStartTime;

    const doX = () => {
      if (xdiff >= thresh) {
        if (this.touchendX < this.touchstartX) {
          this.swiped.emit({
            direction: 'left',
            touch: touchInfo,
            deg,
            distance,
            time,
            touchTarget: this.touchTarget,
          });
        }
        if (this.touchendX > this.touchstartX) {
          this.swiped.emit({
            direction: 'right',
            touch: touchInfo,
            deg,
            distance,
            time,
            touchTarget: this.touchTarget,
          });
        }
      }
    };
    const doY = () => {
      if (ydiff >= thresh) {
        if (this.touchendY < this.touchstartY) {
          this.swiped.emit({
            direction: 'up',
            touch: touchInfo,
            deg,
            distance,
            time,
            touchTarget: this.touchTarget,
          });
        }
        if (this.touchendY > this.touchstartY) {
          this.swiped.emit({
            direction: 'down',
            touch: touchInfo,
            deg,
            distance,
            time,
            touchTarget: this.touchTarget,
          });
        }
      }
    };

    if (xdiff > ydiff) {
      doX();
    } else {
      doY();
    }
  }
}
