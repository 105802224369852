<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center">
    <h1>House Create</h1>
    <div class="card-body">
      <div class="subRow">
        <label
          >Street
          <input
            class="form-control"
            placeholder="Street"
            type="text"
            [(ngModel)]="address.street"
            (blur)="RemoveSpecialCharacters(address)"
          />
        </label>
        <label
          >Street Line 2
          <input
            class="form-control"
            placeholder="Street Line 2"
            type="text"
            [(ngModel)]="address.street2"
          />
        </label>
      </div>
      <div class="subRow">
        <label
          >City
          <input
            class="form-control"
            placeholder="City"
            type="text"
            [(ngModel)]="address.city"
          />
        </label>
        <label
          >Zip
          <input
            class="form-control"
            placeholder="Zip"
            type="text"
            (blur)="checkZip()"
            [(ngModel)]="address.zip"
          />
        </label>
      </div>
      <div class="subRow">
        <div>
          <label
            >Lot
            <input
              class="form-control"
              placeholder="Lot"
              type="text"
              [(ngModel)]="address.lot"
            />
          </label>
        </div>
        <div>
          <label
            >Block
            <input
              class="form-control"
              placeholder="Block"
              type="text"
              [(ngModel)]="address.block"
            />
          </label>
        </div>
      </div>

      <div class="subRow">
        <div>
          <label>
            Customer
            <input
              class="form-control"
              [(ngModel)]="name"
              type="text"
              placeholder="Customer"
              [typeaheadMinLength]="0"
              [typeahead]="customerList"
              typeaheadOptionField="name"
              typeaheadOptionsLimit="150"
              [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="5"
              autocomplete="on"
              (typeaheadOnSelect)="selectCustomer($event)"
            />
          </label>
        </div>
        <div>
          <label>
            Customer Name
            <input
              disabled
              class="form-control"
              [(ngModel)]="customer.CustomerName"
            />
          </label>
        </div>
      </div>
      <div class="subRow">
        <label>
          Subdivision
          <input
            class="form-control"
            [(ngModel)]="subdivision"
            type="text"
            placeholder="Subdivision"
            [typeaheadMinLength]="0"
            [typeahead]="subdivisionList"
            typeaheadOptionField="UDF_SUBDIVISION_CODE"
            typeaheadOptionsLimit="150"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="5"
            autocomplete="on"
            (typeaheadOnBlur)="onSubdivisionSelect($event)"
            (typeaheadOnSelect)="onSubdivisionSelect($event)"
          />
        </label>
        <label>
          Leadman
          <input disabled class="form-control" [(ngModel)]="leadman" />
        </label>
      </div>
      <div class="subRow">
        <label>
          Warehouse
          <input
            class="form-control"
            [(ngModel)]="warehouse"
            type="text"
            placeholder="Warehouse"
            [typeaheadMinLength]="0"
            [typeahead]="warehouseList"
            typeaheadOptionField="name"
            typeaheadOptionsLimit="150"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="5"
            autocomplete="off"
            name="WarehouseCode"
            (typeaheadOnBlur)="warehouseSelect(warehouse)"
            (typeaheadOnSelect)="warehouseSelect($event)"
          />
        </label>
      </div>

      <div id="selectTemplateOrQuote">
        <label>Ticket Type:</label>
        <label>
          <input
            type="radio"
            name="ticketType"
            value="ZROUGHIN"
            [(ngModel)]="template"
            required
          />
          Roughin
        </label>
        <label>
          <input
            type="radio"
            name="ticketType"
            value="ZPUNCH"
            [(ngModel)]="template"
          />
          Punch
        </label>
        <div id="Quote">
          <label>
            <input
              type="radio"
              name="ticketType"
              value="Quote"
              [(ngModel)]="template"
            />
            Quote
            <div
              *ngIf="
                template != 'ZROUGHIN' && template != 'ZPUNCH' && template != ''
              "
            >
              <mat-form-field appearance="outline">
                <mat-label>Select a Quote Template</mat-label>
                <mat-select
                  (selectionChange)="OnQuoteSelected($event.value)"
                  [(ngModel)]="selectedQuote"
                >
                  <input
                    id="matOptionInput"
                    matInput
                    placeholder="search"
                    [(ngModel)]="searchOption"
                    (keyup)="onKey()"
                  />
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let quote of onKey()" [value]="quote">
                    {{ quote.QuoteName }} - {{ quote.QuoteDesc }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </label>
        </div>
      </div>

      <label>Upload Files to the Build Folder:</label>
      <app-drag-and-drop
        (loading)="(loading)"
        [resetFileList]="resetFileList"
        (fileList)="getFiles($event)"
      ></app-drag-and-drop>
    </div>
    <button
      id="button"
      class="btn btn-primary"
      [disabled]="checkForm() || loading"
      (click)="openModal(confirmCreate)"
    >
      <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Creating</span>
      <span *ngIf="!loading">Create</span>
    </button>
  </div>
</div>
<ng-template #confirmCreate>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center" *ngIf="found">
        <h2>Sales Order May Exist</h2>
        <h5>
          Sales Order: {{ shipTo.salesOrder }} exists with address:
          {{ shipTo.shipToStreet }}
        </h5>
      </div>
      <div class="col-10 offset-1 text-center" *ngIf="!found">
        <h2>Confirm Create Address</h2>
        <h5>Are you sure you want to create this address?</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1" id="" *ngIf="!found">
      <div class="col-sm-6 text-center">
        <button
          [disabled]="loading"
          class="btn btn-primary"
          (click)="createSalesOrder()"
        >
          <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
          <span *ngIf="!loading">Yes</span>
        </button>
      </div>
      <div class="col-sm-6 text-center">
        <button
          [disabled]="loading"
          class="btn btn-primary"
          (click)="closeModal()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</ng-template>
