import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ListObjectsCommand,
  ListObjectsV2Command,
  S3Client,
} from '@aws-sdk/client-s3';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class WasabiApiService {
  apiUrl = this.sage.apiUrl;

  imgUrlList: Array<string> = [];

  constructor(private httpClient: HttpClient, private sage: SageApiService) {}

  async getImages(address: string) {
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `uploads/${address}`,
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z',
      },
    });

    // Destination: address
    try {
      this.imgUrlList = [];
      const bucketInfo = await s3.send(new ListObjectsCommand(bucketParams));
      const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
      for (const i in bucketInfo.Contents) {
        this.imgUrlList.push(`${imgBaseUrl}${bucketInfo.Contents[i].Key}`);
      }
      return this.imgUrlList;
    } catch (error) {
      console.log('Error in S3 getImages function: ', error);
    }
  }

  getImagesObservable(address: string) {
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `uploads/${address}`,
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z',
      },
    });

    const prom: Promise<string[]> = new Promise((resolveProm, rejectProm) => {
      const imgUrlList: string[] = [];
      s3.send(new ListObjectsCommand(bucketParams))
        .then(bucketInfo => {
          const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
          for (const i in bucketInfo.Contents) {
            imgUrlList.push(`${imgBaseUrl}${bucketInfo.Contents[i].Key}`);
          }
          resolveProm(imgUrlList);
        })
        .catch(err => {
          rejectProm();
          console.log('Error in S3 getImages function: ', err);
        });
    });
    return fromPromise(prom);
  }

  uploadImage(address: string, sentImages: any) {
    const url = this.apiUrl + `wasabi/images?address=${address}`;
    const body = {
      images: sentImages,
    };
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const formData = new FormData();

    sentImages.forEach((file, index) => {
      formData.append('file' + index, file.data, file.name);
    });

    return this.httpClient.post(url, formData, { headers });
  }

  upload(folder: string, files: File[]) {
    const uploadUrl = `${this.apiUrl}wasabi/${folder}`;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append('file' + index, file);
    });

    return this.httpClient.post(uploadUrl, formData, { headers });
  }

  uploadFile(address: string, filepath: string, sentfiles: any) {
    let url = '';
    if (filepath === 'scouts') {
      url = this.apiUrl + 'wasabi/scouts?address=' + address; // main api
    } else if (filepath == 'quote/') {
      url = this.apiUrl + 'wasabi?guid=' + address; // main api
    } else {
      url = this.apiUrl + 'wasabi?address=' + address; // main api
    }
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const formData = new FormData();

    sentfiles.forEach((file, index) => {
      formData.append('file' + index, file);
    });

    return this.httpClient.post(url, formData, { headers });
  }

  async getQuoteInfo(address: string) {
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `quotes/${address}`,
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z',
      },
    });

    // Destination: address
    try {
      this.imgUrlList = [];
      const bucketInfo = await s3.send(new ListObjectsCommand(bucketParams));
      const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
      for (const i in bucketInfo.Contents) {
        let fileName = bucketInfo.Contents[i].Key;
        if (fileName.includes(' ')) {
          fileName = fileName.replace(/ /g, '%20');
        }
        this.imgUrlList.push(`${imgBaseUrl}${fileName}`);
      }
      return this.imgUrlList;
    } catch (error) {
      console.log('Error in S3 getImages function: ', error);
    }
  }

  async getFiles(src: string) {
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: src,
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z',
      },
    });

    // Destination: address
    try {
      this.imgUrlList = [];
      const bucketInfo = await s3.send(new ListObjectsCommand(bucketParams));
      const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
      for (const i in bucketInfo.Contents) {
        let fileName = bucketInfo.Contents[i].Key;
        if (fileName.includes(' ')) {
          fileName = fileName.replace(/ /g, '%20');
        }
        this.imgUrlList.push(`${imgBaseUrl}${fileName}`);
      }
      return this.imgUrlList;
    } catch (error) {
      console.log('Error in S3 getImages function: ', error);
    }
  }
}
