import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';

interface SuperintendentInterface {
  username: string;
  name: string;
  nickname: string;
  email: string;
  phoneNumber: string;
  initials: string;
  areaManager: string;
}
interface SuperintendentToAddInterface {
  username: string;
  initials: string;
  name: string;
}

@Component({
  selector: 'app-superintendent-manager',
  templateUrl: './superintendent-manager.component.html',
  styleUrls: ['./superintendent-manager.component.css'],
})
export class SuperintendentManagerComponent implements OnInit {
  SuperList = [];

  usernameoptions = [];
  SuperCreateList: SuperintendentToAddInterface[] = [];
  superData: MatTableDataSource<SuperintendentInterface> | null;
  isLoading: boolean;

  LeadmanToEdit: SuperintendentInterface;
  username: string;
  name: string;
  nickname: string;
  email: string;
  phoneNumber: string;
  initials: string;
  areaManager: string;

  modalTemplate: BsModalRef;

  Columns: string[] = [
    'username',
    'name',
    'nickname',
    'email',
    'phoneNumber',
    'initials',
    'areaManager',
    'action',
  ];
  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    public dialog: MatDialog,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getSupers();
  }

  getSupers() {
    this.SuperList = [];
    this.isLoading = true;
    this.sageApi.pullReport('AM-supers').subscribe(
      (activeSupers: Array<any>) => {
        activeSupers.forEach(supe => {
          if (supe != null) {
            const obj: SuperintendentInterface = {
              username: supe.superUser,
              name: supe.leadman,
              nickname: supe.nickname,
              email: supe.email,
              phoneNumber: supe.PhoneNumber,
              initials: supe.UDF_UDF_INITIALS,
              areaManager: supe.UDF_AREA_MANAGER,
            };
            this.SuperList.push(obj);
          }
        });
      },
      error => {
        console.error(error);
        this.isLoading = false;
      },
      () => {
        this.superData = new MatTableDataSource(this.SuperList);
        this.isLoading = false;
      }
    );
  }
  closeModal() {
    this.modalTemplate.hide();
  }
  openModal(template: TemplateRef<any>, e?: SuperintendentInterface) {
    this.username = '';
    if (e != undefined) {
      this.LeadmanToEdit = e;
      this.name = e.name;
      this.nickname = e.nickname;
      this.email = e.email;
      this.phoneNumber = e.phoneNumber;
      this.initials = e.initials;
      this.areaManager = e.areaManager;
    } else {
      this.name = '';
      this.nickname = '';
      this.email = '';
      this.phoneNumber = '';
      this.initials = '';
      this.areaManager = '';
    }
    this.modalTemplate = this.modalService.show(template);
  }
  submit() {
    this.closeModal();
    this.isLoading = true;
    const infoToSend = {
      username: this.LeadmanToEdit.username,
      name: this.name,
      nickname: this.nickname,
      email: this.email,
      phoneNumber: this.phoneNumber,
      initials: this.initials,
      areaManager: this.areaManager,
    };
    const endpoint = 'EditSuperintendent';
    this.sageApi.putRequest(endpoint, infoToSend).subscribe(
      (response: any) => {
        console.log(response);
        this.alertify.success(response);
      },
      err => {
        this.alertify.error(err.error.Message);
        this.isLoading = false;
        this.getSupers();
      },
      () => {
        this.getSupers();
      }
    );
  }

  deleteSuperintendent(e) {
    this.closeModal();
    const endpoint = 'DeleteSuper';
    this.sageApi.putRequest(endpoint, this.LeadmanToEdit).subscribe(
      (response: any) => {
        console.log(response);
        this.alertify.success(response);
      },
      err => {
        this.alertify.error(err.error.Message);
        this.isLoading = false;
        this.getSupers();
      },
      () => {
        this.getSupers();
      }
    );
  }

  getAvailableSupers() {
    this.SuperCreateList = [];
    this.isLoading = true;
    this.sageApi.pullReport('Super/UserList').subscribe(
      (activeSupers: Array<any>) => {
        activeSupers.forEach(supe => {
          if (supe != null) {
            const obj = {
              username: supe.UserLogon,
              name: supe.FirstName + ' ' + supe.LastName,
              initials: supe.UserCode,
            };
            this.SuperCreateList.push(obj);
          }
        });
        this.removeActiveSupers();
      },
      error => {
        console.error(error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  removeActiveSupers() {
    const tempArr = [];
    this.usernameoptions = [];
    this.SuperCreateList.forEach(element => {
      const i = this.SuperList.findIndex(x => x.username == element.username);
      if (i == -1) {
        tempArr.push(element);
        this.usernameoptions.push(element.username);
      }
    });
    this.SuperCreateList = [];
    this.SuperCreateList = tempArr;
  }

  getSuperinformation(e) {
    const obj = this.SuperCreateList.find(x => x.username == e.value);
    if (obj != undefined) {
      this.name = obj.name;
      this.initials = obj.initials;
    } else {
      this.username = '';
      this.name = '';
      this.initials = '';
    }
  }

  createSuper() {
    this.isLoading = true;
    const body = {
      username: this.username,
      name: this.name,
      nickname: this.nickname,
      email: this.email,
      phoneNumber: this.phoneNumber,
      initials: this.initials,
      areaManager: this.areaManager,
    };
    this.closeModal();
    const endpoint = 'CreateSuper';
    this.sageApi.putRequest(endpoint, body).subscribe(
      (response: any) => {
        console.log(response);
        this.alertify.success(response);
      },
      err => {
        this.alertify.error(err.error.Message);
        this.isLoading = false;
        this.getSupers();
      },
      () => {
        this.getSupers();
      }
    );
  }
  canSend(): boolean {
    if (this.username == '' || this.name == '' || this.initials == '') {
      return true;
    } else {
      return false;
    }
  }
}
