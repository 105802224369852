import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css'],
})
export class DragAndDropComponent implements OnInit {
  files = [];

  @Input() loading = false;
  @Input() resetFileList: Observable<boolean>;
  @Output() fileList = new EventEmitter<any[]>();
  constructor(
    public screenSize: ScreenSizeService,
    public sageApi: SageApiService
  ) {}

  ngOnInit(): void {
    this.resetFileList.subscribe(data => {
      if (data == true) {
        this.files = [];
      }
    });
  }

  removeFromFiles(e) {
    const index = this.files.indexOf(e);
    if (index != -1) {
      this.files.splice(index, 1);
    }
    this.fileList.emit(this.files);
  }
  addToFileList(e) {
    for (const item of e) {
      if (!this.files.includes(item)) {
        const obj = {
          name: item.name,
          data: item,
          size: item.size,
        };
        this.files.push(obj);
      }
    }
    this.fileList.emit(this.files);
  }
}
