<!-- * Beginning of Image Modal -->
<ng-template #imageExpandModal>
  <div class="card container">
    <div class="row p-1 d-flex justify-content-between">
      <div class="col-md-10">
        <h5>{{ imageAddressForModal }}</h5>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onCloseImageModal()"
        >
          X
        </button>
      </div>
    </div>
    <div class="row">
      <img
        src="{{ selectedImageUrlForModal }}"
        height="100%"
        width="100%"
        alt=""
      />
    </div>
  </div>
</ng-template>
<!-- * End of Image Modal -->

<ng-template #qrImage>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-9 offset-1 text-center">
        <h2>WT: {{ QRWTNumDash }}</h2>
        <h3>{{ QRWTNumAddress }}</h3>
        <h4>{{ QRWTNumDivision }}</h4>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="onCloseImageModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <hr />
    <div class="container-fluid">
      <div class="row justify-content-center">
        <qr-code value="{{ QRWTNum }}"></qr-code>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="loading == false" class="card border-0 jumbotron p-0">
  <div
    class="card-header"
    *ngIf="
      authService.decodedToken.role !== 'super' &&
      authService.decodedToken.role !== 'superdfw' &&
      authService.decodedToken.role !== 'tech'
    "
  >
    <div class="row">
      <div class="col-sm-3">
        <app-searchable-sel
          (selectChanged)="onTechSelected($event.value)"
          [showEmpty]="false"
          [options]="getTechNames()"
          placeholder="Select Tech"
        ></app-searchable-sel>

        <!-- <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle">
              {{ currentTech }} Selected <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem" *ngFor="let tech of techList; let techIndex= index"
                  [ngStyle]="{'display': tech.name == '' ? 'none' : ''}">
                <a class="dropdown-item" (click)="onTechSelected(tech.techCode, techIndex)">
                  <span>{{ tech.name}}</span>
                  <i *ngIf="tech.selected" class="fas fa-check fa-border fa-pull-right"></i></a>
              </li>
            </ul>
          </div> -->
      </div>
    </div>
  </div>
  <div class="card-header" *ngIf="authService.decodedToken.role == 'superdfw'">
    <div class="row">
      <div class="col-sm-3">
        <div class="card m-1">
          <div class="btn-group" dropdown>
            <button
              dropdownToggle
              type="button"
              class="btn btn-outline-dark dropdown-toggle"
            >
              {{ currentTech }} Selected <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li
                role="menuitem"
                *ngFor="let tech of techList; let techIndex = index"
                [ngStyle]="{
                  display:
                    tech.name == '' ||
                    !(
                      tech.assignedSup !== undefined &&
                      tech.assignedSup.toLocaleLowerCase().includes('dfw')
                    )
                      ? 'none'
                      : ''
                }"
              >
                <a
                  class="dropdown-item"
                  (click)="onTechSelected(tech.techCode)"
                >
                  <span>{{ tech.name }}</span>
                  <i
                    *ngIf="tech.selected"
                    class="fas fa-check fa-border fa-pull-right"
                  ></i
                ></a>
              </li>
              <!-- <li class="divider dropdown-divider"></li>
    <li role="menuitem"><a class="dropdown-item" (click)="onSuperSelected('', 0)">Select None</a>
    </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body border-0">
    <div class="container col-12">
      <!-- Previous One Day Tickets -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto" style="margin-top: 0.5rem">
                <h5>{{ previousTwoDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showPreviousDayTWoTickets"
                  (click)="
                    showPreviousDayTWoTickets = !showPreviousDayTWoTickets
                  "
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showPreviousDayTWoTickets"
                  (click)="
                    showPreviousDayTWoTickets = !showPreviousDayTWoTickets
                  "
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].history[previousTwoDate]?.punch
                .length > 0
            ) && showPreviousDayTWoTickets
          "
        >
          <h3 class="text-primary">No History Tickets For This Date</h3>
        </div>
        <ul
          *ngIf="showPreviousDayTWoTickets"
          class="list-group list-group-flush"
        >
          <ng-container
            *ngFor="
              let itemhistory of techList[currentTechIndex].history[
                previousTwoDate
              ]?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total History Tickets {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.subdivision | titlecase }}
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ itemhistory.notes }}
                  </li>
                </ul>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
              >
                <form>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group"></div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketHistoryImages(
                            itemhistory.address,
                            currentTechIndex,
                            previousTwoDate,
                            ticketIndex
                          );
                          itemhistory.displayImages === false
                            ? (itemhistory.displayImages = true)
                            : (itemhistory.displayImages = false)
                        "
                      >
                        View Images
                      </button>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="itemhistory.displayImages">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].history[
                            previousTwoDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenHistoryImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            previousTwoDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>

      <!-- Previous Day Tickets  -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto" style="margin-top: 0.5rem">
                <h5>{{ previousOneDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showPreviousDayOneTickets"
                  (click)="
                    showPreviousDayOneTickets = !showPreviousDayOneTickets
                  "
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showPreviousDayOneTickets"
                  (click)="
                    showPreviousDayOneTickets = !showPreviousDayOneTickets
                  "
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].tickets[previousOneDate]?.punch
                .length > 0
            ) && showPreviousDayOneTickets
          "
        >
          <h3 class="text-primary">No Tickets To Do For This Date</h3>
        </div>
        <ul
          *ngIf="showPreviousDayOneTickets"
          class="list-group list-group-flush"
        >
          <ng-container
            *ngFor="
              let item of techList[currentTechIndex].tickets[previousOneDate]
                ?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total Tickets To Do{{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.subdivision | titlecase }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="
                        openModal(
                          qrImage,
                          currentTechIndex,
                          previousOneDate,
                          ticketIndex
                        )
                      "
                    >
                      QR Code
                    </button>
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ item.description }}
                  </li>
                </ul>
              </li>
              <li class="list-group-item col-12 p-0">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block"
                  [disabled]="lockUser"
                  [ngClass]="{ 'btn-success': item.updated }"
                  (click)="
                    editTicket(currentTechIndex, previousOneDate, ticketIndex)
                  "
                >
                  Update {{ item.address }}
                </button>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
                *ngIf="item.displayEdit"
              >
                <form>
                  <div class="form-group">
                    <label for="notes">Notes</label>
                    <textarea
                      class="form-control"
                      id="notes"
                      name="notes"
                      rows="3"
                      maxlength="500"
                      [(ngModel)]="item.notes"
                    >
                    {{ item.notes }}</textarea
                    >
                    <mat-hint class="hints">
                      {{ item.notes.length }} / 500</mat-hint
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="complete"
                          name="complete"
                          [(ngModel)]="item.complete"
                        />
                        <label class="custom-control-label" for="complete"
                          >Complete?</label
                        >
                      </div>
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketImages(
                            item.address,
                            currentTechIndex,
                            previousOneDate,
                            ticketIndex
                          )
                        "
                      >
                        View Images
                      </button>
                    </div>
                    <div class="col-3 form-group">
                      <label class="btn btn-primary btn-sm">
                        {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
                        <input
                          type="file"
                          style="display: none"
                          accept="image/*"
                          multiple="true"
                          [disabled]="lockUser"
                          (change)="
                            onFileChanged(
                              $event,
                              item.address,
                              item.soNum +
                                '-' +
                                item.wtNum +
                                '-' +
                                item.wtStepNum
                            )
                          "
                        />
                      </label>
                      <div>
                        <small *ngIf="fileNames.length !== 0">
                          {{ fileNames.join(' ') }}
                        </small>
                      </div>
                      {{ imageUploadComplete ? 'Image Uploaded' : '' }}
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          sendTicket(
                            currentTechIndex,
                            previousOneDate,
                            ticketIndex
                          )
                        "
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].tickets[
                            previousOneDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            previousOneDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
          <ng-container
            *ngFor="
              let itemhistory of techList[currentTechIndex].history[
                previousOneDate
              ]?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total History Tickets {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.subdivision | titlecase }}
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ itemhistory.notes }}
                  </li>
                </ul>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
              >
                <form>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group"></div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketHistoryImages(
                            itemhistory.address,
                            currentTechIndex,
                            previousOneDate,
                            ticketIndex
                          );
                          itemhistory.displayImages === false
                            ? (itemhistory.displayImages = true)
                            : (itemhistory.displayImages = false)
                        "
                      >
                        View Images
                      </button>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="itemhistory.displayImages">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].history[
                            previousOneDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenHistoryImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            previousOneDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>

      <!-- * Current Day Tickets -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto text-center" style="margin-top: 0.5rem">
                <h5>{{ currentDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showTodayTickets"
                  (click)="showTodayTickets = !showTodayTickets"
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showTodayTickets"
                  (click)="showTodayTickets = !showTodayTickets"
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].tickets[currentDate]?.punch.length > 0
            ) && showTodayTickets
          "
        >
          <h3 class="text-primary">No Tickets To Do For This Date</h3>
        </div>
        <ul *ngIf="showTodayTickets" class="list-group list-group-flush">
          <ng-container
            *ngFor="
              let item of techList[currentTechIndex].tickets[currentDate]
                ?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total Tickets To Do {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <!-- <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold">{{item.phase | titlecase}}</li>
                    <li class="list-group-item col-5 border-0 p-0 text-sm"><span class="badge badge-dark" *ngIf="item.phase.toLowerCase() === 'punch'">{{item.punchTime}}</span></li>
                    <li class="list-group-item col-1 border-0 p-0 text-sm"><span class="badge badge-primary badge-pill" *ngIf="!item.disabled">{{item.dip}}</span><span *ngIf="item.disabled && !item.updated"><i class="fas fa-cog fa-spin fa-2x"></i></span></li>
                </ul> -->
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.subdivision | titlecase }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="
                        openModal(
                          qrImage,
                          currentTechIndex,
                          currentDate,
                          ticketIndex
                        )
                      "
                    >
                      QR Code
                    </button>
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ item.description }}
                  </li>
                </ul>
                <i>Days in Progress: {{ item.dip }}</i>
              </li>
              <li class="list-group-item col-12 p-0">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block"
                  [disabled]="lockUser"
                  [ngClass]="{ 'btn-success': item.updated }"
                  (click)="
                    editTicket(currentTechIndex, currentDate, ticketIndex)
                  "
                >
                  Update {{ item.address }}
                </button>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
                *ngIf="item.displayEdit"
              >
                <form>
                  <div class="form-group">
                    <label for="notes">Notes</label>
                    <textarea
                      class="form-control"
                      id="notes"
                      name="notes"
                      rows="3"
                      maxlength="500"
                      [(ngModel)]="item.notes"
                    >
                    {{ item.notes }}</textarea
                    >
                    <mat-hint class="hints">
                      {{ item.notes.length }} / 500</mat-hint
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-12 col-md-3 form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="complete"
                          name="complete"
                          [(ngModel)]="item.complete"
                          [disabled]="lockUser"
                        />
                        <label class="custom-control-label" for="complete"
                          >Complete?</label
                        >
                      </div>
                    </div>
                    <!-- <div class="form-group">
                        <label class="control-label col-md-4">Images</label>
                        <div class="col-md-8">
                          <label
                            class="btn btn-primary"
                          >
                            {{ isImageLoading ? "Uploading" : "Upload Images" }}
                            <input
                              style="display: none;"
                              accept="image/*"
                              multiple="true"
                              id="uploadBtn"
                              type="file"
                              (change)="onFileChanged($event)"
                            />
                          </label>
                          <div>
                            <small *ngIf="fileNames.length !== 0">{{
                              fileNames.join(" ")
                            }}</small>
                          </div>
                          {{ imageUploadComplete ? "Selected Image Uploaded" : "" }}
                        </div>
                      </div> -->
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketImages(
                            item.address,
                            currentTechIndex,
                            currentDate,
                            ticketIndex
                          )
                        "
                      >
                        View Images
                      </button>
                    </div>
                    <div class="col-3 form-group">
                      <label class="btn btn-secondary btn-sm">
                        {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
                        <input
                          type="file"
                          style="display: none"
                          accept="image/*"
                          multiple="true"
                          [disabled]="lockUser"
                          (change)="
                            onFileChanged(
                              $event,
                              item.address,
                              item.soNum +
                                '-' +
                                item.wtNum +
                                '-' +
                                item.wtStepNum
                            )
                          "
                        />
                      </label>
                      <div>
                        <small *ngIf="fileNames.length !== 0">
                          {{ fileNames.join(' ') }}
                        </small>
                      </div>
                      {{ imageUploadComplete ? 'Image Uploaded' : '' }}
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          sendTicket(currentTechIndex, currentDate, ticketIndex)
                        "
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].tickets[
                            currentDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            currentDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
                <!-- <ul class="list-group list-group-horizontal">
                    <li class="list-group-item col-6 border-0 p-0 text-sm">
                        <textarea class="" type="text" value="" id="notes"></textarea>
                    </li>
                    <li class="list-group-item col-5 border-0 p-0 text-sm">
                        <input class="" type="checkbox" value="" id="complete">
                    </li>
                    <li class="list-group-item col-1 border-0 p-0 text-sm"><button type="button" class="btn btn-secondary btn-sm">Send</button></li>
                </ul> -->
              </li>
            </ul>
          </ng-container>

          <ng-container
            *ngFor="
              let itemhistory of techList[currentTechIndex].history[currentDate]
                ?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total History Tickets {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ itemhistory.subdivision | titlecase }}
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ itemhistory.notes }}
                  </li>
                </ul>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
              >
                <form>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group"></div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketHistoryImages(
                            itemhistory.address,
                            currentTechIndex,
                            currentDate,
                            ticketIndex
                          );
                          itemhistory.displayImages === false
                            ? (itemhistory.displayImages = true)
                            : (itemhistory.displayImages = false)
                        "
                      >
                        View Images
                      </button>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="itemhistory.displayImages">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].history[
                            currentDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenHistoryImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            currentDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>
      <!-- * 1 Day Tickets -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto" style="margin-top: 0.5rem">
                <h5>{{ oneDayDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showOneDayTickets"
                  (click)="showOneDayTickets = !showOneDayTickets"
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showOneDayTickets"
                  (click)="showOneDayTickets = !showOneDayTickets"
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].tickets[oneDayDate]?.punch.length > 0
            ) && showOneDayTickets
          "
        >
          <h3 class="text-primary">No Tickets To Do For This Date</h3>
        </div>
        <ul *ngIf="showOneDayTickets" class="list-group list-group-flush">
          <ng-container
            *ngFor="
              let item of techList[currentTechIndex].tickets[oneDayDate]?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total Tickets To Do {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.subdivision | titlecase }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="
                        openModal(
                          qrImage,
                          currentTechIndex,
                          oneDayDate,
                          ticketIndex
                        )
                      "
                    >
                      QR Code
                    </button>
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ item.description }}
                  </li>
                </ul>
              </li>
              <li class="list-group-item col-12 p-0">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block"
                  [disabled]="lockUser"
                  [ngClass]="{ 'btn-success': item.updated }"
                  (click)="
                    editTicket(currentTechIndex, oneDayDate, ticketIndex)
                  "
                >
                  Update {{ item.address }}
                </button>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
                *ngIf="item.displayEdit"
              >
                <form>
                  <div class="form-group">
                    <label for="notes">Notes</label>
                    <textarea
                      class="form-control"
                      id="notes"
                      name="notes"
                      rows="3"
                      maxlength="500"
                      [(ngModel)]="item.notes"
                    >
                    {{ item.notes }}</textarea
                    >
                    <mat-hint class="hints">
                      {{ item.notes.length }} / 500</mat-hint
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="complete"
                          name="complete"
                          [(ngModel)]="item.complete"
                        />
                        <label class="custom-control-label" for="complete"
                          >Complete?</label
                        >
                      </div>
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketImages(
                            item.address,
                            currentTechIndex,
                            oneDayDate,
                            ticketIndex
                          )
                        "
                      >
                        View Images
                      </button>
                    </div>
                    <div class="col-3 form-group">
                      <label class="btn btn-primary btn-sm">
                        {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
                        <input
                          type="file"
                          style="display: none"
                          accept="image/*"
                          multiple="true"
                          [disabled]="lockUser"
                          (change)="
                            onFileChanged(
                              $event,
                              item.address,
                              item.soNum +
                                '-' +
                                item.wtNum +
                                '-' +
                                item.wtStepNum
                            )
                          "
                        />
                      </label>
                      <div>
                        <small *ngIf="fileNames.length !== 0">
                          {{ fileNames.join(' ') }}
                        </small>
                      </div>
                      {{ imageUploadComplete ? 'Image Uploaded' : '' }}
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          sendTicket(currentTechIndex, oneDayDate, ticketIndex)
                        "
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].tickets[
                            oneDayDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            oneDayDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>

      <!-- Find previous day  -->

      <!-- * 2 Day Tickets -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto" style="margin-top: 0.5rem">
                <h5>{{ twoDayDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showTwoDayTickets"
                  (click)="showTwoDayTickets = !showTwoDayTickets"
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showTwoDayTickets"
                  (click)="showTwoDayTickets = !showTwoDayTickets"
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].tickets[twoDayDate]?.punch.length > 0
            ) && showTwoDayTickets
          "
        >
          <h3 class="text-primary">No Tickets To Do For This Date</h3>
        </div>
        <ul *ngIf="showTwoDayTickets" class="list-group list-group-flush">
          <ng-container
            *ngFor="
              let item of techList[currentTechIndex].tickets[twoDayDate]?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total Tickets To Do {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.subdivision | titlecase }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="
                        openModal(
                          qrImage,
                          currentTechIndex,
                          twoDayDate,
                          ticketIndex
                        )
                      "
                    >
                      QR Code
                    </button>
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ item.description }}
                  </li>
                </ul>
              </li>
              <li class="list-group-item col-12 p-0">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block"
                  [disabled]="lockUser"
                  [ngClass]="{ 'btn-success': item.updated }"
                  (click)="
                    editTicket(currentTechIndex, twoDayDate, ticketIndex)
                  "
                >
                  Update {{ item.address }}
                </button>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
                *ngIf="item.displayEdit"
              >
                <form>
                  <div class="form-group">
                    <label for="notes">Notes</label>
                    <textarea
                      class="form-control"
                      id="notes"
                      name="notes"
                      rows="3"
                      maxlength="500"
                      [(ngModel)]="item.notes"
                    >
                    {{ item.notes }}</textarea
                    >
                    <mat-hint class="hints">
                      {{ item.notes.length }} / 500</mat-hint
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="complete"
                          name="complete"
                          [(ngModel)]="item.complete"
                        />
                        <label class="custom-control-label" for="complete"
                          >Complete?</label
                        >
                      </div>
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketImages(
                            item.address,
                            currentTechIndex,
                            twoDayDate,
                            ticketIndex
                          )
                        "
                      >
                        View Images
                      </button>
                    </div>
                    <div class="col-3 form-group">
                      <label class="btn btn-secondary btn-sm">
                        {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
                        <input
                          type="file"
                          style="display: none"
                          accept="image/*"
                          multiple="true"
                          [disabled]="lockUser"
                          (change)="
                            onFileChanged(
                              $event,
                              item.address,
                              item.soNum +
                                '-' +
                                item.wtNum +
                                '-' +
                                item.wtStepNum
                            )
                          "
                        />
                      </label>
                      <div>
                        <small *ngIf="fileNames.length !== 0">
                          {{ fileNames.join(' ') }}
                        </small>
                      </div>
                      {{ imageUploadComplete ? 'Image Uploaded' : '' }}
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          sendTicket(currentTechIndex, twoDayDate, ticketIndex)
                        "
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].tickets[
                            twoDayDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            twoDayDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>
      <!-- * 3 Day Tickets -->
      <div class="card m-1 bg-dark">
        <div class="card-header p-1">
          <div class="row">
            <div class="col-12 d-flex text-white">
              <div class="ml-auto" style="margin-top: 0.5rem">
                <h5>{{ threeDayDate | date: 'fullDate' }}</h5>
              </div>
              <div class="ml-auto" style="margin-top: 0.5rem">
                <i
                  *ngIf="showThreeDayTickets"
                  (click)="showThreeDayTickets = !showThreeDayTickets"
                  class="far fa-caret-square-down section-arrows"
                ></i>
                <i
                  *ngIf="!showThreeDayTickets"
                  (click)="showThreeDayTickets = !showThreeDayTickets"
                  class="far fa-caret-square-left section-arrows"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            !(
              techList[currentTechIndex].tickets[threeDayDate]?.punch.length > 0
            ) && showThreeDayTickets
          "
        >
          <h3 class="text-primary">No Tickets To Do For This Date</h3>
        </div>
        <ul *ngIf="showThreeDayTickets" class="list-group list-group-flush">
          <ng-container
            *ngFor="
              let item of techList[currentTechIndex].tickets[threeDayDate]
                ?.punch;
              let ticketIndex = index;
              let c = count;
              let isFirst = first
            "
          >
            <li
              class="list-group-item col-sm-12 border-dark p-0 text-sm font-weight-bold text-center"
              *ngIf="isFirst"
            >
              Total Tickets To Do {{ c }}
            </li>
            <ul class="list-group">
              <li class="list-group-item col-12">
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.address }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    {{ item.subdivision | titlecase }}
                  </li>
                  <li class="list-group-item border-0 p-0 text-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="
                        openModal(
                          qrImage,
                          currentTechIndex,
                          threeDayDate,
                          ticketIndex
                        )
                      "
                    >
                      QR Code
                    </button>
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal-sm">
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                  >
                    {{ item.description }}
                  </li>
                </ul>
              </li>
              <li class="list-group-item col-12 p-0">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block"
                  [disabled]="lockUser"
                  [ngClass]="{ 'btn-success': item.updated }"
                  (click)="
                    editTicket(currentTechIndex, threeDayDate, ticketIndex)
                  "
                >
                  Update {{ item.address }}
                </button>
              </li>
              <li
                class="list-group-item col-12 border-dark"
                style="border-bottom-width: 1px"
                *ngIf="item.displayEdit"
              >
                <form>
                  <div class="form-group">
                    <label for="notes">Notes</label>
                    <textarea
                      class="form-control"
                      id="notes"
                      name="notes"
                      rows="3"
                      maxlength="500"
                      [(ngModel)]="item.notes"
                    >
                    {{ item.notes }}</textarea
                    >
                    <mat-hint class="hints">
                      {{ item.notes.length }} / 500</mat-hint
                    >
                  </div>
                  <div class="form-row">
                    <div class="col-6 col-md-3 form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="complete"
                          name="complete"
                          [(ngModel)]="item.complete"
                        />
                        <label class="custom-control-label" for="complete"
                          >Complete?</label
                        >
                      </div>
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          onGetTicketImages(
                            item.address,
                            currentTechIndex,
                            threeDayDate,
                            ticketIndex
                          )
                        "
                      >
                        View Images
                      </button>
                    </div>
                    <div class="col-3 form-group">
                      <label class="btn btn-secondary btn-sm">
                        {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
                        <input
                          type="file"
                          style="display: none"
                          accept="image/*"
                          multiple="true"
                          [disabled]="lockUser"
                          (change)="
                            onFileChanged(
                              $event,
                              item.address,
                              item.soNum +
                                '-' +
                                item.wtNum +
                                '-' +
                                item.wtStepNum
                            )
                          "
                        />
                      </label>
                      <div>
                        <small *ngIf="fileNames.length !== 0">
                          {{ fileNames.join(' ') }}
                        </small>
                      </div>
                      {{ imageUploadComplete ? 'Image Uploaded' : '' }}
                    </div>
                    <div class="col-3 form-group">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="lockUser"
                        (click)="
                          sendTicket(
                            currentTechIndex,
                            threeDayDate,
                            ticketIndex
                          )
                        "
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="col-12 d-flex justify-content-center overflow-auto"
                    >
                      <img
                        *ngFor="
                          let url of techList[currentTechIndex].tickets[
                            threeDayDate
                          ]?.punch[ticketIndex]?.images;
                          index as index
                        "
                        src="{{ url }}"
                        (click)="
                          onOpenImageModal(
                            imageExpandModal,
                            currentTechIndex,
                            threeDayDate,
                            ticketIndex,
                            index
                          )
                        "
                        width="10%"
                        class="mr-2"
                      />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
