<div class="container pb-5">
  <div class="card border-0 my-5 shadow-lg">
    <ng-container *ngIf="!receiptSelected">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{
              receiptOfGoodsLoading
                ? 'Receipt Of Goods Loading...'
                : 'Receipt Of Goods with Discrepancies'
            }}
          </h5>
        </div>
        <div class="card-body">
          <div class="row mx-0">
            <div
              class="card-body text-center"
              *ngIf="
                receiptOfGoodsPending.length === 0 && !receiptOfGoodsLoading
              "
            >
              <h5 class="card-title">
                There Are No Receipt Of Goods With Discrepancies!
              </h5>
            </div>
            <ng-container
              *ngFor="
                let goods of receiptOfGoodsPending;
                let goodsIndex = index
              "
            >
              <div class="col-md-6 col-xl-4 my-3">
                <div class="card shadow-lg">
                  <div class="card-body">
                    <h5 class="mb-0">
                      ReceiptNo: {{ goods.ReceiptNo }}
                      <span class="badge badge-pill badge-dark float-right">
                        {{ numberOfErrors(goodsIndex) }} Discrepancies</span
                      >
                    </h5>
                    <div class="row" *ngIf="goods.OverReceivedReceipt == 'Y'">
                      <div class="col text-right">
                        <span class="badge badge-pill badge-danger">
                          Over Received
                        </span>
                      </div>
                    </div>
                    <h5>Receipt Date: {{ goods.ReceiptDate | date }}</h5>
                    <h5>Invoice No: {{ goods.InvoiceNo }}</h5>
                    <h5>PurchaseOrder: {{ goods.PurchaseOrderNo }}</h5>
                    <h5>Vendor: {{ goods.VendorNo }}</h5>
                    <h5>Batch: {{ goods.BatchNo }}</h5>
                    <button
                      class="btn btn-primary btn-block"
                      (click)="onReceiptSelected(goodsIndex)"
                    >
                      Explore
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="receiptSelected">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col text-right">
              <span class="badge badge-pill badge-dark">
                Discrepancies Count {{ numberOfErrors(receiptSelectedIndex) }}
              </span>
            </div>
          </div>
          <div
            class="row"
            *ngIf="
              receiptOfGoodsPending[receiptSelectedIndex].OverReceivedReceipt ==
              'Y'
            "
          >
            <div class="col text-right">
              <span class="badge badge-pill badge-danger"> Over Received </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-sm-5">ReceiptNo</div>
                <div class="col-sm-7">
                  {{ receiptOfGoodsPending[receiptSelectedIndex].ReceiptNo }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">Purchase Order No</div>
                <div class="col-sm-7">
                  {{
                    receiptOfGoodsPending[receiptSelectedIndex].PurchaseOrderNo
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">BatchNo</div>
                <div class="col-sm-7">
                  {{ receiptOfGoodsPending[receiptSelectedIndex].BatchNo }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-sm-5">InvoiceNo</div>
                <div class="col-sm-7">
                  {{ receiptOfGoodsPending[receiptSelectedIndex].InvoiceNo }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">VendorNo</div>
                <div class="col-sm-7">
                  {{ receiptOfGoodsPending[receiptSelectedIndex].VendorNo }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body mx-3">
          <div class="row mb-3 shadow">
            <div class="col pr-0">
              <ng-container
                *ngFor="
                  let item of receiptOfGoodsPending[receiptSelectedIndex].Lines;
                  let itemIndex = index;
                  let isFirst = first
                "
              >
                <div class="row">
                  <ul
                    class="list-group list-group-flush list-group-horizontal col-12"
                    *ngIf="isFirst"
                  >
                    <li
                      class="list-group-item list-group-item-primary border-top-0 rounded-left col-sm-3 py-1"
                    >
                      Info
                      <span class="badge badge-pill badge-dark float-right">
                      </span>
                    </li>
                    <li
                      class="list-group-item list-group-item-primary border-top-0 rounded-0 col-sm-3 py-1 text-center"
                    >
                      On Shipment
                    </li>
                    <li
                      class="list-group-item list-group-item-primary border-top-0 rounded-0 col-sm-3 py-1 text-center"
                    >
                      On Receipt
                    </li>
                    <li
                      class="list-group-item list-group-item-primary border-top-0 rounded-right col-sm-3 py-1 text-center"
                    >
                      On PO
                    </li>
                  </ul>
                  <ng-container
                    *ngIf="!itemDiscrepancy(itemIndex, receiptSelectedIndex)"
                  >
                    <ul class="list-group list-group-horizontal col-12">
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-3"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        No Discrepancy Item
                      </li>
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-9 text-center"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        <span class="badge badge-pill badge-success">
                          Received: {{ item.RogItemCode }} Qty:
                          {{ item.RogQuantity | number: '1.2-2' }}
                        </span>
                      </li>
                    </ul>
                  </ng-container>

                  <ng-container
                    *ngIf="itemDiscrepancy(itemIndex, receiptSelectedIndex)"
                  >
                    <ul class="list-group list-group-horizontal col-12">
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-3"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        Item Code
                      </li>
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-3"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.ItemCodeActual }}
                        <small
                          ><br />
                          {{ item.ItemCodeActual ? 'Line:' : '' }}
                          {{ item.POLineKey }}</small
                        >
                      </li>
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-3"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.RogItemCode }}
                        <small
                          ><br />
                          {{ item.RogItemCode ? 'Line:' : '' }}
                          {{ item.ROGOrderLineKey }}</small
                        >
                      </li>
                      <li
                        class="list-group-item py-1 rounded-0 rounded-top col-sm-3"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.POItemCode }}
                        <small
                          ><br />
                          {{ item.POItemCode ? 'Line:' : '' }}
                          {{ item.LineKey }}</small
                        >
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        Description
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.ItemDescription }}
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.RogItemCodeDesc }}
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{ item.POItemCodeDesc }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        Quantity
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{
                          item.ItemCodeActual
                            ? item.QtyReceived / 1
                            : ('' | number: '1.2-2')
                        }}
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{
                          item.RogItemCode
                            ? item.RogQuantity
                            : ('' | number: '1.2-2')
                        }}
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        {{
                          item.POItemCode
                            ? item.POQuantityOrdered
                            : ('' | number: '1.2-2')
                        }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal col-12">
                      <li
                        class="list-group-item py-1 col-sm-3 border-top-0 rounded-0"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        Discrepancies
                      </li>
                      <li
                        class="list-group-item py-1 col-sm-9 rounded-0 rounded-top text-center"
                        [ngClass]="{
                          'list-group-item-dark': itemIndex % 2 !== 0
                        }"
                      >
                        <span
                          class="badge badge-pill badge-primary mx-2"
                          *ngIf="
                            item.ItemExistsInRog === 1 &&
                            item.ItemExistsInHistory === 1 &&
                            item.QuantitiesMatch === 0
                          "
                        >
                          Edi & Rog Quantities
                        </span>
                        <span
                          class="badge badge-pill badge-primary mx-2"
                          *ngIf="
                            item.ItemsMatch === 0 && item.ItemExistsInRog === 0
                          "
                        >
                          Missing From R.O.G.
                        </span>
                        <span
                          class="badge badge-pill badge-primary mx-2"
                          *ngIf="
                            item.ItemsMatch === 0 &&
                            item.ItemExistsInHistory === 0
                          "
                        >
                          Missing From Edi
                        </span>
                        <span
                          class="badge badge-pill badge-primary mx-2"
                          *ngIf="
                            item.ItemsMatchPO === 0 && item.ItemExistsInPO === 0
                          "
                        >
                          Missing From P.O.
                        </span>
                        <span
                          class="badge badge-pill badge-primary mx-2"
                          *ngIf="
                            item.ItemsMatchPO === 1 &&
                            item.ItemsQuantityValid === 0
                          "
                        >
                          Edi'd > Ordered
                        </span>
                      </li>
                    </ul>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container></ng-container>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-4">
              <div class="float-left">
                <button
                  class="btn btn-secondary pr-5 pl-5"
                  (click)="onReceiptDeselected()"
                >
                  Back
                </button>
              </div>
            </div>
            <div class="col-4 text-center">
              <a
                class="btn p-0"
                (click)="openModal(fileExplorer)"
                (click)="fileNames(receiptOfGoodsPending[receiptSelectedIndex])"
                ><i class="far fa-folder-open fa-3x text-dark"></i
              ></a>
            </div>
            <div class="col-4">
              <div class="float-right">
                <button
                  class="btn btn-primary pr-5 pl-5"
                  (click)="openModal(approvalNotes)"
                  [disabled]="receiptOfGoodsUpdating"
                >
                  <span *ngIf="!receiptOfGoodsUpdating">Approve Rog</span
                  ><span *ngIf="receiptOfGoodsUpdating"
                    ><i class="fas fa-sync fa-spin"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #fileExplorer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          File Contents for PO:
          {{
            receiptOfGoodsPending[receiptSelectedIndex].PurchaseOrderNo
              | titlecase
          }}
        </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="files">
      <div class="col-3 pt-2">
        <div class="card text-center">
          <label class="pt-2 mb-0">
            <i class="fas fa-file-upload fa-4x text-info"></i>
            <input
              type="file"
              (change)="fileChosen($event)"
              style="display: none"
            />
          </label>
          <small>Upload</small>
          <ng-container *ngFor="let file of fileArray">
            <br />
            <small> {{ file.name }}</small>
          </ng-container>
        </div>
      </div>
      <div class="col-3 pt-2" *ngFor="let file of fileList">
        <div class="card text-center">
          <a
            class="btn p-0 pt-2"
            (click)="
              fileDownload(
                receiptOfGoodsPending[receiptSelectedIndex],
                file.name
              )
            "
            ><i class="fas fa-file-alt fa-4x"></i
          ></a>
          <small>{{ file.name }}</small>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
          <button
            class="btn btn-info float-right mr-2"
            (click)="fileUpload(receiptOfGoodsPending[receiptSelectedIndex])"
            *ngIf="fileArray.length > 0"
          >
            <span *ngIf="!fileLoading">Upload</span
            ><span *ngIf="fileLoading"
              ><i class="fas fa-sync fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #approvalNotes>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          Approval Notes For Receipt:
          {{
            receiptOfGoodsPending[receiptSelectedIndex].ReceiptNo | titlecase
          }}
        </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mx-3">
      <div class="col-12 mb-2">
        <textarea
          class="form-control"
          name="rogNotes"
          rows="5"
          [(ngModel)]="
            receiptOfGoodsPending[receiptSelectedIndex].ResolutionNotes
          "
          placeholder="Enter Notes about Discrepancy Resolution"
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center mt-3 mb-3">
        <div class="float-right">
          <button
            class="btn btn-primary pr-5 pl-5"
            (click)="
              onApproveReceiptOfGoods(
                receiptOfGoodsPending[receiptSelectedIndex],
                receiptSelectedIndex
              )
            "
            [disabled]="
              receiptOfGoodsUpdating ||
              receiptOfGoodsPending[receiptSelectedIndex].ResolutionNotes === ''
            "
          >
            <span *ngIf="!receiptOfGoodsUpdating"> Approve Rog </span>
            <span *ngIf="receiptOfGoodsUpdating">
              <i class="fas fa-sync fa-spin"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
