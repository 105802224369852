import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, forkJoin } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import ContextWarehousePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextWarehousePartDtoInterface';
import WarehouseDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/WarehouseDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import ParseCsv, { ParsedCsvInterface } from 'src/app/utils/ParseCsv';

export interface PhysicalCountVarianceDataInterface {
  Part_Code: string;
  Part_Desc: string;
  WarehouseCode: string;
  BinLocation: string;
  QuantityOnHand: number;
  counted: number;
  variance: number;
  StandardCost: number;
  varianceCost: number;
}

@Component({
  selector: 'app-physical-count-variance',
  templateUrl: './PhysicalCountVarianceComponent.html',
  styleUrls: ['./PhysicalCountVarianceComponent.scss'],
  providers: [{ provide: 'loading', useClass: MultiIsLoadingService }],
})
export class PhysicalCountVarianceComponent {
  file: any;
  csv: ParsedCsvInterface | null = null;
  parts: ContextWarehousePartDtoInterface[] = [];
  warehouses: WarehouseDtoInterface[] = [];

  tableData: PhysicalCountVarianceDataInterface[] = [];
  totalQuantityOnHand = 0;
  totalCounted = 0;
  totalVariance = 0;
  totalVarianceCost = 0;

  constructor(
    private api: SageApiService,
    private snkbr: MatSnackBar,
    @Inject('loading') public loading: MultiIsLoadingService
  ) {}

  onFileChange(event: any) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.tryParseCsv(fileReader.result as string);
    };
    fileReader.readAsText(this.file);
  }

  tryParseCsv(csvStr: string) {
    const lid = this.loading.startLoading();
    const acceptedHeaders = ['Whse Code', 'bin', 'Item Code', 'counted'];
    const altAcceptedHeaders = [
      'Warehouse',
      'Bin Location',
      'ItemCode',
      'QuantityCounted',
    ];
    try {
      const parsed = ParseCsv(csvStr);
      // Check that the headers are acceptedHeaders
      if (
        JSON.stringify(parsed.headers) != JSON.stringify(acceptedHeaders) &&
        JSON.stringify(parsed.headers) != JSON.stringify(altAcceptedHeaders)
      ) {
        this.loading.stopLoading([lid]);
        throw new Error('Invalid headers');
      }
      // If you are using the alt headers, rename the headers to the accepted headers
      if (
        JSON.stringify(parsed.headers) == JSON.stringify(altAcceptedHeaders)
      ) {
        parsed.headers = acceptedHeaders;
        parsed.data = parsed.data.map(row => {
          return {
            'Whse Code': row['Warehouse'],
            bin: row['Bin Location'],
            'Item Code': row['ItemCode'],
            counted: row['QuantityCounted'],
          };
        });
      }
      this.csv = parsed;

      // Make a list of all the Whse Codes, then distict them
      const allWhseCodes = parsed.data.map(row => row['Whse Code'] as string);
      const distinctWhseCodes = [...new Set(allWhseCodes)];

      const whpReqData: { [key: string]: string[] } = {};

      // Go over each distict wh code, and get the parts for each and put them in whpReqData where the field is the wh code and the value is an array of the partCodes
      distinctWhseCodes.forEach(wc => {
        if (!whpReqData[wc] && !Array.isArray(whpReqData[wc])) {
          whpReqData[wc] = [];
        }
        parsed.data.forEach(row => {
          if (row['Whse Code'] === wc) {
            whpReqData[wc].push(`${row['Item Code']}`);
          }
        });
      });
      // Now remove any duplicates from the arrays
      Object.keys(whpReqData).forEach(key => {
        whpReqData[key] = [...new Set(whpReqData[key])];
      });

      this.loading.stopLoading([lid]);

      // Call pullReport(`warehouse-parts/${whCode}?ItemCode=${itemCode}&ItemCode=${itemCode}&ItemCode=${itemCode}`) for each field in whpReqData
      const obs = Object.keys(whpReqData).map(whCode => {
        const searchParams = new URLSearchParams();
        for (const pc of whpReqData[whCode]) {
          searchParams.append('ItemCode', pc);
        }
        const ob = new Observable(observer => {
          this.loading
            .loadingUntilComplete(
              this.api.pullReport(`warehouse-parts/${whCode}?${searchParams}`)
            )
            .subscribe((parts: ContextWarehousePartDtoInterface[]) => {
              this.parts = [...this.parts, ...parts];
              observer.next();
              observer.complete();
            });
        }).pipe(shareReplay());
        ob.subscribe();
        return ob;
      });

      // When all the obs are complete, go over the parsed data and create the tableData
      forkJoin(obs).subscribe(() => {
        this.tableData = parsed.data.map(row => {
          const whp = this.parts.find(
            p => p.Part.Part.Part_Code === row['Item Code']
          );
          const tableRow: PhysicalCountVarianceDataInterface = {
            Part_Code: whp.Part.Part.Part_Code,
            Part_Desc: whp.Part.Part.Part_Desc,
            WarehouseCode: `${row['Whse Code']}`,
            BinLocation: `${row['bin']}`,
            QuantityOnHand: whp.WarehousePart.QuantityOnHand,
            counted: Number(row['counted']),
            variance:
              -1 * (whp.WarehousePart.QuantityOnHand - Number(row['counted'])),
            StandardCost: whp.Cost,
            varianceCost:
              -1 *
              ((whp.WarehousePart.QuantityOnHand - Number(row['counted'])) *
                whp.Cost),
          };
          return tableRow;
        });
      });
    } catch (e) {
      console.log(e);
      this.snkbr.open('Invalid CSV', 'Close', {
        duration: Infinity,
      });
    }
  }

  normalizeMoney(row: PhysicalCountVarianceDataInterface, val: string): string {
    let str = `$${parseFloat(val).toFixed(2)}`;
    if (str.includes('-')) {
      str = `-${str.replace('-', '')}`;
    }
    return str;
  }

  normalizeMoneyStr(val: string | number): string {
    let str = `$${parseFloat(`${val}`).toFixed(2)}`;
    if (str.includes('-')) {
      str = `-${str.replace('-', '')}`;
    }
    return str;
  }

  filtered(fdata: PhysicalCountVarianceDataInterface[]) {
    // Loop over each fdata and calculate the totals
    this.totalQuantityOnHand = fdata.reduce(
      (acc, cur) => acc + cur.QuantityOnHand,
      0
    );
    this.totalCounted = fdata.reduce((acc, cur) => acc + cur.counted, 0);
    this.totalVariance = fdata.reduce((acc, cur) => acc + cur.variance, 0);
    this.totalVarianceCost = fdata.reduce(
      (acc, cur) => acc + cur.varianceCost,
      0
    );
  }
}
