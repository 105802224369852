<app-bottom-sheet
  *ngIf="qopkp != null"
  [open]="open"
  (sheetClosed)="sheetClosed.emit()"
  [sheetWidth]="screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'"
  sheetBackground="#fafafa"
  class="editpartbs"
>
  <div class="editpartbs-header">
    <h3 class="editpartbs-title">
      Edit: {{ qopkp.data?.QuoteOptionPart?.QuotePart.QuotePart_Code }}
    </h3>
    <div class="editpartbs-header-controls">
      <mat-form-field
        *ngIf="qopkp.data.QuoteOptionKit != null"
        appearance="outline"
        class="editpartbs-header-controls-ff editpartbs-header-controls-quantity"
      >
        <mat-label>Name</mat-label>
        <input
          [formControl]="nameControl"
          [disabled]="loading.isLoading()"
          matInput
          type="text"
          class="editpartbs-header-controls-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="editpartbs-header-controls-ff editpartbs-header-controls-quantity"
      >
        <mat-label>Quantity</mat-label>
        <input
          [formControl]="quantityControl"
          [disabled]="loading.isLoading()"
          appDigitOnly
          min="0"
          max="9999"
          matInput
          type="text"
          class="editpartbs-header-controls-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="qopkp.data.QuoteOptionPart != null"
        appearance="outline"
        class="editpartbs-header-controls-ff editpartbs-header-controls-phase"
      >
        <mat-label>Phase</mat-label>
        <mat-select
          [formControl]="phaseControl"
          class="kitpartrow-card-header-front-phase-ff-sel"
        >
          <mat-option
            *ngFor="let phase of kitPartPhases"
            [value]="phase.valueOf()"
            >{{ phase.toString() }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-form-field
    *ngIf="qopkp.data.QuoteOptionKit != null"
    appearance="outline"
    class="editpartbs-desc-ff editpartbs-ff"
  >
    <mat-label>Description</mat-label>
    <textarea
      [formControl]="descControl"
      matInput
      type="text"
      class="quoteside-desc-ff-in"
    ></textarea>
  </mat-form-field>
  <p *ngIf="qopkp.data.QuoteOptionPart != null" class="editpartbs-desc">
    {{ qopkp.data.QuoteOptionPart.QuotePart.QuotePart_Desc }}
  </p>
  <div class="editpartbs-actions">
    <button
      (click)="sheetClosed.emit()"
      color="warn"
      mat-flat-button
      class="editpartbs-actions-cancel"
    >
      Cancel
    </button>
    <button
      [disabled]="isSaveDisabled()"
      (click)="saveChanges()"
      color="primary"
      mat-flat-button
      class="editpartbs-actions-cancel"
    >
      Save
    </button>
  </div>
</app-bottom-sheet>
