import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HouseService } from 'src/app/_services/HouseService/HouseService';
import HouseSearchItemDtointerface from 'src/app/_services/HouseService/interfaces/HouseSearchItemDtointerface';

@Component({
  selector: 'app-house-search-view',
  templateUrl: './HouseSearchViewComponent.html',
  styleUrls: ['./HouseSearchViewComponent.scss'],
})
export class HouseSearchViewComponent {
  houses: HouseSearchItemDtointerface[] = [];
  houseSearchControl = new FormControl('');
  houseSearchSize = 20;
  houseSearchCanLoadMore = false;

  constructor(private hs: HouseService) {}

  searchHouses() {
    this.hs
      .searchHouses({
        search: this.houseSearchControl.value,
        size: this.houseSearchSize,
      })
      .subscribe((houses: HouseSearchItemDtointerface[]) => {
        this.houses = [];
        this.houses = houses;
        if (houses.length === this.houseSearchSize) {
          this.houseSearchCanLoadMore = true;
        } else {
          this.houseSearchCanLoadMore = false;
        }
      });
  }

  nextPage() {
    this.hs
      .searchHouses({
        search: this.houseSearchControl.value,
        size: this.houseSearchSize,
        offset: this.houses.length,
      })
      .subscribe((houses: HouseSearchItemDtointerface[]) => {
        this.houses = [...this.houses, ...houses];
        if (houses.length === this.houseSearchSize) {
          this.houseSearchCanLoadMore = true;
        } else {
          this.houseSearchCanLoadMore = false;
        }
      });
  }
}
