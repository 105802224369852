import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import PayLineItemsInterface from 'src/app/_services/sageApi/interfaces/pullReport/PayLineItemsInterface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import MiscPunchCreatedInterface from 'src/app/_services/sageApi/interfaces/pullReport/MiscPunchCreatedInterface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-super-miscpay',
  templateUrl: './super-miscpay.component.html',
  styleUrls: ['./super-miscpay.component.css'],
})
export class SuperMiscpayComponent {
  isMobile = false;

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }
}
