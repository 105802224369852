import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/map';
import { Observable, from as fromPromiseToObs } from 'rxjs';
import * as xml2js from 'xml2js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

interface CachedDataInterface {
  createdAt: number; // Unix time (ms snice epoch). Used for detecting stale
  exp: number; // Milliseconds for determining if data is stale. If Date.now() is older than createdAt+exp than we consider the cached data stale
  matchCriteria: string[]; // When making a req, this is used to see if the request matches a cache response already in cachedData
  data: any; // Neccessary any - sorry not sorry - actually needed
  isResolving?: Observable<any>; // If we are already working on it, then we xpose the observable for them to attach to. After we are done, we set this to undefined
}

interface PullReportCacheOptionsInterface {
  matchCriteria: string[];
  exp: number; // Milliseconds for determining if data is stale. If Date.now() is older than createdAt+exp than we consider the cached data stale
}

@Injectable({
  providedIn: 'root',
})
export class SageApiService {
  // apiUrl = 'https://proxy.gibsonplumbing.com/API_Apps/dev_robert/SageApi/api/';
  sDataUrl = 'https://proxy.gibsonplumbing.com/sdata/MasApp/MasContract/PLU';
  // apiUrl = 'https://proxy.gibsonplumbing.com/API_Apps/dev_jon/SageApi/api/';
  // apiUrl = 'https://localhost:44302/api/'; // Very sad day :(
  apiUrl = 'https://proxy.gibsonplumbing.com/api/';

  imageUrlList: any;
  imageUrlListReports: any = [];

  static cachedData: CachedDataInterface[] = [];

  constructor(
    private httpClient: HttpClient,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  removeStaleCachedData() {
    const now = Date.now();
    for (
      let dataIndex = SageApiService.cachedData.length - 1;
      dataIndex > 0;
      dataIndex--
    ) {
      const data = SageApiService.cachedData[dataIndex];
      if (data.createdAt + data.exp < now) {
        SageApiService.cachedData.splice(dataIndex, 1);
      }
    }
  }

  clearCachedData() {
    SageApiService.cachedData = [];
  }

  pullReport(
    selectedData: string,
    cacheOptions?: PullReportCacheOptionsInterface
  ) {
    this.removeStaleCachedData();
    if (cacheOptions == undefined) {
      const url = this.apiUrl + selectedData;
      const reportAuth = localStorage.getItem('token');
      const headers = new HttpHeaders().set(
        'JWTAuthorization',
        `Bearer ${reportAuth}`
      );

      return this.httpClient.get(url, { headers });
    } else {
      const url = this.apiUrl + selectedData;
      const reportAuth = localStorage.getItem('token');
      let uniqueIdentifier = 'noUniqueIdentifier';
      if (reportAuth && reportAuth != '' && reportAuth.split('.').length >= 2) {
        const pubPartOfToken = window.atob(reportAuth.split('.')[1]);
        const jsonToken = JSON.parse(pubPartOfToken);
        if (jsonToken && jsonToken.unique_name) {
          uniqueIdentifier = jsonToken.unique_name;
        }
      }
      const matchingCriteria = [
        ...cacheOptions.matchCriteria,
        url,
        uniqueIdentifier,
      ];

      const now = Date.now();
      const matchingDataIndex = SageApiService.cachedData.findIndex(cd => {
        if (
          cd.matchCriteria.sort().join(',') ==
            matchingCriteria.sort().join(',') &&
          cd.createdAt + cd.exp >= now
        ) {
          return true;
        }
        return false;
      });
      const matchingData =
        matchingDataIndex != -1
          ? SageApiService.cachedData[matchingDataIndex]
          : undefined;
      if (matchingData != undefined && matchingData.isResolving != undefined) {
        return matchingData.isResolving;
      } else if (matchingData) {
        const newObs = fromPromiseToObs(Promise.resolve(matchingData.data));
        return newObs;
      } else {
        const headers = new HttpHeaders().set(
          'JWTAuthorization',
          `Bearer ${reportAuth}`
        );
        const newRequest = this.httpClient.get(url, { headers });
        SageApiService.cachedData.push({
          createdAt: now,
          exp: cacheOptions.exp,
          matchCriteria: matchingCriteria,
          data: undefined,
          isResolving: newRequest,
        });
        newRequest.subscribe(data => {
          const matchingDataIndexAfterSub = SageApiService.cachedData.findIndex(
            cd => {
              if (
                cd.matchCriteria.sort().join(',') ==
                matchingCriteria.sort().join(',')
              ) {
                return true;
              }
              return false;
            }
          );
          const matchingDataAfterSub =
            matchingDataIndexAfterSub >= 0
              ? SageApiService.cachedData[matchingDataIndexAfterSub]
              : undefined;
          if (matchingDataAfterSub) {
            SageApiService.cachedData[matchingDataIndexAfterSub].data = data;
            SageApiService.cachedData[
              matchingDataIndexAfterSub
            ].isResolving = undefined;
          }
        });
        return newRequest;
      }
    }
  }

  deleteRequest(selectedData) {
    const url = this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    return this.httpClient.delete(url, { headers });
  }

  getFile(selectedData: string) {
    const url = this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.get(url, {
      headers: httpHeaders,
      responseType: 'blob' as 'json',
    });
  }

  uploadFile(selectedData: string, sentfiles: any) {
    const url = this.apiUrl + selectedData;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const formData = new FormData();

    sentfiles.forEach((file, index) => {
      formData.append('file' + index, file);
    });

    return this.httpClient.post(url, formData, { headers });
  }

  putRequest(route: string, body: unknown): Observable<unknown> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.put(url, body, { headers }).map(response => {
      return response;
    });
  }

  postRequest(route: string, body: unknown): Observable<unknown> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.post(url, body, { headers }).map(response => {
      return response;
    });
  }

  postRequest2(route: string, body: unknown): Observable<unknown> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.post(url, body, { headers });
  }

  postBlob(route: string, body: unknown): Observable<Blob> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.post<Blob>(url, body, {
      headers,
      responseType: 'blob' as 'json',
    });
  }

  patchRequest(route: string, body?: unknown): Observable<unknown> {
    const url = this.apiUrl + route;
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    return this.httpClient.patch(url, body, { headers });
  }

  reassignSuper(object): Observable<unknown> {
    const url = this.apiUrl + 'ReassignSuper';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const body = {
      SalesOrderNo: object.salesOrderNo,
      WTNumber: object.wtNumber,
      WTStep: object.wtStep,
      newSuper: object.newSuper,
      oldSuper: object.oldSuper,
      reassignedBy: object.reassignedBy,
      superOverwrite: object.superOverwrite,
    };

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  updateWTStatus(object) {
    const url = this.apiUrl + 'UpdWTStatus';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );
    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      wtnumber: object.wtNumber,
      wtstep: object.wtStep,
      notes: object.notes,
      status: object.status,
    };

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  updateMaterialUsage(object) {
    const url = this.apiUrl + 'UpdMatUsage';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      editedlines: object.editedLines,
      addedlines: object.addedLines,
    };

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  QRupdateMaterialUsage(object) {
    const url = this.apiUrl + 'QRCode/Update/MAT';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    const body = {
      username: object.username,
      password: object.password,
      salesorderno: object.salesOrderNo,
      editedlines: object.editedLines,
      addedlines: object.addedLines,
    };

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  scheduleTicketSuper(object) {
    const url = this.apiUrl + 'SchSuper';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    const body = {
      SalesOrderNo: object.salesOrderNo,
      WTNumber: object.wtNumber,
      WTStep: object.wtStep,
      ScheduleDate: object.newScheduleDate,
      ScheduleOrder: object.Order,
      ScheduleOrderEpoch: object.OrderTime,
      reassignedBy: object.updatedBy,
    };
    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }

  scheduleConfirmationSubmittal(object) {
    const url = this.apiUrl + 'ScheduleConfirmation';
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    const body = object;

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }
}
