<app-bottom-sheet
  [open]="open"
  (sheetClosed)="closeSheet()"
  [sheetWidth]="screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'"
  sheetBackground="#fafafa"
  [class]="screenSize.getClasses()"
>
  <div class="cqkbotsheet">
    <div class="cqkbotsheet-header">
      <h1 class="cqkbotsheet-header-title">
        Add custom kit to: {{ getTitle() }}
      </h1>
    </div>

    <div class="cqkbotsheet-controls">
      <mat-form-field
        appearance="outline"
        class="cqkbotsheet-controls-ff cqkbotsheet-controls-name"
      >
        <mat-label>Name</mat-label>
        <input
          [formControl]="nameControl"
          [disabled]="loading"
          matInput
          type="text"
          class="cqkbotsheet-controls-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="cqkbotsheet-controls-ff cqkbotsheet-controls-quantity"
      >
        <mat-label>Quantity</mat-label>
        <input
          [formControl]="quantityControl"
          [disabled]="loading"
          appDigitOnly
          min="1"
          max="9999"
          matInput
          type="text"
          class="cqkbotsheet-controls-ff-in"
        />
      </mat-form-field>
      <!-- Use a text box for the descControl -->
      <mat-form-field
        appearance="outline"
        class="cqkbotsheet-controls-ff cqkbotsheet-controls-desc"
      >
        <mat-label>Description</mat-label>
        <textarea
          [formControl]="descControl"
          matInput
          type="text"
          class="cqkbotsheet-controls-ff-in"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="cqkbotsheet-actions">
      <button
        [disabled]="loading"
        (click)="closeSheet()"
        color="warn"
        mat-flat-button
        class="cqkbotsheet-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="!canAdd()"
        (click)="addCustomKit()"
        color="primary"
        mat-flat-button
        class="cqkbotsheet-actions-submit"
      >
        Add
      </button>
    </div>
  </div>
</app-bottom-sheet>
