<div
  [class]="screenSize.getClasses()"
  class="quoting-theme koqab-height koqab-toolbar"
>
  <div class="koqab-toolbar-content">
    <div class="koqab-toolbar-content-title">
      <app-grow-input
        [control]="titleControl"
        (blurEmitter)="titleBlur($event)"
        [useNativeInput]="true"
        maxWidth="40vw"
        class="koqab-toolbar-content-title-in"
      ></app-grow-input>
      <div
        *ngIf="titleControl.value != title"
        class="koqab-toolbar-content-title-controls"
      >
        <button
          [disabled]="titleControl.value.trim() == ''"
          (click)="saveChanges()"
          color="primary"
          mat-flat-button
          class="koqab-toolbar-content-title-controls-item koqab-toolbar-content-title-save"
        >
          Save
        </button>
        <button
          (click)="cancelChanges()"
          color="warn"
          mat-flat-button
          class="koqab-toolbar-content-title-controls-item koqab-toolbar-content-title-cancel"
        >
          Cancel
        </button>
      </div>
      <mat-spinner
        *ngIf="saving"
        [diameter]="22"
        class="koqab-toolbar-content-spinner"
      ></mat-spinner>
    </div>
    <div
      class="koqab-toolbar-content-data"
      (touchstart)="totalIsHovered()"
      (mouseenter)="totalIsHovered()"
    >
      <h4 class="koqab-toolbar-content-data-parts">
        {{ partTotal > 0 ? partTotal + ' Parts' : 'No Parts' }}
      </h4>
      <h4 class="koqab-toolbar-content-data-cost">
        <span class="koqab-toolbar-content-data-cost-dollar">$</span>
        {{ costTotal.toFixed(2) }}
      </h4>
    </div>
  </div>
</div>

<ng-content></ng-content>
