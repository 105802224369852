import { Component, OnInit, TemplateRef } from '@angular/core';
import { concat } from 'rxjs';
import { Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';

@Component({
  selector: 'app-material-return',
  templateUrl: './material-return.component.html',
  styleUrls: ['./material-return.component.css'],
})
export class MaterialReturnComponent {
  itemList = [];
  jobNumber = '';
  loading: boolean;
  backgroundColor = '';
  sending: boolean;

  constructor(
    private sageApi: SageApiService,
    private auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  getTransferList(jobNumber: string) {
    this.loading = true;
    this.sageApi.pullReport('PullSheet/Return/' + jobNumber).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            this.itemList.push(item);
          });
        }
        if (this.itemList.length == 0) {
          this.alertify.error('No tickets found for: ' + jobNumber);
        }
      },
      err => {
        this.alertify.error(err.message);
      }
    );
    this.loading = false;
    this.clear();
  }

  clear() {
    this.jobNumber = '';
    this.loading = false;
  }
  reset() {
    this.router.navigate(['']);
    this.itemList = [];
  }

  return(itemList) {
    this.sending = true;
    this.itemList.forEach(item => {
      if (item.Shipped < item.Returned) {
        this.alertify.error(
          'Cannot return ' +
            item.Returned +
            ' of ' +
            item.ItemCode +
            ' since ' +
            item.Shipped +
            ' were sent.'
        );
        this.sending = false;
      }
    });

    const endpoint = 'ServiceTitan/Return';
    const observables = [];
    const object = {
      Username: this.auth.decodedToken.nameid,
      Password: this.auth.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      itemList: this.itemList,
      jobNumber: this.jobNumber,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));
    concat(...observables).subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          response.forEach(resp => {
            if (resp != 'Success') {
              this.alertify.error("Couldn't return: " + resp);
              this.sending = false;
            }
          });
        }
      },
      err => {
        this.alertify.error('Error - ' + err.message);
        this.sending = false;
      },
      () => {
        this.alertify.success('Successfully sent');
        this.reset();
        this.sending = false;
      }
    );
  }
}
