import { Component, OnInit } from '@angular/core';
import { concat } from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css'],
})
export class SchedulingComponent {
  daysToView = 2;

  Schedule = [
    {
      technician: 'Mike',
      today: [
        {
          address: '1234 Street',
          disabled: false,
        },
        {
          address: '4567 Street',
          disabled: false,
        },
        {
          address: '7890 Street',
          disabled: false,
        },
      ],
      tomorrow: [
        {
          address: 'ABCD Street',
          disabled: false,
        },
        {
          address: 'WERT Street',
          disabled: false,
        },
        {
          address: 'EDCF Street',
          disabled: false,
        },
      ],
      twoDaysOut: [
        {
          address: 'WERT Street',
          disabled: false,
        },
      ],
      threeDaysOut: [],
      fourDaysOut: [],
    },
    {
      technician: 'John',
      today: [
        {
          address: 'ABCD Street',
          disabled: false,
        },
        {
          address: 'EDFG Street',
          disabled: false,
        },
        {
          address: 'HIJK Street',
          disabled: false,
        },
      ],
      tomorrow: [],
      twoDaysOut: [
        {
          address: 'ZXCV Drive',
          disabled: false,
        },
      ],
      threeDaysOut: [],
      fourDaysOut: [],
    },
    {
      technician: 'Edgar',
      today: [
        {
          address: 'E1234 Street',
          disabled: false,
        },
        {
          address: 'E4567 Street',
          disabled: false,
        },
        {
          address: 'E7890 Street',
          disabled: false,
        },
      ],
      tomorrow: [],
      twoDaysOut: [
        {
          address: 'E2345 Street',
          disabled: false,
        },
      ],
      threeDaysOut: [],
      fourDaysOut: [],
    },
    {
      technician: 'Paul',
      today: [
        {
          address: 'P1234 Street',
          disabled: false,
        },
        {
          address: 'P4567 Street',
          disabled: false,
        },
        {
          address: 'P7890 Street',
          disabled: false,
        },
      ],
      tomorrow: [],
      twoDaysOut: [
        {
          address: 'P2345 Street',
          disabled: false,
        },
      ],
      threeDaysOut: [],
      fourDaysOut: [],
    },
    {
      technician: 'Hubert',
      today: [
        {
          address: 'H1234 Street',
          disabled: false,
        },
        {
          address: 'H4567 Street',
          disabled: false,
        },
        {
          address: 'H7890 Street',
          disabled: false,
        },
      ],
      tomorrow: [],
      twoDaysOut: [
        {
          address: 'H1234 Street',
          disabled: false,
        },
        {
          address: 'H4567 Street',
          disabled: false,
        },
        {
          address: 'H7890 Street',
          disabled: false,
        },
      ],
      threeDaysOut: [],
      fourDaysOut: [],
    },
  ];

  technicians = ['Mike', 'John', 'Edgar', 'Paul', 'Hubert'];

  constructor(private sageApi: SageApiService) {}

  drop(event: CdkDragDrop<string[]>, newContainer) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  getRequest() {
    this.sageApi.pullReport('QA/0009998030000').subscribe(
      list => {
        // ToDo: Something
      },
      err => {
        console.log('error', err);
      }
    );
  }

  getLogin() {
    // Todo: Something
  }

  tryPut() {
    // Todo: Something
  }
}
