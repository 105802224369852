import {
  Component,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnDestroy,
  ComponentRef,
  AfterViewInit,
} from '@angular/core';
import { SettingsDirective } from '../settings.directive';
import { AuthService } from 'src/app/_services/auth/auth.service';

import { PunchAssignableTechsComponent } from '../punch-assignable-techs/punch-assignable-techs.component';
import { SubdivisionSetupComponent } from '../subdivision-setup/subdivision-setup.component';
import { PlanSetupComponent } from '../plan-setup/plan-setup.component';
import { SuperintendentManagerComponent } from '../superintendent-manager/superintendent-manager.component';
import { WorkTicketUpdateComponent } from '../work-ticket-update/work-ticket-update.component';
import { AccountingValidationComponent } from '../../Accounting/accounting-validation/accounting-validation.component';
interface PageListItemInterface {
  name: string;
  component: any;
  selected: boolean;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  @ViewChild(SettingsDirective, { static: true })
  appSettings: SettingsDirective;
  homePageSelected = true;
  settingsPageList: PageListItemInterface[] = [
    {
      name: 'PunchAssignableTechs',
      component: PunchAssignableTechsComponent,
      selected: false,
    },
    {
      name: 'SubdivisionSetup',
      component: SubdivisionSetupComponent,
      selected: false,
    },
    {
      name: 'PlanSetup',
      component: PlanSetupComponent,
      selected: false,
    },
    {
      name: 'SuperintendentManager',
      component: SuperintendentManagerComponent,
      selected: false,
    },
    {
      name: 'WorkTicketUpdate',
      component: WorkTicketUpdateComponent,
      selected: false,
    },
    {
      name: 'AccountingValidation',
      component: AccountingValidationComponent,
      selected: false,
    },
  ];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public authService: AuthService
  ) {}

  activePage(name: string) {
    const index = this.settingsPageList.findIndex(x => x.name === name);
    return this.settingsPageList[index].selected;
  }

  getActivePage(): PageListItemInterface | null {
    const index = this.settingsPageList.findIndex(x => x.selected === true);
    if (index < 0) {
      return null;
    }
    return this.settingsPageList[index];
  }

  settingPageSelected(name: string) {
    // First, see if the name is the same as the current selected page
    if (name == this.getActivePage()?.name) {
      this.settingsPageList.forEach(page => {
        page.selected = false;
      });
      this.homePageSelected = true;
      const viewContainerRef = this.appSettings.viewContainerRef;
      viewContainerRef.clear();
      return;
    }

    const index = this.settingsPageList.findIndex(x => x.name === name);
    if (index >= 0 && !this.settingsPageList[index].selected) {
      this.settingsPageList.forEach(page => {
        page.selected = false;
      });
      this.settingsPageList[index].selected = true;
      this.loadSettingsPage(this.settingsPageList[index]);
    }
  }

  loadSettingsPage(selectedComponent: {
    name: string;
    component: any;
    selected: boolean;
  }) {
    this.homePageSelected = false;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      selectedComponent.component
    );

    const viewContainerRef = this.appSettings.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
  }
}
