import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { SwipedEventInterface } from 'src/app/Directives/swipeable/swipeable.directive';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() open = false;
  @Input() useBackground = true;
  @Input() canSwipeToClose = true;
  @Input() closeOnHover = true;
  @Input() closeDebounceTime = 25; // ms to wait before clicking/hovering over the bg closes the sidebar
  closeTimestamp = 0; // Recorded time in ms of the last time the sidebar was closed

  @Input() sidebarDirection: 'left' | 'right' = 'left';

  @HostBinding('style.--min-height')
  @Input()
  sidebarMinHeight = '100vh';

  @HostBinding('style.--max-height')
  @Input()
  sidebarMaxHeight = '100vh';

  @HostBinding('style.--height')
  @Input()
  sidebarHeight = '100vh';

  @HostBinding('style.--sidebar-bg-color')
  @Input()
  sidebarBgColor = '#fafafa';

  @HostBinding('style.--bg-color')
  @Input()
  bgColor = '#00000052';

  @Output() sidebarClosed: EventEmitter<false> = new EventEmitter();

  constructor(
    public screenSize: ScreenSizeService,
    private element: ElementRef,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // detect if open was changed
    if (changes.open) {
      if (changes.open.currentValue != changes.open.previousValue) {
        this.closeTimestamp = Date.now();
      }
    }
  }

  ngOnInit() {
    document.addEventListener('keydown', e => {
      if (this.open && e.key == 'Escape') {
        this.sidebarClosed.emit(false);
      }
    });
  }

  closeSidebar() {
    if (this.closeTimestamp + this.closeDebounceTime < Date.now()) {
      this.sidebarClosed.emit(false);
    }
  }

  closeSidebarHover() {
    if (this.closeOnHover) {
      this.closeSidebar();
    }
  }

  sidebarSwiped(event: SwipedEventInterface) {
    if (
      this.canSwipeToClose &&
      event.direction == this.sidebarDirection &&
      (event.deg < 30 || event.deg > 330) &&
      event.time < 100
    ) {
      this.closeSidebar();
    }
    if (
      this.canSwipeToClose &&
      event.direction == this.sidebarDirection &&
      event.deg > 150 &&
      event.deg < 210 &&
      event.time < 100
    ) {
      this.closeSidebar();
    }
  }
}
