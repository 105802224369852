<div class="hssrch">
  <div class="hssrch-hdr">
    <h3 class="hssrch-hdr-ttl">House Search</h3>
    <div class="hssrch-hdr-ctrls">
      <mat-form-field appearance="outline" class="hssrch-hdr-ctrls-ff">
        <mat-label>House</mat-label>
        <input
          (keyup.enter)="searchHouses()"
          matInput
          type="text"
          [formControl]="houseSearchControl"
          class="quotesview-controls-kitsearch-in"
        />
      </mat-form-field>
      <button
        mat-raised-button
        color="accent"
        class="hssrch-hdr-ctrls-srchbtn"
        (click)="searchHouses()"
      >
        Search
      </button>
    </div>
  </div>
  <div class="hssrch-ctnt">
    <div class="hssrch-ctnt-hses">
      <mat-card *ngFor="let house of houses" class="hssrch-ctnt-hses-itm">
        <div class="hssrch-ctnt-hses-itm-ctnt">
          <h6 class="hssrch-ctnt-hses-itm-ctnt-son">
            {{ house.SalesOrderNo }}
          </h6>
          <h4 class="hssrch-ctnt-hses-itm-ctnt-stn">{{ house.ShipToName }}</h4>
        </div>
        <div class="hssrch-ctnt-hses-itm-actns">
          <a
            [routerLink]="'/house/' + house.SalesOrderNo"
            class="hssrch-ctnt-hses-itm-actns-edit"
          >
            <button
              (contextmenu)="menuTrigger.openMenu(); $event.preventDefault()"
              color="primary"
              mat-mini-fab
              class="hssrch-ctnt-hses-itm-actns-btn"
            >
              <i class="fas fa-cog"></i>
              <button
                mat-icon-button
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                [style]="{ width: '0px', height: '0px' }"
              ></button>
              <mat-menu #menu="matMenu">
                <button
                  [routerLink]="'/house/' + house.SalesOrderNo"
                  mat-menu-item
                >
                  Edit House
                </button>
              </mat-menu>
            </button>
          </a>
        </div>
      </mat-card>
    </div>
  </div>
</div>
