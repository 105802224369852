import { Component, OnInit, TemplateRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { concat } from 'rxjs';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';

@Component({
  selector: 'app-tech-todo',
  templateUrl: './tech-todo.component.html',
  styleUrls: ['./tech-todo.component.css'],
})
export class TechTodoComponent implements OnInit {
  techList = [
    {
      name: '',
      techCode: '',
      assignedSup: '',
      selected: false,
      ticketsDisplayed: 0,
      tickets: {},
      history: {},
    },
  ];
  previousTwoDate: string;
  previousOneDate: string;
  currentDate: string;
  oneDayDate: string;
  twoDayDate: string;
  threeDayDate: string;
  currentTech: string;
  currentTechIndex = 0;
  currentTechCode: string;
  lockUser = false;

  // * Image Variables *//
  imageUploadComplete: boolean;
  isImageLoading: boolean;
  imageArr: any = [];
  fileNames: any = [];
  imageUrlList: any;
  imageModal: BsModalRef;
  imageAddressForModal = '';
  selectedImageUrlForModal = '';

  showImages = false;
  // * Show / Hide UI Sections Variables * //
  showTodayTickets = true;
  showOneDayTickets = false;
  showTwoDayTickets = false;
  showThreeDayTickets = false;
  showPreviousDayOneTickets = false;
  showPreviousDayTWoTickets = false;

  QRWTNum = '';
  QRWTNumDash = '';
  QRWTNumAddress = '';
  QRWTNumDivision = '';

  LWCheck = [];

  loading = true;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    public authService: AuthService,
    private wasabiApi: WasabiApiService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    const tmp = new Date();
    this.currentDate = tmp.toISOString().slice(0, 11) + '00:00:00';
    this.oneDayDate = formatDate(
      new Date(this.currentDate).setDate(tmp.getDate() + 1),
      'YYYY-MM-ddT00:00:00',
      'en-US'
    );
    this.twoDayDate = formatDate(
      new Date(this.currentDate).setDate(tmp.getDate() + 2),
      'YYYY-MM-ddT00:00:00',
      'en-US'
    );
    this.threeDayDate = formatDate(
      new Date(this.currentDate).setDate(tmp.getDate() + 3),
      'YYYY-MM-ddT00:00:00',
      'en-US'
    );
    this.previousOneDate = formatDate(
      new Date(this.currentDate).setDate(tmp.getDate() - 1),
      'YYYY-MM-ddT00:00:00',
      'en-US'
    );
    this.previousTwoDate = formatDate(
      new Date(this.currentDate).setDate(tmp.getDate() - 2),
      'YYYY-MM-ddT00:00:00',
      'en-US'
    );

    this.getPunchUserList();
    this.lockUser =
      this.authService.decodedToken?.role.includes('material') ||
      this.authService.decodedToken?.role.includes('mat_mgt');
    this.currentTechCode = this.authService.decodedToken.TechCode;
  }

  onOpenImageModal(
    template: TemplateRef<any>,
    currentTechIndex: number,
    ticketDate: string,
    ticketIndex: number,
    imageIndex: string
  ) {
    const config: ModalOptions = { class: 'modal-lg' };
    this.imageModal = this.modalService.show(template, config);
    this.selectedImageUrlForModal = this.techList[currentTechIndex].tickets[
      ticketDate
    ].punch[ticketIndex].images[imageIndex];
  }
  onOpenHistoryImageModal(
    template: TemplateRef<any>,
    currentTechIndex: number,
    ticketDate: string,
    ticketIndex: number,
    imageIndex: string
  ) {
    const config: ModalOptions = { class: 'modal-lg' };
    this.imageModal = this.modalService.show(template, config);
    this.selectedImageUrlForModal = this.techList[currentTechIndex].history[
      ticketDate
    ].punch[ticketIndex].images[imageIndex];
  }
  openModal(
    template: TemplateRef<any>,
    currentTechIndex: number,
    ticketDate: string,
    ticketIndex: number
  ) {
    this.QRWTNum =
      this.techList[currentTechIndex].tickets[ticketDate].punch[ticketIndex]
        .soNum +
      this.techList[currentTechIndex].tickets[ticketDate].punch[ticketIndex]
        .wtNum;
    this.QRWTNumDash =
      this.techList[currentTechIndex].tickets[ticketDate].punch[ticketIndex]
        .soNum +
      '-' +
      this.techList[currentTechIndex].tickets[ticketDate].punch[ticketIndex]
        .wtNum;
    this.QRWTNumAddress = this.techList[currentTechIndex].tickets[
      ticketDate
    ].punch[ticketIndex].address;
    this.QRWTNumDivision = this.techList[currentTechIndex].tickets[
      ticketDate
    ].punch[ticketIndex].subdivision;
    const config: ModalOptions = { class: 'modal' };
    this.imageModal = this.modalService.show(template);
  }

  onCloseImageModal() {
    this.imageModal.hide();
  }

  sortTickets(techListIndex: number, colDate: string, keyToSortBy: any) {
    const arr = this.techList[techListIndex].tickets[colDate].punch;
    arr.sort((a, b) => {
      let ascDirection = true; // sort numbers in ascending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === 'string') {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = false; // sort epoch descendingly
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  onTechSelected(tname: string) {
    const tech = this.techNameToTech(tname);

    // Get the techListIndex of the selected tech
    const techListIndex = this.techList.findIndex(tech => tech.name === tname);

    if (!this.techList[techListIndex].selected) {
      this.techList.forEach(tech => {
        if (tech.techCode !== tech.techCode) {
          tech.selected = false;
        }
      });
      this.currentTechIndex = techListIndex;
      this.currentTech = this.techList[techListIndex].name;
      this.getPunchTickets(tech.techCode, techListIndex);
      this.getPunchHistoryTickets(tech.techCode, techListIndex);
      this.techList[techListIndex].selected = true;
    }
  }

  onFileChanged(event, address: string, wtnum: string) {
    this.imageUploadComplete = false;
    this.isImageLoading = true;
    this.imageArr = [];
    this.fileNames = [];

    const files = event.target.files;

    for (const index in files) {
      if (files[index] instanceof File) {
        this.fileNames.push(files[index].name);
        const obj = {
          name: wtnum + files[index].name,
          data: files[index],
        };
        this.imageArr.push(obj);
      }
    }
    if (files.length > 0) {
      this.onFileLoadComplete(address);
    }
  }

  onFileLoadComplete(address: string) {
    this.wasabiApi.uploadImage(address, this.imageArr).subscribe(
      res => {
        this.imageUploadComplete = true;
      },
      err => {
        console.log(err);
        this.fileNames = [];
        this.isImageLoading = false;
      },
      () => {
        this.fileNames = [];
        this.isImageLoading = false;
      }
    );
  }

  async onGetTicketImages(
    address: string,
    currentTechIndex: number,
    ticketDate: string,
    ticketIndex: number
  ) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.techList[currentTechIndex].tickets[ticketDate].punch[
        ticketIndex
      ].images = response;
    } catch (error) {
      this.alertify.error(
        'There was an error loading the images for this house - please try again'
      );
      console.warn(`Error loading images: ${error}`);
    }
  }
  async onGetTicketHistoryImages(
    address: string,
    currentTechIndex: number,
    ticketDate: string,
    ticketIndex: number
  ) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.techList[currentTechIndex].history[ticketDate].punch[
        ticketIndex
      ].images = response;
    } catch (error) {
      this.alertify.error(
        'There was an error loading the images for this house - please try again'
      );
      console.warn(`Error loading images: ${error}`);
    }
  }

  editTicket(techListIndex: number, ticketDate: string, ticketIndex: number) {
    this.imageAddressForModal = this.techList[techListIndex].tickets[
      ticketDate
    ].punch[ticketIndex].address;
    this.techList[techListIndex].tickets[ticketDate].punch[
      ticketIndex
    ].displayEdit = !this.techList[techListIndex].tickets[ticketDate].punch[
      ticketIndex
    ].displayEdit;
    // change displayedit to true;
  }

  LoadQALWCheck(
    techListIndex: number,
    ticketDate: string,
    ticketIndex: number
  ) {
    this.lockUser = true;
    const salesOrderNumber = this.techList[techListIndex].tickets[ticketDate]
      .punch[ticketIndex].soNum;
    const wtNumber = this.techList[techListIndex].tickets[ticketDate].punch[
      ticketIndex
    ].wtNum;
    this.LWCheck = [];

    const LWSalesOrder = this.sageApi
      .pullReport(
        'QAWorkticket/LWCheck?salesOrderNo=' +
          salesOrderNumber +
          '&WTNum=' +
          wtNumber
      )
      .subscribe(
        (qa: Array<any>) => {
          if (Array.isArray(qa)) {
            qa.forEach(q => {
              this.LWCheck.push(q);
            });
          }
        },
        err => {
          console.log(err);
          this.lockUser = false;
        },
        () => {
          this.lockUser = false;
        }
      );
    return this.LWCheck.length < 1;
  }

  sendTicket(techListIndex: number, ticketDate: string, ticketIndex: number) {
    this.lockUser = true;

    if (
      this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
        .complete &&
      !this.LoadQALWCheck(techListIndex, ticketDate, ticketIndex)
    ) {
      this.alertify.error(
        'Material for this House has not been Picked up Yet. Please Confirm with Warehouse crew that Material has been picked up ' +
          'before Submitting House as Complete.'
      );
      return;
    }

    this.techList[techListIndex].tickets[ticketDate].punch[
      ticketIndex
    ].loading = true;
    const updatedStatus =
      this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
        .complete === true
        ? this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
            .statusCode === 'REP'
          ? 'QAR'
          : 'COM'
        : this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
            .statusCode;

    const body = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesOrderNo: this.techList[techListIndex].tickets[ticketDate].punch[
        ticketIndex
      ].soNum,
      wtNumber: this.techList[techListIndex].tickets[ticketDate].punch[
        ticketIndex
      ].wtNum,
      notes: this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
        .notes,
      status: updatedStatus,
    };

    const observables = [];

    observables.push(this.sageApi.updateWTStatus(body));

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        this.alertify.error('Error ' + err.errorMessage);
        this.lockUser = false;
      },
      () => {
        this.techList[techListIndex].tickets[ticketDate].punch[
          ticketIndex
        ].displayEdit = !this.techList[techListIndex].tickets[ticketDate].punch[
          ticketIndex
        ].displayEdit;
        this.techList[techListIndex].tickets[ticketDate].punch[
          ticketIndex
        ].updated = true;
        this.alertify.success(
          this.techList[techListIndex].tickets[ticketDate].punch[ticketIndex]
            .address + ' sent'
        );
        this.lockUser = false;
      }
    );
  }

  getPunchUserList() {
    this.loading = true;
    this.sageApi
      .pullReport('TechTodo?assignablePunch=Y&currentStatus=')
      .subscribe(
        (techs: Array<any>) => {
          this.loading = false;
          if (Array.isArray(techs)) {
            techs.forEach(tech => {
              this.techList.push({
                name: tech.TechName,
                techCode: tech.TechCode,
                assignedSup: tech.AssignedSuper,
                selected: false,
                ticketsDisplayed: 0,
                tickets: {},
                history: {},
              });
            });
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.loadInitTech();
        }
      );
  }

  loadInitTech() {
    this.techList.some((tech, techIndex) => {
      if (tech.techCode === this.currentTechCode) {
        this.currentTechIndex = techIndex;
        this.currentTech = this.techList[techIndex].name;
        this.techList[techIndex].selected = true;
        this.getPunchTickets(this.currentTechCode, this.currentTechIndex);
        this.getPunchHistoryTickets(
          this.currentTechCode,
          this.currentTechIndex
        );
      }
    });
  }

  getPunchTickets(code: string, techListIndex: number) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 4);
    this.techList[techListIndex].tickets = {};

    this.sageApi.pullReport('TechTodo?techcode=' + code).subscribe(
      (list: Array<any>) => {
        if (Array.isArray(list)) {
          list.forEach(item => {
            const obj = {
              superName: item.AssignedLeadman,
              soNum: item.SalesOrderNo,
              wtNum: item.WTNumber,
              wtStepNum: item.WTStep,
              address: item.ShipToName,
              subdivision: item.UDF_SUBDIVISION,
              phase: item.HdrParentItemCode.slice(1),
              status: item.StatusCode,
              statusCode: item.Expr3,
              description: item.ItemCodeDesc,
              punchTime:
                item.UDF_PUNCH_TIME > 60
                  ? item.UDF_PUNCH_TIME / 60 + ' hrs'
                  : item.UDF_PUNCH_TIME + ' mins',
              superOrder: item.UDF_GS_ORDER_OF_IMPORTANCE,
              superOrderEpoch: item.UDF_GS_ORDER_EPOCH,
              superScheduleDate: item.UDF_GS_SCHEDULE_DATE,
              scheduleGroup:
                new Date(item.UDF_GS_SCHEDULE_DATE) < yesterday &&
                item.StatusCode !== 'Completed'
                  ? 'unsorted'
                  : item.UDF_GS_SCHEDULE_DATE,
              dip: item.DIP,
              notes: item.UDF_TRACKING_COMMENT,
              complete: item.StatusCode !== 'Completed' ? false : true,
              displayEdit: false,
              disabled: false,
              loading: false,
              updated: false,
              errorMessage: '',
              images: [],
            };
            if (!this.techList[techListIndex].tickets[obj.scheduleGroup]) {
              this.techList[techListIndex].tickets[obj.scheduleGroup] = {
                punch: [obj],
                phase: [],
              };
            } else {
              this.techList[techListIndex].tickets[
                obj.scheduleGroup
              ].punch.push(obj);
            }
          });
        } else {
          // will be a single object
        }
      },
      err => {
        console.log('error', err);
      },
      () => {
        Object.keys(this.techList[techListIndex].tickets).forEach(
          (key: string) => {
            if (
              this.techList[techListIndex].tickets[key].punch.length > 0 &&
              key !== 'unsorted'
            ) {
              this.sortTickets(techListIndex, key, 'superOrderEpoch');
              this.sortTickets(techListIndex, key, 'superOrder');
            }
          }
        );
      }
    );
  }

  getPunchHistoryTickets(code: string, techListIndex: number) {
    this.techList[techListIndex].history = {};
    this.sageApi.pullReport('TechTodo/History?techcode=' + code).subscribe(
      (list: Array<any>) => {
        if (Array.isArray(list)) {
          list.forEach(item => {
            const obj = {
              superName: item.AssignedLeadman,
              soNum: item.SalesOrderNo,
              wtNum: item.WTNumber,
              wtStepNum: item.WTStep,
              address: item.ShipToName,
              subdivision: item.UDF_SUBDIVISION,
              phase: item.HdrParentItemCode.slice(1),
              status: item.StatusCode,
              statusCode: item.Expr3,
              description: item.ItemCodeDesc,
              punchTime:
                item.UDF_PUNCH_TIME > 60
                  ? item.UDF_PUNCH_TIME / 60 + ' hrs'
                  : item.UDF_PUNCH_TIME + ' mins',
              superOrder: item.UDF_GS_ORDER_OF_IMPORTANCE,
              superOrderEpoch: item.UDF_GS_ORDER_EPOCH,
              superScheduleDate: item.UDF_GS_SCHEDULE_DATE,
              scheduleGroup:
                new Date(item.UDF_GS_SCHEDULE_DATE) &&
                item.StatusCode !== 'Completed'
                  ? 'unsorted'
                  : item.UDF_GS_SCHEDULE_DATE,
              dip: item.DIP,
              notes: item.UDF_TRACKING_COMMENT,
              complete: item.StatusCode !== 'Completed' ? false : true,
              displayImages: false,
              disabled: false,
              loading: false,
              updated: false,
              errorMessage: '',
              images: [],
            };
            if (!this.techList[techListIndex].history[obj.scheduleGroup]) {
              this.techList[techListIndex].history[obj.scheduleGroup] = {
                punch: [obj],
                phase: [],
              };
            } else {
              this.techList[techListIndex].history[
                obj.scheduleGroup
              ].punch.push(obj);
            }
          });
        } else {
          // will be a single object
        }
      },
      err => {
        console.log('error', err);
      }
    );
  }

  getTechNames() {
    return this.techList.map(tech => tech.name).sort();
  }

  techNameToTech(techName: string) {
    return this.techList.find(tech => tech.name === techName);
  }
}
