import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-searchable-msel',
  templateUrl: './searchable-msel.component.html',
  styleUrls: ['./searchable-msel.component.css'],
})
export class SearchableMselComponent implements OnChanges {
  isMobile = false;

  @Input() options: string[];
  @Input() defaultSelected: string[];
  @Input() disabled = false;
  @Input() placeholder = 'Placeholder';
  @Input() required = false;
  @Input() ffClass = '';
  @Input() mselClass = '';
  @Input() class = '';
  @Input()
  selectedOptions: FormControl /*<(typeof this.options[number])[]>*/ = new FormControl(
    [],
    this.required ? [Validators.required] : []
  );
  @Input() useNativeControl = false;

  @Output() selectChanged = new EventEmitter<FormControl>();

  randId: string = (Math.random() + 1).toString(36).substring(2); // Funny hack gets random string of [1-9][A-z]

  filteredOptions: string[];
  errorMatcher = new ErrorStateMatcher();

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      JSON.stringify(changes?.options?.currentValue?.sort()) !=
      JSON.stringify(changes?.options?.previousValue?.sort())
    ) {
      this.filteredOptions = this.options;
    }
    if (this.disabled) {
      this.selectedOptions.disable();
    } else {
      this.selectedOptions.enable();
    }
    if (this.required) {
      this.selectedOptions.setValidators([Validators.required]);
    }
    if (this.defaultSelected) {
      this.selectedOptions.setValue(this.defaultSelected);
    }
  }

  onSelChange() {
    this.selectChanged.emit(this.selectedOptions);
  }

  onSearch(value: string | null) {
    const newFOps = [];
    this.options.map(op => {
      if (value == null || op.toLowerCase().includes(value.toLowerCase())) {
        newFOps.push(op);
      }
    });
    this.filteredOptions = newFOps;
  }

  isFilteredSubOfSelected() {
    if (this.selectedOptions.value.length < this.filteredOptions.length) {
      return false;
    }
    for (const fop of this.filteredOptions) {
      const matchingSelected = this.selectedOptions.value.find(
        sop => fop == sop
      );
      if (!matchingSelected) {
        return false;
      }
    }
    return true;
  }

  getBoolSelectedFromFiltered() {
    const newSel = [...this.selectedOptions.value];
    for (
      let sopIndex = this.selectedOptions.value.length;
      sopIndex >= 0;
      sopIndex--
    ) {
      const sop = this.selectedOptions.value[sopIndex];
      const matchingSelected = this.filteredOptions.find(fop => fop == sop);
      if (matchingSelected) {
        newSel.splice(sopIndex, 1);
      }
    }
    return newSel;
  }

  onSelectAll() {
    const toRemoveIndex = this.selectedOptions.value.findIndex(
      so => so == this.randId + '-searchable-msel'
    );
    if (toRemoveIndex >= 0) {
      const newSelected = [...this.selectedOptions.value];
      newSelected.splice(toRemoveIndex, 1);
      this.selectedOptions.setValue(newSelected);
    }
    if (
      this.selectedOptions.value &&
      this.selectedOptions.value.sort().join() ==
        this.filteredOptions.sort().join()
    ) {
      this.selectedOptions.setValue([]);
    } else if (this.isFilteredSubOfSelected()) {
      this.selectedOptions.setValue(this.getBoolSelectedFromFiltered());
    } else {
      const newSelected = [...this.selectedOptions.value];
      for (const fop of this.filteredOptions) {
        const matchingSelect = newSelected.find(sop => sop == fop);
        if (!matchingSelect) {
          newSelected.push(fop);
        }
      }
      this.selectedOptions.setValue(newSelected);
    }
    this.selectChanged.emit(this.selectedOptions);
  }

  nativeSelectAll(selector?: 'Deselect' | 'Select All') {
    const toRemoveIndex = this.selectedOptions.value.findIndex(
      so => so == this.randId + '-searchable-msel'
    );
    if (toRemoveIndex >= 0) {
      const newSelected = [...this.selectedOptions.value];
      newSelected.splice(toRemoveIndex, 1);
      this.selectedOptions.setValue(newSelected);
    }
    if (selector == 'Deselect') {
      this.selectedOptions.setValue([]);
    } else if (selector == 'Select All') {
      const newSelected = [...this.selectedOptions.value];
      for (const fop of this.filteredOptions) {
        const matchingSelect = newSelected.find(
          sop => sop == fop && sop != this.randId + '-searchable-msel'
        );
        if (!matchingSelect) {
          newSelected.push(fop);
        }
      }
      this.selectedOptions.setValue(newSelected);
    }
    this.selectChanged.emit(this.selectedOptions);
  }

  isThisOptionHidden(option: string) {
    return this.filteredOptions.findIndex(fop => fop == option) == -1;
  }
}
