import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { HouseService } from 'src/app/_services/HouseService/HouseService';
import HouseDtointerface from 'src/app/_services/HouseService/interfaces/HouseDtoInterface';
import HousePhaseDtointerface from 'src/app/_services/HouseService/interfaces/HousePhaseDtointerface';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';

@Component({
  selector: 'app-house-editor-view',
  templateUrl: './HouseEditorViewComponent.html',
  styleUrls: ['./HouseEditorViewComponent.scss'],
  providers: [
    { provide: 'initLoading', useClass: MultiIsLoadingService },
    { provide: 'loading', useClass: MultiIsLoadingService },
  ],
})
export class HouseEditorViewComponent implements OnInit, AfterViewInit {
  salesOrderNo = this.activatedRoute.snapshot.params.salesOrderNo;
  house: HouseDtointerface = null;

  dataSource: MatTableDataSource<HousePhaseDtointerface> = new MatTableDataSource();
  displayedColumns: string[] = [
    'ItemCode',
    'WTNumber',
    'Template',
    'PONumber',
    'Price',
    'action',
  ];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private hs: HouseService,
    private bm: BaseModalService,
    @Inject('initLoading') public initLoading: MultiIsLoadingService,
    @Inject('loading') public loading: MultiIsLoadingService
  ) {}

  ngOnInit() {
    this.initLoading
      .loadingUntilComplete(
        this.hs.getHouse({ salesOrderNo: this.salesOrderNo })
      )
      .subscribe(house => {
        console.log(house);
        this.house = house;
        this.dataSource = new MatTableDataSource(house.phases);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        });
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  confirmDeletePhase(phase: HousePhaseDtointerface) {
    const phaseName =
      phase.ItemCode.slice(1).charAt(0).toUpperCase() +
      phase.ItemCode.slice(1).slice(1).toLowerCase();

    this.bm
      .superConfirm(
        'Delete Phase',
        `Are you sure you want to delete phase ${phaseName}?`,
        `delete ${phaseName}`
      )
      .subscribe(conf => {
        if (conf) {
          this.loading
            .loadingUntilComplete(
              this.hs.destroyHousePhases({
                salesOrderNo: this.salesOrderNo,
                WTNumber: [`${phase.WTNumber}`],
              })
            )
            .subscribe(() => {
              this.dataSource.data = this.dataSource.data.filter(
                p => p.WTNumber !== phase.WTNumber
              );
              this.dataSource.sort = this.sort;
            });
        }
      });
  }
}
