<ng-container *ngIf="kitPart?.Part">
  <mat-card
    (mouseover)="mOver($event)"
    (mouseout)="mOut($event)"
    [class.hover]="isHovered"
    [class.selected]="selected"
    [class]="screenSize.getClasses() + ' kitpartrow-card kitpartrow-card-part'"
  >
    <div class="kitpartrow-card-header">
      <div class="kitpartrow-card-header-front">
        <mat-checkbox
          *ngIf="editable"
          [checked]="selected"
          (change)="selectedChanged($event.checked)"
          class="kitpartrow-card-header-front-cb"
        ></mat-checkbox>
        <div class="kitpartrow-card-header-front-title">
          <h3 class="kitpartrow-card-header-front-title-txt">
            {{ kitPart.Part.Part.Part_Code }}
          </h3>
          <h6 class="kitpartrow-card-phase">
            {{ kitPart.KitPart.KitPart_Phase }}
          </h6>
        </div>
        <div class="kitpartrow-card-header-front-quan">
          <i class="kitpartrow-card-header-front-quan-icon">&times;</i>
          <app-grow-input
            *ngIf="editable"
            [control]="quantityControl"
            (inputEmitter)="quantityControlInput($event)"
            [useNativeInput]="true"
            minWidth="unset"
            maxWidth="unset"
            class="kitpartrow-card-header-front-quan-in"
          ></app-grow-input>
          <span *ngIf="!editable" class="kitpartrow-card-header-front-quan-txt"
            >{{ quantityControl.value }}</span
          >
          <div
            *ngIf="
              quantityControl.value != kitPart.KitPart.KitPart_Quantity ||
              phaseControl.value != kitPart.KitPart.KitPart_Phase
            "
            class="kitpartrow-card-header-front-quan-controls"
          >
            <button
              [disabled]="
                quantityControl.value == '' || quantityControl.valid == false
              "
              (click)="saveChanges()"
              color="primary"
              mat-flat-button
              class="kitpartrow-card-header-front-quan-controls-item kitpartrow-card-header-front-quan-controls-save"
            >
              Save
            </button>
            <button
              (click)="cancelChanges()"
              color="warn"
              mat-flat-button
              class="kitpartrow-card-header-front-quan-controls-item kitpartrow-card-header-front-quan-controls-cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="kitpartrow-card-header-end">
        <div class="kitpartrow-card-header-end-content">
          <h4 class="kitpartrow-card-header-end-content-cost">
            <span class="kitpartrow-card-header-end-content-cost-icon">$</span>
            {{ cost.toFixed(2) }}
          </h4>
          <div class="kitpartrow-card-header-end-content-explanation">
            <h4 class="kitpartrow-card-header-end-content-explanation-cost">
              <span class="kitpartrow-card-header-end-content-cost-icon"
                >$</span
              >
              {{ kitPart.Part.Part.Part_Cost.toFixed(2) }}<span
                class="kitpartrow-card-header-end-content-cost-per"
                >/part</span
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="cost == 0" class="kitpartrow-card-noprice"
      >No price associated with this part!</span
    >
    <div [class.editable]="editable" class="kitpartrow-card-content active">
      <div class="kitpartrow-card-content-tags">
        <span
          *ngFor="let tag of kitPart.Part.PartTags"
          [matTooltip]="tag.Tag.Tag_Desc"
          [style.background]="getTagColor(tag.Tag.Tag_Name)"
          class="kitpartrow-card-content-tags-item"
        >
          {{ tag.Tag.Tag_Name }}
        </span>
      </div>
      <div class="kitpartrow-card-content-desc">
        {{ kitPart.Part.Part.Part_Desc }}
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-container *ngIf="kitPart?.Kit">
  <mat-card
    (mouseover)="mOver($event)"
    (mouseout)="mOut($event)"
    [class.hover]="isHovered"
    [class.selected]="selected"
    [class.expanded]="expanded"
    [class]="screenSize.getClasses() + ' kitpartrow-card kitpartrow-card-kit'"
  >
    <div class="kitpartrow-card-header">
      <div class="kitpartrow-card-header-front">
        <mat-checkbox
          *ngIf="editable"
          [checked]="selected"
          (change)="selectedChanged($event.checked)"
          class="kitpartrow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="kitpartrow-card-header-front-title">
          {{ kitPart?.Kit.Kit.Kit_Name }}
        </h3>
        <div class="kitpartrow-card-header-front-quan">
          <i class="kitpartrow-card-header-front-quan-icon">&times;</i>
          <app-grow-input
            *ngIf="editable"
            [control]="quantityControl"
            (inputEmitter)="quantityControlInput($event)"
            [useNativeInput]="true"
            minWidth="25px"
            maxWidth="100px"
            class="kitpartrow-card-header-front-quan-in"
          ></app-grow-input>

          <span *ngIf="!editable" class="kitpartrow-card-header-front-quan-txt"
            >{{ quantityControl.value }}</span
          >
          <div
            *ngIf="quantityControl.value != kitPart.KitPart.KitPart_Quantity"
            class="kitpartrow-card-header-front-quan-controls"
          >
            <button
              [disabled]="
                quantityControl.value == '' || quantityControl.valid == false
              "
              (click)="saveChanges()"
              color="primary"
              mat-flat-button
              class="kitpartrow-card-header-front-quan-controls-item kitpartrow-card-header-front-quan-controls-save"
            >
              Save
            </button>
            <button
              (click)="cancelChanges()"
              color="warn"
              mat-flat-button
              class="kitpartrow-card-header-front-quan-controls-item kitpartrow-card-header-front-quan-controls-cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="kitpartrow-card-header-end">
        <div class="kitpartrow-card-header-end-content">
          <h5 class="kitpartrow-card-header-end-content-parts">
            {{ getKitTotalParts(kitPart.Kit) * kitPart.KitPart.KitPart_Quantity
            }} parts
          </h5>
          <h4 class="kitpartrow-card-header-end-content-cost">
            <span class="kitpartrow-card-header-end-content-cost-icon">$</span>
            {{ cost.toFixed(2) }}
          </h4>
          <div class="kitpartrow-card-header-end-content-explanation">
            <h5 class="kitpartrow-card-header-end-content-explanation-parts">
              {{ getKitTotalParts(kitPart.Kit) }} parts/kit
            </h5>
            <h4 class="kitpartrow-card-header-end-content-explanation-cost">
              <span class="kitpartrow-card-header-end-content-cost-icon"
                >$</span
              >
              {{ getKitCost(kitPart.Kit).toFixed(2) }}<span
                class="kitpartrow-card-header-end-content-cost-per"
                >/kit</span
              >
            </h4>
          </div>
        </div>
        <button
          (click)="expanded = !expanded"
          mat-mini-fab
          class="kitpartrow-card-header-end-drop mat-elevation-z2"
        >
          <i
            [class.active]="expanded"
            class="kitpartrow-card-header-end-drop-icon fas fa-chevron-down"
          ></i>
        </button>
      </div>
    </div>
    <div class="kitpartrow-card-location">
      <span
        *ngIf="kitPart.Kit.BuildLocation.BuildLocation_Code != 'NONE'"
        [matTooltip]="kitPart.Kit.BuildLocation.BuildLocation_Desc"
        [style.background]="
          getTagColor(kitPart.Kit.BuildLocation?.BuildLocation_Code)
        "
        class="kitpartrow-card-location-item"
      >
        {{ kitPart.Kit.BuildLocation?.BuildLocation_Code }}
      </span>
    </div>
    <div [class.active]="expanded" class="kitpartrow-card-content">
      <div class="kitpartrow-card-content-subkit">
        <ng-container *ngFor="let row of getImmediateSubkits(kitPart.Kit)">
          <app-kitpartrow
            [kitPart]="row"
            [editable]="subEditable"
            [subEditable]="subEditable"
          ></app-kitpartrow>
        </ng-container>
        <ng-container *ngFor="let row of getImmediateKitParts(kitPart.Kit)">
          <app-kitpartrow [kitPart]="row"></app-kitpartrow>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>
