<div class="dragNDrop">
  <div class="file-area">
    <input type="file" multiple (change)="addToFileList($event.target.files)" />
    <div class="file-dummy">
      <span class="default">
        <mat-icon> file_upload </mat-icon>
        Click to select a file, or drag it here
      </span>
    </div>
  </div>
  <div class="uploadList">
    <div class="matspin" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading">
      <label style="text-align: center">Files To Be Uploaded</label>
      <ul>
        <li *ngFor="let file of files" class="listitems">
          {{ file.name }}
          <button mat-flat-button color="warn" (click)="removeFromFiles(file)">
            <mat-icon> delete </mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
