import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { concat } from 'rxjs';
import { Template } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css'],
})
export class TemplateEditorComponent implements OnInit {
  // * Modal Variables *//
  createNewTemplateModal: BsModalRef;
  copyTemplateFromEditScreenModal: BsModalRef;
  MassTemplateSubmit: BsModalRef;
  updateSalesOrders: BsModalRef;
  templateNumberToCopy = '';
  selectedTemplateToCopy = [];
  showTemplateToCopySearchArea = false;

  // * Selection Navigation Variables *//
  selectionTabInfo = true;
  selectionTab000 = false;
  selectionTab005 = false;
  selectionTab006 = false;
  selectionTab010 = false;
  selectionTab020 = false;
  selectionTabReview = false;
  selectedTabName = 'Info';

  selectedTempName = 'Template Editor';
  selectionTempSingle = true;
  selectionTempMass = false;
  selectionSales = false;
  allTemplates = false;

  activeMassTemplates = [
    {
      TemplateNo: '',
      Description: '',
      InitialStatusCode: '',
    },
  ];

  activeSubmitTemplates = [
    {
      TemplateNo: '',
      Description: '',
      InitialStatusCode: '',
    },
  ];

  // * Template Info Variables *//
  activeTemplates = [
    {
      TemplateNo: '',
      Description: '',
      InitialStatusCode: '',
    },
  ];
  activeTemplateLines = [];
  selectedTemplate = [];
  userInputTemplateNumber = '';
  MassTemplateNumber = '';
  userSelectedTemplate = false;
  masstemplateSelected = false;
  creatingNewTemplate = false;
  copyingTemplate = false;
  locationList = [
    {
      Location: '',
      Description: '',
      Name: '',
    },
  ];
  userRemovedTemplateItems = [];
  templateItemsToAddToTemplate = {
    TemplateNo: '',
    RevisionNo: '000',
    WTStep: '',
    ItemCode: '',
    ItemCodeDesc: '',
    QuantityRequired: '',
    CommentText: '',
    UDF_LOCATION: '',
    ExplodeBOM: '',
    ChargePart: 'N',
    PrintPart: 'N',
    ScalePart: 'Y',
  };
  userAddedTemplateItems = [];
  templateItemList = [
    {
      Name: '',
      ItemCode: '',
      Description: '',
      Kit: '',
      inactive: '',
    },
  ];
  allItemList = [
    {
      Name: '',
      ItemCode: '',
      Description: '',
      Kit: '',
      inactive: '',
    },
  ];

  LocationList = [
    {
      UDF_LOCATION_CODE: '',
      UDF_LOCATION_DESC: '',
    },
  ];

  massTemplateItemList = [
    {
      Name: '',
      ItemCode: '',
      Qty: 0,
      Description: '',
      Kit: 'N',
      partAction: 'Remove',
      RepName: '',
      RepItemCode: '',
      RepQty: 0,
      RepDescription: '',
      RepKit: 'N',
      wtStep: '010',
      location: '',
      showKit: false,
      RepShowKit: false,
      kitList: [],
      RepkitList: [],
    },
  ];

  selectedOptionList = [];
  ChosenOptionList = [];

  newBlankTemplate = [
    {
      '000': {
        name: 'step 000',
        templateData: [
          {
            TemplateNo: '',
            RevisionNo: '000',
            WTStep: '000',
            CurrentRevision: 'Y',
            Description: 'Default Activity Code',
            InitialStatusCode: 'NEW',
            created: false,
          },
        ],
        templateLines: [],
        wtStep: '000',
      },
      '005': {
        name: 'step 005',
        templateData: [
          {
            TemplateNo: '',
            RevisionNo: '000',
            WTStep: '005',
            CurrentRevision: 'Y',
            Description: 'Default Activity Code',
            InitialStatusCode: 'NEW',
            created: false,
          },
        ],
        templateLines: [],
        wtStep: '005',
      },
      '006': {
        name: 'step 006',
        templateData: [
          {
            TemplateNo: '',
            RevisionNo: '000',
            WTStep: '006',
            CurrentRevision: 'Y',
            Description: 'Default Activity Code',
            InitialStatusCode: 'NEW',
            created: false,
          },
        ],
        templateLines: [],
        wtStep: '006',
      },
      '010': {
        name: 'step 010',
        templateData: [
          {
            TemplateNo: '',
            RevisionNo: '000',
            WTStep: '010',
            CurrentRevision: 'Y',
            Description: 'Parts',
            InitialStatusCode: 'NEW',
            created: false,
          },
        ],
        templateLines: [],
        wtStep: '010',
      },
      '020': {
        name: 'step 020',
        templateData: [
          {
            TemplateNo: '',
            RevisionNo: '000',
            WTStep: '020',
            CurrentRevision: 'Y',
            Description: 'Labor',
            InitialStatusCode: 'NEW',
            created: false,
          },
        ],
        templateLines: [],
        wtStep: '020',
      },
    },
  ];
  kitItems = [
    {
      Kit: '',
      LineKey: '',
      ItemCode: '',
      Description: '',
      Quantity: '',
      CommentText: '',
    },
  ];

  kitItemsMass = [
    {
      Kit: '',
      LineKey: '',
      ItemCode: '',
      Description: '',
      Quantity: '',
      CommentText: '',
    },
  ];
  templateNumberForCopiedTemplate = '';

  // * Prevent Action Variables *//
  loadingData = false;
  SubmittingData = false;
  templateNumbersMatchError = false;

  // * Show/Hide Variables *//
  showReviewStep000 = true;
  showReviewStep005 = true;
  showReviewStep006 = true;
  showReviewStep010 = true;
  showReviewStep020 = true;

  ItemCodeLines = [];

  ItemCodeSales = [];
  itemAdded: {
    itemCode: string;
    action: string;
    replacement: string;
  } = { itemCode: '', action: 'Remove', replacement: '' };

  ableToAdd: boolean;
  warningMessage = [];
  countLoading: boolean;
  sending: boolean;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getTemplates();
    this.getLocationList();
    this.getItemList();
    this.pullLocation();
    this.getAllItems();
  }
  addItem(item) {
    const obj = {
      itemCode: item.itemCode,
      action: item.action,
      replacement: item.replacement,
    };
    this.itemAdded.itemCode = '';
    this.itemAdded.action = 'Remove';
    this.itemAdded.replacement = '';
    this.ItemCodeSales.push(obj);
  }

  removeItem(index) {
    this.ItemCodeSales.splice(index, 1);
  }

  checkAmounts() {
    this.warningMessage = [];
    this.ItemCodeSales.forEach(item => {
      this.countLoading = true;
      this.sageApi
        .pullReport('Builder/CountSalesOrders?itemCode=' + item.itemCode)
        .subscribe(mess => {
          const message =
            mess +
            ' Sales Orders will be affected by changing item code: ' +
            item.itemCode;
          this.warningMessage.push(message);
          this.countLoading = false;
        });
    });
  }

  confirmedChangeSalesOrders() {
    this.onCloseSalesOrderUpdate();
    const endpoint = 'Builder/UpdateSalesOrders';
    const observables = [];
    const body = {
      username: this.authService.decodedToken.nameid,
      lines: this.ItemCodeSales,
    };

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(
      (response: any) => {
        this.alertify.success(response + ' total Sales Orders Changed.');
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      },
      () => {
        // ToDo: Something
      }
    );
    this.onResetData();
  }

  setItemToChange(event, type) {
    if (event.item.Kit == 'N') {
      if (type == 'original') {
        this.itemAdded.itemCode == event.item.ItemCode;
        this.ableToAdd = true;
      }
      if (type == 'replacement') {
        if (event.item.inactive == 'N') {
          this.itemAdded.replacement = event.item.ItemCode;
          this.ableToAdd = true;
        } else {
          this.alertify.warning(
            event.item.ItemCode +
              ' is inactive. Please choose a different item.'
          );
        }
      }
    }
    if (event.item.Kit == 'Y') {
      this.ableToAdd = false;
      this.itemAdded.itemCode = '';
      this.alertify.warning(
        event.item.ItemCode +
          " is a kit. If you remove this from sales orders, it won't remove this items that are inside of the kit."
      );
    }
  }

  checkIfActiveAndNotKit() {
    if (this.itemAdded.itemCode != '') {
      if (this.itemAdded.action == 'Remove') {
        const checkIfInList = this.allItemList.find(
          x => x.ItemCode == this.itemAdded.itemCode && x.Kit == 'N'
        );
        if (checkIfInList != undefined) {
          return false;
        } else {
          return true;
        }
      }
      if (this.itemAdded.action == 'Replace') {
        const checkIfInList = this.allItemList.find(
          x => x.ItemCode == this.itemAdded.itemCode && x.Kit == 'N'
        );
        const checkIfReplacementInList = this.templateItemList.find(
          x =>
            x.ItemCode == this.itemAdded.replacement &&
            x.Kit == 'N' &&
            x.inactive == 'N'
        );
        if (
          checkIfInList != undefined &&
          checkIfReplacementInList != undefined
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  getTemplates() {
    this.loadingData = true;
    this.activeTemplates = [];
    this.sageApi
      .pullReport('Builder/TemplatesEditor')
      .subscribe((templates: Array<any>) => {
        templates.forEach(template => {
          const templateObj = {
            TemplateNo: template.TemplateNo,
            RevisionNo: template.RevisionNo,
            WTStep: template.WTStep,
            ExtendedDescriptionKey: template.ExtendedDescriptionKey,
            CurrentRevision: template.CurrentRevision,
            Description: template.Description,
            WTClass: template.WTClass,
            ActivityCode: template.ActivityCode,
            APDivisionNo: template.APDivisionNo,
            VendorNo: template.VendorNo,
            ProductionStartDateOption: template.ProductionStartDateOption,
            ProductionStartDate: template.ProductionStartDate,
            ProductionDueDateOption: template.ProductionDueDateOption,
            ProductionDueDate: template.ProductionDueDate,
            AssignTo1: template.AssignTo1,
            AssignTo2: template.AssignTo2,
            AssignTo3: template.AssignTo3,
            UserDefinedInfo1: template.UserDefinedInfo1,
            UserDefinedInfo2: template.UserDefinedInfo2,
            UserDefinedInfo3: template.UserDefinedInfo3,
            UserDefinedInfo4: template.UserDefinedInfo4,
            UserDefinedInfo5: template.UserDefinedInfo5,
            BudgetCalculated: template.BudgetCalculated,
            InitialStatusCode: template.InitialStatusCode,
            ScalingMethod: template.ScalingMethod,
            ScaleParts: template.ScaleParts,
            ScaleLabor: template.ScaleLabor,
            ScalingFactorLabor: template.ScalingFactorLabor,
            ScalingFactorParts: template.ScalingFactorParts,
            ProductionRun: template.ProductionRun,
            BudgetSetupHours: template.BudgetSetupHours,
            BudgetHours: template.BudgetHours,
            BudgetLaborCost: template.BudgetLaborCost,
            BudgetMaterialsCost: template.BudgetMaterialsCost,
            ProductionStartDays: template.ProductionStartDays,
            ProductionDueDays: template.ProductionDueDays,
            TemplateText: template.TemplateText,
            TemplateName: `${template.TemplateNo} - ${template.Description}`,
            updated: false,
            created: false,
          };
          this.activeTemplates.push(templateObj);
          if (template.WTStep === '000') {
            if (this.activeMassTemplates[0].TemplateNo === '') {
              this.activeMassTemplates = [templateObj];
            } else {
              this.activeMassTemplates.push(templateObj);
            }
          }
        });
        this.loadingData = false;
      });
  }

  addMassItem() {
    const obj = {
      Name: '',
      ItemCode: '',
      Qty: 0,
      Description: '',
      Kit: 'no',
      partAction: 'Remove',
      RepName: '',
      RepItemCode: '',
      RepQty: 0,
      RepDescription: '',
      RepKit: 'N',
      wtStep: '010',
      location: '',
      showKit: false,
      RepShowKit: false,
      kitList: [],
      RepkitList: [],
    };
    this.massTemplateItemList.push(obj);
  }

  RemoveMassItem(index) {
    this.massTemplateItemList.splice(index, 1);
  }

  getTemplateLines(templateNo: string, templateType: string) {
    this.loadingData = true;
    this.sageApi
      .pullReport(
        `Builder/TemplateEditor/TemplateLines?templateNo=${templateNo}`
      )
      .subscribe(lines => {
        if (Array.isArray(lines)) {
          lines.forEach(lineItem => {
            if (templateType === 'existing') {
              const obj = {
                ChargePart: lineItem.ChargePart,
                CommentText: lineItem.CommentText,
                ExplodeBOM: lineItem.ExplodeBOM,
                ExtendedDescriptionKey: lineItem.ExtendedDescriptionKey,
                ExtendedDescriptionText: lineItem.ExtendedDescriptionText,
                FindNo: lineItem.FindNo,
                ItemCode: lineItem.ItemCode,
                ItemCodeDesc: lineItem.ItemCodeDesc,
                InitialStatusCode: lineItem.InitialStatusCode,
                LineKey: lineItem.LineKey,
                LineSeqNo: lineItem.LineSeqNo,
                PrintPart: lineItem.PrintPart,
                Purchase: lineItem.Purchase,
                QuantityRequired: lineItem.QuantityRequired,
                RevisionNo: lineItem.RevisionNo,
                ScalePart: lineItem.ScalePart,
                TemplateNo: lineItem.TemplateNo,
                UDF_LOCATION: lineItem.UDF_LOCATION,
                UDF_TEST: lineItem.UDF_TEST,
                WTStep: lineItem.WTStep,
                locationUpdate: false,
                updated: false,
              };
              for (const item in this.selectedTemplate[0]) {
                if (obj.WTStep === this.selectedTemplate[0][item].wtStep) {
                  this.selectedTemplate[0][item].templateLines.push(obj);
                }
              }
            } else if (templateType === 'copied') {
              const obj = {
                ChargePart: lineItem.ChargePart,
                CommentText: lineItem.CommentText,
                ExplodeBOM: lineItem.ExplodeBOM,
                ExtendedDescriptionKey: lineItem.ExtendedDescriptionKey,
                ExtendedDescriptionText: lineItem.ExtendedDescriptionText,
                FindNo: lineItem.FindNo,
                ItemCode: lineItem.ItemCode,
                ItemCodeDesc: lineItem.ItemCodeDesc,
                InitialStatusCode: lineItem.InitialStatusCode,
                LineKey: lineItem.LineKey,
                LineSeqNo: lineItem.LineSeqNo,
                PrintPart: lineItem.PrintPart,
                Purchase: lineItem.Purchase,
                QuantityRequired: lineItem.QuantityRequired,
                RevisionNo: lineItem.RevisionNo,
                ScalePart: lineItem.ScalePart,
                TemplateNo: this.templateNumberForCopiedTemplate,
                UDF_LOCATION: lineItem.UDF_LOCATION,
                UDF_TEST: lineItem.UDF_TEST,
                WTStep: lineItem.WTStep,
                // locationUpdate: false,
                // updated: false,
              };
              for (const item in this.selectedTemplateToCopy[0]) {
                if (
                  obj.WTStep === this.selectedTemplateToCopy[0][item].wtStep
                ) {
                  this.selectedTemplateToCopy[0][item].templateLines.push(obj);
                }
              }
            }
            this.loadingData = false;
          });
        }
      });
  }

  onSelectTemplateNumber(event: TypeaheadMatch) {
    this.getTemplateLines(event.item.TemplateNo, 'existing');
    this.userSelectedTemplate = true;
    this.selectedTemplate = [
      {
        '000': {
          name: 'step 000',
          templateData: [],
          templateLines: [],
          wtStep: '000',
        },
        '005': {
          name: 'step 005',
          templateData: [],
          templateLines: [],
          wtStep: '005',
        },
        '006': {
          name: 'step 006',
          templateData: [],
          templateLines: [],
          wtStep: '006',
        },
        '010': {
          name: 'step 010',
          templateData: [],
          templateLines: [],
          wtStep: '010',
        },
        '020': {
          name: 'step 020',
          templateData: [],
          templateLines: [],
          wtStep: '020',
        },
      },
    ];
    const matchingTemplates: Array<any> = this.activeTemplates.filter(
      item => item.TemplateNo === event.item.TemplateNo
    );

    matchingTemplates.forEach(item => {
      switch (item.WTStep) {
        case '000':
          this.selectedTemplate[0][item.WTStep].templateData.push(item);
          break;
        case '005':
          this.selectedTemplate[0][item.WTStep].templateData.push(item);
          break;
        case '006':
          this.selectedTemplate[0][item.WTStep].templateData.push(item);
          break;
        case '010':
          this.selectedTemplate[0][item.WTStep].templateData.push(item);
          break;
        case '020':
          this.selectedTemplate[0][item.WTStep].templateData.push(item);
          break;
      }
    });
  }

  getLocationList() {
    this.locationList = [
      {
        Location: '',
        Description: '',
        Name: '',
      },
    ];
    this.sageApi.pullReport('HouseEditor/ItemLocation').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const location = {
              Location: row.Location,
              Description: row.Description,
              Name: `${row.Location} - ${row.Description}`,
            };

            this.locationList.push(location);
          });
        }
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  getItemList() {
    this.templateItemList = [
      {
        Name: '',
        ItemCode: '',
        Description: '',
        Kit: '',
        inactive: '',
      },
    ];
    this.sageApi.pullReport('HouseEditor/Item').subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(row => {
            const item = {
              Name: `${row.ItemCode} - ${row.ItemCodeDesc}`,
              ItemCode: row.ItemCode,
              Description: row.ItemCodeDesc,
              Kit: row.Kit,
              inactive: row.InactiveItem,
            };
            if (row.Kit === 'Y') {
              0;
            }
            this.templateItemList.push(item);
          });
        }
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  getAllItems() {
    this.allItemList = [
      {
        Name: '',
        ItemCode: '',
        Description: '',
        Kit: '',
        inactive: '',
      },
    ];
    this.sageApi.pullReport('AllItems').subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(row => {
            let kit = '';
            if (row.ExplodeKitItems == 'P') {
              kit = 'N';
            } else if (row.ExplodeKitItems == 'A') {
              kit = 'Y';
            }
            const item = {
              Name: `${row.ItemCode} - ${row.ItemCodeDesc}`,
              ItemCode: row.ItemCode,
              Description: row.ItemCodeDesc,
              Kit: kit,
              inactive: row.InactiveItem,
            };
            this.allItemList.push(item);
          });
        }
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  getKitItems(
    kitNumber: string,
    location: string,
    step: string,
    quantity: string,
    templateType: string
  ) {
    this.kitItems = [
      {
        Kit: '',
        LineKey: '',
        ItemCode: '',
        Description: '',
        Quantity: '',
        CommentText: '',
      },
    ];

    this.sageApi.pullReport(`HouseEditor/Kit?kit=${kitNumber}`).subscribe(
      lines => {
        if (Array.isArray(lines)) {
          lines.forEach(item => {
            if (templateType === 'existing') {
              const kitItemForExisting = {
                TemplateNo: this.selectedTemplate[0][step].templateData[0]
                  .TemplateNo,
                RevisionNo: this.selectedTemplate[0][step].templateData[0]
                  .RevisionNo,
                WTStep: step,
                ItemCode: item.ItemCode,
                ItemCodeDesc: item.CommentText,
                QuantityRequired: Number(item.Quantity) * Number(quantity),
                CommentText: item.CommentText,
                UDF_LOCATION: location,
                ExplodeBOM: 'N',
                ChargePart: 'N',
                PrintPart: 'N',
                ScalePart: 'Y',
              };

              this.selectedTemplate[0][step].templateLines.unshift(
                kitItemForExisting
              );
              this.userAddedTemplateItems.unshift(kitItemForExisting);
            } else if (templateType === 'copied') {
              const kitItem = {
                TemplateNo: this.templateNumberForCopiedTemplate,
                RevisionNo: '000',
                WTStep: step,
                ItemCode: item.ItemCode,
                ItemCodeDesc: item.CommentText,
                QuantityRequired: Number(item.Quantity) * Number(quantity),
                CommentText: item.CommentText,
                UDF_LOCATION: location,
                ExplodeBOM: 'N',
                ChargePart: 'N',
                PrintPart: 'N',
                ScalePart: 'Y',
              };

              this.selectedTemplateToCopy[0][step].templateLines.unshift(
                kitItem
              );
            } else if (templateType === 'new') {
              const kitItem = {
                TemplateNo: this.newBlankTemplate[0][step].templateData[0]
                  .TemplateNo,
                RevisionNo: '000',
                WTStep: step,
                ItemCode: item.ItemCode,
                ItemCodeDesc: item.Description,
                QuantityRequired: Number(item.Quantity) * Number(quantity),
                CommentText: item.CommentText,
                UDF_LOCATION: location,
                ExplodeBOM: 'N',
                ChargePart: 'N',
                PrintPart: 'N',
                ScalePart: 'Y',
              };
              this.newBlankTemplate[0][step].templateLines.unshift(kitItem);
            }
          });
        }
      },
      error => {
        console.warn('Error loading kit items: ${error}');
      },
      () => {
        // ToDo: Something
      }
    );
  }

  getKitItemsMass(
    kitNumber: string,
    location: string,
    step: string,
    quantity: string,
    index,
    isRep: boolean
  ) {
    this.kitItemsMass = [
      {
        Kit: '',
        LineKey: '',
        ItemCode: '',
        Description: '',
        Quantity: '',
        CommentText: '',
      },
    ];
    this.massTemplateItemList[index].kitList = this.kitItemsMass;
    if (isRep) {
      this.massTemplateItemList[index].RepkitList = this.kitItemsMass;
    } else {
      this.massTemplateItemList[index].kitList = this.kitItemsMass;
    }

    this.sageApi.pullReport(`HouseEditor/Kit?kit=${kitNumber}`).subscribe(
      lines => {
        if (Array.isArray(lines)) {
          lines.forEach(item => {
            const kitItem = {
              RevisionNo: '000',
              WTStep: step,
              ItemCode: item.ItemCode,
              ItemCodeDesc: item.Description,
              QuantityRequired: Number(item.Quantity),
              CommentText: item.CommentText,
              UDF_LOCATION: location,
            };
            if (isRep) {
              this.massTemplateItemList[index].RepkitList.push(kitItem);
            } else {
              this.massTemplateItemList[index].kitList.push(kitItem);
            }
          });
        }
      },
      error => {
        console.warn('Error loading kit items: ' + error);
        this.alertify.error(error);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  // ! Existing Templates
  onAddStepToExistingTemplate(step: string, templateType: string) {
    if (templateType === 'existing') {
      switch (step) {
        case '005':
          this.selectedTemplate[0]['005'].templateData[0] = {
            ...this.selectedTemplate[0]['000'].templateData[0],
          };
          this.selectedTemplate[0]['005'].templateData[0].WTStep = step;
          this.selectedTemplate[0]['005'].templateData[0].created = true;
          break;
        case '006':
          this.selectedTemplate[0]['006'].templateData[0] = {
            ...this.selectedTemplate[0]['000'].templateData[0],
          };
          this.selectedTemplate[0]['006'].templateData[0].WTStep = step;
          this.selectedTemplate[0]['006'].templateData[0].created = true;
          break;
        case '010':
          this.selectedTemplate[0]['010'].templateData[0] = {
            ...this.selectedTemplate[0]['000'].templateData[0],
          };
          this.selectedTemplate[0]['010'].templateData[0].WTStep = step;
          this.selectedTemplate[0]['010'].templateData[0].created = true;
          break;
        case '020':
          this.selectedTemplate[0]['020'].templateData[0] = {
            ...this.selectedTemplate[0]['000'].templateData[0],
          };
          this.selectedTemplate[0]['020'].templateData[0].WTStep = step;
          this.selectedTemplate[0]['020'].templateData[0].created = true;
          break;
        default:
          break;
      }
    } else if (templateType === 'copied') {
      switch (step) {
        case '005':
          this.selectedTemplateToCopy[0]['005'].templateData[0] = {
            ...this.selectedTemplateToCopy[0]['000'].templateData[0],
          };
          this.selectedTemplateToCopy[0]['005'].templateData[0].WTStep = step;
          this.selectedTemplateToCopy[0]['005'].templateData[0].created = true;
          break;
        case '006':
          this.selectedTemplateToCopy[0]['006'].templateData[0] = {
            ...this.selectedTemplateToCopy[0]['000'].templateData[0],
          };
          this.selectedTemplateToCopy[0]['006'].templateData[0].WTStep = step;
          this.selectedTemplateToCopy[0]['006'].templateData[0].created = true;
          break;
        case '010':
          this.selectedTemplateToCopy[0]['010'].templateData[0] = {
            ...this.selectedTemplateToCopy[0]['000'].templateData[0],
          };
          this.selectedTemplateToCopy[0]['010'].templateData[0].WTStep = step;
          this.selectedTemplateToCopy[0]['010'].templateData[0].created = true;
          break;
        case '020':
          this.selectedTemplateToCopy[0]['020'].templateData[0] = {
            ...this.selectedTemplateToCopy[0]['000'].templateData[0],
          };
          this.selectedTemplateToCopy[0]['020'].templateData[0].WTStep = step;
          this.selectedTemplateToCopy[0]['020'].templateData[0].created = true;
          break;
        default:
          break;
      }
    }
  }

  // ! Editing Existing Templates
  onRemoveItemFromTemplate(line, index: string, templateType: string) {
    if (templateType === 'existing') {
      this.userRemovedTemplateItems.push(line);
      this.selectedTemplate[0][line.WTStep].templateLines.splice(index, 1);
    } else if (templateType === 'new') {
      this.newBlankTemplate[0][line.WTStep].templateLines.splice(index, 1);
    } else if (templateType === 'copied') {
      this.selectedTemplateToCopy[0][line.WTStep].templateLines.splice(
        index,
        1
      );
    }
  }

  // ! Editing Existing Templates
  onRemoveAllItemsFromTemplateStep(step: string, templateType: string) {
    if (templateType === 'existing') {
      this.selectedTemplate[0][step].templateLines.forEach(line => {
        line.updated = true;
        this.userRemovedTemplateItems.push(line);
      });
      this.selectedTemplate[0][step].templateLines = [];
    } else if (templateType === 'copied') {
      this.selectedTemplateToCopy[0][step].templateLines = [];
    }
  }

  // ! Editing Existing Templates
  onAddItemToTemplate(wtStep: string, templateType: string) {
    if (templateType === 'existing') {
      // * Sets object properties that aren't set through ngModel
      this.templateItemsToAddToTemplate.TemplateNo = this.selectedTemplate[0][
        wtStep
      ].templateData[0].TemplateNo;
      this.templateItemsToAddToTemplate.WTStep = wtStep;
      this.templateItemsToAddToTemplate.RevisionNo = this.selectedTemplate[0][
        wtStep
      ].templateData[0].RevisionNo;

      if (this.templateItemsToAddToTemplate.ExplodeBOM !== 'Y') {
        this.userAddedTemplateItems.push(this.templateItemsToAddToTemplate);
        this.selectedTemplate[0][wtStep].templateLines.unshift(
          this.templateItemsToAddToTemplate
        );
      }

      if (this.templateItemsToAddToTemplate.ExplodeBOM === 'Y') {
        this.getKitItems(
          this.templateItemsToAddToTemplate.ItemCode,
          this.templateItemsToAddToTemplate.UDF_LOCATION,
          wtStep,
          this.templateItemsToAddToTemplate.QuantityRequired,
          'existing'
        );
      }

      // ! Resets item to add to template fields in view once previous item has been added to template
      this.templateItemsToAddToTemplate = {
        TemplateNo: '',
        RevisionNo: '',
        WTStep: '',
        ItemCode: '',
        ItemCodeDesc: '',
        QuantityRequired: '',
        CommentText: '',
        UDF_LOCATION: '',
        ExplodeBOM: '',
        ChargePart: 'N',
        PrintPart: 'N',
        ScalePart: 'Y',
      };
    } else if (templateType === 'copied') {
      /// * Sets object properties that aren't set through ngModel
      this.templateItemsToAddToTemplate.TemplateNo = this.selectedTemplateToCopy[0][
        wtStep
      ].templateData[0].TemplateNo;
      this.templateItemsToAddToTemplate.WTStep = wtStep;
      this.templateItemsToAddToTemplate.RevisionNo = this.selectedTemplateToCopy[0][
        wtStep
      ].templateData[0].RevisionNo;

      if (this.templateItemsToAddToTemplate.ExplodeBOM !== 'Y') {
        this.selectedTemplateToCopy[0][wtStep].templateLines.unshift(
          this.templateItemsToAddToTemplate
        );
      }

      if (this.templateItemsToAddToTemplate.ExplodeBOM === 'Y') {
        this.getKitItems(
          this.templateItemsToAddToTemplate.ItemCode,
          this.templateItemsToAddToTemplate.UDF_LOCATION,
          wtStep,
          this.templateItemsToAddToTemplate.QuantityRequired,
          'copied'
        );
      }

      // ! Resets item to add to template fields in view once previous item has been added to template
      this.templateItemsToAddToTemplate = {
        TemplateNo: '',
        RevisionNo: '',
        WTStep: '',
        ItemCode: '',
        ItemCodeDesc: '',
        QuantityRequired: '',
        CommentText: '',
        UDF_LOCATION: '',
        ExplodeBOM: '',
        ChargePart: 'N',
        PrintPart: 'N',
        ScalePart: 'Y',
      };
    }
  }

  // ! Sets the same template number across all steps of the new template if that template number does not exist already !//
  onSetTemplateNumberOnAllSteps(templateNo: string, templateType: string) {
    if (templateType === 'new') {
      for (let item = 0; item < this.activeTemplates.length; item++) {
        if (
          this.activeTemplates[item].TemplateNo.toLowerCase() ===
          templateNo.toLowerCase()
        ) {
          this.templateNumbersMatchError = true;
          console.warn(
            `MATCH`,
            this.activeTemplates[item].TemplateNo,
            templateNo,
            this.templateNumbersMatchError
          );
          this.newBlankTemplate[0]['000'].templateData[0].TemplateNo = '';
          this.newBlankTemplate[0]['005'].templateData[0].TemplateNo = '';
          this.newBlankTemplate[0]['006'].templateData[0].TemplateNo = '';
          this.newBlankTemplate[0]['010'].templateData[0].TemplateNo = '';
          this.newBlankTemplate[0]['020'].templateData[0].TemplateNo = '';
          break;
        }
        if (
          this.activeTemplates[item].TemplateNo.toLowerCase() !==
          templateNo.toLowerCase()
        ) {
          this.templateNumbersMatchError = false;
          // this.newBlankTemplate[0]['000'].templateData[0].TemplateNo = templateNo;
          this.newBlankTemplate[0][
            '005'
          ].templateData[0].TemplateNo = templateNo;
          this.newBlankTemplate[0][
            '006'
          ].templateData[0].TemplateNo = templateNo;
          this.newBlankTemplate[0][
            '010'
          ].templateData[0].TemplateNo = templateNo;
          this.newBlankTemplate[0][
            '020'
          ].templateData[0].TemplateNo = templateNo;
        }
      }
    } else if (templateType === 'copied') {
      // ! Not sure I'll need to use this but it's added just in case
    }
  }

  onCheckNewTemplateNumberForCopiedTemplate(event) {
    const templateNo = event.target.value;

    for (let item = 0; item < this.activeTemplates.length; item++) {
      if (
        this.activeTemplates[item].TemplateNo.toLowerCase() ===
        templateNo.toLowerCase()
      ) {
        this.templateNumbersMatchError = true;
        console.warn(
          `MATCH`,
          this.activeTemplates[item].TemplateNo,
          templateNo,
          this.templateNumbersMatchError
        );
        break;
      }
      if (
        this.activeTemplates[item].TemplateNo.toLowerCase() !==
        templateNo.toLowerCase()
      ) {
        this.templateNumbersMatchError = false;
      }
    }
  }

  onAddItemsToBlankTemplate(wtStep: string) {
    this.templateItemsToAddToTemplate.TemplateNo = this.newBlankTemplate[0][
      wtStep
    ].templateData[0].TemplateNo;
    this.templateItemsToAddToTemplate.WTStep = wtStep;
    this.templateItemsToAddToTemplate.RevisionNo = '000';
    if (this.templateItemsToAddToTemplate.ExplodeBOM !== 'Y') {
      this.newBlankTemplate[0][wtStep].templateLines.unshift(
        this.templateItemsToAddToTemplate
      );
    }
    if (this.templateItemsToAddToTemplate.ExplodeBOM === 'Y') {
      this.getKitItems(
        this.templateItemsToAddToTemplate.ItemCode,
        this.templateItemsToAddToTemplate.UDF_LOCATION,
        wtStep,
        this.templateItemsToAddToTemplate.QuantityRequired,
        'new'
      );
    }

    // ! Resets item to add to template fields in view once previous item has been added to template
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '000',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
  }

  onSetItemCodeDescOfAddedItem(event: TypeaheadMatch) {
    // * Sets object properties from type ahead select
    this.templateItemsToAddToTemplate.ItemCodeDesc = event.item.Description;
    this.templateItemsToAddToTemplate.ExplodeBOM = event.item.Kit;
  }

  // ! *** For EXISTING templates*** ! //
  onSubmitTemplateChanges() {
    this.SubmittingData = true;
    const observables = [];
    const bodyToSubmit = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      templateHeaderData: [],
      lines: [],
    };

    // * If ADDING items to an existing template *//
    if (this.userAddedTemplateItems.length > 0) {
      this.userAddedTemplateItems.forEach(item => {
        const addedItem = [
          'add',
          item.TemplateNo,
          item.RevisionNo,
          item.WTStep,
          item.ItemCode,
          item.ItemCodeDesc,
          item.QuantityRequired,
          item.CommentText,
          item.UDF_LOCATION,
        ];
        bodyToSubmit.lines.push(addedItem);
      });
    }

    // * If REMOVING items from an existing template *//
    if (this.userRemovedTemplateItems.length > 0) {
      this.userRemovedTemplateItems.forEach(item => {
        const removedItem = [
          'remove',
          item.TemplateNo,
          item.RevisionNo,
          item.WTStep,
          item.ItemCode,
          item.ItemCodeDesc,
          item.QuantityRequired,
          item.CommentText,
          item.UDF_LOCATION,
          item.ExplodeBOM,
          item.LineKey,
        ];
        bodyToSubmit.lines.push(removedItem);
      });
    }

    // * If EDITING Quantity, comment, or location of an existing template item on an existing template *//

    for (const step in this.selectedTemplate[0]) {
      Object.values(this.selectedTemplate[0][step].templateLines).forEach(
        (item: any) => {
          if (item.updated === true) {
            const editedItem = [
              'edit',
              item.TemplateNo,
              item.RevisionNo,
              item.WTStep,
              item.ItemCode,
              item.ItemCodeDesc,
              item.QuantityRequired,
              item.CommentText,
              item.UDF_LOCATION,
              item.ExplodeBOM,
              item.LineKey,
            ];
            bodyToSubmit.lines.push(editedItem);
          }
        }
      );
    }

    // * If UPDATING Initial Status on an existing template (Template Header) *//

    for (const step in this.selectedTemplate[0]) {
      Object.values(this.selectedTemplate[0][step].templateData).forEach(
        (item: any) => {
          if (item.updated === true) {
            const updatedItem = [
              'edit',
              item.TemplateNo,
              item.RevisionNo,
              item.WTStep,
              item.Description,
              item.InitialStatusCode,
            ];
            bodyToSubmit.templateHeaderData.push(updatedItem);
          }
        }
      );
    }

    // * If CREATING template headers for user added steps to existing templates
    for (const step in this.selectedTemplate[0]) {
      Object.values(this.selectedTemplate[0][step].templateData).forEach(
        (item: any) => {
          if (item.created === true) {
            const updatedItem = [
              'create',
              item.TemplateNo,
              item.RevisionNo,
              item.WTStep,
              item.Description,
              item.InitialStatusCode,
              item.CurrentRevision,
            ];
            bodyToSubmit.templateHeaderData.push(updatedItem);
          }
        }
      );
    }

    observables.push(
      this.sageApi.putRequest('Builder/TemplateEditor/Update', bodyToSubmit)
    );

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        this.SubmittingData = false;
        console.log(err);
        this.alertify.error('Error ' + err.error);
      },
      () => {
        this.SubmittingData = false;
        this.alertify.success(
          `TemplateNo ${this.userInputTemplateNumber} Updated!`
        );
        setTimeout(() => {
          this.onResetData();
        }, 1500);
      }
    );
  }

  onSubmitMassTemplateChanges() {
    this.SubmittingData = true;
    const observables = [];
    const bodyToSubmit = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      allTemp: 'N',
      templateData: [],
      ItemCodeLines: [],
    };
    this.ItemCodeLines = [];
    let partFailed = false;
    // Generating item list
    this.massTemplateItemList.forEach(item => {
      const finditem = this.templateItemList.find(
        x =>
          x.ItemCode.toLocaleLowerCase() === item.ItemCode.toLocaleLowerCase()
      );
      const finditem2 = this.templateItemList.find(
        x =>
          x.ItemCode.toLocaleLowerCase() ===
          item.RepItemCode.toLocaleLowerCase()
      );
      if (
        (item.partAction === 'Add' ||
          item.partAction === 'Remove' ||
          item.partAction === 'Replace') &&
        item.ItemCode !== '' &&
        finditem &&
        (item.partAction === 'Remove' ? finditem2 : true)
      ) {
        if (item.partAction === 'Replace') {
          const itObj2 = [
            item.ItemCode.toLocaleUpperCase(),
            '',
            item.wtStep,
            item.RepKit,
            item.RepItemCode,
            item.location,
            'Replace',
          ];
          bodyToSubmit.ItemCodeLines.push(itObj2);
        } else {
          const itObj = [
            item.ItemCode.toLocaleUpperCase(),
            item.Qty,
            item.wtStep,
            item.Kit,
            item.Description,
            item.location,
            item.partAction,
          ];
          bodyToSubmit.ItemCodeLines.push(itObj);
        }
      } else {
        this.alertify.error(
          'Error: ' + item.ItemCode + ' is not a valid or Inactive Item Code.'
        );
        partFailed = true;
        // return;
      }
    });
    if (partFailed) {
      this.SubmittingData = false;
      return;
    }
    if (this.allTemplates) {
      bodyToSubmit.allTemp = 'Y';
    } else {
      this.activeSubmitTemplates.forEach(temp => {
        if (temp.TemplateNo !== '') {
          const tempObj = [temp.TemplateNo, temp.Description];
          bodyToSubmit.templateData.push(tempObj);
        }
      });
    }

    observables.push(
      this.sageApi.putRequest('Builder/TemplateEditor/MassUpdate', bodyToSubmit)
    );

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.SubmittingData = false;
        this.alertify.error('Error ' + err.error);
      },
      () => {
        this.SubmittingData = false;
        this.alertify.success(`Update Templates Request Submitted!`);
        this.MassTemplateSubmit.hide();
      }
    );
  }

  // ! *** CREATING New Templates (either Blank or Copied) *** ! //
  onSubmitTemplateCreation(templateType: string) {
    const observables = [];
    const bodyToSubmit = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      templateHeaderData: [],
      lines: [],
    };

    if (templateType === 'new') {
      // * Adding Items to Template *//
      for (const step in this.newBlankTemplate[0]) {
        if (this.newBlankTemplate[0][step].templateLines.length > 0) {
          Object.values(this.newBlankTemplate[0][step].templateLines).forEach(
            (item: any) => {
              const addedItem = [
                'add',
                item.TemplateNo,
                item.RevisionNo,
                item.WTStep,
                item.ItemCode,
                item.ItemCodeDesc,
                item.QuantityRequired,
                item.CommentText,
                item.UDF_LOCATION,
              ];
              bodyToSubmit.lines.push(addedItem);
            }
          );
        }
      }
      // * If CREATING template headers for user created templates

      for (const step in this.newBlankTemplate[0]) {
        Object.values(this.newBlankTemplate[0][step].templateData).forEach(
          (item: any) => {
            if (item.created === true) {
              const updatedItem = [
                'create',
                item.TemplateNo,
                item.RevisionNo,
                item.WTStep,
                item.Description,
                item.InitialStatusCode,
                item.CurrentRevision,
              ];
              bodyToSubmit.templateHeaderData.push(updatedItem);
            }
          }
        );
      }
    } else if (templateType === 'copied') {
      for (const step in this.selectedTemplateToCopy[0]) {
        if (this.selectedTemplateToCopy[0][step].templateLines.length > 0) {
          Object.values(
            this.selectedTemplateToCopy[0][step].templateLines
          ).forEach((item: any) => {
            const addedItem = [
              'add',
              item.TemplateNo,
              item.RevisionNo,
              item.WTStep,
              item.ItemCode,
              item.ItemCodeDesc,
              item.QuantityRequired,
              item.CommentText,
              item.UDF_LOCATION,
            ];
            bodyToSubmit.lines.push(addedItem);
          });
        }
      }

      // * If CREATING template headers for user created templates

      for (const step in this.selectedTemplateToCopy[0]) {
        Object.values(
          this.selectedTemplateToCopy[0][step].templateData
        ).forEach((item: any) => {
          const createdItem = [
            'create',
            item.TemplateNo,
            item.RevisionNo,
            item.WTStep,
            item.Description,
            item.InitialStatusCode,
            item.CurrentRevision,
          ];
          bodyToSubmit.templateHeaderData.push(createdItem);
        });
      }
    }

    observables.push(
      this.sageApi.putRequest('Builder/TemplateEditor/Update', bodyToSubmit)
    );

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.alertify.error('Error ' + err.error);
      },
      () => {
        if (templateType === 'new') {
          this.alertify.success(
            `TemplateNo ${this.newBlankTemplate[0]['000'].templateData[0].TemplateNo} Updated!`
          );
        } else {
          this.alertify.success(
            `TemplateNo ${this.selectedTemplateToCopy[0]['000'].templateData[0].TemplateNo} Updated!`
          );
        }
        setTimeout(() => {
          this.onResetData();
        }, 1500);
      }
    );
  }

  moveSelectionTab(tabName: string) {
    // !Resets item to add to template object
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
    switch (tabName.toLowerCase()) {
      case 'info':
        this.selectedTabName = 'Info';
        this.selectionTabInfo = true;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 000':
        this.selectedTabName = 'Step 000';
        this.selectionTabInfo = false;
        this.selectionTab000 = true;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 005':
        this.selectedTabName = 'Step 005';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = true;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 006':
        this.selectedTabName = 'Step 006';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = true;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 010':
        this.selectedTabName = 'Step 010';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = true;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 020':
        this.selectedTabName = 'Step 020';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = true;
        this.selectionTabReview = false;
        break;
      case 'review':
        this.selectedTabName = 'Review';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = true;
        break;
    }
  }

  moveTemplateTab(tabName: string) {
    // !Resets item to add to template object
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
    switch (tabName.toLowerCase()) {
      case 'single':
        this.selectedTempName = 'Template Editor';
        this.selectionTempSingle = true;
        this.selectionTempMass = false;
        this.selectionSales = false;
        break;

      case 'mass':
        this.selectedTempName = 'Mass Template Update';
        this.selectionTempSingle = false;
        this.selectionTempMass = true;
        this.selectionSales = false;
        break;

      case 'sales':
        this.selectedTempName = 'Part Replace';
        this.selectionTempSingle = false;
        this.selectionTempMass = false;
        this.selectionSales = true;
        break;
    }
  }

  onClickNextButton() {
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
    switch (this.selectedTabName.toLowerCase()) {
      case 'info':
        this.selectedTabName = 'Step 000';
        this.selectionTabInfo = false;
        this.selectionTab000 = true;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 000':
        this.selectedTabName = 'Step 005';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = true;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 005':
        this.selectedTabName = 'Step 006';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = true;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 006':
        this.selectedTabName = 'Step 010';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = true;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 010':
        this.selectedTabName = 'Step 020';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = true;
        this.selectionTabReview = false;
        break;
      case 'step 020':
        this.selectedTabName = 'Review';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = true;
        break;
    }
  }

  onClickPreviousButton() {
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
    switch (this.selectedTabName.toLowerCase()) {
      case 'step 000':
        this.selectedTabName = 'Info';
        this.selectionTabInfo = true;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 005':
        this.selectedTabName = 'Step 000';
        this.selectionTabInfo = false;
        this.selectionTab000 = true;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 006':
        this.selectedTabName = 'Step 005';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = true;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 010':
        this.selectedTabName = 'Step 006';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = true;
        this.selectionTab010 = false;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'step 020':
        this.selectedTabName = 'Step 010';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = true;
        this.selectionTab020 = false;
        this.selectionTabReview = false;
        break;
      case 'review':
        this.selectedTabName = 'Step 020';
        this.selectionTabInfo = false;
        this.selectionTab000 = false;
        this.selectionTab005 = false;
        this.selectionTab006 = false;
        this.selectionTab010 = false;
        this.selectionTab020 = true;
        this.selectionTabReview = false;
        break;
    }
  }

  onCreateBlankTemplateButtonClick() {
    this.creatingNewTemplate = true;
    this.copyingTemplate = false;
    this.showTemplateToCopySearchArea = false;
    this.createNewTemplateModal.hide();
    this.moveSelectionTab('Step 000');
    this.newBlankTemplate[0]['000'].templateData[0].created = true;
  }

  onCopyExistingTemplateButtonClick() {
    this.showTemplateToCopySearchArea = true;
  }

  onSelectTemplateToCopyFrom(event: TypeaheadMatch) {
    this.getTemplateLines(event.item.TemplateNo, 'copied');
    this.copyingTemplate = true;
    this.creatingNewTemplate = false;
    this.templateNumberToCopy = event.item.TemplateName;

    this.selectedTemplateToCopy = [
      {
        '000': {
          name: 'step 000',
          templateData: [],
          templateLines: [],
          wtStep: '000',
        },
        '005': {
          name: 'step 005',
          templateData: [],
          templateLines: [],
          wtStep: '005',
        },
        '006': {
          name: 'step 006',
          templateData: [],
          templateLines: [],
          wtStep: '006',
        },
        '010': {
          name: 'step 010',
          templateData: [],
          templateLines: [],
          wtStep: '010',
        },
        '020': {
          name: 'step 020',
          templateData: [],
          templateLines: [],
          wtStep: '020',
        },
      },
    ];
    const matchTemplateSteps: Array<any> = this.activeTemplates.filter(
      item => item.TemplateNo === event.item.TemplateNo
    );

    matchTemplateSteps.forEach(item => {
      switch (item.WTStep) {
        case '000':
          this.selectedTemplateToCopy[0][item.WTStep].templateData.push(item);
          this.selectedTemplateToCopy[0][
            item.WTStep
          ].templateData[0].TemplateNo = this.templateNumberForCopiedTemplate;
          break;
        case '005':
          this.selectedTemplateToCopy[0][item.WTStep].templateData.push(item);
          this.selectedTemplateToCopy[0][
            item.WTStep
          ].templateData[0].TemplateNo = this.templateNumberForCopiedTemplate;
          break;
        case '006':
          this.selectedTemplateToCopy[0][item.WTStep].templateData.push(item);
          this.selectedTemplateToCopy[0][
            item.WTStep
          ].templateData[0].TemplateNo = this.templateNumberForCopiedTemplate;
          break;
        case '010':
          this.selectedTemplateToCopy[0][item.WTStep].templateData.push(item);
          this.selectedTemplateToCopy[0][
            item.WTStep
          ].templateData[0].TemplateNo = this.templateNumberForCopiedTemplate;
          break;
        case '020':
          this.selectedTemplateToCopy[0][item.WTStep].templateData.push(item);
          this.selectedTemplateToCopy[0][
            item.WTStep
          ].templateData[0].TemplateNo = this.templateNumberForCopiedTemplate;
          break;
      }
    });

    this.onCloseCreateNewTemplateModal();
  }

  onCopyTemplateFromEditScreen() {
    // ToDo: Something
  }

  onOpenModal(template: TemplateRef<any>) {
    // this.copyTemplateFromEditScreenModal = this.modalService.show(template);
    this.ItemCodeLines = [];
    // Generating item list
    this.massTemplateItemList.forEach((item: any) => {
      if (
        (item.partAction === 'Add' ||
          item.partAction === 'Remove' ||
          item.partAction === 'Replace') &&
        item.ItemCode !== ''
      ) {
        const itObj = [
          item.ItemCode, // 0
          item.Kit, // 1
          'N',
        ];
        const finditem = this.templateItemList
          .map(e => e.ItemCode)
          .indexOf(item.ItemCode);
        if (finditem > -1) {
          itObj[2] = this.templateItemList[finditem].inactive;
        }

        if (itObj[2] === 'Y' && itObj[0] !== '') {
          this.ItemCodeLines.push(itObj);
        }
        if (itObj[1] === 'Y' || item.kitList !== []) {
          item.kitList.forEach(kitem => {
            const kitObj = [
              kitem.ItemCode, // 0
              'N', // 1
              'N',
            ];
            const Kfinditem = this.templateItemList
              .map(e => e.ItemCode)
              .indexOf(kitem.ItemCode);
            if (Kfinditem > -1) {
              kitObj[2] = this.templateItemList[Kfinditem].inactive;
            }
            if (kitObj[2] === 'Y' && kitObj[0] !== '') {
              this.ItemCodeLines.push(kitObj);
            }
          });
        }

        if (item.partAction === 'Replace') {
          const itObj2 = [item.RepItemCode, item.RepKit, 'N'];
          const finditem2 = this.templateItemList
            .map(e => e.ItemCode)
            .indexOf(item.RepItemCode);
          if (finditem2 > -1) {
            itObj2[2] = this.templateItemList[finditem2].inactive;
          }
          if (itObj2[2] === 'Y' && itObj2[0] !== '') {
            this.ItemCodeLines.push(itObj2);
          }
          if (itObj2[1] === 'Y' || item.kitList !== []) {
            item.kitList.forEach(kitem => {
              const kitObj = [
                kitem.ItemCode, // 0
                'N', // 1
                'N',
              ];
              const Kfinditem = this.templateItemList
                .map(e => e.ItemCode)
                .indexOf(kitem.ItemCode);
              if (Kfinditem > -1) {
                kitObj[2] = this.templateItemList[finditem].inactive;
              }
              if (kitObj[2] === 'Y' && kitObj[0] !== '') {
                this.ItemCodeLines.push(kitObj);
              }
            });
          }
        }
      } else {
        this.alertify.error(
          'Error: Failed to apply correct action to Part: ' +
            item.ItemCode +
            ', Action: ' +
            item.partAction
        );
        return;
      }
    });

    this.MassTemplateSubmit = this.modalService.show(template);
    // this.templateConflicts();
    // this.modalService.show(template);
  }

  templateConflicts() {
    this.loadingData = true;
    const observables = [];
    // this.activeTemplates = [];
    const bodyToSubmit = {
      username: '',
      password: '',
      allTemp: this.allTemplates ? 'Y' : 'N',
      templateData: [],
      ItemCodeLines: [],
    };

    // Generating item list
    this.massTemplateItemList.forEach(item => {
      if (
        (item.partAction === 'Add' ||
          item.partAction === 'Remove' ||
          item.partAction === 'Replace') &&
        item.ItemCode !== ''
      ) {
        const itObj = [
          item.ItemCode, // 0
          item.Qty, // 1
          item.wtStep, // 2
          item.Kit, // 3
          item.Description, // 4
          item.location, // 5
          item.partAction === 'Replace' ? 'Remove' : item.partAction, // 6
        ];
        bodyToSubmit.ItemCodeLines.push(itObj);

        if (item.partAction === 'Replace') {
          const itObj2 = [
            item.RepItemCode,
            item.RepQty,
            item.wtStep,
            item.RepKit,
            item.RepDescription,
            item.location,
            'Add',
          ];
          bodyToSubmit.ItemCodeLines.push(itObj2);
        }
      } else {
        this.alertify.error(
          'Error: Failed to apply correct action to Part: ' +
            item.ItemCode +
            ', Action: ' +
            item.partAction
        );
        return;
      }
    });

    if (this.allTemplates) {
      bodyToSubmit.allTemp = 'Y';
    } else {
      this.activeSubmitTemplates.forEach(temp => {
        if (temp.TemplateNo !== '') {
          const tempObj = [temp.TemplateNo, temp.Description];
          bodyToSubmit.templateData.push(tempObj);
        }
      });
    }

    observables.push(
      this.sageApi.postRequest2(
        'Builder/TemplateEditor/TemplateSubmitCheck',
        bodyToSubmit
      )
    );

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.alertify.error('Error ' + err.error);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  onCloseModal(template: TemplateRef<any>, templateName: string) {
    if (templateName === 'createNewTemplate') {
      this.selectedTemplateToCopy = [
        {
          '000': {
            name: 'step 000',
            templateData: [],
            templateLines: [],
            wtStep: '000',
          },
          '005': {
            name: 'step 005',
            templateData: [],
            templateLines: [],
            wtStep: '005',
          },
          '006': {
            name: 'step 006',
            templateData: [],
            templateLines: [],
            wtStep: '006',
          },
          '010': {
            name: 'step 010',
            templateData: [],
            templateLines: [],
            wtStep: '010',
          },
          '020': {
            name: 'step 020',
            templateData: [],
            templateLines: [],
            wtStep: '020',
          },
        },
      ];
      this.creatingNewTemplate = true;
      this.showTemplateToCopySearchArea = false;
    }

    if (templateName === 'copyTemplateFromEditScreen') {
      this.copyTemplateFromEditScreenModal.hide();
    }

    if (templateName === 'MassTemplateSubmit') {
      this.MassTemplateSubmit.hide();
    }
  }

  onOpenCreateNewTemplateModal(template) {
    this.createNewTemplateModal = this.modalService.show(template);
    this.showTemplateToCopySearchArea = false;
  }

  onCloseCreateNewTemplateModal() {
    this.createNewTemplateModal.hide();
  }

  onOpenSalesOrderUpdate(template) {
    this.checkAmounts();
    this.updateSalesOrders = this.modalService.show(template);
  }
  onCloseSalesOrderUpdate() {
    this.updateSalesOrders.hide();
  }
  onChangeItemLocation(
    event: TypeaheadMatch,
    line,
    index: string,
    templateType: string
  ) {
    if (templateType === 'existing') {
      this.selectedTemplate[0][line.WTStep].templateLines[index].UDF_LOCATION =
        event.item.Location;
    } else if (templateType === 'copied') {
      this.selectedTemplateToCopy[0][line.WTStep].templateLines[
        index
      ].UDF_LOCATION = event.item.Location;
    }
  }

  onExplodeKitItems() {
    if (this.templateItemsToAddToTemplate.ExplodeBOM === 'Y') {
      this.templateItemsToAddToTemplate.ExplodeBOM = 'N';
    } else {
      this.templateItemsToAddToTemplate.ExplodeBOM = 'Y';
    }
  }

  onCancelNewTemplateCreation() {
    this.onResetData();
  }

  pullLocation() {
    this.LocationList = [
      {
        UDF_LOCATION_CODE: '',
        UDF_LOCATION_DESC: '',
      },
    ];
    this.sageApi.pullReport('Builder/TemplatesEditor/locations').subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            this.LocationList.push(item);
          });
        }
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  setMassIC(event: TypeaheadMatch, index) {
    this.massTemplateItemList[index].Name = event.item.Name;
    this.massTemplateItemList[index].ItemCode = event.item.ItemCode;
    this.massTemplateItemList[index].Kit = event.item.Kit;
    if (event.item.Kit === 'Y') {
      this.getKitItemsMass(
        this.massTemplateItemList[index].ItemCode,
        this.massTemplateItemList[index].location,
        this.massTemplateItemList[index].wtStep,
        this.massTemplateItemList[index].Qty.toString(),
        index,
        false
      );
    }
  }

  setRepIC(event: TypeaheadMatch, index) {
    this.massTemplateItemList[index].RepName = event.item.Name;
    this.massTemplateItemList[index].RepItemCode = event.item.ItemCode;
    this.massTemplateItemList[index].RepKit = event.item.Kit;
    if (event.item.RepShowKit === 'Y') {
      this.getKitItemsMass(
        this.massTemplateItemList[index].RepItemCode,
        this.massTemplateItemList[index].location,
        this.massTemplateItemList[index].wtStep,
        this.massTemplateItemList[index].RepQty.toString(),
        index,
        true
      );
    }
  }

  rotateAction(index) {
    if (this.massTemplateItemList[index].partAction === 'Remove') {
      this.massTemplateItemList[index].partAction = 'Replace';
    } else if (this.massTemplateItemList[index].partAction === 'Replace') {
      this.massTemplateItemList[index].partAction = 'Add';
    } else {
      this.massTemplateItemList[index].partAction = 'Remove';
    }
  }

  addSelected() {
    let indexDown = 0;
    this.selectedOptionList.forEach(template => {
      const ind = template.split(' - ')[0];

      if (
        this.activeSubmitTemplates === [] ||
        this.activeSubmitTemplates[0] === undefined
      ) {
        this.activeSubmitTemplates.push(
          this.activeMassTemplates[ind - indexDown]
        );
      } else if (this.activeSubmitTemplates[0].TemplateNo === '') {
        this.activeSubmitTemplates = [
          this.activeMassTemplates[ind - indexDown],
        ];
      } else {
        this.activeSubmitTemplates.push(
          this.activeMassTemplates[ind - indexDown]
        );
      }

      this.activeMassTemplates.splice(ind - indexDown, 1);
      indexDown++;
    });
  }

  RemoveSelected() {
    let indexDown = 0;
    this.ChosenOptionList.forEach(template => {
      const ind = template.split(' - ')[0];

      if (this.activeMassTemplates[0].TemplateNo === '') {
        this.activeMassTemplates = [
          this.activeSubmitTemplates[ind - indexDown],
        ];
      } else {
        this.activeMassTemplates.push(
          this.activeSubmitTemplates[ind - indexDown]
        );
      }

      this.activeSubmitTemplates.splice(ind - indexDown, 1);
      indexDown++;
    });
  }
  // ! Reset Data !//
  onResetData() {
    this.getTemplates();
    this.templateNumberToCopy = '';
    this.selectedTemplateToCopy = [];
    this.showTemplateToCopySearchArea = false;
    this.selectionTabInfo = true;
    this.selectionTab000 = false;
    this.selectionTab005 = false;
    this.selectionTab006 = false;
    this.selectionTab010 = false;
    this.selectionTab020 = false;
    this.selectionTabReview = false;
    this.selectedTabName = 'Info';
    this.showReviewStep000 = true;
    this.showReviewStep005 = true;
    this.showReviewStep006 = true;
    this.showReviewStep010 = true;
    this.showReviewStep020 = true;
    this.copyingTemplate = false;
    this.selectedTemplate = [
      { name: 'step 000' },
      { name: 'step 005' },
      { name: 'step 006' },
      { name: 'step 010' },
      { name: 'step 020' },
      { name: 'user created step' },
      { name: 'review' },
    ];
    this.selectedTemplateToCopy = [
      {
        '000': {
          name: 'step 000',
          templateData: [],
          templateLines: [],
          wtStep: '000',
        },
        '005': {
          name: 'step 005',
          templateData: [],
          templateLines: [],
          wtStep: '005',
        },
        '006': {
          name: 'step 006',
          templateData: [],
          templateLines: [],
          wtStep: '006',
        },
        '010': {
          name: 'step 010',
          templateData: [],
          templateLines: [],
          wtStep: '010',
        },
        '020': {
          name: 'step 020',
          templateData: [],
          templateLines: [],
          wtStep: '020',
        },
      },
    ];
    this.userRemovedTemplateItems = [];
    this.templateItemsToAddToTemplate = {
      TemplateNo: '',
      RevisionNo: '000',
      WTStep: '',
      ItemCode: '',
      ItemCodeDesc: '',
      QuantityRequired: '',
      CommentText: '',
      UDF_LOCATION: '',
      ExplodeBOM: '',
      ChargePart: 'N',
      PrintPart: 'N',
      ScalePart: 'Y',
    };
    this.userAddedTemplateItems = [];
    this.newBlankTemplate = [
      {
        '000': {
          name: 'step 000',
          templateData: [
            {
              TemplateNo: '',
              RevisionNo: '000',
              WTStep: '000',
              CurrentRevision: 'Y',
              Description: 'Default Activity Code',
              InitialStatusCode: 'NEW',
              created: false,
            },
          ],
          templateLines: [],
          wtStep: '000',
        },
        '005': {
          name: 'step 005',
          templateData: [
            {
              TemplateNo: '',
              RevisionNo: '000',
              WTStep: '005',
              CurrentRevision: 'Y',
              Description: 'Default Activity Code',
              InitialStatusCode: 'NEW',
              created: false,
            },
          ],
          templateLines: [],
          wtStep: '005',
        },
        '006': {
          name: 'step 006',
          templateData: [
            {
              TemplateNo: '',
              RevisionNo: '000',
              WTStep: '006',
              CurrentRevision: 'Y',
              Description: 'Default Activity Code',
              InitialStatusCode: 'NEW',
              created: false,
            },
          ],
          templateLines: [],
          wtStep: '006',
        },
        '010': {
          name: 'step 010',
          templateData: [
            {
              TemplateNo: '',
              RevisionNo: '000',
              WTStep: '010',
              CurrentRevision: 'Y',
              Description: 'Parts',
              InitialStatusCode: 'NEW',
              created: false,
            },
          ],
          templateLines: [],
          wtStep: '010',
        },
        '020': {
          name: 'step 020',
          templateData: [
            {
              TemplateNo: '',
              RevisionNo: '000',
              WTStep: '020',
              CurrentRevision: 'Y',
              Description: 'Labor',
              InitialStatusCode: 'NEW',
              created: false,
            },
          ],
          templateLines: [],
          wtStep: '020',
        },
      },
    ];
    this.userInputTemplateNumber = '';
    this.selectedTemplate = [];
    this.userSelectedTemplate = false;
    this.creatingNewTemplate = false;
    this.templateNumberToCopy = '';
    this.ItemCodeSales = [];
    this.ableToAdd = false;
  }
  // ! Reset Data !//
}
