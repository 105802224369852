<!-- * BEGINNING of Edit Modal -->
<ng-template #editPhaseTicketModal>
  <div class="container card">
    <div class="row p-2">
      <div
        *ngIf="phaseTickets.length > 0"
        class="col-md-12 d-flex justify-content-between"
      >
        <h5>
          Editing
          <b
            ><u>{{ phaseTickets[editIndex].address }}</u></b
          >
          in
          <b
            ><u>{{ phaseTickets[editIndex].subdivision }}</u></b
          >
        </h5>
        <div *ngIf="updatingData" class="updating-data">
          <p>
            Updating data, do not leave the Phase Pay area...
            <i class="fas fa-sync-alt updating-data-icon"></i>
          </p>
        </div>
        <button
          class="btn btn-primary"
          type="button"
          (click)="onClosePhaseTicketModal('editPhaseTicketModal')"
        >
          X
        </button>
      </div>
    </div>
    <div class="row p-2 d-flex justify-content-between">
      <div *ngIf="phaseTickets.length > 0" class="col-md-6">
        <h5>
          SO#: <strong>{{ phaseTickets[editIndex].soNum }}</strong>
        </h5>
      </div>
      <div *ngIf="phaseTickets.length > 0" class="col-md-6">
        <h5>
          Phase: <strong>{{ phaseTickets[editIndex].phase }}</strong>
        </h5>
      </div>
    </div>
    <div
      class="row p-2"
      *ngIf="
        phaseTickets.length > 0 &&
        phaseTickets[editIndex].phasePayStatus === 'approved' &&
        (authService.decodedToken.role === 'admin' ||
          authService.decodedToken.role === 'superadmin' ||
          authService.decodedToken.role === 'acct_mgt')
      "
    >
      <div class="col-md-12">
        <h5
          *ngIf="
            todayDate < phaseTickets[1].UDF_PAY_DATE &&
            phaseTickets[editIndex].phasePayStatus === 'approved'
          "
          class="alert alert-danger text-center"
        >
          <i class="fas fa-exclamation-triangle"></i> You are editing an
          <span class="font-weight-bold">APPROVED</span> phase
          <i class="fas fa-exclamation-triangle"></i>
        </h5>
        <h5
          *ngIf="
            todayDate > phaseTickets[1].UDF_PAY_DATE &&
            phaseTickets[editIndex].phasePayStatus === 'approved'
          "
          class="alert alert-danger text-center"
        >
          <i class="fas fa-exclamation-triangle"></i> The pay date has passed,
          you may not edit this ticket
          <i class="fas fa-exclamation-triangle"></i>
        </h5>
      </div>
    </div>
    <hr />
    <div class="row p-2">
      <form *ngIf="phaseTickets.length > 0" class="form col-md-12">
        <div class="d-flex col-md-12">
          <div class="form-group col-md-6">
            <label for="phase-approval-phase">Phase</label>
            <input
              disabled
              type="text"
              name="phase-approval-phase"
              id="phase-approval-phase"
              class="form-control"
              placeholder="{{
                phaseTickets[editIndex].phase.slice(
                  1,
                  phaseTickets[editIndex].length
                )
              }}"
              aria-describedby="phase-approval-phase"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="phase-approval-schedule-date">Schedule Date</label>
            <input
              disabled
              type="text"
              name="phase-approval-schedule-date"
              id="phase-approval-schedule-date"
              class="form-control"
              placeholder="{{
                phaseTickets[editIndex].dateScheduled | date: 'shortDate'
              }}"
              aria-describedby="phase-approval-schedule-date"
            />
          </div>
        </div>
        <div class="d-flex col-md-12">
          <div class="form-group col-md-6">
            <label for="phase-approval-tech">Technician</label>
            <input
              disabled
              type="text"
              name="phase-approval-tech"
              id="phase-approval-tech"
              class="form-control"
              placeholder="{{ phaseTickets[editIndex].techName }}"
              aria-describedby="phase-approval-tech"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="phase-approval-subvendor">Subvendor</label>
            <input
              disabled
              type="text"
              name="phase-approval-subvendor"
              id="phase-approval-subvendor"
              class="form-control"
              placeholder="{{ phaseTickets[editIndex].subVendor }}"
              aria-describedby="phase-approval-subvendor"
            />
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="phase-approval-notes">Ticket Notes</label>
          <small> *Required</small>
          <textarea
            class="form-control"
            name="phase-approval-notes"
            id="phase-approval-notes"
            rows="3"
            [disabled]="
              phaseTickets[editIndex].phasePayStatus === 'approved' &&
              authService.decodedToken.role !== 'admin' &&
                authService.decodedToken.role !== 'superadmin' &&
                authService.decodedToken.role !== 'acct_mgt'
            "
            required
            [required]="allowEditOnRestrictedTickets"
            [(ngModel)]="phaseTickets[editIndex].phasePayComment"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row p-2 d-flex">
      <div class="col-md-4 offset-md-4 text-center">
        <button
          type="button"
          class="btn btn-primary"
          (click)="showTicketLines = !showTicketLines"
        >
          {{ showTicketLines ? 'Hide Ticket Lines' : 'Show Ticket Lines' }}
        </button>
      </div>
      <div
        class="col-md-4 text-right"
        *ngIf="
          phaseTickets[editIndex] !== undefined &&
          phaseTickets[editIndex].phase.toLocaleLowerCase().includes('roughin')
        "
      >
        <button
          type="button"
          class="btn btn-warning float-right"
          (click)="onAddRoughInAdders(addRoughInAddersModal, editIndex)"
        >
          Adders <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div *ngIf="throwDateError" class="row p-2 d-flex justify-content-center">
      <h4>
        <i class="fas fa-exclamation-triangle"></i> Pay dates must be a Friday!
        <i class="fas fa-exclamation-triangle"></i>
      </h4>
    </div>
    <div class="row p-2" *ngIf="showTicketLines">
      <div class="col-md-12 table-responsive">
        <form class="form">
          <table class="table table-sm table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">SO#</th>
                <th scope="col">Item</th>
                <th scope="col">Loc</th>
                <th scope="col">Desc</th>
                <th scope="col">Status</th>
                <th scope="col">Pay Date</th>
                <th scope="col">Item Notes</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="phaseTicketLines.length > 0">
                <tr
                  [ngClass]="{
                    approvedLines: line.phasePayStatus === 'approved'
                  }"
                  *ngFor="let line of phaseTicketLines; index as index"
                >
                  <td>{{ line.soNum }}</td>
                  <td>{{ line.phase }}</td>
                  <td>{{ line.location }}</td>
                  <td>{{ line.itemCodeDesc }}</td>
                  <td *ngIf="line.phasePayStatus !== 'approved'">
                    <div class="form-group">
                      <label for="modal-phase-pay-status-edit" class="sr-only"
                        >Edit Phase Pay Status</label
                      >
                      <select
                        *ngIf="line.wtParent === 'N'"
                        [(ngModel)]="line.phasePayStatus"
                        class="form-select"
                        name="modal-phase-pay-status-edit"
                        [disabled]="
                          throwDateError ||
                          line.phasePayStatus === 'approved' ||
                          (showApprovedTable &&
                            line.phase.substring(0, 1).toLowerCase() === 'z' &&
                            line.phasePayStatus === 'approved')
                        "
                        id="modal-phase-pay-status-edit"
                      >
                        <option value="active">Active</option>
                        <option value="hold">Hold</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </td>
                  <td *ngIf="line.phasePayStatus === 'approved'">
                    {{ line.phasePayStatus }}
                  </td>
                  <td *ngIf="line.phasePayStatus !== 'approved'">
                    <div class="form-group w-75">
                      <label for="phase-pay-pay-date" class="sr-only"
                        >Pay Date</label
                      >
                      <input
                        *ngIf="line.wtParent === 'N'"
                        type="date"
                        class="form-control pay-date-inputs"
                        value="{{ fridayDate }}"
                        (change)="onPayDateCheck($event, index)"
                        [disabled]="line.phasePayStatus === 'approved'"
                        [(ngModel)]="line.UDF_PAY_DATE"
                        name="phase-pay-pay-date"
                        id="phase-pay-pay-date"
                        aria-describedby="phase-pay-pay-date"
                      />
                    </div>
                  </td>
                  <td
                    *ngIf="
                      line.phasePayStatus === 'approved' &&
                      line.wtParent === 'N'
                    "
                  >
                    {{ line.UDF_PAY_DATE | date: 'longDate' }}
                  </td>
                  <td
                    *ngIf="
                      line.phasePayStatus === 'approved' &&
                      line.wtParent === 'Y'
                    "
                  >
                    {{ fridayDate | date: 'longDate' }}
                  </td>
                  <td>
                    <div class="form-group" style="min-width: 150px">
                      <label for="phase-pay-line-notes" class="sr-only"
                        >Line Notes</label
                      >
                      <textarea
                        [(ngModel)]="line.phasePayComment"
                        class="form-control"
                        name="{{ 'phase-pay-line-notes' + index }}"
                        [disabled]="
                          throwDateError ||
                          phaseTicketLines[0].phasePayStatus === 'approved'
                        "
                        id="{{ 'phase-pay-line-notes' + index }}"
                        rows="2"
                        >{{ line.phasePayComment }}</textarea
                      >
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>
    </div>
    <hr />
    <div
      class="row p-2"
      *ngIf="
        phaseTickets.length > 0 &&
        phaseTickets[editIndex].phasePayStatus === 'approved' &&
        (authService.decodedToken.role === 'admin' ||
          authService.decodedToken.role === 'superadmin' ||
          authService.decodedToken.role === 'acct_mgt')
      "
    >
      <div class="col-md-12">
        <h5
          *ngIf="
            todayDate < phaseTickets[1].UDF_PAY_DATE &&
            phaseTickets[editIndex].phasePayStatus === 'approved'
          "
          class="alert alert-danger text-center"
        >
          <i class="fas fa-exclamation-triangle"></i> This will remove the Pay
          Date <i class="fas fa-exclamation-triangle"></i>
        </h5>
        <h5
          *ngIf="
            todayDate > phaseTickets[1].UDF_PAY_DATE &&
            phaseTickets[editIndex].phasePayStatus === 'approved'
          "
          class="alert alert-danger text-center"
        >
          <i class="fas fa-exclamation-triangle"></i> The pay date has passed,
          you may not edit this ticket
          <i class="fas fa-exclamation-triangle"></i>
        </h5>
      </div>
    </div>
    <div *ngIf="throwDateError" class="row p-2 d-flex justify-content-center">
      <h4>
        <i class="fas fa-exclamation-triangle"></i> Pay dates must be a Friday!
        <i class="fas fa-exclamation-triangle"></i>
      </h4>
    </div>
    <div
      *ngIf="phaseTickets.length > 0"
      class="row p-2 d-flex justify-content-between"
    >
      <button
        *ngIf="phaseTickets[editIndex].phasePayStatus !== 'approved'"
        [disabled]="
          phaseTickets[editIndex].phasePayComment === '' ||
          throwDateError === true ||
          updatingData
        "
        type="button"
        class="btn btn-success"
        (click)="onApproveTicket(editIndex, 'editPhaseTicketModal')"
      >
        {{ updatingData === false ? 'Submit Changes' : 'Updating...' }}
      </button>
      <button
        *ngIf="
          phaseTickets[editIndex].phasePayStatus === 'approved' &&
          (authService.decodedToken.role === 'admin' ||
            authService.decodedToken.role === 'superadmin' ||
            authService.decodedToken.role === 'acct_mgt')
        "
        [disabled]="
          (phaseTickets[editIndex].phasePayComment === '' ||
            todayDate > phaseTickets[1].UDF_PAY_DATE) &&
          !(
            phaseTickets[editIndex].techName
              .toLocaleLowerCase()
              .includes('unassigned') ||
            phaseTickets[editIndex].techName === '' ||
            phaseTickets[editIndex].techName === null ||
            phaseTickets[editIndex].techName === undefined
          )
        "
        type="button"
        class="btn btn-success"
        (click)="onEditApprovedTicket(editIndex)"
      >
        Send Back to Unapproved Status
      </button>
      <div *ngIf="updatingData" class="updating-data">
        <p>
          Updating data, do not leave the Phase Pay area...
          <i class="fas fa-sync-alt updating-data-icon"></i>
        </p>
      </div>
      <button
        [disabled]="
          phaseTickets[editIndex].phasePayComment === '' ||
          todayDate > phaseTickets[1].UDF_PAY_DATE
        "
        type="button"
        class="btn btn-primary"
        *ngIf="
          phaseTickets[editIndex].phasePayStatus !== 'hold' &&
          (authService.decodedToken.role === 'admin' ||
            authService.decodedToken.role === 'superadmin' ||
            authService.decodedToken.role === 'acct_mgt')
        "
        (click)="onHoldTicket(editIndex)"
      >
        HOLD ENTIRE TICKET
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onClosePhaseTicketModal('editPhaseTicketModal')"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
<!-- * END of Edit Modal -->

<!-- * BEGINNING of Hold Modal -->
<ng-template #phaseTicketHoldModal>
  <div class="container-fluid">
    <div class="row p-2">
      <div
        *ngIf="phaseTicketHoldLines.length > 0"
        class="col-md-12 d-flex justify-content-between"
      >
        <h5>
          Editing
          <b
            ><u>{{ phaseTicketHoldLines[editIndex].address }}</u></b
          >
          in
          <b
            ><u>{{ phaseTicketHoldLines[editIndex].subdivision }}</u></b
          >
        </h5>
        <button
          class="btn btn-primary"
          type="button"
          (click)="onClosePhaseTicketModal('phaseTicketHoldModal')"
        >
          X
        </button>
      </div>
    </div>
    <div class="row p-2 d-flex justify-content-between">
      <div *ngIf="phaseTicketHoldLines.length > 0" class="col-md-6">
        <h5>
          SO#: <strong>{{ phaseTicketHoldLines[editIndex].soNum }}</strong>
        </h5>
      </div>
      <div *ngIf="phaseTicketHoldLines.length > 0" class="col-md-6">
        <h5>
          Item: <strong>{{ phaseTicketHoldLines[editIndex].phase }}</strong>
        </h5>
      </div>
    </div>
    <div
      class="row p-2"
      *ngIf="
        phaseTicketHoldLines.length > 0 &&
        phaseTicketHoldLines[editIndex].phasePayStatus === 'approved'
      "
    >
      <div class="col-md-12">
        <h5 class="alert alert-danger text-center">
          <i class="fas fa-exclamation-triangle"></i> You are editing an
          <span class="font-weight-bold">APPROVED</span> phase
          <i class="fas fa-exclamation-triangle"></i>
        </h5>
      </div>
    </div>
    <hr />
    <div class="row p-2">
      <form *ngIf="phaseTicketHoldLines.length > 0" class="form col-md-12">
        <div class="d-flex col-md-12">
          <div class="form-group col-md-6">
            <label for="phase-approval-phase">Desc</label>
            <input
              disabled
              type="text"
              name="phase-approval-phase"
              id="phase-approval-phase"
              class="form-control"
              placeholder="{{ phaseTicketHoldLines[editIndex].itemCodeDesc }}"
              aria-describedby="phase-approval-phase"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="phase-approval-schedule-date">Schedule Date</label>
            <input
              disabled
              type="text"
              name="phase-approval-schedule-date"
              id="phase-approval-schedule-date"
              class="form-control"
              placeholder="{{
                phaseTicketHoldLines[editIndex].dateScheduled
                  | date: 'shortDate'
              }}"
              aria-describedby="phase-approval-schedule-date"
            />
          </div>
        </div>
        <div class="d-flex col-md-12">
          <div class="form-group col-md-6">
            <label for="phase-approval-tech">Technician</label>
            <input
              disabled
              type="text"
              name="phase-approval-tech"
              id="phase-approval-tech"
              class="form-control"
              placeholder="{{ phaseTicketHoldLines[editIndex].techName }}"
              aria-describedby="phase-approval-tech"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="phase-approval-subvendor">Subvendor</label>
            <input
              disabled
              type="text"
              name="phase-approval-subvendor"
              id="phase-approval-subvendor"
              class="form-control"
              placeholder="{{ phaseTicketHoldLines[editIndex].subVendor }}"
              aria-describedby="phase-approval-subvendor"
            />
          </div>
        </div>
        <div *ngIf="!showHoldTable" class="form-group col-md-12">
          <label for="phase-approval-notes">Notes</label>
          <textarea
            class="form-control"
            name="phase-approval-notes"
            id="phase-approval-notes"
            rows="3"
            required
            [required]="allowEditOnRestrictedTickets"
            [(ngModel)]="phaseTicketHoldLines[editIndex].phasePayComment"
          ></textarea>
        </div>
        <div *ngIf="showHoldTable" class="form-group col-md-12">
          <label for="phase-approval-notes">Hold Notes</label>
          <textarea
            class="form-control"
            name="phase-approval-notes"
            id="phase-approval-notes"
            rows="3"
            required
            [required]="allowEditOnRestrictedTickets"
            [(ngModel)]="phaseTicketHoldLines[editIndex].phasePayComment"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row p-2" *ngIf="showTicketLines">
      <div class="col-md-12">
        <table class="table table-hover mx-auto">
          <thead class="thead-dark">
            <tr>
              <th scope="col">SO#</th>
              <th scope="col">WT#</th>
              <th scope="col">Item</th>
              <th scope="col">Desc</th>
              <th scope="col">Tech</th>
              <th scope="col">Status</th>
              <th scope="col">Pay Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="phaseTicketLines.length > 0">
              <tr
                [ngClass]="{
                  approvedLines: line.phasePayStatus === 'approved'
                }"
                *ngFor="let line of phaseTicketLines; index as index"
              >
                <td>{{ line.soNum }}</td>
                <td>{{ line.wtNumber }}</td>
                <td>{{ line.phase }}</td>
                <td>{{ line.itemCodeDesc }}</td>
                <td>{{ line.techName }}</td>
                <td *ngIf="line.phasePayStatus !== 'approved'">
                  <div class="form-group">
                    <label for="modal-phase-pay-status-edit" class="sr-only"
                      >Edit Phase Pay Status</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="line.phasePayStatus"
                      class="form-control"
                      name="modal-phase-pay-status-edit"
                      aria-describedby="modal-phase-pay-status-edit"
                      placeholder="{{ line.phasePayStatus }}"
                    />
                  </div>
                </td>
                <td *ngIf="line.phasePayStatus === 'approved'">
                  {{ line.phasePayStatus }}
                </td>
                <td
                  *ngIf="
                    line.phasePayStatus !== 'approved' &&
                    line.phase.substring(0, 1).toLowerCase() !== 'z'
                  "
                >
                  <div class="form-group">
                    <label for="phase-pay-pay-date" class="sr-only"
                      >Pay Date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      [disabled]="line.WTParent === 'Y'"
                      value="{{ fridayDate }}"
                      [(ngModel)]="line.UDF_PAY_DATE"
                      name="phase-pay-pay-date"
                      id="phase-pay-pay-date"
                      aria-describedby="phase-pay-pay-date"
                    />
                  </div>
                </td>
                <td *ngIf="line.phasePayStatus === 'approved'">
                  {{ line.UDF_PAY_DATE | date: 'longDate' }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <hr />
    <div
      class="row p-2"
      *ngIf="
        phaseTicketHoldLines.length > 0 &&
        phaseTicketHoldLines[editIndex].phasePayStatus === 'approved'
      "
    >
      <div class="col-md-12">
        <h5 class="alert alert-danger text-center">
          <i class="fas fa-exclamation-triangle"></i> This will remove the Pay
          Date <i class="fas fa-exclamation-triangle"></i>
        </h5>
      </div>
    </div>
    <div
      *ngIf="phaseTicketHoldLines.length > 0"
      class="row p-2 d-flex justify-content-between"
    >
      <button
        *ngIf="phaseTicketHoldLines[editIndex].phasePayStatus !== 'approved'"
        [disabled]="phaseTicketHoldLines[editIndex].phasePayComment === ''"
        type="button"
        class="btn btn-success"
        (click)="onApproveTicket(editIndex, 'phaseTicketHoldModal')"
      >
        Approve
      </button>
      <button
        type="button"
        [disabled]="phaseTicketHoldLines[editIndex].phasePayComment === ''"
        class="btn btn-success"
        (click)="
          onApproveSingleHoldLine(
            phaseTicketHoldLines[editIndex],
            editIndex,
            'phaseTicketHoldModal'
          )
        "
      >
        Approve ONLY This Item
      </button>
      <button
        [disabled]="
          phaseTicketHoldLines[editIndex].phasePayComment === '' ||
          phaseTicketHoldLines[editIndex].phasePayComment ===
            phaseTicketHoldLines[editIndex].phasePayComment
        "
        type="button"
        class="btn btn-primary"
        *ngIf="phaseTicketHoldLines[editIndex].phasePayStatus !== 'hold'"
        (click)="onHoldTicket(editIndex)"
      >
        HOLD
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onClosePhaseTicketModal('phaseTicketHoldModal')"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
<!-- * END of Hold Modal -->

<!-- * BEGINNING of Chipping Modal* -->
<ng-template #addChippingLineModal>
  <div class="container card">
    <div class="row p-2 d-flex justify-content-between">
      <div>
        <h4>Chipping for {{ phaseTickets[editIndex].address }}</h4>
      </div>
      <div>
        <button
          type="button"
          (click)="onClosePhaseTicketModal('addChippingLineModal')"
          class="btn btn-primary"
        >
          X
        </button>
      </div>
    </div>
    <div class="card-body p-2">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="">6" or LESS out</label>
          <input
            [(ngModel)]="phaseTickets[editIndex].completePipes"
            (change)="phaseTickets[editIndex].completePipesUpdated = true"
            type="number"
            min="0"
            max="99"
            class="form-control"
            name="phase-pay-complete-pipes"
            id="phase-pay-complete-pipes"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="">6" or MORE out</label>
          <input
            [(ngModel)]="phaseTickets[editIndex].pipesOutExtended"
            (change)="phaseTickets[editIndex].pipesOutExtendedUpdated = true"
            type="number"
            min="0"
            max="99"
            class="form-control"
            name="phase-pay-extended-pipes"
            id="phase-pay-extended-pipes"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="">Partially Out</label>
          <input
            [(ngModel)]="phaseTickets[editIndex].partialPipes"
            (change)="phaseTickets[editIndex].partialPipesUpdated = true"
            type="number"
            min="0"
            max="99"
            class="form-control"
            name="phase-pay-partial-pipes"
            id="phase-pay-partial-pipes"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="">Damaged Pipes</label>
          <input
            [(ngModel)]="phaseTickets[editIndex].damagedPipes"
            (change)="phaseTickets[editIndex].damagedPipesUpdated = true"
            type="number"
            min="0"
            max="99"
            class="form-control"
            name="phase-pay-damaged-pipes"
            id="phase-pay-damaged-pipes"
          />
        </div>
        <div class="form-group col-md-6">
          <label for="">Tub Box Chipping</label>
          <input
            [(ngModel)]="phaseTickets[editIndex].tubBox"
            (change)="phaseTickets[editIndex].tubBoxUpdated = true"
            type="number"
            min="0"
            max="99"
            class="form-control"
            name="phase-pay-tub-box"
            id="phase-pay-tub-box"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="phase-pay-chipping-comment">Chipping Comment</label>
            <textarea
              [(ngModel)]="phaseTickets[editIndex].chippingComment"
              class="form-control"
              name="phase-pay-chipping-comment"
              id="phase-pay-chipping-comment"
              rows="2"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row col-md-12 mt-2 mb-2 d-flex">
        <div class="col-6 d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="onLoadAddressImages(phaseTickets[editIndex].address)"
          >
            Load Images
          </button>
        </div>
        <div
          class="col-6 mt-2 d-flex justify-content-center align-items-center"
        >
          <label class="btn btn-primary btn-sm">
            {{ isImageLoading ? 'Uploading' : 'Upload Images' }}
            <input
              type="file"
              style="display: none"
              accept="image/*"
              multiple="true"
              (change)="
                onFileChanged(
                  $event,
                  phaseTickets[editIndex].address,
                  phaseTickets[editIndex]
                )
              "
            />
          </label>
          <div>
            <small *ngIf="fileNames.length !== 0">
              {{ fileNames.join(' ') }}
            </small>
          </div>
          {{ imageUploadComplete ? 'Image Uploaded' : '' }}
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-12 chipping-img-container d-flex flex-wrap justify-content-center"
        >
          <div *ngFor="let url of houseImages">
            <a href="{{ url }}"><img src="{{ url }}" /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 p-2 d-flex justify-content-around">
      <button
        type="button"
        (click)="onSubmitChippingLines(phaseTickets[editIndex])"
        class="btn btn-primary"
      >
        {{
          !phaseTickets[editIndex].chippingSubmitted
            ? 'Submit'
            : '&#10004;
        Submitted'
        }}
      </button>
      <button
        type="button"
        (click)="onClosePhaseTicketModal('addChippingLineModal')"
        class="btn btn-warning"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>
<!-- * END of Chipping Modal* -->

<!-- *BEGINNING of Incentive Modal -->
<ng-template #addIncentiveLineModal>
  <div class="container card">
    <div class="row flex justify-content-between p-2">
      <h4>Incentive for: {{ phaseTickets[editIndex].address }}</h4>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onClosePhaseTicketModal('addIncentiveLineModal')"
      >
        X
      </button>
    </div>
    <div class="card-body p-2">
      <form class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="phase-pay-incentive-tech">Tech</label>
              <input
                type="text"
                disabled
                [(ngModel)]="phaseTickets[editIndex].techName"
                class="form-control"
                name="phase-pay-incentive-tech"
                id="phase-pay-incentive-tech"
                aria-describedby="phase-pay-incentive-amount"
                placeholder=""
              />
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="phase-pay-incentive-amount">Amount</label>
            <input
              [(ngModel)]="phaseTickets[editIndex].incentive.amount"
              type="number"
              min="1"
              max="999"
              class="form-control"
              name="phase-pay-incentive-amount"
              id="phase-pay-incentive-amount"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-group">
          <label for="incentive-comment">Reason For Incentive</label>
          <textarea
            [(ngModel)]="phaseTickets[editIndex].incentive.reason"
            class="form-control"
            name="incentive-comment"
            id="incentive-comment"
            rows="2"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row p-2 d-flex justify-content-around">
      <button
        type="button"
        class="btn btn-primary"
        (click)="onSubmitIncentive(phaseTickets[editIndex])"
      >
        Submit
      </button>
      <button type="button" class="btn btn-warning">Cancel</button>
    </div>
  </div>
</ng-template>
<!-- *END of Incentive Modal -->

<!-- *BEGINNING of RoughIn Adder Modal -->
<ng-template #addRoughInAddersModal>
  <div class="container card">
    <div class="row flex justify-content-between p-2">
      <h4>Adders for: {{ phaseTickets[editIndex].address }}</h4>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onClosePhaseTicketModal('addRoughInAddersModal')"
      >
        X
      </button>
    </div>
    <div class="card-body p-2">
      <form class="form">
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Choose an Adder</label>
              <select
                class="form-control"
                id=""
                name="SelectRoughInAdder"
                [(ngModel)]="selectedRoughInAdder"
              >
                <option selected disabled>Select Adder</option>
                <ng-container *ngFor="let adder of roughInAddersList">
                  <option [ngValue]="adder">
                    {{ adder.description | titlecase }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="">Enter a Qty</label>
            <input
              class="form-control text-center"
              type="number"
              name="selectedRoughInAdderQty"
              [(ngModel)]="selectedRoughInAdderQty"
            />
          </div>
          <div
            class="form-group col-md-4"
            *ngIf="selectedRoughInAdder?.itemCode != '/RIPUNCH'"
          >
            <label for="">Add Item</label>
            <button
              type="button"
              class="form-control btn btn-primary"
              (click)="
                addRoughInAdder(selectedRoughInAdder, selectedRoughInAdderQty)
              "
            >
              Add
            </button>
          </div>
        </div>
        <div
          class="form-row"
          *ngIf="selectedRoughInAdder?.itemCode == '/RIPUNCH'"
        >
          <div class="col-md-4">
            <label for="">Enter Price</label>
            <input
              class="form-control text-center"
              type="number"
              name="selectedRoughInAdderPrice"
              [(ngModel)]="selectedRoughInAdderPrice"
            />
          </div>
          <div class="col-md-4">
            <label for="">Enter Description</label>
            <input
              class="form-control"
              type="text"
              name="selectedRoughInAdderDesc"
              [(ngModel)]="selectedRoughInAdderDesc"
            />
          </div>
          <div class="col-md-4">
            <label for="">Add Item</label>
            <button
              type="button"
              class="form-control btn btn-primary"
              (click)="
                addRoughInAdder(
                  selectedRoughInAdder,
                  selectedRoughInAdderQty,
                  selectedRoughInAdderPrice,
                  selectedRoughInAdderDesc
                )
              "
              [disabled]="otherAdderDisabled()"
            >
              Add
            </button>
          </div>
        </div>
      </form>
      <hr />
      <div class="overflow-auto" style="height: 150px">
        <mat-list dense *ngIf="roughInAddersSelected.length > 0">
          <div mat-subheader>
            <span
              [matBadge]="roughInAddersSelected.length"
              matBadgeSize="small"
              matBadgeOverlap="false"
              >Number of Adders</span
            >
          </div>
          <div class="container">
            <div class="row no-gutters">
              <mat-list-item
                class="col"
                *ngFor="
                  let adder of roughInAddersSelected;
                  let adderIndex = index
                "
              >
                <div mat-line>
                  <mat-icon
                    mat-list-icon
                    [ngClass]="{ 'text-primary': checkAdderInTicket(adder) }"
                  >
                    inventory
                  </mat-icon>
                  {{ adder.itemCodeDesc }} - Qty: {{ adder.qty }}
                  {{
                    adder.itemCode == '/RIPUNCH'
                      ? ' - Price: $' + adder?.price
                      : ''
                  }}
                  <mat-icon
                    mat-list-icon
                    class="text-warning"
                    (click)="deleteRoughInAdder(adderIndex)"
                    style="cursor: pointer"
                  >
                    delete
                  </mat-icon>
                </div>
                <div mat-line *ngIf="checkAdderInTicket(adder)">
                  {{ 'Item Exists in Ticket Already. Delete if Not Intended.' }}
                </div>
              </mat-list-item>
            </div>
          </div>
        </mat-list>
      </div>
    </div>
    <div class="row p-2 d-flex justify-content-around">
      <button
        type="button"
        class="btn btn-warning"
        (click)="onClosePhaseTicketModal('addRoughInAddersModal')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitRoughInAdders(editIndex)"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
<!-- *END of Incentive Modal -->

<div class="card">
  <!-- * BEGINNING of Selection Tabs -->
  <div class="container mt-2">
    <div class="search">
      <mat-form-field
        appearance="outline"
        *ngIf="authService.decodedToken.role !== 'super'"
      >
        <mat-label>Super</mat-label>
        <mat-select
          [(ngModel)]="selectedSuper"
          (selectionChange)="onSuperNameSelected()"
        >
          <mat-option
            *ngFor="let superintendent of superintendentsList"
            [value]="superintendent.superName"
            >{{ superintendent.superName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <app-searchable-msel
        [selectedOptions]="phaseCodes"
        (selectChanged)="phaseCodes.setValue($event.value)"
        placeholder="Phase Codes"
        [options]="phaseOptions"
        [disabled]="loadingData"
        class="suprep-card-content-controls-ff"
      ></app-searchable-msel>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          [(ngModel)]="table"
          (selectionChange)="selectTableToShow(table)"
        >
          <mat-option value="unapproved">Unapproved</mat-option>
          <mat-option value="approved">Approved</mat-option>
          <mat-option value="hold">Hold Lines</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="updatingData"
      class="row d-flex justify-content-center mt-4 updating-data"
    >
      <p>
        Updating data, do not leave the Phase Pay area...
        <i class="fas fa-sync-alt updating-data-icon"></i>
      </p>
    </div>
  </div>
  <!-- * END of Selection Tabs-->
  <div class="card-body">
    <div class="container row mx-auto">
      <!-- ! BEGINNING of Unapproved Tickets -->
      <div class="card border-0 col-md-12" *ngIf="showUnapprovedTable">
        <table class="table table-responsive table-hover">
          <tr class="thead-dark">
            <th (click)="onColumnSort('soNum', wtSortDirection)">
              SO#
              <span *ngIf="wtSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="wtSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('address', addressSortDirection)">
              Address
              <span *ngIf="addressSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="addressSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('subdivision', subdivisionSortDirection)">
              Subdivision
              <span *ngIf="subdivisionSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="subdivisionSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('dateScheduled', scheduleDateSortDirection)"
            >
              Schedule Date
              <span *ngIf="scheduleDateSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="scheduleDateSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('status', statusSortDirection)">
              Status
              <span *ngIf="statusSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="statusSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('techName', technicianSortDirection)">
              Technician
              <span *ngIf="statusSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="statusSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('estimatedPayDate', epdSortDirection)">
              Est. Pay Date
              <span *ngIf="epdSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="epdSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
              <br />
              (if approved this week)
            </th>
            <th>Action</th>
          </tr>
          <!-- ! Commented out until Phase 2 (undergrounds) -->
          <!-- ? Water -->
          <!-- <tbody>
            <h5 (click)="showWater= !showWater" style="cursor: pointer;">WATER <span *ngIf="showWater">&#9660;</span> <span *ngIf="!showWater">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container
                *ngIf="
                (ticket.UDF_PAY_DATE === '1753-01-01T00:00:00' ||
                 ticket.UDF_PAY_DATE === '1753-01-01' || ticket.UDF_PAY_DATE === '1900-01-01T00:00:00' || ticket.UDF_PAY_DATE === null || ticket.UDF_PAY_DATE === '') && (ticket.phasePayStatus === 'active')
                    && (ticket.status.toLowerCase() === 'com'
                || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase()
                === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() === 'qar' || ticket.status.toLowerCase() === 'rec' || ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phase.toLowerCase() === 'zwater'">
                <ng-container *ngIf="showWater">
                  <td><button (click)="onEditPhaseTicket(editPhaseTicketModal, index)" *ngIf="allowEditOnRestrictedTickets || ticket.status.toLowerCase() !== 'rec' && ticket.status.toLowerCase() !== 'qar' &&
                      ticket.status.toLowerCase() !== 'ptl' && ticket.status.toLowerCase() !== 'qan'" type="button"
                      class="btn btn-primary soBtn">{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</button></td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td class="text-center">{{fridayDisplayDate | date:'longDate'}}</td>
                  <td>
                    <button *ngIf="allowAddIncentiveLine" type="button" class="btn btn-primary incentiveBtn" (click)="onAddIncentiveLine(addIncentiveLineModal, index)">+ Incentive</button>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody> -->
          <!-- ? Sewer -->
          <!-- ! Commented out until Phase 2 (undergrounds) -->
          <!-- <tbody>
            <h5 (click)="showSewer= !showSewer" style="cursor: pointer;">SEWER<span *ngIf="showSewer">&#9660;</span> <span *ngIf="!showSewer">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container
                *ngIf="
                (ticket.UDF_PAY_DATE === '1753-01-01T00:00:00' ||
                 ticket.UDF_PAY_DATE === '1753-01-01' || ticket.UDF_PAY_DATE === '1900-01-01T00:00:00' || ticket.UDF_PAY_DATE === null || ticket.UDF_PAY_DATE === '') && (ticket.phasePayStatus === 'active')
                    && (ticket.status.toLowerCase() === 'com'
                || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase()
                === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() === 'qar' || ticket.status.toLowerCase() === 'rec' || ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phase.toLowerCase() === 'zsewer'">
                <ng-container *ngIf="showSewer">
                  <td><button (click)="onEditPhaseTicket(editPhaseTicketModal, index)" *ngIf="allowEditOnRestrictedTickets || ticket.status.toLowerCase() !== 'ptl' &&
                      ticket.status.toLowerCase() !== 'qar' &&
                      ticket.status.toLowerCase() !== 'rec' && ticket.status.toLowerCase() !==
                      'qan'" type="button" class="btn btn-primary soBtn">{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</button></td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td class="text-center">{{fridayDisplayDate | date:'longDate'}}</td>
                  <td>
                    <button *ngIf="allowAddIncentiveLine" type="button" class="btn btn-primary incentiveBtn" (click)="onAddIncentiveLine(addIncentiveLineModal, index)">+ Incentive</button>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody> -->
          <!-- ? Roughin -->
          <ng-container>
            <tbody>
              <tr *ngFor="let ticket of getIncludedSteps(); index as index">
                <ng-container
                  *ngIf="
                    (ticket.UDF_PAY_DATE === '1753-01-01T00:00:00' ||
                      ticket.UDF_PAY_DATE === '1753-01-01' ||
                      ticket.UDF_PAY_DATE === '1900-01-01T00:00:00' ||
                      ticket.UDF_PAY_DATE === null ||
                      ticket.UDF_PAY_DATE === '') &&
                    ticket.phasePayStatus === 'active'
                  "
                >
                  <!--  if wanting to show roughins to supers, then uncomment this-->
                  <ng-container
                    *ngIf="
                      !(
                        (ticket.phase == 'ZROUGHIN' ||
                          ticket.phase == 'ZREROUGH') &&
                        (this.authService.decodedToken.role == 'super' ||
                          this.authService.decodedToken.role == 'superdfw' ||
                          this.authService.decodedToken.role == 'am')
                      ) && statusFilter(ticket)
                    "
                  >
                    <td title="Click to Edit">
                      <button
                        [disabled]="
                          (ticket.phase == 'ZROUGHIN' &&
                            (this.authService.decodedToken.role == 'super' ||
                              this.authService.decodedToken.role ==
                                'superdfw' ||
                              this.authService.decodedToken.role == 'am')) ||
                          ((ticket.status.toLowerCase() === 'qar' ||
                            ticket.status.toLowerCase() === 'rec' ||
                            ticket.status.toLowerCase() === 'qan') &&
                            (this.authService.decodedToken.role == 'super' ||
                              this.authService.decodedToken.role == 'superdfw'))
                        "
                        (click)="
                          onEditPhaseTicket(editPhaseTicketModal, ticket)
                        "
                        type="button"
                        class="btn btn-primary soBtn"
                      >
                        {{ ticket.salesOrderNo }}-{{ ticket.wtNumber }}
                      </button>
                    </td>
                    <td>{{ ticket.address }}</td>
                    <td>{{ ticket.subdivision }}</td>
                    <td>{{ ticket.dateScheduled | date: 'longDate' }}</td>
                    <td>{{ ticket.status }}</td>
                    <td>{{ ticket.techName }}</td>
                    <td class="text-center">
                      {{ fridayDate | date: 'longDate' }}
                    </td>
                    <td>
                      <div>
                        <button
                          *ngIf="allowAddIncentiveLine"
                          type="button"
                          class="btn btn-primary incentiveBtn"
                          (click)="
                            onAddIncentiveLine(addIncentiveLineModal, ticket)
                          "
                        >
                          &#43; Incentive
                        </button>
                        <button
                          *ngIf="
                            ticket.phase == 'ZTOPOUT' ||
                            ticket.phase == 'ZRETOPOUT'
                          "
                          type="button"
                          class="btn btn-primary btn-small mb-2 chippingBtn"
                          (click)="
                            onAddChippingLines(addChippingLineModal, ticket)
                          "
                        >
                          {{
                            !ticket.chippingSubmitted
                              ? '&#43; Chipping'
                              : '&#10004; Submitted'
                          }}
                        </button>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
      <!-- !END of Unapproved Tickets -->

      <!-- ! BEGINNING of Approved Tickets -->
      <div class="card border-0 col-md-12" *ngIf="showApprovedTable">
        <table class="table table-responsive table-hover">
          <tr class="thead-dark" style="min-width: 100%">
            <th
              (click)="onColumnSort('soNum', wtSortDirection)"
              style="min-width: 120px"
            >
              SO#
              <span *ngIf="wtSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="wtSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('address', addressSortDirection)">
              Address
              <span *ngIf="addressSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="addressSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('subdivision', subdivisionSortDirection)"
              style="min-width: 120px"
            >
              Subdivision
              <span *ngIf="subdivisionSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="subdivisionSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('dateScheduled', scheduleDateSortDirection)"
              style="min-width: 120px"
            >
              Schedule Date
              <span *ngIf="scheduleDateSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="scheduleDateSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('status', statusSortDirection)"
              style="min-width: 80px"
            >
              Status
              <span *ngIf="statusSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="statusSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('techName', technicianSortDirection)">
              Technician
              <span *ngIf="technicianSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="technicianSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <!--            <th>Approved By-->
            <!--            </th>-->
            <th
              (click)="
                onColumnSort(
                  'phasePayStatusDate',
                  phasePayStatusDateSortDirection
                )
              "
            >
              Approved Date
              <span *ngIf="phasePayStatusDateSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="phasePayStatusDateSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('estimatedPayDate', epdSortDirection)">
              Est. Pay Date
              <span *ngIf="epdSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="epdSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th>Action</th>
          </tr>
          <!-- ! Commented out until Phase 2 (undergrounds) -->
          <!-- <tbody>
            <h5 (click)="showWater= !showWater" style="cursor: pointer;">WATER <span *ngIf="showWater">&#9660;</span> <span *ngIf="!showWater">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container *ngIf="(ticket.status.toLowerCase() === 'com' || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase() === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() === 'qar' ||
                ticket.status.toLowerCase() === 'rec' || ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phasePayStatus !== '' && ticket.phasePayStatus !== 'hold' && ticket.phasePayStatus === 'approved' &&
                ticket.phase.toLowerCase() === 'zwater'">
                <ng-container *ngIf="showWater">
                  <td>{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td class="text-center">{{ticket.phasePayStatusDate | date: 'shortDate'}}</td>
                  <td class="text-center">{{ticket.estimatedPayDate | date:'longDate'}}</td>
                  <td><button type="button" class="btn btn-primary" (click)="onEditPhaseTicket(editPhaseTicketModal, index)">{{ticket.phasePayStatus === 'approved' &&
                      (authService.decodedToken.role === 'admin' || authService.decodedToken.role === 'superadmin' || authService.decodedToken.role === 'acct_mgt') ? 'Edit' :
                      'View'}}</button>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody>
          <tbody>
            <h5 (click)="showSewer= !showSewer" style="cursor: pointer;">SEWER<span *ngIf="showSewer">&#9660;</span> <span *ngIf="!showSewer">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container *ngIf="(ticket.status.toLowerCase() === 'com' || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase() === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() === 'qar' ||
                ticket.status.toLowerCase() === 'rec' || ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phasePayStatus !== '' && ticket.phasePayStatus !== 'hold' && ticket.phasePayStatus === 'approved' &&
                ticket.phase.toLowerCase() === 'zsewer'">
                <ng-container *ngIf="showSewer">
                  <td>{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td class="text-center">{{ticket.phasePayStatusDate | date: 'shortDate'}}</td>
                  <td class="text-center">{{ticket.estimatedPayDate | date:'longDate'}}</td>
                  <td><button type="button" class="btn btn-primary" (click)="onEditPhaseTicket(editPhaseTicketModal, index)">{{ticket.phasePayStatus === 'approved' &&
                      (authService.decodedToken.role === 'admin' || authService.decodedToken.role === 'superadmin' || authService.decodedToken.role === 'acct_mgt') ? 'Edit' :
                      'View'}}</button>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody> -->
          <ng-container>
            <tbody>
              <tr *ngFor="let ticket of getIncludedSteps(); index as index">
                <ng-container
                  *ngIf="
                    ticket.wtParent == 'Y' &&
                    ticket.phasePayStatus !== '' &&
                    ticket.phasePayStatus !== 'hold' &&
                    ticket.phasePayStatus === 'approved'
                  "
                >
                  <ng-container>
                    <td>{{ ticket.salesOrderNo }}-{{ ticket.wtNumber }}</td>
                    <td>{{ ticket.address }}</td>
                    <td>{{ ticket.subdivision }}</td>
                    <td>{{ ticket.dateScheduled | date: 'longDate' }}</td>
                    <td>{{ ticket.status }}</td>
                    <td>{{ ticket.techName }}</td>
                    <td class="text-center">
                      {{ ticket.phasePayStatusDate | date: 'shortDate' }}
                    </td>
                    <td class="text-center">
                      {{ ticket.estimatedPayDate | date: 'longDate' }}
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="
                          onEditPhaseTicket(editPhaseTicketModal, ticket)
                        "
                      >
                        {{
                          ticket.phasePayStatus === 'approved' &&
                          (authService.decodedToken.role === 'admin' ||
                            authService.decodedToken.role === 'superadmin' ||
                            authService.decodedToken.role === 'acct_mgt')
                            ? 'Edit'
                            : 'View'
                        }}
                      </button>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
      <!-- !END of Approved Tickets -->

      <!-- !BEGINNING of Hold Tickets -->
      <div class="card border-0 col-md-12" *ngIf="showHoldTable">
        <table class="table table-responsive table-hover">
          <tr class="thead-dark" style="min-width: 100%">
            <th (click)="onColumnSort('soNum', wtSortDirection)">
              SO#
              <span *ngIf="wtSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="wtSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th (click)="onColumnSort('address', addressSortDirection)">
              Address
              <span *ngIf="addressSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="addressSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('subdivision', subdivisionSortDirection)"
              style="min-width: 120px"
            >
              Subdivision
              <span *ngIf="subdivisionSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="subdivisionSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('dateScheduled', scheduleDateSortDirection)"
              style="min-width: 120px"
            >
              Schedule Date
              <span *ngIf="scheduleDateSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="scheduleDateSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('status', statusSortDirection)"
              style="min-width: 120px"
            >
              WT Status
              <span *ngIf="statusSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="statusSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th
              (click)="onColumnSort('techName', technicianSortDirection)"
              style="min-width: 120px"
            >
              Tech
              <span *ngIf="technicianSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="technicianSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <!--            <th>Hold By-->
            <!--            </th>-->
            <th
              (click)="
                onColumnSort(
                  'phasePayStatusDate',
                  phasePayStatusDateSortDirection
                )
              "
            >
              Hold Date
              <span *ngIf="phasePayStatusDateSortDirection === 'desc'"
                ><i class="fas fa-caret-down"></i
              ></span>
              <span *ngIf="phasePayStatusDateSortDirection === 'asc'"
                ><i class="fas fa-caret-up"></i
              ></span>
            </th>
            <th style="max-width: 150px">Notes</th>
            <th>Action</th>
          </tr>
          <tbody>
            <tr *ngFor="let ticket of phaseTicketHoldLines; index as index">
              <ng-container *ngIf="ticket.phasePayStatus === 'hold'">
                <td>{{ ticket.salesOrderNo }}-{{ ticket.wtNumber }}</td>
                <td>{{ ticket.address }}</td>
                <td>{{ ticket.subdivision }}</td>
                <td>{{ ticket.dateScheduled | date: 'longDate' }}</td>
                <td>{{ ticket.status }}</td>
                <td>{{ ticket.techName }}</td>
                <!--                <td>{{ticket.lastUser}}</td>-->
                <td>{{ ticket.phasePayStatusDate }}</td>
                <td>{{ ticket.phasePayComment }}</td>
                <td>
                  <button
                    (click)="
                      onEditHoldTicket(phaseTicketHoldModal, index, ticket)
                    "
                    *ngIf="
                      allowEditOnRestrictedTickets ||
                      (ticket.status.toLowerCase() !== 'ptl' &&
                        ticket.status.toLowerCase() !== 'qar' &&
                        ticket.status.toLowerCase() !== 'rec' &&
                        ticket.status.toLowerCase() !== 'qan')
                    "
                    type="button"
                    class="btn btn-primary"
                  >
                    Edit
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <!--
          <tbody>
            <h5 (click)="showWater= !showWater" style="cursor: pointer;">WATER <span *ngIf="showWater">&#9660;</span> <span *ngIf="!showWater">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container *ngIf="(ticket.status.toLowerCase() === 'com' || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase() === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() ===
                'qar'||ticket.status.toLowerCase() === 'rec'|| ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phase.toLowerCase() === 'zwater' && ticket.phasePayStatus === 'hold'">
                <ng-container *ngIf="showWater">
                  <td>{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td>{{ticket.phasePayStatusDate}}</td>
                  <td>{{ticket.phasePayComment}}</td>
                  <td><button (click)="onEditPhaseTicket(editPhaseTicketModal, index)" *ngIf="allowEditOnRestrictedTickets || ticket.status.toLowerCase() !== 'ptl' && ticket.status.toLowerCase() !== 'qar' && ticket.status.toLowerCase() !== 'rec' &&
                      ticket.status.toLowerCase() !==
                      'qan'" type="button" class="btn btn-primary">Edit</button></td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody>
          <tbody>
            <h5 (click)="showSewer= !showSewer" style="cursor: pointer;">SEWER<span *ngIf="showSewer">&#9660;</span> <span *ngIf="!showSewer">&#9650;</span></h5>
            <tr *ngFor="let ticket of phaseTickets; index as index">
              <ng-container *ngIf="(ticket.status.toLowerCase() === 'com' || ticket.status.toLowerCase() === 'qac' || ticket.status.toLowerCase() === 'ins' || ticket.status.toLowerCase() === 'ptl' || ticket.status.toLowerCase() ===
                'qar'||ticket.status.toLowerCase() === 'rec'|| ticket.status.toLowerCase() === 'qan' || ticket.status.toLowerCase() === 'wob' || ticket.status.toLowerCase() === 'cld' || ticket.status.toLowerCase() === 'ins') && ticket.phase.toLowerCase() === 'zsewer' && ticket.phasePayStatus === 'hold'">
                <ng-container *ngIf="showSewer">
                  <td>{{ticket.salesOrderNo}}-{{ticket.wtNumber}}</td>
                  <td>{{ticket.address}}</td>
                  <td>{{ticket.subdivision}}</td>
                  <td>{{ticket.dateScheduled | date:'longDate'}}</td>
                  <td>{{ticket.status}}</td>
                  <td>{{ticket.techName}}</td>
                  <td>{{ticket.phasePayStatusDate}}</td>
                  <td>{{ticket.phasePayComment}}</td>
                  <td><button (click)="onEditPhaseTicket(editPhaseTicketModal, index)" *ngIf="allowEditOnRestrictedTickets || ticket.status.toLowerCase() !== 'ptl' && ticket.status.toLowerCase() !== 'qar' && ticket.status.toLowerCase() !== 'rec' &&
                      ticket.status.toLowerCase() !==
                      'qan'" type="button" class="btn btn-primary">Edit</button></td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </div>
</div>
