import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { isBoolean } from 'ngx-bootstrap/chronos/utils/type-checks';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pull-ticket-creation',
  templateUrl: './pull-ticket-creation.component.html',
  styleUrls: ['./pull-ticket-creation.component.css'],
})
export class PullTicketCreationComponent implements OnInit {
  jobCollection: {
    jobInformation: string;
    jobNumber: string;
    street: string;
    businessUnit: string;
  }[] = [];
  technicians = [];
  history = [];
  tech = [];
  item = {
    ItemName: '',
    ItemCode: '',
    ItemCodeDesc: '',
    ItemQuantity: 0,
    QuantityOnHand: 0,
  };
  itemsToTransfer = [];
  itemMenu = true;
  itemList = [];
  AddItems = [];
  pdfTodayDate: string;
  pdfTime: string;
  jobNumber = '';
  techName = '';
  changed = false;
  address = '';
  loading = false;
  onHand = 0;
  sending: boolean;
  businessType = '';
  loadjobs: boolean;
  scheduledDate: string;
  baseUrl: string;
  previouslyCreated: boolean;
  job = {
    jobInformation: '',
    jobNumber: '',
    street: '',
    businessUnit: '',
  };
  jobSelected: boolean;
  kitNames = [];

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router
  ) {}
  ngOnInit() {
    const today = new Date();
    this.getAllJobsFromServiceTitan();
    this.getAllTechniciansFromServiceTitan();
    this.pdfTodayDate = this.datePipe.transform(today, 'MM/dd/yyyy');
    this.pdfTime = this.formatAMPM(today);
    this.baseUrl = window.location.origin;
    this.getKitNames();
  }

  getKitNames() {
    this.sageApi.pullReport('Punch/Kits').subscribe(
      (kits: Array<any>) => {
        if (Array.isArray(kits)) {
          kits.forEach(kit => {
            const obj = {
              kitName: kit.Kit,
              ItemCode: kit.ItemCode,
              ItemName: kit.ItemCode + '-' + kit.Description,
              ItemCodeDesc: kit.Description,
              qty: kit.Quantity,
            };
            this.kitNames.push(obj);
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }
  getAllJobsFromServiceTitan() {
    this.loadjobs = true;
    this.sageApi.pullReport('ServiceTitan/JobsScheduled').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const obj = {
              jobInformation: row.jobInformation,
              jobNumber: row.jobNumber,
              street: row.street,
              businessUnit: row.businessUnit,
            };

            this.jobCollection.push(obj);
          });
        }
        this.loadjobs = false;
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
        this.loadjobs = false;
      }
    );
  }

  pullSageItemList(tech) {
    this.sageApi.pullReport('MaterialOnHand/' + tech.id).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(row => {
            const item = {
              name: row.ItemCode + ' - ' + row.Description,
              itemCode: row.ItemCode,
              description: row.Description,
              quantityOnHand: row.TotalQuantityOnHand,
            };
            this.itemList.push(item);
          });
        }
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  setJobNumber(id: any) {
    const jobFound = this.jobCollection.find(
      x => x.jobInformation == id.jobInformation
    );
    this.address = jobFound.street;
    this.jobNumber = jobFound.jobNumber;
    this.businessType = jobFound.businessUnit;
    this.sageApi
      .pullReport('JobScheduledDate/' + jobFound.jobNumber)
      .subscribe((date: string) => {
        this.scheduledDate = date;
      });
    this.sageApi
      .pullReport('PullSheet/' + jobFound.jobNumber)
      .subscribe((items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(itemOrdered => {
            const ordered = {
              ItemName: itemOrdered.Description,
              ItemCode: itemOrdered.ItemCode,
              ItemCodeDesc: itemOrdered.Description,
              QuantityOnHand: itemOrdered.OnHand,
              ItemQuantity: itemOrdered.Ordered,
            };
            if (
              !this.itemsToTransfer.some(r => r.ItemCode == ordered.ItemCode)
            ) {
              this.itemsToTransfer.push(ordered);
            }
            this.previouslyCreated = true;
          });
        }
      });
    this.jobSelected = true;
    this.checkForEstimate();
  }

  setTech(tech) {
    this.pullSageItemList(tech);
    this.techName = tech.name;
  }
  deleteExistingPullTicket() {
    this.itemsToTransfer = [];
    this.sendInformation();
  }

  getAllTechniciansFromServiceTitan() {
    this.sageApi
      .pullReport('ServiceTitan/settings/technicians')
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            this.technicians.push(row);
          });
        }
      });
  }

  sendInformation() {
    this.sending = true;
    const observables = [];
    const endpoint = 'PullSheet';
    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      techName: this.techName,
      jobNumber: this.jobNumber,
      items: this.itemsToTransfer,
      address: this.address,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));
    concat(...observables).subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          response.forEach(resp => {
            this.alertify.error(resp);
          });
        }
      },
      err => {
        this.router.navigate(['']);
        this.loading = false;
        this.alertify.error('Error - ' + err.message);
      },
      () => {
        this.router.navigate(['']);
        this.loading = false;
        this.alertify.success('Submitted');
        this.itemsToTransfer = [];
        this.sending = false;
      }
    );
  }

  amountOnHand(passedVar: any) {
    const itemCode = passedVar.ItemName.split(' - ')[0];
    let amountOnHand = 0;
    this.itemList.forEach(element => {
      if (element.itemCode == itemCode) {
        amountOnHand = element.quantityOnHand;
      }
    });
    this.onHand = amountOnHand;
  }

  pushItem(passedVar: any) {
    const itemCode = passedVar.ItemName.split(' - ')[0];
    const kit = this.kitNames.filter(x => x.kitName == itemCode);
    if (kit.length > 0) {
      kit.forEach(kitItem => {
        let amountOnHand = 0;
        this.itemList.forEach(element => {
          if (element.itemCode == kitItem.ItemCode) {
            amountOnHand = element.quantityOnHand;
          }
        });
        this.item = {
          ItemName: kitItem.ItemName,
          ItemCode: kitItem.ItemCode,
          ItemCodeDesc: kitItem.ItemCodeDesc,
          ItemQuantity: kitItem.qty * passedVar.ItemQuantity,
          QuantityOnHand: amountOnHand,
        };
        this.itemsToTransfer.push(this.item);
      });
    } else {
      let amountOnHand = 0;
      this.itemList.forEach(element => {
        if (element.itemCode == itemCode) {
          amountOnHand = element.quantityOnHand;
        }
      });
      const ItemCodeDesc = passedVar.ItemName.split(' - ')[1];
      this.item = {
        ItemName: passedVar.ItemName,
        ItemCode: itemCode,
        ItemCodeDesc: ItemCodeDesc,
        ItemQuantity: passedVar.ItemQuantity,
        QuantityOnHand: amountOnHand,
      };
      this.itemsToTransfer.push(this.item);
    }

    this.clear();
  }
  checkForEstimate() {
    this.sageApi
      .pullReport('ServiceTitanIntegration/Estimate/' + this.jobNumber)
      .subscribe((items: Array<any>) => {
        if (Array.isArray(items)) {
          if (items.length == 0) {
            this.alertify.warning(
              'No sold estimate found for the job number. '
            );
          }
          items.forEach(itemOrdered => {
            const ordered = {
              ItemName: itemOrdered.ItemName,
              ItemCode: itemOrdered.ItemCode,
              ItemCodeDesc: itemOrdered.ItemCodeDesc,
              QuantityOnHand: itemOrdered.QuantityOnHand,
              ItemQuantity: itemOrdered.ItemQuantity,
            };

            if (!this.itemsToTransfer.includes(itemOrdered)) {
              this.itemsToTransfer.push(ordered);
            }
          });
        }
      });
  }

  clear() {
    this.item = {
      ItemCode: '',
      ItemName: '',
      ItemCodeDesc: '',
      ItemQuantity: 0,
      QuantityOnHand: 0,
    };
    this.onHand = 0;
    return this.item;
  }

  DeleteItem(item: any) {
    const number = this.itemsToTransfer.indexOf(item);
    this.itemsToTransfer.splice(number, 1);
  }

  itemReady() {
    for (const answer of this.itemList) {
      if (answer.name === this.item.ItemName && this.item.ItemQuantity > 0) {
        const resp = true;
        return resp;
      }
    }
  }
  printReady() {
    if (
      this.techName != '' &&
      this.jobNumber != '' &&
      this.itemsToTransfer.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  printPage() {
    this.sendInformation();
    const today = new Date();
    this.pdfTodayDate = this.datePipe.transform(today, 'MM/dd/yyyy');
    // this.pdfTime = today.getHours() + ':' + today.getMinutes();
    this.pdfTime = this.formatAMPM(today);
    window.print();
  }
}
