<div class="container pb-5">
  <div class="card my-5 mx-5 shadow-lg text-center">
    <div class="headerOptions">
      <div class="searchOptions">
        <app-searchable-msel
          [selectedOptions]="roles"
          (selectChanged)="roles.setValue($event.value)"
          placeholder="Roles"
          [options]="roleOptions"
          class="suprep-card-content-controls-ff"
        ></app-searchable-msel>
        <app-searchable-msel
          [selectedOptions]="jobDepart"
          (selectChanged)="jobDepart.setValue($event.value)"
          placeholder="Job Departments"
          [options]="jobDepartOptions"
          class="suprep-card-content-controls-ff"
        ></app-searchable-msel>
        <mat-form-field
          appearance="outline"
          class="suprep-card-content-controls-ff"
        >
          <mat-label>Search By Name</mat-label>
          <input
            [(ngModel)]="filterSearch"
            placeholder="Search By Name"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="newUsers">
        <button class="btn btn-primary" (click)="RouteToNew()">
          <i class="fas fa-user-plus"></i> New User
        </button>
      </div>
    </div>
    <h1>Existing Users</h1>
    <table class="table table-striped">
      <tr>
        <th>Edit</th>
        <th (click)="sortByUsername()">
          Username
          <i *ngIf="usernameSort == 'asc'" class="fas fa-caret-up"></i>
          <i *ngIf="usernameSort == 'desc'" class="fas fa-caret-down"></i>
        </th>
        <th>Name</th>
        <th>Role</th>
        <th>Super Name</th>
        <th>Tech Code</th>
        <th>Job Title</th>
        <th>Job Department</th>
      </tr>
      <tr *ngFor="let row of getIncludedSteps()">
        <td>
          <button
            class="btn btn-primary"
            (click)="openModal(editUserModal, row)"
          >
            <i class="fas fa-pen"></i>
          </button>
        </td>
        <td>{{ row.username }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.role }}</td>
        <td>{{ row.superName }}</td>
        <td>{{ row.techCode }}</td>
        <td>{{ row.jobTitle }}</td>
        <td>{{ row.jobDepartment }}</td>
      </tr>
    </table>
  </div>
</div>
<!-- *Edit User Modal -->
<ng-template #editUserModal>
  <div class="card container">
    <div class="row p-2">
      <div class="col-md-10">
        <h3>Edit User</h3>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="closeModal()"
          class="btn btn-primary btn-sm"
        >
          <i class="far fa-times-circle" style="font-size: 1.3rem"></i>
        </button>
      </div>
      <div>
        <form class="NewUserForm">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input
              matInput
              type="text"
              appearance="outline"
              [disabled]="true"
              name="username"
              placeholder="username"
              [(ngModel)]="userinformation.username"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <input
              matInput
              type="text"
              name="role"
              placeholder="Role"
              [(ngModel)]="userinformation.role"
              [typeahead]="allRoles"
              (typeaheadOnBlur)="confirmRole()"
              autocomplete="off"
              (typeaheadOnSelect)="selectRole($event)"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Super Name</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userinformation.superName"
              [typeahead]="allSupers"
              typeaheadOptionField="nickname"
              (typeaheadOnBlur)="confirmSuper()"
              name="superName"
              placeholder="Super Name"
              (typeaheadOnSelect)="selectRole($event)"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tech Code</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userinformation.techCode"
              [typeahead]="allTechs"
              typeaheadOptionField="name"
              autocomplete="on"
              (typeaheadOnSelect)="selectTech($event)"
              name="techCode"
              placeholder="techCode"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Job Title</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userinformation.jobTitle"
              [typeahead]="jobDepartments"
              name="jobTitle"
              placeholder="Job Title"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Job Department</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userinformation.jobDepartment"
              [typeahead]="jobTitlesForRole"
              name="jobDepartment"
              placeholder="Job Department"
            />
          </mat-form-field>
          <button
            type="submit"
            [disabled]="checkForm()"
            (click)="submitForm()"
            class="btn btn-primary"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
