import { DatePipe, formatDate, KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { BuilderDashboard } from 'src/app/_services/sageApi/interfaces/pullReport/BuilderDashboard';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';

@Component({
  selector: 'app-scheduled-phases',
  templateUrl: './scheduled-phases.component.html',
  styleUrls: ['./scheduled-phases.component.css'],
})
export class ScheduledPhasesComponent implements OnInit {
  startDate: Date | null = null;
  endDate: Date | null = null;
  DateContrlMin = new Date();
  DateContrlMax = new Date();
  username = '';
  originalSalesOrder = [];
  salesOrders = [];
  salesOrdersLoading = false;
  range: any;
  assignedOptions: string[];
  assigned: FormControl;
  upcomingDue: BuilderDashboard[] = [];
  Today: Date;
  Phase = '';
  WarehouseMap;
  selectedTab = 0;

  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const DateInfoMin = new Date();
    //DateInfoMin.setDate(DateInfoMin.getDate() - 1);
    this.startDate = DateInfoMin;
    const DateInfo = new Date();
    DateInfo.setDate(DateInfo.getDate() + 7);
    this.endDate = DateInfo;
    this.getInformation();
  }
  getInformation() {
    this.salesOrders = [];
    this.salesOrdersLoading = true;
    this.assignedOptions = [];
    this.assigned = new FormControl();
    let DateMin = '';
    let DateMax = '';
    const FromDateFormatted = formatDate(this.startDate, 'MM-dd-yyyy', 'en-US');
    const ToDateFormatted = formatDate(this.endDate, 'MM-dd-yyyy', 'en-US');
    this.sageApi
      .pullReport(
        'BuilderDashboard/' + FromDateFormatted + '/' + ToDateFormatted
      )
      .subscribe(
        (rows: Array<BuilderDashboard>) => {
          rows.forEach(row => {
            if (!this.assignedOptions.includes(row.UDF_ASSIGNED_TO_BUILD)) {
              this.assignedOptions.push(row.UDF_ASSIGNED_TO_BUILD);
            }

            if (row.UDF_DATE_SCHEDULED != undefined) {
              if (DateMin == '' || DateMax == '') {
                DateMin = row.UDF_DATE_SCHEDULED;
                DateMax = row.UDF_DATE_SCHEDULED;
              } else if (row.UDF_DATE_SCHEDULED < DateMin) {
                DateMin = row.UDF_DATE_SCHEDULED;
              } else if (row.UDF_DATE_SCHEDULED > DateMax) {
                DateMax = row.UDF_DATE_SCHEDULED;
              }
              this.salesOrders.push(row);
            }
            this.Phase = row.Phase;
          });
          this.salesOrdersLoading = false;
          this.originalSalesOrder = this.salesOrders;
          this.DateContrlMin = new Date(DateMin);
          this.DateContrlMax = new Date(DateMax);
          this.salesOrders.sort(
            (a, b) =>
              new Date(a.UDF_DATE_SCHEDULED).getTime() -
              new Date(b.UDF_DATE_SCHEDULED).getTime()
          );
        },
        err => {
          console.log(err);
          this.alertify.error(err.message);
          this.salesOrdersLoading = false;
        },
        () => {
          this.salesOrdersLoading = false;
          this.assigned.setValue(this.assignedOptions);
          const map = new Map();

          this.salesOrders.forEach(so => {
            const key = so.WarehouseCode;
            const col = map.get(key);
            if (!col) {
              map.set(key, [so]);
            } else {
              col.push(so);
            }
          });
          this.WarehouseMap = map;
        }
      );
  }
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
  normalizeDate(val) {
    return formatDate(val.UDF_DATE_SCHEDULED, 'MM/dd/yyyy', 'en-US');
  }
  warehouseName(e) {
    if (e == '001') {
      return 'San Antonio';
    } else if (e == '005') {
      return 'Dallas';
    } else {
      return e;
    }
  }
}
