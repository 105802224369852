<div class="spinner" *ngIf="sending">
  <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="!sending">
  <div>
    <form [formGroup]="Textarea" class="ParseText">
      <div class="test">
        <mat-form-field appearance="outline" class="test">
          <mat-label>Copy and Paste the Data Validation Report Here</mat-label>
          <textarea
            matInput
            name="Textarea"
            formControlName="Textarea"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="btn">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!Textarea.valid"
          (click)="parseTextArea()"
        >
          Add Group
        </button>
      </div>
    </form>
  </div>
  <mat-label>Or enter each individual Sales Order</mat-label>

  <div>
    <form [formGroup]="EditForm" class="EditForm">
      <mat-form-field appearance="outline">
        <mat-label>MessageNo</mat-label>
        <input
          #MessageNo
          matInput
          type="text"
          appearance="outline"
          name="MessageNo"
          placeholder="MessageNo"
          formControlName="MessageNo"
        />
        <!-- [typeahead]="usernameoptions"
          [typeaheadMinLength]="0"
          (typeaheadOnBlur)="getSuperinformation($event)"
          (typeaheadOnSelect)="getSuperinformation($event)" -->
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>SalesOrderNo</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="SalesOrderNo"
          placeholder="SalesOrderNo"
          formControlName="SalesOrderNo"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>WTNumber</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="WTNumber"
          placeholder="WTNumber"
          formControlName="WTNumber"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>WTStep</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="WTStep"
          placeholder="WTStep"
          formControlName="WTStep"
        />
      </mat-form-field>
      <div class="btn">
        <button
          mat-flat-button
          color="primary"
          (click)="AddToFix()"
          [disabled]="!EditForm.valid"
        >
          Add Individually
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="totalList.length > 0">
    <table class="table" id="dataToSend">
      <tr>
        <th>MessageNo</th>
        <th>SalesOrderNo</th>
        <th>WTNumber</th>
        <th>WTStep</th>
        <th>Action</th>
      </tr>
      <tr *ngFor="let item of totalList" class="rowInformation">
        <td>{{ item.MessageNo }}</td>
        <td>{{ item.SalesOrderNo }}</td>
        <td>{{ item.WTNumber }}</td>
        <td>{{ item.WTStep }}</td>
        <td>
          <button mat-flat-button color="warn" (click)="removeRow(item)">
            <mat-icon> delete </mat-icon>
          </button>
        </td>
      </tr>
    </table>
    <div class="valButton">
      <button mat-flat-button color="primary" (click)="submitFix()">
        Submit Fix
      </button>
    </div>
  </div>
</div>
