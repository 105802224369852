/* 
  Pain in the ass method. Basically, you can't trust type="number" for shit
  So this method takes the event, truncates the value after param charLength,
  and attempts to place the cursor at it's old location.
  It's not perfect, but it's better than nothing. You may want to look at the
  directive DigitOnly if you are unsure if you should use this.
  That one is pretty dope and does pretty much what you need.
*/
const InputIntSanitizer = (event: InputEvent, charLength = 4): string => {
  // Test if e.value is a number
  if (!/^[0-9]*$/.test((event.target as HTMLInputElement).value)) {
    const newValue = (event.target as HTMLInputElement).value
      .replace(/\D/g, '')
      .slice(0, charLength);
    // First, get the cursor position in the input
    const cursorPosStart =
      (event.target as HTMLInputElement).selectionStart - 1;
    const cursorPosEnd = (event.target as HTMLInputElement).selectionEnd - 1;

    (event.target as HTMLInputElement).value = newValue;
    // Now, set the cursor position back to cursorPos
    (event.target as HTMLInputElement).setSelectionRange(
      cursorPosStart,
      cursorPosEnd
    );
    setTimeout(() => {
      // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
      (event.target as HTMLInputElement).setSelectionRange(
        cursorPosStart,
        cursorPosEnd
      );
    }, 0);
    return newValue;
  } else {
    const cursorPosStart = (event.target as HTMLInputElement).selectionStart;
    const cursorPosEnd = (event.target as HTMLInputElement).selectionEnd;
    (event.target as HTMLInputElement).setSelectionRange(
      cursorPosStart,
      cursorPosEnd
    );
    setTimeout(() => {
      // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
      (event.target as HTMLInputElement).setSelectionRange(
        cursorPosStart,
        cursorPosEnd
      );
    }, 0);
    return (event.target as HTMLInputElement).value.slice(0, charLength);
  }
};
export default InputIntSanitizer;
