<div class="phsvcnt">
  <app-data-table
    title="Physical Count Variance"
    [isLoading]="loading.isLoading()"
    [rawData]="tableData"
    [tableColumns]="
      [
        {col: 'WarehouseCode', value: 'WarehouseCode', title: 'Warehouse', size: 'sm'},
        {col: 'Part_Code', value: 'Part_Code', title: 'Part Code', size: 'md'},
        {col: 'Part_Desc', value: 'Part_Desc', title: 'Description', size: 'xl', includeColumnByDefault: false},
        {col: 'BinLocation', value: 'BinLocation', title: 'Bin', size: 'md', includeColumnByDefault: false},
        {col: 'QuantityOnHand', value: 'QuantityOnHand', title: 'Qty On Hand', size: 'sm'},
        {col: 'counted', value: 'counted', title: 'Cycle Counted', size: 'sm'},
        {col: 'variance', value: 'variance', title: 'Variance Count', size: 'sm'},
        {col: 'StandardCost', value: normalizeMoney, title: 'Standard Cost', size: 'sm'},
        {col: 'varianceCost', value: normalizeMoney, title: 'Cost Diff', size: 'sm'},
      ]"
    [inColumns]="
      [
        {col: 'Part_Code',  title: 'Part Code'},
        {col: 'BinLocation', title: 'Bin'},
      ]"
    [mselColumns]="
      [
        {col: 'WarehouseCode', title: 'Warehouse'},
      ]"
    [includeColumnSelector]="true"
    [searchOnChange]="true"
    [accordianControls]="true"
    [includePdfDownload]="true"
    accordianScreenSize="smd"
    (filterChanged)="filtered($event)"
  >
    <ng-container table-footer>
      <div class="phsvcnt-ftr">
        <h4 class="phsvcnt-ftr-ttl">Totals</h4>
        <div class="phsvcnt-ftr-ctnt">
          <p class="phsvcnt-ftr-ctnt-itm">
            Qty On Hand:
            <span class="phsvcnt-ftr-ctnt-itm-ttl">
              {{ totalQuantityOnHand }}
            </span>
          </p>
          <p class="phsvcnt-ftr-ctnt-itm">
            Cycle Counted:
            <span class="phsvcnt-ftr-ctnt-itm-ttl"> {{ totalCounted }} </span>
          </p>
          <p class="phsvcnt-ftr-ctnt-itm">
            Variance Count:
            <span class="phsvcnt-ftr-ctnt-itm-ttl"> {{ totalVariance }} </span>
          </p>
          <p class="phsvcnt-ftr-ctnt-itm">
            Cost Diff:
            <span class="phsvcnt-ftr-ctnt-itm-ttl">
              {{ normalizeMoneyStr(totalVarianceCost) }}
            </span>
          </p>
        </div>
      </div>
    </ng-container>

    <div table-content class="phsvcnt-ctrls">
      <!-- Add a mat form field that takes a csv file -->
      <input
        #csvInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        (change)="onFileChange($event)"
        accept=".csv"
      />
      <button
        [disabled]="loading.isLoading()"
        (click)="csvInput.click()"
        color="primary"
        mat-flat-button
      >
        Choose Spreadsheet File (CSV)
      </button>
    </div>
  </app-data-table>
</div>
