<div class="stngs">
  <div class="stngs-nav">
    <div class="list-group list-group-flush">
      <div
        *ngIf="
          authService.decodedToken.role == 'admin' ||
          authService.decodedToken.role == 'superadmin'
        "
        class="stngs-nav-hdr"
      >
        Punch
      </div>
      <a
        *ngIf="
          authService.decodedToken.role == 'admin' ||
          authService.decodedToken.role == 'superadmin'
        "
        (click)="settingPageSelected('PunchAssignableTechs')"
        [class.active]="activePage('PunchAssignableTechs')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Assignable Techs
      </a>
      <a
        *ngIf="
          authService.decodedToken.role == 'admin' ||
          authService.decodedToken.role == 'superadmin'
        "
        (click)="settingPageSelected('SuperintendentManager')"
        [class.active]="activePage('SuperintendentManager')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Superintendent Manager
      </a>

      <div
        *ngIf="
          authService.decodedToken.role == 'acct_mgt' ||
          authService.decodedToken.role == 'baAdmin' ||
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        class="stngs-nav-hdr"
      >
        Sales Order
      </div>
      <a
        *ngIf="
          authService.decodedToken.role == 'acct_mgt' ||
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        (click)="settingPageSelected('SubdivisionSetup')"
        [class.active]="activePage('SubdivisionSetup')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Subdivision Edit
      </a>
      <a
        *ngIf="
          authService.decodedToken.role == 'acct_mgt' ||
          authService.decodedToken.role == 'baAdmin' ||
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        (click)="settingPageSelected('PlanSetup')"
        [class.active]="activePage('PlanSetup')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Plan Setup
      </a>
      <a
        *ngIf="
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        (click)="settingPageSelected('AccountingValidation')"
        [class.active]="activePage('AccountingValidation')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Accounting Validation
      </a>
      <div
        *ngIf="
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        class="stngs-nav-hdr"
      >
        Work Ticket
      </div>
      <a
        *ngIf="
          authService.decodedToken.role == 'superadmin' ||
          authService.decodedToken.role == 'admin'
        "
        (click)="settingPageSelected('WorkTicketUpdate')"
        [class.active]="activePage('WorkTicketUpdate')"
        class="list-group-item list-group-item-action py-1 border stngs-nav-item"
      >
        Status Update
      </a>
    </div>
  </div>
  <div class="col-10 py-2">
    <div
      *ngIf="homePageSelected"
      class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light"
    >
      <div class="col-md-5 p-lg-5 mx-auto my-5">
        <h1 class="display-4 font-weight-normal">Settings</h1>
        <p class="lead font-weight-normal">
          Click on a tab in the left column to select a settings page.
        </p>
      </div>
      <div class="product-device box-shadow d-none d-md-block"></div>
      <div
        class="product-device product-device-2 box-shadow d-none d-md-block"
      ></div>
    </div>
    <ng-template appSettings></ng-template>
  </div>
</div>
