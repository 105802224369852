import {
  ChangeDetectionStrategy,
  EventEmitter,
  Inject,
  Injectable,
} from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  catchError,
  map,
  share,
  shareReplay,
  tap,
  throwIfEmpty,
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SageApiService } from '../sageApi/sageApi.service';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { BaseModalService } from '../BaseModalService/BaseModalService';
import { MultiIsLoadingService } from '../multi-is-loading/multi-is-loading.service';
import HouseSearchItemDtointerface from './interfaces/HouseSearchItemDtointerface';
import HouseDtointerface from './interfaces/HouseDtoInterface';
import HouseHeaderDtointerface from './interfaces/HouseHeaderDtointerface';

@Injectable({
  providedIn: 'root',
})
export class HouseService {
  constructor(
    public api: SageApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private modals: BaseModalService
  ) {}

  /**
   * Search Houses
   * @param options.search - Search string
   * @param options.size - Number of results to return
   * @param options.offset - Offset of results
   * @returns Observable<HouseSearchItemDtointerface[]> - List of houses
   */
  searchHousesRequest(options: {
    search: string;
    size?: number;
    offset?: number;
  }): Observable<HouseSearchItemDtointerface[]> {
    const obs = new Observable<HouseSearchItemDtointerface[]>(subscriber => {
      const queryParams = new URLSearchParams();
      queryParams.set('search', options.search);
      if (options.size != undefined) {
        queryParams.set('size', options.size.toString());
      }
      if (options.offset != undefined) {
        queryParams.set('offset', options.offset.toString());
      }
      this.api
        .pullReport(`house-search?${queryParams}`, {
          matchCriteria: [
            options.search,
            `${options.size}`,
            `${options.offset}`,
          ],
          exp: 60000,
        })
        .subscribe(
          (data: HouseSearchItemDtointerface[]) => {
            subscriber.next(data);
            subscriber.complete();
          },
          err => {
            console.log(err);
            subscriber.error(err);
            subscriber.complete();
          }
        );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Get House
   * @param options.salesOrderNo - Sales order number
   * @returns Observable<HouseDtointerface> - House
   */
  getHouseRequest(options: {
    salesOrderNo: string;
  }): Observable<HouseDtointerface> {
    const obs = new Observable<HouseDtointerface>(subscriber => {
      this.api.pullReport(`house/${options.salesOrderNo}`).subscribe(
        (data: HouseDtointerface) => {
          subscriber.next(data);
          subscriber.complete();
        },
        err => {
          console.log(err);
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Destroy House Phases
   * @param options.salesOrderNo - Sales order number
   * @param options.WTNumber - WT numbers
   * @returns Observable<string[]> - List of WT numbers
   */
  destroyHousePhasesRequest(options: {
    salesOrderNo: string;
    WTNumber: string[];
  }): Observable<string[]> {
    const obs = new Observable<string[]>(subscriber => {
      const queryParams = new URLSearchParams();
      for (const wtnum of options.WTNumber) {
        queryParams.append('WTNumber', wtnum.toString());
      }
      this.api
        .deleteRequest(
          `house/remove-phases/${options.salesOrderNo}?${queryParams}`
        )
        .subscribe(
          (data: string[]) => {
            subscriber.next(data);
            subscriber.complete();
          },
          err => {
            console.log(err);
            subscriber.error(err);
            subscriber.complete();
          }
        );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Search Houses
   * @param options.search - Search string
   * @param options.size - Number of results to return
   * @param options.offset - Offset of results
   * @param options.showErrors - Show errors
   * @returns Observable<HouseSearchItemDtointerface[]> - List of houses
   */
  searchHouses(options: {
    search: string;
    size?: number;
    offset?: number;
    showErrors?: boolean;
  }): Observable<HouseSearchItemDtointerface[]> {
    const obs = new Observable<HouseSearchItemDtointerface[]>(subscriber => {
      this.searchHousesRequest({
        search: options.search,
        size: options.size,
        offset: options.offset,
      }).subscribe(
        (data: HouseSearchItemDtointerface[]) => {
          subscriber.next(data);
          subscriber.complete();
        },
        err => {
          if (options.showErrors === undefined || options.showErrors == true) {
            this.snackBar.open(`Couldn't perform search.`, 'Close', {
              duration: Infinity,
            });
          }
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    // const sobs = obs.pipe(shareReplay());
    return obs;
  }

  /**
   * Get House
   * @param options.salesOrderNo - Sales order number
   * @param options.showErrors - Show errors
   * @returns Observable<HouseDtointerface> - House
   */
  getHouse(options: {
    salesOrderNo: string;
    showErrors?: boolean;
  }): Observable<HouseDtointerface> {
    const obs = new Observable<HouseDtointerface>(subscriber => {
      this.getHouseRequest({
        salesOrderNo: options.salesOrderNo,
      }).subscribe(
        (data: HouseDtointerface) => {
          subscriber.next(data);
          subscriber.complete();
        },
        err => {
          if (options.showErrors === undefined || options.showErrors == true) {
            this.snackBar.open(`Couldn't get house. Does it exist?`, 'Close', {
              duration: Infinity,
            });
          }
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }

  /**
   * Destroy House Phases
   * @param options.salesOrderNo - Sales order number
   * @param options.WTNumber - WT numbers
   * @param options.showErrors - Show errors
   * @returns Observable<string[]> - List of WT numbers
   */
  destroyHousePhases(options: {
    salesOrderNo: string;
    WTNumber: string[];
    showErrors?: boolean;
  }): Observable<string[]> {
    const obs = new Observable<string[]>(subscriber => {
      this.destroyHousePhasesRequest({
        salesOrderNo: options.salesOrderNo,
        WTNumber: options.WTNumber,
      }).subscribe(
        (data: string[]) => {
          subscriber.next(data);
          subscriber.complete();
        },
        err => {
          if (options.showErrors === undefined || options.showErrors == true) {
            this.snackBar.open(`Couldn't destroy phases.`, 'Close', {
              duration: Infinity,
            });
          }
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
    const sobs = obs.pipe(shareReplay());
    return sobs;
  }
}
