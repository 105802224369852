import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import QAWorkticketAddressInterface from 'src/app/_services/sageApi/interfaces/pullReport/QAWorkticketAddressInterface';
import { WasabiApiService } from 'src/app/_services/wasabiApi/wasabiApi.service';

@Component({
  selector: 'app-address-files',
  templateUrl: './address-files.component.html',
  styleUrls: ['./address-files.component.css'],
})
export class AddressFilesComponent implements OnInit {
  isMobile = false;

  addresses: QAWorkticketAddressInterface[] = [];
  addressesOptions: string[] = [];
  addressesLoading = true;

  documents: string[] = [];
  documentsLoading = false;

  constructor(
    private sageApi: SageApiService,
    private wasabiApi: WasabiApiService,
    private alertify: AlertifyService,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe([Breakpoints.Tablet, Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  ngOnInit(): void {
    this.pullAllAddresses();
  }

  pullAllAddresses() {
    this.sageApi.pullReport('QAWorkticket/Addresses').subscribe(
      (results: QAWorkticketAddressInterface[]) => {
        const newAddresses: QAWorkticketAddressInterface[] = [];
        for (const r of results) {
          const matching = newAddresses.find(
            adr => adr.ShipToName == r.ShipToName
          );
          if (!matching) {
            newAddresses.push(r);
          }
        }
        this.addresses = newAddresses;
        this.addressesOptions = newAddresses.map(addr => addr.ShipToName);
        this.addressesLoading = false;
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
      }
    );
  }

  pullDocuments(address: string) {
    this.wasabiApi.getImagesObservable(address).subscribe(
      results => {
        this.documents = results;
        this.documentsLoading = false;
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
      }
    );
  }

  onSelectedAddressChange(selectedAddress) {
    if (selectedAddress?.value && selectedAddress?.value?.trim() != '') {
      this.documentsLoading = true;
      this.pullDocuments(selectedAddress.value);
    } else {
      this.documents = [];
      this.documentsLoading = false;
    }
  }

  getMediaDocuments() {
    const acceptableMediaExtentions = [
      'png',
      'jpg',
      'jpeg',
      'svg',
      'gif',
      'webm',
    ];

    const media: string[] = [];
    for (const doc of this.documents) {
      const split = doc.split('.');
      const last = split[split.length - 1];
      if (acceptableMediaExtentions.includes(last.toLowerCase())) {
        media.push(doc);
      }
    }
    return media;
  }

  documentsToTableData(): { FileName: string }[] {
    return this.documents.map(d => {
      return { FileName: d };
    });
  }
}
