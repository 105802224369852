import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';

export interface ChangeOrderApprovalInterface {
  id: number;
  SubmittedBy: string;
  BuilderName: string;
  City: string;
  ItemCode: string;
  Reason: string;
  Inspector: string;
  Subdivision: string;
  SubmissionDate: Date;
  DateOfChange: Date;
  Cost: number;
  Quantity: number;
  Action: string;
  Margin: number;
  Warehouse: string;
  itemcost: number;
  AreaManagerApproval: string;
  AreaManagerApprovalDate: Date;
  SalesManagerApproval: string;
  SalesManagerApprovalDate: Date;
}

@Component({
  selector: 'app-change-order-approval',
  templateUrl: './ChangeOrderApproval.component.html',
  styleUrls: ['./ChangeOrderApproval.component.css'],
})
export class ChangeOrderApprovalComponent implements OnInit {
  modalTemplate: BsModalRef;
  selectedChangeOrder: ChangeOrderApprovalInterface;
  loading: boolean;
  dataSource: MatTableDataSource<ChangeOrderApprovalInterface> | null;
  changeOrders: ChangeOrderApprovalInterface[] = [];
  tableDisplayedColumns: string[] = [
    'BuilderName',
    'City',
    'ItemCode',
    'Subdivision',
    'DateOfChange',
    'Reason',
    'Warehouse',
    'Cost',
    'SalesManagerApproval',
    'AreaManagerApproval',
    'Action',
  ];
  sending = false;
  user;

  edit = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private sageApi: SageApiService,
    private modalService: BsModalService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getChangeOrders();
    this.user = this.authService.decodedToken;
    this.specialDallasCase();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  specialDallasCase() {
    if (this.user.nameid == 'wharris') {
      this.user.role = 'am';
    }
  }

  getChangeOrders() {
    this.changeOrders = [];
    this.loading = true;
    this.sageApi.pullReport('ChangeOrder').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            this.changeOrders.push(row);
          });
        }
        this.dataSource = new MatTableDataSource(this.changeOrders);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      err => {
        this.alertify.error(err.message);
      }
    );
  }
  changeOrderSelected(element) {
    this.selectedChangeOrder = element;
  }
  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  ManagerApproval(type) {
    this.sending = true;
    const id = this.authService.decodedToken.nameid;
    if (type == 'SalesManager') {
      this.selectedChangeOrder.SalesManagerApproval = id;
    } else if (type == 'AreaManager') {
      this.selectedChangeOrder.AreaManagerApproval = id;
    }

    const observables = [];

    this.sageApi
      .putRequest('ChangeOrder/ManagerApproval', this.selectedChangeOrder)
      .subscribe(
        (response: any) => {
          this.alertify.success(response);
        },
        err => {
          this.alertify.error(err.error.Message);
          this.sending = false;
          this.closeModal();
          this.getChangeOrders();
        },
        () => {
          this.sending = false;
          this.closeModal();
          this.getChangeOrders();
        }
      );
  }
  editInformation() {
    const queryParams = {};
    queryParams['tab'] = 1;
    const url = this.router.navigate(['/Builder/ChangeOrder/Create'], {
      queryParams: { id: this.selectedChangeOrder.id },
    });
    this.closeModal();
  }
}
