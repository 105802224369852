import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import TagDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/TagDtoInterface';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import GetColorFromString from 'src/app/utils/GetColorFromString';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-prevquotepdf',
  templateUrl: './PreviewQuotePdfViewComponent.html',
  styleUrls: ['./PreviewQuotePdfViewComponent.css', '../QuotingTheme.scss'],
  providers: [MultiIsLoadingService],
})
export class PreviewQuotePdfViewComponent {
  quoteGuid = this.activatedRoute.snapshot.params.quoteGuid;

  loading = false;
  error = false;
  content: any = undefined;

  constructor(
    public api: SageApiService,
    public searchLoading: MultiIsLoadingService,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public modals: BaseModalService,
    private router: Router,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = true;
    this.api.getFile(`preview-quote-pdf/${this.quoteGuid}`).subscribe(
      (d: Blob) => {
        this.loading = false;
        this.content = this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(d)
        );
      },
      err => {
        console.error(err);
        this.loading = false;
        this.error = true;
      }
    );
  }
}
