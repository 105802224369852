<div class="pageContents">
  <div class="header">
    <!-- <mat-form-field appearance="outline" class="suprep-card-content-controls-ff">
        <mat-label>Report</mat-label>
        <mat-select [formControl]="report" placeholder="Report" (selectionChange)="report.setValue($event.value)">
          <mat-option *ngFor="let op of reportOptions" [value]="op"
            [class.empty]="op==''">{{op!=''?op:'Empty'}}</mat-option>
        </mat-select>
      </mat-form-field> -->
    <div class="options">
      <app-searchable-msel
        [selectedOptions]="report"
        (selectChanged)="report.setValue($event.value)"
        placeholder="Report"
        [options]="reportOptions"
        [disabled]="isLoading"
        class="suprep-card-content-controls-ff"
      ></app-searchable-msel>
      <app-searchable-msel
        [selectedOptions]="companies"
        (selectChanged)="companies.setValue($event.value)"
        placeholder="Companies"
        [options]="companiesOptions"
        [disabled]="isLoading"
        class="suprep-card-content-controls-ff"
      ></app-searchable-msel>
      <mat-form-field
        appearance="outline"
        class="suprep-card-content-controls-ff"
      >
        <mat-label>Address Search</mat-label>
        <input
          [(ngModel)]="addressSearch"
          (change)="getIncludedSteps()"
          placeholder="Address Search"
          matInput
          type="text"
          [disabled]="isLoading"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="suprep-card-content-controls-ff"
      >
        <mat-label>Sales Order Search</mat-label>
        <input
          [(ngModel)]="salesOrderSearch"
          (change)="getIncludedSteps()"
          placeholder="Sales Order Search"
          matInput
          type="text"
          [disabled]="isLoading"
        />
      </mat-form-field>
    </div>
    <!-- <button class="btn btn-primary m-2 p-2" (click)="loadHistory(report.value)" [disabled]="isLoading">
        <span *ngIf="!isLoading">Refresh</span>
        <span *ngIf="isLoading" class="suprep-card-title-spinner"><mat-spinner diameter="20"></mat-spinner></span>
      </button> -->
    <!--      <button class="btn btn-info btn-sm pull-right" (click)="loadHistory()">-->
    <div class="searchBtns">
      <div class="dateOptions">
        <div class="dateOptions">
          <input
            type="text"
            class="form-control"
            id="FromDate"
            name="FromDate"
            bsDatepicker
            [(bsValue)]="FromDate"
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
            (blur)="confirmDateRange()"
          />
          <input
            type="text"
            class="form-control"
            id="ToDate"
            name="ToDate"
            bsDatepicker
            [(bsValue)]="ToDate"
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
            (blur)="confirmDateRange()"
          />
        </div>
        <div>
          <button
            class="btn btn-primary"
            (click)="confirmDateRange()"
            [disabled]="isLoading"
          >
            <span *ngIf="!isLoading">Search By Date</span>
            <span *ngIf="isLoading" class="suprep-card-title-spinner"
              ><mat-spinner diameter="20"></mat-spinner
            ></span>
          </button>
        </div>
      </div>
      <div class="val">
        <button
          (click)="openModal(valFix)"
          [disabled]="isLoading"
          class="btn btn-primary"
        >
          Validation
        </button>
      </div>
    </div>
  </div>
  <div class="row justify-content-end">
    <h3>
      <span *ngIf="isLoading" class="suprep-card-title-spinner"
        ><mat-spinner diameter="20"></mat-spinner
      ></span>
      <span *ngIf="!isLoading"
        >Total Cost: ${{ allTicketTotalCost.toFixed(2) }}</span
      >
    </h3>
  </div>
  <div class="row justify-content-center">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th
              (click)="onColumnSort('wtNumber', wtSortDirection)"
              class="col-xs-1"
              style="min-width: 100px"
            >
              WT#
              {{
                wtSortDirection == 'asc'
                  ? '&#9650;'
                  : wtSortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th
              (click)="onColumnSort('address', addressSortDirection)"
              class="col-xs-2"
            >
              Address
              {{
                addressSortDirection == 'asc'
                  ? '&#9650;'
                  : addressSortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th
              (click)="onColumnSort('subdivision', subdivisionSortDirection)"
              class="col-xs-2"
            >
              Subdivision
              {{
                subdivisionSortDirection == 'asc'
                  ? '&#9650;'
                  : subdivisionSortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th class="col-xs-1">
              <!-- (click)="onColumnSort('status', statusSortDirection)"  -->
              Status
              <!-- {{
              statusSortDirection == "asc"
              ? "&#9650;"
              : statusSortDirection == "desc"
              ? "&#9660;"
              : ""
              }} -->
            </th>
            <th
              (click)="onColumnSort('builderCompany', companySortDirection)"
              class="col-xs-1"
            >
              Company{{
                companySortDirection == 'asc'
                  ? '&#9650;'
                  : companySortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th class="col-xs-6">Description of Work</th>
            <th
              (click)="onColumnSort('amount', amountSortDirection)"
              class="col-xs-1"
            >
              Amount
              {{
                amountSortDirection == 'asc'
                  ? '&#9650;'
                  : amountSortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th (click)="onColumnSort('currentStatusDate', dateSortDirection)">
              Status Date
              {{
                dateSortDirection == 'asc'
                  ? '&#9650;'
                  : dateSortDirection == 'desc'
                  ? '&#9660;'
                  : ''
              }}
            </th>
            <th class="col-xs-1">VPO Notes</th>
            <th class="col-xs-1">Action</th>
            <th class="col-xs-1">History</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let step of getIncludedSteps()">
              <!--          <ng-container *ngIf="step.currentStatus === 'ACCRV'">-->
              <ng-container
                *ngIf="
                  sortByHistory
                    ? step.currentStatusDate >= FromDate &&
                      step.currentStatusDate <= ToDate
                    : 1
                "
              >
                <td>
                  {{ step.wtNumber }}
                </td>
                <td>
                  {{ step.address }}
                </td>
                <td>
                  {{ step.subdivision }}
                </td>
                <td>{{ step.currentStatus }} - {{ step.statusDescription }}</td>
                <td>
                  {{ step.builderCompany }}
                </td>
                <td>
                  {{ step.descriptionOfWork }}
                  <div
                    *ngIf="
                      step.statusDescription == 'Pre-Dispute Builder Charge'
                    "
                  >
                    <br />
                    {{ step.commentText }}
                  </div>
                </td>
                <td>${{ step.amount }}</td>
                <td style="min-width: 100px">
                  {{ step.currentStatusDate | date: 'MM/dd/yyyy' }}
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    name="vpo-notes"
                    id="vpo-notes-button"
                    (click)="onShowVPONotes(step)"
                    (click)="openModal(vpoNotesModal)"
                  >
                    View
                  </button>
                </td>
                <td>
                  <button
                    class="{{
                      step.updated == ''
                        ? 'btn btn-primary btn-sm'
                        : 'btn btn-success btn-sm'
                    }}"
                    (click)="actionEdit(step)"
                    (click)="openModal(editInterface)"
                  >
                    {{ step.updated == '' ? 'Edit' : 'Updated' }}
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="loadHistory3(step)"
                    (click)="openModal(WomsHistModal)"
                  >
                    History
                  </button>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- *Edit Modal -->
<ng-template
  #editInterface
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <!--  <div-->
  <!--  class="modal fade"-->
  <!--  id="editInterface"-->
  <!--  bsModal-->
  <!--  #editInterface="bs-modal"-->
  <!--  tabindex="-1"-->
  <!--  role="dialog"-->
  <!--  aria-labelledby="editor"-->
  <!--&gt;-->
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="dataModel.steps.length > 0">
      <div class="modal-header">
        <div class="row">
          <div class="col">
            <h4 class="modal-title" id="editor">
              Editing {{ dataModel.steps[index].address }} in
              {{ dataModel.steps[index].subdivision }}
            </h4>
            <ng-container *ngIf="errorMsg !== ''">
              <h5 style="color: red">
                <img
                  src="../../../assets/importantYellow.png"
                  width="20"
                  alt="a black exclamation point on a yellow background"
                />
                {{ loadingStatus }}
              </h5>
            </ng-container>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="actionClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center"></div>
      </div>
      <!--      <div class="modal-header">-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          class="close"-->
      <!--          aria-label="Close"-->
      <!--          (click)="actionClose()"-->
      <!--        >-->
      <!--          <span aria-hidden="true">&times;</span>-->
      <!--        </button>-->
      <!--        <h4 class="modal-title" id="editor">-->
      <!--          Editing {{ dataModel.steps[index].address }} in-->
      <!--          {{ dataModel.steps[index].subdivision }}-->
      <!--        </h4>-->
      <!--        <h5 *ngIf="errorMsg !== ''" style="color: red;">-->
      <!--          <img-->
      <!--            src="../../../assets/importantYellow.png"-->
      <!--            width="20"-->
      <!--            alt="a black exclamation point on a yellow background"-->
      <!--          />-->
      <!--          {{ loadingStatus }}-->
      <!--        </h5>-->
      <!--      </div>-->
      <div class="modal-body">
        <div class="row">
          <label for="typeNotes"><b>Charge Reason</b></label>
          <blockquote>
            <p class="lead" id="typeNotes">
              {{ dataModel.steps[index].notes }}
            </p>
          </blockquote>
        </div>
        <form class="">
          <div class="row">
            <div class="col-12">
              <label class="" for="builderInfo" style="min-width: 120px"
                ><b>Builder Info</b></label
              >
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="BuilderInfo"
                disabled
                value="{{ dataModel.steps[index].builderName }}"
                style="width: 16rem"
              />
            </div>
            <div class="col-12 mt-1 mb-2">
              <!--              <label class="sr-only" for="builderEmail">Builder Email</label>-->
              <input
                type="text"
                class="form-control"
                id="BuilderPhone"
                disabled
                value="{{ dataModel.steps[index].builderPhone }}"
                style="width: 24.5rem"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="" for="AdditionalInfo" style="min-width: 120px"
                ><b>Additional Info</b></label
              >
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="AdditionalInfo"
                disabled
                value="{{ dataModel.steps[index].builderCompany }}"
              />
            </div>
            <div class="col-6">
              <!--              <label-->
              <!--                class="sr-only"-->
              <!--                for="builderSubdivision"-->
              <!--                style="min-width: 120px;">Builder Subdivision</label>-->
              <input
                type="text"
                class="form-control"
                id="BuilderSubdivision"
                disabled
                value="{{ dataModel.steps[index].builderSubdivision }}"
              />
            </div>
          </div>
          <div class="clearfix" style="margin-top: 20px"></div>
          <div class="row">
            <div class="col-2">
              <label class="mt-2" for="poNumber" style="min-width: 120px"
                ><b>PO Number</b></label
              >
            </div>
            <div class="col-10">
              <input
                type="text"
                class="form-control"
                id="poNumber"
                name="poNumber"
                [(ngModel)]="dataModel.steps[index].poNumber"
              />
            </div>
          </div>
          <div class="clearfix" style="margin-top: 20px"></div>
          <div class="row">
            <div class="col-12">
              <label class="" for="oldStatus" style="min-width: 120px"
                ><b>Update Status</b></label
              >
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="oldStatus"
                disabled
                value="{{ dataModel.steps[index].currentStatus }}"
              />
            </div>
            <div class="col-6">
              <!--              <label class="sr-only" for="newStatus" style="min-width: 120px;">New Ticket Status</label>-->
              <input
                list="Statuses"
                type="text"
                class="form-control"
                id="newStatus"
                name="statusCode"
                [(ngModel)]="dataModel.steps[index].newACCStatus"
              />
              <datalist id="Statuses">
                <select class="form-control" id="Statuses">
                  <option disabled>--Select--</option>
                  <option
                    *ngFor="let status of dataModel.loadstatuses"
                    value="{{ status.statusCode }}"
                  >
                    {{ status.statusCode }} - {{ status.statusDesc }}
                  </option>
                </select>
              </datalist>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="actionClose()">
          Close
        </button>
        <button
          #modalSubmitButton
          type="button"
          class="btn btn-primary"
          (click)="saveChanges(index)"
          [disabled]="
            dataModel.steps[index].poNumber == '' &&
            dataModel.steps[index].newACCStatus === 'EPOR'
          "
          [ngClass]="
            dataModel.steps[index].poNumber == '' &&
            dataModel.steps[index].newACCStatus === 'EPOR'
              ? 'disabledButton'
              : 'enabledButton'
          "
        >
          <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
            *ngIf="!isLoading"
            >{{
              dataModel.steps[index].newAmount !== ''
                ? 'Update & Approve'
                : 'Approve'
            }}</span
          >
        </button>
      </div>
    </div>
  </div>
  <!--</div>-->
</ng-template>

<!-- *VPO Notes Modal -->
<ng-template
  #vpoNotesModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <!--  <div-->
  <!--    bsModal-->
  <!--    #vpoNotesModal="bs-modal"-->
  <!--    class="modal fade"-->
  <!--    id="vpo-notes-modal"-->
  <!--    tabindex="-1"-->
  <!--    role="dialog"-->
  <!--    aria-labelledby="vpo-notes-modal"-->
  <!--    aria-hidden="true"-->
  <!--  >-->
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col">
            <h4 class="modal-title">
              VPO Notes for {{ dataModel.steps[index].wtNumber }}
            </h4>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center"></div>
      </div>
      <!--        <div class="modal-header">-->
      <!--          <button-->
      <!--            type="button"-->
      <!--            class="close"-->
      <!--            data-dismiss="modal"-->
      <!--            aria-label="Close"-->
      <!--          >-->
      <!--            <span aria-hidden="true" (click)="onVPONotesClose()">&times;</span>-->
      <!--          </button>-->
      <!--          <h4 class="modal-title">-->
      <!--            VPO Notes for {{ dataModel.steps[index].wtNumber }}-->
      <!--          </h4>-->
      <!--        </div>-->
      <div class="modal-body">
        <blockquote>
          <p>{{ dataModel.steps[index].vpoNotes }}</p>
        </blockquote>
        <div class="form-group">
          <label for="update-vpo-notes">Add Notes</label>
          <textarea
            [(ngModel)]="dataModel.steps[index].vpoNotes"
            class="form-control"
            name="update-vpo-notes"
            id="update-vpo-notes"
            rows="3"
          ></textarea>
        </div>
        <h4 [className]="failed ? 'fail' : 'succeed'">
          {{ loadingStatus }}
        </h4>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="onVPONotesClose()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="isLoading"
          (click)="onAddVPOComments(index)"
        >
          <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
            *ngIf="!isLoading"
            >{{ vpoNotesUpdated ? 'Updated' : 'Update Comment' }}</span
          >
        </button>
      </div>
    </div>
  </div>
  <!--  </div>-->
</ng-template>
<!-- */VPO Notes Modal -->

<!-- *WOMS History Modal -->
<ng-template
  #WomsHistModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col">
            <h4 class="modal-title">
              History: {{ dataModel.steps[index].address }} --
              {{ dataModel.steps[index].wtNumber }}
            </h4>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center"></div>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="dataModel.steps[index].womsHist.length <= 0">
          <span><b>No WOMs History is found for this WT.</b></span>
        </ng-container>
        <ng-container *ngIf="dataModel.steps[index].womsHist.length > 0">
          <table class="table table-striped table-hover">
            <thead>
              <th>Date Updated</th>
              <th>Old Status</th>
              <th>New Status</th>
              <th>New PO</th>
              <th>Username</th>
            </thead>
            <tbody>
              <tr *ngFor="let Hist of dataModel.steps[index].womsHist">
                <td>{{ Hist.NEW_STATUS_DATE | date }}</td>
                <td>{{ Hist.CUR_STATUS_CODE }}</td>
                <td>{{ Hist.NEW_STATUS }}</td>
                <td>{{ Hist.NEW_PO }}</td>
                <td>{{ Hist.USERNAME }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="onVPONotesClose()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
  <!--  </div>-->
</ng-template>
<!-- */WOMS Hostory Modal -->

<ng-template #valFix>
  <app-accounting-validation></app-accounting-validation>
</ng-template>
