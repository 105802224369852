import { Component, OnInit, TemplateRef } from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth/auth.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { getSortHeaderNotContainedWithinSortError } from '@angular/material/sort/sort-errors';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-qrpartspull',
  templateUrl: './qrpartspull.component.html',
  styleUrls: ['./qrpartspull.component.css'],
})
export class QrpartspullComponent implements OnInit {
  workticket: string;
  urlParams: URLSearchParams;
  noTicket: boolean;
  isloading: boolean;
  isLoading: boolean;
  toLWSubmitted: boolean;
  matUpdated: boolean;
  imgUpdated: boolean;
  imgOverride: boolean;
  isSubmitting: boolean;
  toWhseCheck: boolean;
  servPun = true;
  soNum: string;
  wtNum: string;
  fullticket: string;
  address: string;
  status: string;
  images: string[];
  loadingStatus: string;
  MESSAGES = {
    INVALID_WORKTICKET: 'Invalid Work Ticket Number',
  };

  // IMAGES
  fileLoading: boolean;
  fileArray = [];
  fileList = [];
  imageAdded: boolean;
  fileAddress: string;
  submitOnce = false;

  transHistory = [];
  hasLW = false;
  hasUnder = false;
  hasOver = false;

  modalTemplate: BsModalRef;
  importTemplateInfo = {
    templateName: '',
    template: '',
    step: '',
  };

  shipAll: boolean;
  itemList: {
    name: string;
    newQtyOrderedTotal: number;
    newQtyShippedTotal: number;
    tickets: any;
  }[] = [];

  noShipList: {
    name: string;
    QtyOrderedTotal: string;
    QtyShippedTotal: string;
    tickets: any;
  }[] = [];

  shipTotals = [];

  itemCollection = [];
  filtereditems = [];

  shownotes: boolean;
  partsfailmessage = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.shownotes = false;
    this.toLWSubmitted = false;
    this.matUpdated = false;
    this.imgUpdated = false;
    this.imgOverride = false;
    this.noTicket = true;
    this.shipAll = true;
    this.isSubmitting = false;
    this.itemList = [];

    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.has('workticket')) {
      this.workticket = this.urlParams.get('workticket');
      this.pullTicketInfo();
      this.pullMaterial();
    }
  }

  createfilter() {
    this.filtereditems = [];
    this.itemCollection[this.workticket].forEach(item => {
      if (!item.JT158_WTParentLineKey.includes(item.LineKey)) {
        if (
          this.filtereditems === undefined ||
          this.filtereditems === null ||
          this.filtereditems.length === 0
        ) {
          this.filtereditems.push(item);
        } else {
          if (
            this.filtereditems.findIndex(
              lst =>
                lst.ItemCode.toLocaleLowerCase() ===
                item.ItemCode.toLocaleLowerCase()
            ) === -1
          ) {
            this.filtereditems.push(item);
          }
        }
      }
    });
  }

  pullTransaction() {
    this.isLoading = true;
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    const loadingWT = this.sageApi
      .pullReport(
        'QRCode/Transaction?SONum=' + salesOrderNumber + '&WTNum=' + wtNumber
      )
      .subscribe(
        (Lines: Array<any>) => {
          if (Array.isArray(Lines)) {
            Lines.forEach((item: any) => {
              if (item.NewStatus === 'LW') {
                this.hasLW = true;
              }
            });
          }
        },
        err => {
          console.log(err);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  pullTicketInfo() {
    this.imageAdded = false;
    this.isLoading = true;
    if (!this.workticket || this.workticket.length < 10) {
      this.loadingStatus = this.MESSAGES.INVALID_WORKTICKET;
      this.isLoading = false;
      this.noTicket = true;
      return;
    }
    const salesOrderNumber = this.workticket.substring(0, 7);
    const wtNumber = this.workticket.substring(7);
    const loadingWT = this.sageApi
      .pullReport(
        'QRCode/Parts?salesOrderNo=' + salesOrderNumber + '&WTNum=' + wtNumber
      )
      .subscribe(
        (tickets: Array<any>) => {
          if (Array.isArray(tickets)) {
            tickets.forEach((ticket: any) => {
              this.soNum = ticket.SalesOrderNo;
              this.wtNum = ticket.WTNumber;
              this.address = ticket.Address;
              this.status = ticket.StatusCode;
              this.toWhseCheck = ticket.UDF_PARTS_PULL_TO_WHSE === 'Y';
            });
            this.pullTransaction();
          }
        },
        err => {
          console.log(err);
          this.noTicket = false;
          this.isLoading = false;
        },
        () => {
          this.noTicket = false;
          this.isLoading = false;
        }
      );
  }

  changeToLW() {
    this.isSubmitting = true;
    this.isLoading = true;
    const observables = [];
    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.soNum,
      wtnumber: this.wtNum,
      notes: '',
      status: 'LW',
    };
    observables.push(this.sageApi.putRequest('UpdWTStatus', obj));
    this.alertify.warning(`Updating Ticket to LW`);
    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      error => {
        console.log(error);
        this.isLoading = false;
        this.alertify.error(`Error - ` + error);
        this.isSubmitting = false;
      },
      () => {
        this.isLoading = false;
        this.isSubmitting = false;
        this.toLWSubmitted = true;
        this.alertify.success(`Success!`);
        this.router.navigate(['/']);
      }
    );
  }

  toWhse() {
    this.isSubmitting = true;
    this.isLoading = true;
    const observables = [];
    const obj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.soNum,
      wtnumber: this.wtNum,
      toWhse: this.toWhseCheck ? 'Y' : 'N',
    };
    observables.push(this.sageApi.putRequest('QRCode/Update/ToWhse', obj));
    this.alertify.warning(`Updating Ticket to Warehouse`);
    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      error => {
        console.log(error);
        this.isLoading = false;
        this.alertify.error(`Error - ` + error);
        this.isSubmitting = false;
      },
      () => {
        this.isLoading = false;
        this.isSubmitting = false;
        this.alertify.success(`Success!`);
      }
    );
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  fileChosen(event) {
    const today = new Date();
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };
        this.fileArray.push(obj);
      }
    }
  }

  fileNames() {
    const route = `QRCode/Files?address=${this.address}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach(file => {
          this.fileList.push({ name: file.split(this.address + '\\')[1] });
        });
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  fileDownload(fileName: string) {
    const route = `QRCode/Files/download?address=${this.address}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  fileUpload() {
    this.fileLoading = true;
    const route = `QRCode/Files/upload?address=${this.address}`;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        this.alertify.success('File Loaded');
        this.imgUpdated = true;
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  openModal(
    template: TemplateRef<any>,
    phaseIndex = 0,
    stepIndex = 0,
    itemIndex = 0
  ) {
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: '',
    };

    this.modalTemplate = this.modalService.show(template);
  }

  shipAllItems() {
    this.itemCollection[this.workticket].forEach(item => {
      if (
        item.newQtyOrdered !== item.newQtyShipped &&
        item.JT158_WTParentLineKey !== undefined &&
        item.JT158_WTParentLineKey !== '' &&
        !item.JT158_WTParentLineKey.includes(item.LineKey)
      ) {
        item.newQtyShipped = item.newQtyOrdered;
        item.edited = true;
      }
    });
  }

  itemListMath() {
    this.itemList.forEach(lst => {
      let total = lst.newQtyShippedTotal;
      lst.tickets.forEach(single => {
        if (single.newQtyOrdered >= total) {
          single.newQtyShipped = total;
          total = 0;
        } else {
          single.newQtyShipped = single.newQtyOrdered;
          total = total - single.newQtyOrdered;
        }
      });
    });
  }

  serviceOrPunch() {
    if (this.itemCollection[this.workticket] !== undefined) {
      this.servPun =
        this.itemCollection[
          this.workticket
        ][0].ItemCode.toLocaleUpperCase().includes('ZPUNCH') ||
        this.itemCollection[
          this.workticket
        ][0].ItemCode.toLocaleUpperCase().includes('ZSERVICE');
    } else {
      this.servPun = true;
    }
  }

  backorderShipAll() {
    try {
      this.itemListMath();
    } finally {
      this.itemCollection[this.workticket].forEach(item => {
        const backorderindex = this.itemList.findIndex(
          back =>
            back.name.toLocaleLowerCase() === item.ItemCode.toLocaleLowerCase()
        );

        if (
          (item.newQtyOrdered !== item.newQtyShipped &&
            item.JT158_WTParentLineKey !== undefined &&
            item.JT158_WTParentLineKey !== '' &&
            !item.JT158_WTParentLineKey.includes(item.LineKey)) ||
          backorderindex > -1
        ) {
          if (backorderindex > -1) {
            const ind = this.itemList[backorderindex].tickets.findIndex(
              lst => lst.lineKey === item.LineKey
            );
            if (ind > -1) {
              const ticket = this.itemList[backorderindex].tickets[ind];
              item.newQtyOrdered = ticket.newQtyOrdered;
              item.newQtyShipped = ticket.newQtyShipped;
            } else {
              this.alertify.error(
                'ERROR: there was an issue with updating material: ' +
                  item.ItemCode +
                  ' Please mark this item to be fixed later.'
              );
            }
          } else {
            item.newQtyShipped = item.newQtyOrdered;
          }
          item.edited = true;
        }
      });
    }
  }

  notShippedList() {
    this.noShipList = [];
    this.itemCollection[this.workticket].forEach(ticket => {
      if (ticket.JT158_WTParentLineKey === ticket.LineKey) {
        return;
      }
      const itemIndex = this.noShipList.findIndex(
        lst =>
          lst.name.toLocaleLowerCase() === ticket.ItemCode.toLocaleLowerCase()
      );
      if (itemIndex === -1) {
        this.noShipList.push({
          name: ticket.ItemCode,
          QtyOrderedTotal: ticket.newQtyOrdered,
          QtyShippedTotal: ticket.newQtyShipped,
          tickets: [ticket],
        });
      } else {
        this.noShipList[itemIndex].QtyOrderedTotal += ticket.newQtyOrdered;
        this.noShipList[itemIndex].QtyShippedTotal += ticket.newQtyShipped;
        this.noShipList[itemIndex].tickets.push(ticket);
      }
    });
    this.noShipList.forEach(item => {
      if (item.QtyOrderedTotal > item.QtyShippedTotal) {
        this.hasUnder = true;
      }
      if (item.QtyOrderedTotal < item.QtyShippedTotal) {
        this.hasOver = true;
      }
    });
  }

  itemSelected(event: TypeaheadMatch, index: any, workticket: any) {
    this.itemList[index].name = event.item.ItemCode;
    this.itemCollection[workticket].forEach(token => {
      if (
        token.ItemCode.toLocaleLowerCase() ===
        this.itemList[index].name.toLocaleLowerCase()
      ) {
        if (isNaN(this.itemList[index].newQtyOrderedTotal)) {
          this.itemList[index].newQtyOrderedTotal = token.QuantityOrdered;
        } else {
          this.itemList[index].newQtyOrderedTotal += token.QuantityOrdered;
        }
        if (isNaN(this.itemList[index].newQtyShippedTotal)) {
          this.itemList[index].newQtyShippedTotal = token.newQtyShipped;
        } else {
          this.itemList[index].newQtyShippedTotal += token.newQtyShipped;
        }
        const obj = {
          name: token.ItemCode,
          lineKey: token.LineKey,
          newQtyOrdered: token.newQtyOrdered,
          newQtyShipped: token.newQtyShipped,
        };
        this.itemList[index].tickets.push(obj);
      }
    });
  }

  generatebackorders() {
    this.shipAll = false;
    if (this.itemList.length === 0) {
      this.AddBackorderList();
    }
  }

  lineEdited(index: number) {
    if (this.itemList[index].newQtyOrderedTotal < 0) {
      this.alertify.error(
        'Error: quantity ordered for ' +
          this.itemList[index].name +
          ' cannot be below 0.'
      );
      this.itemList[index].newQtyOrderedTotal = 0;
      return;
    }

    let totalOrdered = 0;
    this.itemList[index].tickets.forEach(lst => {
      totalOrdered += lst.newQtyOrdered;
    });
    if (this.itemList[index].newQtyOrderedTotal > totalOrdered) {
      const toAdd = this.itemList[index].newQtyOrderedTotal - totalOrdered;
      const minQty = this.minQtyOrdered(this.itemList[index].tickets);
      let tickIndex = this.itemList[index].tickets.findIndex(item => {
        const num = item.newQtyOrdered;
        return num === minQty;
      });
      tickIndex = 0;

      if (this.itemList[index].tickets[tickIndex] !== undefined) {
        this.itemList[index].tickets[tickIndex].newQtyOrdered += toAdd;
      } else {
        this.itemList[index].tickets[tickIndex].newQtyOrdered = 0 + toAdd;
      }
    } else if (this.itemList[index].newQtyOrderedTotal < totalOrdered) {
      const tickLength = this.itemList[index].tickets.length;
      let qtyToRemove = totalOrdered - this.itemList[index].newQtyOrderedTotal;

      for (let i = tickLength - 1; i >= 0; i--) {
        if (
          this.itemList[index].tickets[i].newQtyOrdered !== 0 ||
          qtyToRemove !== 0
        ) {
          if (this.itemList[index].tickets[i].newQtyOrdered < qtyToRemove) {
            qtyToRemove =
              qtyToRemove - this.itemList[index].tickets[i].newQtyOrdered;
            this.itemList[index].tickets[i].newQtyOrdered = 0;
          } else {
            this.itemList[index].tickets[i].newQtyOrdered =
              this.itemList[index].tickets[i].newQtyOrdered - qtyToRemove;
            qtyToRemove = 0;
          }
        }
      }

      this.itemList[index].tickets.forEach(lst => {
        if (lst.newQtyShipped > lst.newQtyOrdered) {
          lst.newQtyShipped = lst.newQtyOrdered;
        }
      });
    }
  }

  minQtyOrdered(tickets: any) {
    const retItem = tickets;
    return retItem.reduce((min, p) =>
      p.newQtyOrdered < min ? p.newQtyOrdered : retItem[0].newQtyOrdered
    );
  }

  AddBackorderList() {
    const obj = {
      name: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: [],
    };
    this.itemList.push(obj);
  }

  RemoveBackorderList(index: number) {
    this.itemList.splice(index, 1);
  }

  pullMaterial() {
    if (this.workticket.length !== 10) {
      return;
    }

    if (this.itemCollection[this.workticket] != undefined) {
      this.itemCollection[this.workticket] = [];
    }

    this.isLoading = true;
    this.soNum = this.workticket.substring(0, 7);
    this.wtNum = this.workticket.substring(7);

    this.sageApi
      .pullReport(`UpdMatUsage?soNum=${this.soNum}&wtNum=${this.wtNum}`)
      .subscribe(
        (items: Array<any>) => {
          if (Array.isArray(items)) {
            items.forEach(item => {
              item.newQtyOrdered = item.QuantityOrdered;
              item.newQtyShipped = item.QuantityShipped;
              item.newCommentText = item.CommentText;
              item.loading = false;
              item.updated = false;
              item.edited = false;
              item.added = false;

              if (this.itemCollection[this.workticket] == undefined) {
                this.itemCollection[this.workticket] = [item];
              } else {
                this.itemCollection[this.workticket].push(item);
              }
            });
            this.serviceOrPunch();
            this.notShippedList();
          }
        },
        err => {
          this.isLoading = false;
          this.alertify.error(
            'Error loading ' + this.workticket + ': ' + err.error.Message
          );
          console.log(err);
        },
        () => {
          this.isLoading = false;
          this.createfilter();
        }
      );
  }

  updateMaterial(wtNumber: string) {
    this.matUpdated = false;
    this.isSubmitting = true;
    if (this.shipAll) {
      this.shipAllItems();
    } else {
      this.backorderShipAll();
    }
    if (
      this.itemCollection[wtNumber][this.itemCollection[wtNumber].length - 1]
        .ItemCode === ''
    ) {
      this.alertify.error('Error : Please Enter an ItemCode');
      return;
    }

    this.itemCollection[wtNumber][0].updated = true;
    this.itemCollection[wtNumber][0].loading = true;

    const soNum = wtNumber.substring(0, 7);
    const wtNum = wtNumber.substring(7);

    const observables = [];
    const editedLinesArr = [];
    const addedLinesArr = [];
    const endpoint = 'UpdMatUsage';

    this.itemCollection[wtNumber].forEach(item => {
      if (item.added && item.edited) {
        const arr = [
          item.ItemCode,
          item.newQtyOrdered,
          item.newQtyShipped,
          wtNum,
          item.JT158_WTParentLineKey,
          item.newCommentText,
        ];
        addedLinesArr.push(arr);
      } else if (item.edited) {
        const arr = [
          item.LineKey,
          item.newQtyOrdered,
          item.newQtyShipped,
          item.newCommentText,
        ];
        editedLinesArr.push(arr);
      }
    });

    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesOrderNo: soNum,
      wtNum,
      editedLines: editedLinesArr,
      addedLines: addedLinesArr,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));
    this.alertify.warning(`Updating shipped materials`);
    concat(...observables).subscribe(
      (response: any) => {
        if (response.Response.ReasonPhrase !== '') {
          this.alertify.message(
            'Successful with the following message ' +
              response.Response.ReasonPhrase,
            0
          );
          this.partsfailmessage = response.Response.ReasonPhrase.split(',');
        } else {
          this.alertify.success('Success');
        }
      },
      err => {
        this.itemCollection[wtNumber][0].loading = false;
        this.alertify.error('Error on ' + wtNumber + ': ' + err.error.Message);
        this.isSubmitting = false;
      },
      () => {
        this.resetTicket(wtNumber);
        this.alertify.success('Material Updated on ' + wtNumber);
        this.isSubmitting = false;
        this.matUpdated = true;
        this.submitOnce = true;

        this.pullMaterial();
      }
    );
  }

  QRupdateMaterial(wtNumber: string) {
    this.isSubmitting = true;
    if (this.shipAll) {
      this.shipAllItems();
    } else {
      this.backorderShipAll();
    }
    this.closeModal();
    if (
      this.itemCollection[wtNumber][this.itemCollection[wtNumber].length - 1]
        .ItemCode === ''
    ) {
      this.alertify.error('Error : Please Enter an ItemCode');
      return;
    }

    this.itemCollection[wtNumber][0].updated = true;
    this.itemCollection[wtNumber][0].loading = true;

    const soNum = wtNumber.substring(0, 7);
    const wtNum = wtNumber.substring(7);

    const observables = [];
    const editedLinesArr = [];
    const addedLinesArr = [];

    this.itemCollection[wtNumber].forEach(item => {
      if (item.added && item.edited) {
        const arr = [
          item.ItemCode,
          item.newQtyOrdered,
          item.newQtyShipped,
          wtNum,
          item.JT158_WTParentLineKey,
          item.newCommentText,
        ];
        addedLinesArr.push(arr);
      } else if (item.edited) {
        const arr = [
          item.LineKey,
          item.newQtyOrdered,
          item.newQtyShipped,
          item.newCommentText,
        ];
        editedLinesArr.push(arr);
      }
    });

    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesOrderNo: soNum,
      wtnumber: wtNum,
      notes: '',
      status: 'LW',
      toWhse: this.toWhseCheck ? 'Y' : 'N',
      editedLines: editedLinesArr,
      addedLines: addedLinesArr,
    };
    observables.push(this.sageApi.putRequest('QRCode/Update/LW', object));
    observables.push(this.sageApi.QRupdateMaterialUsage(object));
    this.alertify.warning(`Updating Workticket`);
    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        this.itemCollection[wtNumber][0].loading = false;
        this.alertify.error('Error on ' + wtNumber + ': ' + err.error.Message);
        this.isSubmitting = false;
      },
      () => {
        this.resetTicket(wtNumber);
        this.alertify.success('Ticket Updated: ' + wtNumber);
        this.isSubmitting = false;
        this.alertify.success(`Success!`);
        this.router.navigate(['/']);
      }
    );
  }

  resetTicket(wtNumber: string) {
    delete this.itemCollection[wtNumber];
  }

  imgoverride() {
    this.alertify.warning(
      'Skipping images. please make sure Pull Sheet is delivered to office marked as images not taken.'
    );
    this.imgUpdated = true;
    this.imgOverride = true;
  }
}
