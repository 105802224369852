import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { isBoolean } from 'ngx-bootstrap/chronos/utils/type-checks';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface WorkTicketCreate {
  NameFromQuote: '';
  WTName: '';
  WTNumber: '';
  Information: WorkTicketInformation[];
}
export interface WorkTicketInformation {
  ItemCode: '';
  QuantityOrdered: 0;
  WTStep: '';
  Location: '';
}
@Component({
  selector: 'app-address-creation',
  templateUrl: './address-creation.component.html',
  styleUrls: ['./address-creation.component.css'],
})
export class AddressCreationComponent implements OnInit {
  loading = false;
  modalTemplate: BsModalRef;
  address = {
    street: '',
    street2: '',
    city: '',
    zip: '',
    lot: '',
    block: '',
  };
  name = '';
  found = false;
  shipToAddressList = [];
  shipTo = {
    shipToStreet: '',
    salesOrder: '',
  };
  customerList = [];
  customer = {
    name: '',
    ARDivisionNo: '',
    CustomerNo: '',
    CustomerName: '',
  };
  subdivision = '';
  leadman = '';
  subdivisionList = [];
  warehouseList = [];
  warehouse = '';
  template = '';
  quoteList = [];
  selectedQuote = '';
  SelectedQuote = new FormControl('', []);
  searchOption = '';
  selectedQuoteInfo: WorkTicketCreate[] = [];

  files = [];
  @Output() resetFileList = new EventEmitter<boolean>();

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCustomers();
    this.getSubdivision();
    this.getWarehouses();
    this.getExistingShipTo();
    this.quotes();
  }

  getExistingShipTo() {
    this.sageApi
      .pullReport('SalesOrderEntry/ShipToAddress')
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const shipTo = {
              shipToStreet: row.ShipToAddress1,
              salesOrder: row.SalesOrderNo,
            };
            this.shipToAddressList.push(shipTo);
          });
        }
      });
    this.sageApi
      .pullReport('SalesOrderEntry/InProcess')
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const shipTo = {
              shipToStreet: row.Address1,
              salesOrder: 'In Progress',
            };
            this.shipToAddressList.push(shipTo);
          });
        }
      });
  }

  getCustomers() {
    this.customerList = [];
    this.sageApi
      .pullReport('SalesOrderEntry/Customers')
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const cust = {
              name:
                row.ARDivisionNo +
                '-' +
                row.CustomerNo +
                ' ' +
                row.CustomerName,
              ARDivisionNo: row.ARDivisionNo,
              CustomerNo: row.CustomerNo,
              CustomerName: row.CustomerName,
            };
            this.customerList.push(cust);
          });
        }
      });
  }
  selectCustomer(customerInfo) {
    this.clearCustomer();
    const cust = this.customerList.find(
      x =>
        x.ARDivisionNo == customerInfo.item.ARDivisionNo &&
        x.CustomerNo.toLowerCase() == customerInfo.item.CustomerNo.toLowerCase()
    );
    if (cust != undefined) {
      if (
        cust.name !== '' &&
        cust.ARDivisionNo !== '' &&
        cust.CustomerNo !== ''
      ) {
        this.customer = cust;
        this.customer.name = cust.name;
        this.customer.ARDivisionNo = cust.ARDivisionNo;
        this.customer.CustomerNo = cust.CustomerNo;
        this.customer.CustomerName = cust.CustomerName;
        this.name = cust.name;
      }
    } else {
      const attempt = this.customerList.find(
        x =>
          x.ARDivisionNo + '-' + x.CustomerNo.toLowerCase() ==
          customerInfo.value.toLowerCase()
      );

      this.customer = attempt;
    }
  }
  clearCustomer() {
    this.customer.name = '';
    this.customer.ARDivisionNo = '';
    this.customer.CustomerNo = '';
    this.customer.CustomerName = ' ';
  }

  getSubdivision() {
    this.sageApi.pullReport('SalesOrderEntry/Subdivisions').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            this.subdivisionList.push(row);
          });
        }
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  quotes() {
    this.sageApi
      .pullReport('SalesOrderEntry/GetQuotes')
      .subscribe((rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            this.quoteList.push(row);
          });
        }
      });
  }

  onKey() {
    return this.quoteList.filter(x =>
      x.QuoteName.toLowerCase().includes(this.searchOption.toLowerCase())
    );
  }

  onSubdivisionSelect(subdivision) {
    this.clearSubdivision();
    const sub = this.subdivisionList.find(
      x =>
        x.UDF_SUBDIVISION_CODE.toLowerCase() ==
        subdivision.item.UDF_SUBDIVISION_CODE.toLowerCase()
    );
    if (sub != undefined) {
      this.subdivision = sub.UDF_SUBDIVISION_CODE;
      this.leadman = sub.UDF_LEADMAN_CODE;
    } else {
      this.alertify.warning('Invalid subdivision selected.');
    }
  }
  clearSubdivision() {
    this.leadman = '';
    this.subdivision = '';
  }
  checkZip() {
    const checkZip = /^\d{5}(-\d{4})?$/.test(this.address.zip);

    if (checkZip == false) {
      this.alertify.warning('Please enter a valid zipcode.');
      this.address.zip = '';
    }
  }

  getWarehouses() {
    this.warehouseList = [
      {
        name: '001 - San Antonio',
        value: '001',
      },
      {
        name: '005 - Dallas',
        value: '005',
      },
    ];
  }
  warehouseSelect(warehouse) {
    this.warehouse = '';
    const wrhse = this.warehouseList.find(x => x.value == warehouse.item.value);
    if (wrhse != undefined) {
      this.warehouse = wrhse.value;
    } else {
      this.alertify.warning('Invalid warehouse selected.');
    }
  }
  checkForm() {
    if (
      this.template == '' ||
      this.address.street == '' ||
      this.address.zip == '' ||
      this.address.city == '' ||
      this.customer.name == '' ||
      this.warehouse == ''
    ) {
      return true;
    } else {
      return false;
    }
  }

  OnQuoteSelected(e: any) {
    this.template = '';
    this.template = e.QuoteId;
  }

  RemoveSpecialCharacters(addressLine) {
    let variable = this.address.street.replace(/[^a-zA-Z0-9]/g, ' ');
    variable = variable.trim();
    this.address.street = variable;
    return this.address.street;
  }

  createSalesOrder() {
    this.loading = true;
    const observables = [];
    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      ARDivisionNo: this.customer.ARDivisionNo,
      CustomerNo: this.customer.CustomerNo,
      warehouseCode: this.warehouse,
      address1: this.address.street,
      address2: this.address.street2,
      city: this.address.city,
      zipCode: this.address.zip,
      lot: this.address.lot,
      block: this.address.block,
      subdivision: this.subdivision,
      leadman: this.leadman,
      template: this.template,
    };
    const endpoint = 'SalesOrderEntry';

    observables.push(this.sageApi.putRequest(endpoint, object));

    concat(...observables).subscribe(
      (response: any) => {
        this.closeModal();
        this.alertify.success(response);
        if (this.files.length > 0) {
          this.fileUpload();
        }
      },
      err => {
        this.loading = false;
        this.clearForm();
        this.closeModal();
        this.alertify.error('Error creating house: ' + err.error.Message);
      },
      () => {
        this.clearForm();
        this.loading = false;
        this.closeModal();
      }
    );
  }

  clearForm() {
    this.address.street = '';
    this.address.street2 = '';
    this.address.city = '';
    this.address.zip = '';
    this.address.lot = '';
    this.address.block = '';
    this.subdivision = '';
    this.leadman = '';
    this.warehouse = '';
    this.name = '';
    this.clearCustomer();
    this.clearSubdivision();
    this.getCustomers();
    this.selectedQuote = '';
    this.selectedQuote = '';
    this.resetFileList.emit(true);
  }

  fileUpload() {
    this.loading = true;
    const route = `HouseEditor/file/upload?address=${this.address.street}`;
    const sentFiles = [];
    this.files.forEach(file => {
      sentFiles.push(file.data);
    });
    this.sageApi.uploadFile(route, sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        this.alertify.error('Error: ' + err.message);
        console.log(err);
      },
      () => {
        this.files = [];
        this.alertify.success('File Loaded');
        this.loading = false;
      }
    );
  }

  getFiles(event) {
    this.files = event;
    console.log(this.files);
  }

  openModal(template: TemplateRef<any>) {
    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      ARDivisionNo: this.customer.ARDivisionNo,
      CustomerNo: this.customer.CustomerNo,
      warehouseCode: this.warehouse,
      address1: this.address.street,
      address2: this.address.street2,
      city: this.address.city,
      zipCode: this.address.zip,
      lot: this.address.lot,
      block: this.address.block,
      subdivision: this.subdivision,
      leadman: this.leadman,
      template: this.template,
    };
    const found = this.shipToAddressList.find(
      x =>
        x.shipToStreet.trim().toUpperCase() == this.address.street.toUpperCase()
    );
    if (found == undefined) {
      this.found = false;
    } else {
      this.shipTo.salesOrder = found.salesOrder;
      this.shipTo.shipToStreet = found.shipToStreet;
      this.found = true;
    }
    this.modalTemplate = this.modalService.show(template);
  }
  closeModal() {
    this.modalTemplate.hide();
  }
}
