import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import TagDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/TagDtoInterface';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import GetColorFromString from 'src/app/utils/GetColorFromString';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';

interface EditablePartInterface {
  data: ContextPartDtoInterface;
  editable: boolean;
  selected: FormControl;
  partCodeControl: FormControl;
  partCostControl: FormControl;
  partDescControl: FormControl;
  partInactiveControl: FormControl;
  partTypeControl: FormControl;
  tagsControl: FormArray;
  tagPickerOpen: boolean;
}

interface LoadMoreCache {
  search: string;
  tags: string[];
  // allowEmptyTags: boolean;
  showInactive: boolean;
  searchDesc: boolean;
}

@Component({
  selector: 'app-partsview',
  templateUrl: './PartsViewComponent.html',
  styleUrls: ['./PartsViewComponent.css', '../QuotingTheme.scss'],
  providers: [MultiIsLoadingService],
})
export class PartsViewComponent {
  searchControl = new FormControl('');
  page = 0;
  size = 25;

  tags: TagDtoInterface[] = [];
  tagsLoading = false;
  tagSearchOptions: string[] = []; // TagNames
  tagsControl = new FormControl(this.tags);

  showInactive = new FormControl(false);
  searchDesc = new FormControl(false);

  parts: EditablePartInterface[] = [];
  showLoadMore = false;
  showNoResults = false;

  loadMoreCache: LoadMoreCache = {
    search: '',
    tags: [],
    showInactive: false,
    searchDesc: false,
  };

  constructor(
    public api: SageApiService,
    public searchLoading: MultiIsLoadingService,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public modals: BaseModalService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getAllTags();

    // Listen to see if the escape key is pressed and delselect all parts
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        this.parts.forEach(part => part.selected.setValue(false));
      }
    });
  }

  getAllTags() {
    this.tagsLoading = true;
    this.searchLoading
      .loadingUntilComplete(this.api.pullReport('tags'))
      .subscribe((tags: TagDtoInterface[]) => {
        this.tagsLoading = false;
        this.tags = tags.sort();
        this.tagSearchOptions = [
          'Empty',
          ...this.tags.map(tag => tag.Tag_Name),
        ].sort();
        this.tagsControl.setValue(
          ['Empty', ...this.tags.map(tag => tag.Tag_Name)].sort()
        );
      });
  }

  search() {
    this.parts = this.parts.filter(part => part.selected.value); // Remove all the unselected parts
    this.page = 0;

    const tagGuids = this.tagsControl.value
      .filter(tagName => tagName != 'Empty')
      .map(tagName => this.getTag({ tagName })?.Tag_guid);

    this.loadMoreCache = {
      search: this.searchControl.value,
      tags: tagGuids,
      showInactive: this.showInactive.value,
      searchDesc: this.searchDesc.value,
    };

    this.loadParts(this.page);
  }

  loadParts(page: number) {
    const queryParams = new URLSearchParams();
    queryParams.set('size', `${this.size}`);
    queryParams.set('offset', `${this.size * page}`);
    queryParams.set('Part_Code', this.loadMoreCache.search);
    queryParams.set('searchInactive', '' + this.loadMoreCache.showInactive);
    queryParams.set('searchDesc', '' + this.loadMoreCache.searchDesc);

    this.searchLoading
      .loadingUntilComplete(this.api.pullReport(`parts?${queryParams}`))
      .subscribe((parts: ContextPartDtoInterface[]) => {
        this.parts.push(...this.contextPartsToEditableParts(parts));
        this.showLoadMore = parts.length == this.size;
        this.showNoResults = this.parts.length == 0;
      });
  }

  contextPartsToEditableParts(
    parts: ContextPartDtoInterface[]
  ): EditablePartInterface[] {
    return parts.map(part => {
      return {
        data: part,
        editable: true,
        selected: new FormControl(false),
        partCodeControl: new FormControl(part.Part.Part_Code, [
          Validators.required,
          Validators.maxLength(30),
        ]),
        partCostControl: new FormControl(part.Part.Part_Cost, [
          Validators.required,
          Validators.min(0.0001),
          Validators.max(99999999.9999),
        ]),
        partDescControl: new FormControl(part.Part.Part_Desc, [
          Validators.min(0),
          Validators.maxLength(4096),
        ]),
        partInactiveControl: new FormControl(part.Part.Part_Inactive),
        partTypeControl: new FormControl(part.Part.Part_Type, [
          Validators.required,
          Validators.maxLength(30),
        ]),
        tagsControl: new FormArray(
          part.PartTags.map(tag => new FormControl(tag.Tag.Tag_Name))
        ),
        tagPickerOpen: false,
      };
    });
  }

  onTagsChange(e) {
    this.tagsControl.setValue(e.value);
  }

  selectedChanged(pIndex: number, selected: boolean) {
    this.parts[pIndex].selected.setValue(selected);
  }

  tagsControlsChanged(pIndex: number): boolean {
    // First, check if the lengths are the same
    if (
      this.parts[pIndex].tagsControl.value.length !=
      this.parts[pIndex].data.PartTags.length
    ) {
      return true;
    }
    // Next, check if the values are the same
    return !this.parts[pIndex].tagsControl.value
      .sort()
      .every(
        (tag, i) => tag == this.parts[pIndex].data.PartTags[i].Tag.Tag_Name
      );
  }

  codeControlInput(pIndex: number, e) {
    const target = e.target as HTMLInputElement;
    if (target.value.length > 30) {
      target.value = target.value.slice(0, 30);
      this.parts[pIndex].partCodeControl.setValue(target.value);
    } else {
      this.parts[pIndex].partCodeControl.setValue(target.value);
    }
  }

  costControlInput(pIndex: number, e) {
    const wholeValueMax = 8;
    const decimalValueMax = 4;

    const target = e.target as HTMLInputElement;

    // test is e.value has more than one period. If it does, remove all but the first one
    if (target.value.split('.').length > 2) {
      const splitByPeriod = target.value.split('.');
      const firstTwo = splitByPeriod.slice(0, 2).join('.');
      const newValue = [firstTwo, ...splitByPeriod.slice(2)].join('');

      target.value = newValue;
      this.parts[pIndex].partCostControl.setValue(newValue);

      const cursorPosStart = newValue.split('.')[0].length + 1;
      const cursorPosEnd = newValue.split('.')[0].length + 1;
      target.setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(() => {
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        target.setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    }

    // Test if e.value is a number
    if (!/^\d*\.?\d*$/.test(target.value)) {
      const arrVal = target.value.replace(/\D/g, '').split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if (arrVal.length >= 2) {
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      // First, get the cursor position in the input
      const cursorPosStart = target.selectionStart - 1;
      const cursorPosEnd = target.selectionEnd - 1;

      target.value = newValue;
      this.parts[pIndex].partCostControl.setValue(newValue);
      // Now, set the cursor position back to cursorPos
      target.setSelectionRange(cursorPosStart, cursorPosEnd);
      setTimeout(() => {
        // It wont get set if we dont do this, but we need the previous one so it doesnt jump to the end before this hits
        target.setSelectionRange(cursorPosStart, cursorPosEnd);
      }, 0);
    } else {
      const arrVal = this.parts[pIndex].partCostControl.value.split('.');
      arrVal[0] = arrVal[0].slice(0, wholeValueMax);
      if (arrVal.length >= 2) {
        arrVal[1] = arrVal[1].slice(0, decimalValueMax);
      }
      const newValue = arrVal.join('.');
      this.parts[pIndex].partCostControl.setValue(newValue);
    }
  }

  costControlBlur(pIndex: number, e) {
    if (`${this.parts[pIndex].partCostControl.value}`.trim() == '') {
      this.parts[pIndex].partCostControl.setValue(
        parseFloat('' + this.parts[pIndex].data.Part.Part_Cost)
      );
    } else {
      // Set the costControl value to the parseFloat of the value
      this.parts[pIndex].partCostControl.setValue(
        parseFloat(this.parts[pIndex].partCostControl.value)
      );
    }
  }

  getTag(params: { tagName: string } | { tagGuid: string }): TagDtoInterface {
    return this.tags.find(
      tag =>
        tag.Tag_Name == params['tagName'] || tag.Tag_guid == params['tagGuid']
    );
  }

  getAvailableTags(pIndex: number): string[] {
    return this.tags
      .map(tag => tag.Tag_Name)
      .filter(
        tagName => !this.parts[pIndex].tagsControl.value.includes(tagName)
      )
      .sort();
  }

  removeTag(pIndex: number, tag: string) {
    this.modals
      .confirm(
        `Remove ${tag}?`,
        `Really Remove ${tag} from ${this.parts[pIndex].partCodeControl.value}?`
      )
      .subscribe((result: boolean) => {
        if (result) {
          const index = this.parts[pIndex].tagsControl.controls.findIndex(
            t => t.value == tag
          );
          this.parts[pIndex].tagsControl.removeAt(index);
        }
      });
  }

  getTagColor(tagName?: string) {
    if (tagName) {
      return GetColorFromString(tagName);
    }
    return '#fff';
  }

  tagPickerControlChange(pIndex: number, e) {
    this.parts[pIndex].tagPickerOpen = !this.parts[pIndex].tagPickerOpen;
    const newVal = e.value;
    if (newVal.trim() != '') {
      this.parts[pIndex].tagsControl.push(new FormControl(newVal));
    }
  }

  areAnySelected() {
    return this.parts.some(part => part.selected.value);
  }

  areAllSelected() {
    return (
      this.parts.length > 0 && this.parts.every(part => part.selected.value)
    );
  }

  selectAll() {
    this.parts.forEach(part => part.selected.setValue(true));
  }

  selectAllChanged(checked: boolean) {
    this.parts.forEach(part => part.selected.setValue(checked));
  }

  deleteSelected() {
    const selectedParts = this.parts.filter(part => part.selected.value);

    const deleteThoseParts = () => {
      const partGuids = selectedParts.map(part => part.data.Part.Part_guid);
      const guidUrlParams = new URLSearchParams();
      for (const guid of partGuids) {
        guidUrlParams.append('Part_guids', guid);
      }
      this.searchLoading
        .loadingUntilComplete(
          this.api.deleteRequest(`parts?${guidUrlParams.toString()}`)
        )
        .subscribe(() => {
          this.parts = this.parts.filter(part => !part.selected.value);
          this.snackBar.open(`${selectedParts.length} parts deleted`, 'Close', {
            duration: Infinity,
          });
        });
    };

    const disclaimer =
      "Make sure you don't want to just set it to inactive instead. This action cannot be undone. These represent parts only used in quoting. Deleting these parts will not remove them from sage. All kits will lose this part permanently. All Quotes will convert these parts to custom parts.";

    if (selectedParts.length > 1) {
      this.modals
        .superConfirm(
          'Delete Parts?',
          `Really delete ${selectedParts.length} parts? ` + disclaimer,
          `permanently delete ${selectedParts.length} parts`
        )
        .subscribe((result: boolean) => {
          if (result) {
            deleteThoseParts();
          }
        });
    } else {
      const partToDelete = selectedParts[0];
      this.modals
        .superConfirm(
          'Delete Part?',
          `Really delete ${partToDelete.data.Part.Part_Code}? ` + disclaimer,
          `permanently delete ${partToDelete.data.Part.Part_Code}`
        )
        .subscribe((result: boolean) => {
          if (result) {
            deleteThoseParts();
          }
        });
    }
  }

  saveDisabled(pIndex: number): boolean {
    const part = this.parts[pIndex];
    // First, see if any of the controls are invalid
    if (
      this.parts[pIndex].partCodeControl.invalid ||
      this.parts[pIndex].partCostControl.invalid ||
      this.parts[pIndex].partDescControl.invalid ||
      this.parts[pIndex].partTypeControl.invalid
    ) {
      return true;
    }

    // Next, see if any of the controls dont match the original data
    if (
      this.parts[pIndex].partCodeControl.value ==
        this.parts[pIndex].data.Part.Part_Code &&
      this.parts[pIndex].partCostControl.value ==
        this.parts[pIndex].data.Part.Part_Cost &&
      this.parts[pIndex].partDescControl.value ==
        this.parts[pIndex].data.Part.Part_Desc &&
      this.parts[pIndex].partTypeControl.value ==
        this.parts[pIndex].data.Part.Part_Type &&
      this.parts[pIndex].partInactiveControl.value ==
        this.parts[pIndex].data.Part.Part_Inactive &&
      !this.tagsControlsChanged(pIndex)
    ) {
      return true;
    }

    return false;
  }

  cancelDisabled(pIndex: number): boolean {
    return (
      this.parts[pIndex].partCodeControl.value ==
        this.parts[pIndex].data.Part.Part_Code &&
      this.parts[pIndex].partCostControl.value ==
        this.parts[pIndex].data.Part.Part_Cost &&
      this.parts[pIndex].partDescControl.value ==
        this.parts[pIndex].data.Part.Part_Desc &&
      this.parts[pIndex].partTypeControl.value ==
        this.parts[pIndex].data.Part.Part_Type &&
      this.parts[pIndex].partInactiveControl.value ==
        this.parts[pIndex].data.Part.Part_Inactive &&
      !this.tagsControlsChanged(pIndex)
    );
  }

  saveChanges(pIndex: number) {
    const part = this.parts[pIndex];
    const partData = part.data.Part;
    const partTags = part.tagsControl.controls.map(tagControl => {
      return this.getTag({ tagName: tagControl.value }).Tag_guid;
    });

    const partToUpdate = {
      Part_guid: partData.Part_guid,
      Part_Code: part.partCodeControl.value,
      Part_Desc: part.partDescControl.value,
      Part_Cost: part.partCostControl.value,

      Part_Inactive: part.partInactiveControl.value == 'Y' ? 'Y' : 'N',
      Part_Type: part.partTypeControl.value,
      Tags: partTags,
    };

    this.searchLoading
      .loadingUntilComplete(this.api.patchRequest('parts', [partToUpdate]))
      .subscribe((updatedPart: ContextPartDtoInterface) => {
        this.parts[pIndex].data = updatedPart[0];
      });
  }

  cancelChanges(pIndex: number) {
    this.parts[pIndex].partCodeControl.setValue(
      this.parts[pIndex].data.Part.Part_Code
    );
    this.parts[pIndex].partCostControl.setValue(
      this.parts[pIndex].data.Part.Part_Cost
    );
    this.parts[pIndex].partDescControl.setValue(
      this.parts[pIndex].data.Part.Part_Desc
    );
    this.parts[pIndex].partInactiveControl.setValue(
      this.parts[pIndex].data.Part.Part_Inactive
    );
    this.parts[pIndex].partTypeControl.setValue(
      this.parts[pIndex].data.Part.Part_Type
    );
    this.parts[pIndex].tagsControl = new FormArray(
      this.parts[pIndex].data.PartTags.map(
        tag => new FormControl(tag.Tag.Tag_Name)
      )
    );
  }

  upsertPartsFromSage() {
    this.modals
      .superConfirm(
        'Upsert Parts?',
        `Really upsert parts from sage? Upsert, a combination of update and insert, will update existing parts and import new ones from Sage. This will drastically change the parts in kits, but leave quotes alone. The estimated time to complete this operation is 2-5 minutes. During that time, do not leave this page, do not edit any part information, and keep your computer on.`,
        `upsert parts from sage`
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.parts = [];
          this.tagsLoading = true;
          this.searchLoading
            .loadingUntilComplete(
              this.api.putRequest(`upsert-parts-from-sage`, undefined)
            )
            .subscribe(() => {
              this.tagsLoading = false;
              this.snackBar.open(`Upsert complete`, 'Close', {
                duration: Infinity,
              });
            });
        }
      });
  }
}
