<mat-spinner *ngIf="loading" class="loadingSpinner"></mat-spinner>
<div class="startingOut">
  <div class="routerButtons">
    <button color="accent" mat-flat-button routerLink="/quoting/questions">
      Go To Questions
    </button>
    <button color="accent" mat-flat-button routerLink="/quoting/quotes">
      Go To Quotes
    </button>
    <button color="accent" mat-flat-button routerLink="/quoting/kits">
      Go To Kits
    </button>
  </div>

  <div class="chart-container">
    <canvas id="BuilderChart" width="400" height="400"></canvas>
    <canvas id="newAttempt" width="400" height="400"></canvas>
    <canvas id="newDoughnut" width="400" height="400"></canvas>
  </div>
  <hr />
  <div [hidden]="loading" class="tableData">
    <mat-form-field>
      <mat-label>Filter Quotes</mat-label>
      <input
        matInput
        #input
        type="text"
        (keyup)="applyFilter($event)"
        placeholder="Search By Name or Description"
      />
    </mat-form-field>
    <div class="tableInfo">
      <table mat-table [dataSource]="dataSource" matSort class="tableInfo">
        <ng-container matColumnDef="QuoteName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">
            <a
              routerLink="/quoting/quote/{{ row.guid }}"
              title="Go to Quote"
              target="_blank"
              >{{ row.QuoteName }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="Desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let row">{{ row.Description }}</td>
        </ng-container>
        <ng-container matColumnDef="Builder">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Builder</th>
          <td mat-cell *matCellDef="let row">{{ row.Builder }}</td>
        </ng-container>
        <ng-container matColumnDef="TotalPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
          <td mat-cell *matCellDef="let row">
            ${{ row.TotalPrice.toFixed(2) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">{{ row.Status }}</td>
        </ng-container>
        <ng-container matColumnDef="Region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
          <td mat-cell *matCellDef="let row">{{ row.Region }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator
      #dataPaginator
      [pageSizeOptions]="[10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
