<div [class]="screenSize.getClasses() + ' womsmgt'">
  <h1 class="womsmgt-title">
    WOMs Management Queue
    <mat-spinner
      *ngIf="isLoading"
      diameter="35"
      class="womsmgt-title-spinner"
    ></mat-spinner>
  </h1>
  <div class="womsmgt-cntrls">
    <app-searchable-sel
      [selectedOption]="AmgtControl"
      [disabled]="isLoading || userrole == 'super' || userrole == 'superdfw'"
      [showEmpty]="false"
      (selectChanged)="AmgtControlChange()"
      placeholder="Area Manager"
      [options]="areaManagers"
      class="womsmgt-cntrls-item"
    ></app-searchable-sel>
    <app-searchable-sel
      [selectedOption]="superControl"
      [disabled]="
        AmgtControl.value == '' ||
        isLoading ||
        userrole == 'super' ||
        userrole == 'superdfw'
      "
      [showEmpty]="false"
      (selectChanged)="setTableData()"
      placeholder="Super"
      [options]="supers"
      class="womsmgt-cntrls-item"
    ></app-searchable-sel>
    <mat-form-field appearance="outline" class="womsmgt-cntrls-item">
      <mat-label>Ticket Status</mat-label>
      <mat-select
        [formControl]="ticketStatusControl"
        (selectionChange)="loadHistory()"
        [disabled]="
          AmgtControl.value == '' ||
          isLoading ||
          userrole == 'super' ||
          userrole == 'superdfw'
        "
        class=""
      >
        <mat-option value="1" class="">MGTRV/REVBC/PBCK</mat-option>
        <mat-option value="EPOI" class="">EPOI</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="womsmgt-cntrls-item">
      <mat-label>JT-WT Status</mat-label>
      <mat-select
        [formControl]="ticketJtWtStatusControl"
        (selectionChange)="setTableData()"
        [disabled]="
          AmgtControl.value == '' ||
          isLoading ||
          userrole == 'super' ||
          userrole == 'superdfw'
        "
        class=""
      >
        <mat-option value="All" class="">All</mat-option>
        <mat-option
          *ngFor="let status of ticketStatus"
          [value]="status"
          class=""
          >{{ status }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="womsmgt-cntrls-item">
      <mat-label>Ticket Type</mat-label>
      <mat-select
        [formControl]="ticketTypeControl"
        (selectionChange)="setTableData()"
        [disabled]="
          AmgtControl.value == '' ||
          isLoading ||
          userrole == 'super' ||
          userrole == 'superdfw'
        "
        class=""
      >
        <mat-option value="All" class="">All</mat-option>
        <mat-option value="REVBC" class="">Review Builder Charge</mat-option>
        <mat-option value="PBCK" class=""
          >Pending Backcharge (Actual)</mat-option
        >
        <mat-option value="Warranty" class="">Warranty</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <span class="text-danger"
      >**if a Super is missing from the Super filter, then the super does not
      have any active WOM tickets or has not been assigned to the selected Area
      Manager**</span
    >
  </div>
  <div class="womsmgt-data">
    <ng-container>
      <div
        *ngIf="
          !isLoading && tableData.data.length == 0 && AmgtControl.value != ''
        "
        class="womsmgt-data-ctn-empty"
      >
        <h2>No tickets found</h2>
      </div>
    </ng-container>
    <ng-container *ngIf="tableData.data.length > 0">
      <div class="womsmgt-data-ctn">
        <table
          mat-table
          [dataSource]="tableData"
          matSort
          class="womsmgt-data-ctn-table"
        >
          <ng-container matColumnDef="WT#">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              WT#
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell md"
            >
              {{ element.data.soNum }}-{{ element.data.soWT }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Address">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              Address
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell md"
            >
              {{ element.data.address }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Type">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              Type
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell sm"
            >
              {{ element.data.type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Description">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              Description
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell lg"
            >
              {{ element.data.descriptionOfWork }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Tech">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              Tech
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell md"
            >
              {{ element.data.technician }}
            </td>
          </ng-container>
          <ng-container matColumnDef="DIP">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              DIP
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell sm"
            >
              {{ element.data.DIP }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              JT-WT Status
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell sm"
            >
              {{ element.data.jtWTStatus }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Action">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="womsmgt-data-ctn-table-th womsmgt-data-ctn-table-cell"
            >
              Action
            </th>
            <td
              mat-cell
              *matCellDef="let element; let ticketIndex = index"
              [class.odd]="ticketIndex % 2 == 0"
              class="womsmgt-data-ctn-table-td womsmgt-data-ctn-table-cell sm"
            >
              <button
                (click)="editClick(element.index)"
                color="accent"
                mat-flat-button
              >
                Edit
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'WT#',
              'Address',
              'Type',
              'Description',
              'Tech',
              'DIP',
              'Status',
              'Action'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: [
                'WT#',
                'Address',
                'Type',
                'Description',
                'Tech',
                'DIP',
                'Status',
                'Action'
              ]
            "
          ></tr>
        </table>
      </div>
    </ng-container>
  </div>
</div>

<!-- *Edit Modal -->
<ng-template
  #editModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="dataModel.steps.length > 0">
      <div class="modal-header">
        <ng-container *ngIf="errorMsg !== ''">
          <div class="row">
            <div class="div">
              <h5 *ngIf="errorMsg !== ''" style="color: red">
                <img
                  src="../../../assets/importantYellow.png"
                  alt="a black exclamation point on a yellow background"
                  width="20"
                />
                Failed to submit: {{ errorMsg }} Please try again later.
              </h5>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col">
            <h3 class="modal-title" id="editor">
              <img
                *ngIf="isLoading"
                src="../../../../assets/ring-alt-colored.svg"
              />Editing {{ dataModel.steps[index].address }}
            </h3>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center"></div>
      </div>
      <p
        *ngIf="
          dataModel.steps[index].matNeededCheck === 'Y' &&
          dataModel.womsMaterial.length <= 0
        "
        style="padding-left: 5px; color: red; font-size: 1.5rem"
      >
        <img src="../../../assets/flag-16.png" alt="a red flag" />
        Material marked as needed but it has NOT been added to ticket!
      </p>
      <div class="modal-body">
        <div>
          <div class="row p-2 no-gutters">
            <div class="col-sm-6">
              <h4>Area Manager: {{ dataModel.steps[index].lmManager }}</h4>
              <h4>LM: {{ dataModel.steps[index].leadman }}</h4>
            </div>
            <div class="col-sm-6">
              <h4>Status: {{ dataModel.steps[index].jtWTStatus }}</h4>
            </div>
          </div>
        </div>
        <label for="typeNotes1"><b>Work Description</b></label>
        <div id="typeNotes1">
          <p class="lead ml-3">
            {{ dataModel.steps[index].descriptionOfWork }}
          </p>
        </div>
        <label for="typeNotes3"><b>Tech Comments</b></label>
        <div id="typeNotes3">
          <p class="lead ml-3">
            {{ dataModel.steps[index].techComment }}
          </p>
        </div>
        <label for="typeNotes2">Charge Reason</label>
        <blockquote>
          <textarea
            type="text"
            class="lead"
            id="typeNotes2"
            name="notes"
            [(ngModel)]="dataModel.steps[index].notes"
            style="width: 100%"
            rows="3"
          ></textarea>
        </blockquote>
        <hr />
        <ng-container
          *ngIf="dataModel.steps[index].UDF_MGT_LAST_STATUS == 'WAR'"
        >
          <label for="typeNotes3">Warranty Reason</label>
          <blockquote>
            <textarea
              type="text"
              class="lead"
              id="typeNotes3"
              name="notes"
              [(ngModel)]="dataModel.steps[index].UDF_MGT_LAST_COMMENT"
              style="width: 100%"
              rows="3"
            ></textarea>
          </blockquote>
          <!-- Warranty Codes -->
          <div class="form-group mt-1">
            <label>Warranty Codes</label>
            <app-searchable-sel
              class="app-searchable-front"
              [selectedOption]="AssigenWarrantyContrl"
              placeholder="Warranty Code"
              [disabled]="isLoading"
              [options]="punchWarLoadSelect"
              (selectChanged)="onWarrantyCodeSelected($event.value)"
            ></app-searchable-sel>
          </div>
        </ng-container>
        <h4 class="modal-headings">Phase Techs</h4>
        <form class="form-inline justify-content-center container-fluid">
          <div class="table-responsive w-auto">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Roughin</th>
                  <th>Vanguard</th>
                  <th>Top Out</th>
                  <th>Gas</th>
                  <th>Drop In</th>
                  <th>Trim</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">{{ dataModel.steps[index].riCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].vgCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].toCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].gasCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].diCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].trimCrew }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--            <hr />-->
          <div class="row mb-1 justify-content-center">
            <div class="col-12">
              <button
                type="button"
                *ngIf="dataModel.womsMaterial.length > 0"
                class="btn btn-primary btn-sm"
                (click)="showMaterial = !showMaterial"
                style="margin: 0 0 10px 220px"
              >
                {{ showMaterial ? 'Hide Material' : 'Show Material' }}
              </button>
            </div>
          </div>
          <div *ngIf="showMaterial">
            <h4 class="modal-headings">Material</h4>
            <div>
              <div
                class="table-responsive"
                *ngIf="dataModel.womsMaterial.length > 0"
              >
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Desc</th>
                      <th>Number</th>
                      <th>Quantity</th>
                      <th>Item Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let mat of dataModel.womsMaterial; let i = index"
                    >
                      <td scope="row">{{ mat.itemDesc }}</td>
                      <td scope="row">{{ mat.itemCode }}</td>
                      <td scope="row">{{ mat.quantity }}</td>
                      <td scope="row">${{ mat.itemCost.toFixed(3) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--            <div class="form-group">-->
          <ng-container
            *ngIf="dataModel.steps[index].UDF_MGT_LAST_STATUS != 'WAR'"
          >
            <div class="row m-2 justify-content-center">
              <div class="col-12">
                <label class="" for="currentType"><b>Type of Charge</b></label>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="currentType"
                    disabled
                    value="{{ dataModel.steps[index].type }}"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="sr-only" for="newType">Change Type</label>
                  <input
                    type="text"
                    class="form-control"
                    id="newType"
                    placeholder="New Type"
                    list="types"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dataModel.steps[index].newType"
                  />
                  <datalist id="types">
                    <option value="BUILDER"></option>
                    <option value="CREW"></option>
                    <option value="GIBSON"></option>
                    <option value="CANCEL"></option>
                  </datalist>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <div
              class="row"
              *ngIf="dataModel.steps[index].newType === 'CANCEL'"
            >
              <div class="col">
                <div class="margin-bottom-10">
                  <label for="reasonToCancel"><b>Reason to Cancel</b></label>
                  <textarea
                    [(ngModel)]="reasonToCancel"
                    class="form-control"
                    name="reasonToCancel"
                    id="reasonToCancel"
                    rows="2"
                    style="width: 400px"
                  ></textarea>
                </div>
              </div>
            </div>
            <ng-container *ngIf="dataModel.steps[index].workTime * 1 > 0">
              <div class="row justify-content-center mb-2">
                <div class="col justify-content-center">
                  <label for="timeToComplete"><b>Time To Complete</b></label>
                  <input
                    disabled
                    type="number"
                    name="timeToComplete"
                    class="form-control"
                    value="{{ dataModel.steps[index].workTime }}"
                  />
                </div>
              </div>
            </ng-container>
            <div class="row mb-2 justify-content-center">
              <div class="col-12 justify-content-center mb-1">
                <label for="punchPayAmount"><b>WT Billed Amount</b></label>
              </div>
              <!--          </div>-->
              <!--          <div class="row mb-2">-->
              <div class="col-6">
                <b>$</b
                ><input
                  disabled
                  type="number"
                  name="punchPayAmount"
                  class="form-control"
                  value="{{ dataModel.steps[index].billPayAmount }}"
                />
              </div>
              <!--            <div class="col-6">-->
              <!--              <label class="sr-only" for="newPunchPayAmount">Change Bill Amount</label>-->
              <!--              <div class="input-group">-->
              <!--                <div class="input-group-addon"><b>$</b>&nbsp;</div>-->
              <!--                <input-->
              <!--                  type="number"-->
              <!--                  name="newPunchPayAmount"-->
              <!--                  class="form-control"-->
              <!--                  (click)="$event.target.select()"-->
              <!--                  [(ngModel)]="dataModel.steps[index].newBillPayAmount"-->
              <!--                />-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="col-12 justify-content-center mb-1">
                <label for="punchPayAmount"><b>Pay Amount</b></label>
              </div>
              <!--          </div>-->
              <!--          <div class="row mb-2">-->
              <div class="col-6">
                <input
                  disabled
                  type="number"
                  name="punchPayAmount"
                  class="form-control"
                  value="{{ dataModel.steps[index].punchPayAmount }}"
                />
              </div>
              <div class="col-6">
                <label class="sr-only" for="newPunchPayAmount"
                  >Change Pay Amount</label
                >
                <div class="input-group">
                  <div class="input-group-addon"><b>$</b>&nbsp;</div>
                  <input
                    type="number"
                    name="newPunchPayAmount"
                    class="form-control"
                    (click)="$event.target.select()"
                    [(ngModel)]="dataModel.steps[index].newPunchPayAmount"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <button
                  class="btn btn-sm btn-primary mr-2"
                  (click)="loadPayLineHist(dataModel.steps[index].soNum)"
                >
                  {{
                    payLinesToggle
                      ? 'Hide Line Items'
                      : 'Show Previous Pay Items'
                  }}
                </button>
                <!--                  <button-->
                <!--                    class="btn btn-sm btn-info"-->
                <!--                    (click)="-->
                <!--                  loadImagesS3(-->
                <!--                    dataModel.steps[index].address,-->
                <!--                    dataModel.steps[index].punchImageKey-->
                <!--                  )-->
                <!--                "-->
                <!--                  >-->
                <!--                    Load Images-->
                <!--                  </button>-->
              </div>
            </div>
            <ng-container *ngIf="payLinesToggle">
              <div class="row mb-2">
                <div class="col">
                  <div class="table-responsive w-auto" *ngIf="payLinesToggle">
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Description</th>
                          <th>Price</th>
                          <th>Tech</th>
                          <th>Lead</th>
                          <th>PayDate</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let line of payLines">
                          <tr>
                            <td>{{ line.itemCode }}</td>
                            <td>{{ line.description }}</td>
                            <td>{{ line.price }}</td>
                            <td>{{ line.techName }}</td>
                            <td>{{ line.prtLeadName }}</td>
                            <td>{{ line.payDate | date }}</td>
                          </tr>
                          <tr *ngIf="line.comment != ''">
                            <td colspan="6">
                              <strong>Comment:</strong> {{ line.comment }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--            <div class="row mb-2">-->
            <!--              <div class="col">-->
            <!--                  <img-->
            <!--                    *ngFor="let img of imgArr; let i = index"-->
            <!--                    class="navbar-buttons"-->
            <!--                    width="25%"-->
            <!--                    height="25%"-->
            <!--                    src="{{ img }}"-->
            <!--                    (click)="onBlowUpImage([img])"-->
            <!--                  />-->
            <!--              </div>-->
            <!--            </div>-->
            <ng-container
              *ngIf="
                !(
                  dataModel.steps[index].prtDetails.prt === 'N' &&
                  dataModel.steps[index].subVendor === ''
                ) ||
                (dataModel.steps[index].UDF_MGT_LAST_STATUS === 'WAR' &&
                  dataModel.steps[index].subVendor !== '')
              "
            >
              <div class="row">
                <div class="col-sm-6">
                  <label class="form-check-label" for="payTech">
                    Pay {{ dataModel.steps[index].technician }} ${{
                      dataModel.steps[index].newPunchPayAmount
                    }}?
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="payTech"
                    name="payTech"
                    [(ngModel)]="dataModel.steps[index].payTech"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-check-label ml-3" for="payTrip">
                    Pay Trip Charge of ${{ dataModel.steps[index].techTrip }}?
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="payTrip"
                    name="payTrip"
                    [(ngModel)]="dataModel.steps[index].payTrip"
                  />
                </div>
              </div>
              <div class="row" *ngIf="dataModel.steps[index].helper !== ''">
                The helpers techcode is {{ dataModel.steps[index].helper }}
              </div>
              <div
                class="row"
                *ngIf="
                  dataModel.steps[index].prtDetails.prt === 'Y' &&
                  (dataModel.steps[index].payTrip ||
                    dataModel.steps[index].payTech)
                "
              >
                <div class="form-group" style="width: 100%">
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <label for="crews" class="" style="min-width: 80px"
                      >Crew Lead</label
                    >
                  </div>
                  <div class="col-xs-6">
                    <label for="crews" class="" style="min-width: 80px"
                      >Percent</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div
                    class="col-xs-6 margin-bottom-10"
                    style="padding-left: 0"
                  >
                    <input
                      list="techNames"
                      type="text"
                      class="form-control"
                      id="CrewLead"
                      [(ngModel)]="dataModel.steps[index].prtDetails.crewLead"
                      [ngModelOptions]="{ standalone: true }"
                      style="max-width: 100%"
                      (change)="onPRTSelected($event.target.value, '1', index)"
                    />
                  </div>
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <div class="input-group">
                      <input
                        type="type"
                        class="form-control"
                        id="crewLeadSplit"
                        [(ngModel)]="
                          dataModel.steps[index].prtDetails.crewLeadSplit
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <div class="input-group-addon">%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  dataModel.steps[index].prtDetails.prt === 'Y' &&
                  (dataModel.steps[index].payTrip ||
                    dataModel.steps[index].payTech)
                "
              >
                <div class="form-group" style="width: 100%">
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <label for="crews" class="" style="min-width: 80px"
                      >Crew 1</label
                    >
                  </div>
                  <div class="col-xs-6">
                    <label for="crews" class="" style="min-width: 80px"
                      >Percent</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div
                    class="col-xs-6 margin-bottom-10"
                    style="padding-left: 0"
                  >
                    <input
                      list="techNames"
                      type="text"
                      class="form-control"
                      id="Crew1"
                      [(ngModel)]="dataModel.steps[index].prtDetails.crew1"
                      [ngModelOptions]="{ standalone: true }"
                      style="max-width: 100%"
                      (change)="onPRTSelected($event.target.value, '2', index)"
                    />
                  </div>
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <div class="input-group">
                      <input
                        type="type"
                        class="form-control"
                        id="crew1Split"
                        [(ngModel)]="
                          dataModel.steps[index].prtDetails.crew1Split
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <div class="input-group-addon">%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  dataModel.steps[index].prtDetails.prt === 'Y' &&
                  (dataModel.steps[index].payTrip ||
                    dataModel.steps[index].payTech)
                "
              >
                <div class="form-group" style="width: 100%">
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <label for="crews" class="" style="min-width: 80px"
                      >Crew Lead</label
                    >
                  </div>
                  <div class="col-xs-6">
                    <label for="crews" class="" style="min-width: 80px"
                      >Percent</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div
                    class="col-xs-6 margin-bottom-10"
                    style="padding-left: 0"
                  >
                    <input
                      list="techNames"
                      type="text"
                      class="form-control"
                      id="Crew2"
                      [(ngModel)]="dataModel.steps[index].prtDetails.crew2"
                      [ngModelOptions]="{ standalone: true }"
                      style="max-width: 100%"
                      (change)="onPRTSelected($event.target.value, '3', index)"
                    />
                  </div>
                  <div
                    class="col-xs-6"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <div class="input-group">
                      <input
                        type="type"
                        class="form-control"
                        id="crew2Split"
                        [(ngModel)]="
                          dataModel.steps[index].prtDetails.crew2Split
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <div class="input-group-addon">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--            </div>-->
          </ng-container>
        </form>
        <form
          class="form-inline"
          *ngIf="
            (dataModel.steps[index].type == 'CREW' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'CREW'
          "
        >
          <!--            <hr/>-->
          <div class="row mt-2 mb-1">
            <div class="col form-group">
              <label class="mr-2" for="currentAmount"
                ><b>Back Charge Amount</b></label
              >
              <input
                type="text"
                class="form-control"
                style="width: 50%"
                id="currentAmount"
                disabled
                value="{{ dataModel.steps[index].amount }}"
              />
            </div>
          </div>
          <div class="row mt-2 mb-1">
            <div class="col form-group">
              <label class="mr-2" for="newAmount"><b>Change Amount</b></label>
              <div class="input-group">
                <div class="input-group-addon"><b>$</b>&nbsp;</div>
                <input
                  type="text"
                  class="form-control"
                  id="newAmount"
                  placeholder="new amount"
                  name="newAmount"
                  [(ngModel)]="dataModel.steps[index].newAmount"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="clearfix" style="margin-top: 20px"></div>
        <form
          class="form-inline"
          *ngIf="
            (dataModel.steps[index].type == 'BUILDER' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'BUILDER'
          "
        >
          <div class="row justify-content-center mb-1">
            <div class="col text-center">
              <label class="" for="builderInfo"> <b>Builder Info</b></label>
            </div>
          </div>
          <div class="row form-group mb-2">
            <div class="col-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderInfo"
                  placeholder="Enter Builder Name"
                  name="builderName"
                  [(ngModel)]="dataModel.steps[index].builderName"
                />
              </div>
            </div>
            <div class="col-6">
              <label class="sr-only" for="builderPhone" style="min-width: 120px"
                >Builder Phone</label
              >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderPhone"
                  placeholder="Enter Builder Email"
                  name="BuilderPhone"
                  [(ngModel)]="dataModel.steps[index].builderPhone"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-1">
            <div class="col text-center">
              <label class="" for="AdditionalInfo"
                ><b>Additional Info</b></label
              >
            </div>
          </div>
          <div class="row form-group">
            <div class="col-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="AdditionalInfo"
                  disabled
                  value="{{ dataModel.steps[index].builderCompany }}"
                />
              </div>
            </div>
            <div class="col-6">
              <label
                class="sr-only"
                for="builderSubdivision"
                style="min-width: 120px"
                >Builder Subdivision</label
              >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderSubdivision"
                  disabled
                  value="{{ dataModel.steps[index].builderSubdivision }}"
                />
              </div>
            </div>
          </div>
        </form>
        <form
          class="form-inline"
          *ngIf="
            (dataModel.steps[index].type == 'CREW' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'CREW'
          "
        >
          <div class="row justify-content-center" style="width: 100%">
            <h6><b>Existing Pay/BackCharge Lines</b></h6>
            <div class="table-responsive w-auto">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Tech Code</th>
                    <th>Tech Name</th>
                    <th>Cost</th>
                    <th>Pay Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payline of dataModel.steps[index].womsLabor">
                    <td scope="row">{{ payline.itemCode }}</td>
                    <td scope="row">{{ payline.techNo }}</td>
                    <td scope="row">{{ payline.techName }}</td>
                    <td scope="row">${{ payline.unitCost }}</td>
                    <td scope="row">
                      {{ payline.payDate | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row form-group mb-2 womsmgt-crewbc-rw">
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl">Add Crews</label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <mat-label>Tech Name</mat-label>
                <mat-select
                  standalone
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="dataModel.steps[index].crew1"
                >
                  <input
                    standalone
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="bcselinTechSearch"
                    type="text"
                    placeholder="Tech Name"
                    class="womsmgt-bc-ff-c1sel-in"
                  />
                  <mat-option
                    *ngFor="let name of filterLoadtech(bcselinTechSearch)"
                    value="{{ name.techCode }}"
                    [style]="{ height: 'auto' }"
                  >
                    <strong>
                      {{ name.techCode }} - {{ name.firstName }}
                      {{ name.lastName }}
                    </strong>
                    <div [style]="{ marginTop: '-20px' }">
                      {{ name.firstName }} {{ name.lastName }}
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl">Percent</label>
              <div class="input-group">
                <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                  <input
                    type="text"
                    id="crewPercent1"
                    [(ngModel)]="dataModel.steps[index].crewPercentage1"
                    [ngModelOptions]="{ standalone: true }"
                    matInput
                    class="womsmgt-bc-ff-prcnt"
                  />
                  <span matSuffix class="womsmgt-bc-ff-iprcnt">%</span>
                </mat-form-field>
                <div class="input-group-addon"></div>
              </div>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl"> PayPeriods </label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <mat-select
                  name="weeksPay1"
                  id="weeksPay1"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay1.payPeriods"
                >
                  <mat-option selected value="1">1 Week</mat-option>
                  <mat-option value="2">2 Weeks</mat-option>
                  <mat-option value="3">3 Weeks</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col">
              <label class="womsmgt-bc-lbl"> StartDate </label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <input
                  id="scheduleDate1"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay1.startDate"
                  [ngModelOptions]="{ standalone: true }"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <ng-container *ngIf="displayAdditionalChargeLines">
            <div class="row form-group mb-2">
              <div class="col-3 justify-content-center pl-0">
                <input
                  list="techNames"
                  type="text"
                  class="form-control-sm"
                  id="chargeCrew2"
                  [(ngModel)]="dataModel.steps[index].crew2"
                  [ngModelOptions]="{ standalone: true }"
                  style="max-width: 100%"
                  (change)="onTechSelected($event.target.value, '2', index)"
                />
              </div>
              <div class="col-2 justify-content-center px-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control-sm"
                    id="crewPercent2"
                    style="width: 50%"
                    [(ngModel)]="dataModel.steps[index].crewPercentage2"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <div class="input-group-addon">%</div>
                </div>
              </div>
              <div class="col-2 justify-content-center">
                <select
                  name="weeksPay2"
                  id="weeksPay2"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay2.payPeriods"
                >
                  <option selected value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                </select>
              </div>
              <div class="col-2">
                <input
                  id="scheduleDate2"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay2.startDate"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="row form-group mb-2">
              <div class="col-3 justify-content-center pl-0">
                <input
                  list="techNames"
                  type="text"
                  class="form-control-sm"
                  id="chargeCrew3"
                  [(ngModel)]="dataModel.steps[index].crew3"
                  [ngModelOptions]="{ standalone: true }"
                  style="max-width: 100%"
                  (change)="onTechSelected($event.target.value, '3', index)"
                />
              </div>
              <div class="col-2 justify-content-center px-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control-sm"
                    id="crewPercent3"
                    style="width: 50%"
                    [(ngModel)]="dataModel.steps[index].crewPercentage3"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <div class="input-group-addon">%</div>
                </div>
              </div>
              <div class="col-2 justify-content-center">
                <select
                  name="weeksPay3"
                  id="weeksPay3"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay3.payPeriods"
                >
                  <option selected value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                </select>
              </div>
              <div class="col-2">
                <input
                  id="scheduleDate3"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay3.startDate"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </ng-container>
        </form>
        <datalist id="techNames">
          <select class="form-control" id="Techname">
            <option disabled>--Select--</option>
            <option
              *ngFor="let name of dataModel.loadtech; let techIndex = index"
              value="{{ name.techCode }} - {{ name.firstName }} {{
                name.lastName
              }} - {{ techIndex }}"
            >
              {{ name.firstName }} {{ name.lastName }}
            </option>
          </select>
        </datalist>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="
            dataModel.steps[index].type == 'CREW' ||
            dataModel.steps[index].newType == 'CREW'
          "
          (click)="displayAdditionalChargeLines = !displayAdditionalChargeLines"
          type="button"
          class="btn btn-primary btn-sm pull-left"
        >
          {{
            displayAdditionalChargeLines
              ? 'Hide Charge Lines'
              : 'Add Charge Lines'
          }}
        </button>
        <button type="button" class="btn btn-default" (click)="actionClose()">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="isLoading"
          (click)="saveChanges(index)"
        >
          <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
            *ngIf="!isLoading"
            >Update & Approve</span
          >
        </button>
      </div>
    </div>
  </div>
  <!--  </div>-->
</ng-template>

<!-- *Modal BackCharge-->
<ng-template
  #BackchargeModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="dataModel.steps.length > 0">
      <div class="modal-header">
        <ng-container *ngIf="errorMsg !== ''">
          <div class="row">
            <div class="div">
              <h5 *ngIf="errorMsg !== ''" style="color: red">
                <img
                  src="../../../assets/importantYellow.png"
                  alt="a black exclamation point on a yellow background"
                  width="20"
                />
                Failed to submit: {{ errorMsg }} Please try again later.
              </h5>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col">
            <h3 class="modal-title" id="editor">
              <img
                *ngIf="isLoading"
                src="../../../../assets/ring-alt-colored.svg"
              />Editing {{ dataModel.steps[index].address }}
            </h3>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center"></div>
      </div>
      <div class="container">
        <h4>
          <b>Area Manager: {{ dataModel.steps[index].lmManager }}</b>
        </h4>
        <h4>
          <b>LM: {{ dataModel.steps[index].leadman }}</b>
        </h4>
        <h5>
          <b>WOMs Status: {{ dataModel.steps[index].currentStatus }}</b>
        </h5>
      </div>
      <div class="modal-body">
        <label for="typeNotes3"><b>Builder BackCharge Reason</b></label>
        <div id="typeNotes3">
          <p class="lead ml-3">
            {{ dataModel.steps[index].backChargeComment }}
          </p>
        </div>
        <label for="typeNotes3"><b>Leadman Explanation</b></label>
        <blockquote>
          <textarea
            type="text"
            class="lead ml-3"
            id="typeNotes"
            name="notes"
            [(ngModel)]="dataModel.steps[index].notes"
            style="width: 90%"
            rows="3"
          ></textarea>
        </blockquote>
        <hr />
        <form class="form-inline justify-content-center container-fluid">
          <div class="table-responsive w-auto">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Roughin</th>
                  <th>Vanguard</th>
                  <th>Top Out</th>
                  <th>Gas</th>
                  <th>Drop In</th>
                  <th>Trim</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">{{ dataModel.steps[index].riCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].vgCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].toCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].gasCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].diCrew }}</td>
                  <td scope="row">{{ dataModel.steps[index].trimCrew }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div
              class="table-responsive"
              *ngIf="dataModel.womsMaterial.length > 0"
            >
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Desc</th>
                    <th>Number</th>
                    <th>Quantity</th>
                    <th>Item Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let mat of dataModel.womsMaterial; let i = index">
                    <td scope="row">{{ mat.itemDesc }}</td>
                    <td scope="row">{{ mat.itemCode }}</td>
                    <td scope="row">{{ mat.quantity }}</td>
                    <td scope="row">${{ mat.itemCost.toFixed(3) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center mb-2">
            <!--              <div class="row" style="text-align: center;">-->
            <div class="col">
              <label class="lead" for="backChargeAmount"
                ><b>Builder BackCharge Amount: $</b
                >{{ dataModel.steps[index].backChargeAmount * -1 }}</label
              >
            </div>
          </div>
          <div
            class="row justify-content-center mb-2"
            *ngIf="
              userrole != 'super' &&
              userrole != 'superdfw' &&
              dataModel.steps[index].currentStatus !== 'PBCK'
            "
          >
            <!-- Backcharge Codes -->
            <div class="form-group mt-1">
              <div class="col-12">
                <label><b>Backcharge Reason Code</b></label>
              </div>
              <div class="col-12">
                <app-searchable-sel
                  class="app-searchable-front"
                  [selectedOption]="AssigenBackchargeContrl"
                  placeholder="Backcharge Code"
                  [disabled]="isLoading"
                  [options]="punchBackLoadSelect"
                  (selectChanged)="onBackchargeCodeSelected($event.value)"
                ></app-searchable-sel>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-2">
            <div class="col-12">
              <label class="" for="currentType-bc"><b>Action</b></label>
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="currentType-bc"
                disabled
                value="{{ dataModel.steps[index].type }}"
              />
            </div>
            <div class="col-6">
              <label class="sr-only" for="newType-bc">Change Type</label>
              <input
                type="text"
                class="form-control"
                id="newType-bc"
                placeholder="New Type"
                list="typesBC"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dataModel.steps[index].newType"
              />
              <datalist id="typesBC">
                <option value="DISPUTE">Dispute the Charge</option>
                <option value="CREW">Charge a Crew</option>
                <option value="GIBSON">Gibson Takes Loss</option>
                <option value="MAN_DEFECT">Manufacturer Defect</option>
              </datalist>
            </div>
          </div>
          <!--            </div>-->
        </form>
        <form
          class=""
          *ngIf="
            (dataModel.steps[index].type == 'CREW' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'CREW'
          "
        >
          <div class="row justify-content-center" style="width: 100%">
            <h6><b>Existing Pay/BackCharge Lines</b></h6>
            <div class="table-responsive w-auto">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Tech Code</th>
                    <th>Tech Name</th>
                    <th>Cost</th>
                    <th>Pay Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payline of dataModel.steps[index].womsLabor">
                    <td scope="row">{{ payline.itemCode }}</td>
                    <td scope="row">{{ payline.techNo }}</td>
                    <td scope="row">{{ payline.techName }}</td>
                    <td scope="row">${{ payline.unitCost }}</td>
                    <td scope="row">
                      {{ payline.payDate | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center mb-1">
            <div class="col text-center">
              <label class="" for="currentAmount"><b>Amount</b></label>
            </div>
          </div>
          <div class="row justify-content-center mb-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="currentAmount-bc"
                disabled
                value="{{ dataModel.steps[index].amount }}"
              />
            </div>
            <div class="col-6">
              <!--              <label class="sr-only" for="newAmount">Change Amount</label>-->
              <div class="input-group">
                <div class="input-group-addon"><b>$</b></div>
                <input
                  type="text"
                  class="form-control"
                  id="newAmount-bc"
                  placeholder="new amount"
                  name="newAmount"
                  [(ngModel)]="dataModel.steps[index].newAmount"
                />
              </div>
            </div>
          </div>
        </form>
        <!--        <div class="clearfix" style="margin-top: 20px;"></div>-->
        <form
          class="form-inline"
          *ngIf="
            (dataModel.steps[index].type == 'BUILDER' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'BUILDER'
          "
        >
          <div class="row justify-content-center mb-1">
            <div class="col text-center">
              <label class="" for="builderInfo"> <b>Builder Info</b></label>
            </div>
          </div>
          <div class="row form-group mb-2">
            <div class="col-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderInfo"
                  placeholder="Enter Builder Name"
                  name="builderName"
                  [(ngModel)]="dataModel.steps[index].builderName"
                />
              </div>
            </div>
            <div class="col-6">
              <label class="sr-only" for="builderPhone" style="min-width: 120px"
                >Builder Phone</label
              >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderPhone"
                  placeholder="Enter Builder Email"
                  name="BuilderPhone"
                  [(ngModel)]="dataModel.steps[index].builderPhone"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-1">
            <div class="col text-center">
              <label class="" for="AdditionalInfo"
                ><b>Additional Info</b></label
              >
            </div>
          </div>
          <div class="row form-group">
            <div class="col-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="AdditionalInfo"
                  disabled
                  value="{{ dataModel.steps[index].builderCompany }}"
                />
              </div>
            </div>
            <div class="col-6">
              <label
                class="sr-only"
                for="builderSubdivision"
                style="min-width: 120px"
                >Builder Subdivision</label
              >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="BuilderSubdivision"
                  disabled
                  value="{{ dataModel.steps[index].builderSubdivision }}"
                />
              </div>
            </div>
          </div>
        </form>
        <form
          class="form-inline"
          *ngIf="
            (dataModel.steps[index].type == 'CREW' &&
              dataModel.steps[index].newType == '') ||
            dataModel.steps[index].newType == 'CREW'
          "
        >
          <div class="row form-group mb-2 womsmgt-crewbc-rw">
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl">Add Crews</label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <mat-label>Tech Name</mat-label>
                <mat-select
                  standalone
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="dataModel.steps[index].crew1"
                >
                  <input
                    standalone
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="bcselinTechSearch"
                    type="text"
                    placeholder="Tech Name"
                    class="womsmgt-bc-ff-c1sel-in"
                  />
                  <mat-option
                    *ngFor="let name of filterLoadtech(bcselinTechSearch)"
                    value="{{ name.techCode }}"
                    [style]="{ height: 'auto' }"
                  >
                    <strong>
                      {{ name.techCode }} - {{ name.firstName }}
                      {{ name.lastName }}
                    </strong>
                    <div [style]="{ marginTop: '-20px' }">
                      {{ name.firstName }} {{ name.lastName }}
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl">Percent</label>
              <div class="input-group">
                <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                  <input
                    type="text"
                    id="crewPercent1"
                    [(ngModel)]="dataModel.steps[index].crewPercentage1"
                    [ngModelOptions]="{ standalone: true }"
                    matInput
                    class="womsmgt-bc-ff-prcnt"
                  />
                  <span matSuffix class="womsmgt-bc-ff-iprcnt">%</span>
                </mat-form-field>
                <div class="input-group-addon"></div>
              </div>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col justify-content-center">
              <label class="womsmgt-bc-lbl"> PayPeriods </label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <mat-select
                  name="weeksPay1"
                  id="weeksPay1"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay1.payPeriods"
                >
                  <mat-option selected value="1">1 Week</mat-option>
                  <mat-option value="2">2 Weeks</mat-option>
                  <mat-option value="3">3 Weeks</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 womsmgt-crewbc-rw-col">
              <label class="womsmgt-bc-lbl"> StartDate </label>
              <mat-form-field appearance="outline" class="womsmgt-bc-ff">
                <input
                  id="scheduleDate1"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay1.startDate"
                  [ngModelOptions]="{ standalone: true }"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <ng-container *ngIf="displayAdditionalChargeLines">
            <div class="row form-group mb-2">
              <div class="col-3 justify-content-center pl-0">
                <input
                  list="techNames"
                  type="text"
                  class="form-control-sm"
                  id="chargeCrew2"
                  [(ngModel)]="dataModel.steps[index].crew2"
                  [ngModelOptions]="{ standalone: true }"
                  style="max-width: 100%"
                  (change)="onTechSelected($event.target.value, '2', index)"
                />
              </div>
              <div class="col-2 justify-content-center px-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control-sm"
                    id="crewPercent2"
                    style="width: 50%"
                    [(ngModel)]="dataModel.steps[index].crewPercentage2"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <div class="input-group-addon">%</div>
                </div>
              </div>
              <div class="col-2 justify-content-center">
                <select
                  name="weeksPay2"
                  id="weeksPay2"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay2.payPeriods"
                >
                  <option selected value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                </select>
              </div>
              <div class="col-2">
                <input
                  id="scheduleDate2"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay2.startDate"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="row form-group mb-2">
              <div class="col-3 justify-content-center pl-0">
                <input
                  list="techNames"
                  type="text"
                  class="form-control-sm"
                  id="chargeCrew3"
                  [(ngModel)]="dataModel.steps[index].crew3"
                  [ngModelOptions]="{ standalone: true }"
                  style="max-width: 100%"
                  (change)="onTechSelected($event.target.value, '3', index)"
                />
              </div>
              <div class="col-2 justify-content-center px-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control-sm"
                    id="crewPercent3"
                    style="width: 50%"
                    [(ngModel)]="dataModel.steps[index].crewPercentage3"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <div class="input-group-addon">%</div>
                </div>
              </div>
              <div class="col-2 justify-content-center">
                <select
                  name="weeksPay3"
                  id="weeksPay3"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay3.payPeriods"
                >
                  <option selected value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                </select>
              </div>
              <div class="col-2">
                <input
                  id="scheduleDate3"
                  type="date"
                  class="form-control-sm"
                  [(ngModel)]="dataModel.steps[index].crewPay3.startDate"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </ng-container>
        </form>
        <datalist id="techNames">
          <select class="form-control" id="Techname">
            <option disabled>--Select--</option>
            <option
              *ngFor="let name of dataModel.loadtech"
              value="{{ name.techCode }} - {{ name.firstName }} {{
                name.lastName
              }}"
            >
              {{ name.firstName }} {{ name.lastName }}
            </option>
          </select>
        </datalist>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="
            dataModel.steps[index].type == 'CREW' ||
            dataModel.steps[index].newType == 'CREW'
          "
          (click)="displayAdditionalChargeLines = !displayAdditionalChargeLines"
          type="button"
          class="btn btn-primary btn-sm pull-left"
        >
          {{
            displayAdditionalChargeLines
              ? 'Hide Charge Lines'
              : 'Add Charge Lines'
          }}
        </button>
        <button type="button" class="btn btn-default" (click)="actionClose()">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="isLoading"
          (click)="saveChanges(index)"
        >
          <img *ngIf="isLoading" src="assets/ring-alt.svg" /><span
            *ngIf="!isLoading"
            >{{
              dataModel.steps[index].itemCode == 'ZBACKCHARGE'
                ? 'Submit'
                : dataModel.steps[index].newAmount !== '' ||
                  dataModel.steps[index].newType !== '' ||
                  dataModel.steps[index].crew1 !== '' ||
                  dataModel.steps[index].crew2 !== '' ||
                  dataModel.steps[index].crew3 !== '' ||
                  dataModel.steps[index].newTime !== ''
                ? 'Update & Approve'
                : 'Approve'
            }}</span
          >
        </button>
      </div>
    </div>
  </div>
  <!--  </div>-->
</ng-template>

<!-- Image Modal-->
<ng-template
  #ImageModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Image</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="imageModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img class="img-responsive" src="{{ imgUrl }}" />
      </div>
    </div>
  </div>
  <!--  </div>-->
</ng-template>
