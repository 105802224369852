import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { SalesOrder } from './../../../_services/sageApi/interfaces/pullReport/SalesOrderLookup';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-accounting-validation',
  templateUrl: './accounting-validation.component.html',
  styleUrls: ['./accounting-validation.component.css'],
})
export class AccountingValidationComponent implements OnInit {
  @ViewChild('MessageNo') MessageNoFocus;
  Textarea = new FormGroup({
    Textarea: new FormControl('', [Validators.required]),
  });

  EditForm = new FormGroup({
    MessageNo: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
    ]),
    SalesOrderNo: new FormControl('', [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(7),
    ]),
    WTNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
    ]),
    WTStep: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
    ]),
  });
  totalList = [];
  sending: boolean;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService
  ) {}

  ngOnInit(): void {
    this.sending = false;
  }

  submitFix(): void {
    this.sending = true;
    this.sageApi.putRequest('AccountingValidation', this.totalList).subscribe(
      (response: any) => {
        console.log(response);
        this.alertify.success('Successfully submitted fix');
      },
      err => {
        this.alertify.error(err.error.Message);
        this.sending = false;
        console.log(err);
      },
      () => {
        this.sending = false;
        this.totalList = [];
      }
    );
  }
  removeRow(item) {
    const index = this.totalList.indexOf(item);
    this.totalList.splice(index, 1);
  }

  parseTextArea() {
    const textarray = this.Textarea.value.Textarea.split(/^/gm);
    textarray.forEach(element => {
      const ob = element.split(' ');
      const workticket = ob[2].split('-');
      const itemsToSend = {
        MessageNo: ob[0],
        SalesOrderNo: workticket[0],
        WTNumber: workticket[1],
        WTStep: workticket[2],
      };
      const find = this.totalList.find(
        x =>
          x.MessageNo == itemsToSend.MessageNo &&
          x.SalesOrderNo == itemsToSend.SalesOrderNo &&
          x.WTNumber == itemsToSend.WTNumber &&
          x.WTStep == itemsToSend.WTStep
      );
      if (
        itemsToSend.WTNumber != undefined &&
        itemsToSend.MessageNo != undefined &&
        itemsToSend.SalesOrderNo != undefined &&
        itemsToSend.WTStep != undefined
      ) {
        if (find == undefined) {
          this.totalList.push(itemsToSend);
        }
      }
    });
    this.Textarea.reset();
  }
  AddToFix() {
    const obj = {
      MessageNo: this.EditForm.value.MessageNo,
      SalesOrderNo: this.EditForm.value.SalesOrderNo,
      WTNumber: this.EditForm.value.WTNumber,
      WTStep: this.EditForm.value.WTStep,
    };
    this.totalList.push(obj);
    this.EditForm.reset();
    this.MessageNoFocus.nativeElement.focus();
  }
}
